<mat-card>
  <mat-card-header>
    <mat-card-title>
      <h2>{{ 'dataManagement.versions.title' | translate }}</h2>
    </mat-card-title>
  </mat-card-header>
  <div class="outer-container">
    <div *ngFor="let dependency of displayedDependencyList" id="dependency-div">
      <div><strong>{{ dependency.name }}</strong> ({{ 'dataManagement.versions.version' | translate }} {{ dependency.version}}) <a href="{{ dependency.link }}"
          target="_blank">{{ dependency.link }}</a></div>
      <div>{{ 'dataManagement.versions.license' | translate }}: <a href="{{ dependency.license }}" target="_blank">{{ dependency.license }}</a></div>
    </div>
  </div>
</mat-card>
