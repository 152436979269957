import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiMaterialTableComponent } from '../../shared-components/multi-material-table/multi-material-table.component';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';
import { CreateUpdateHandler } from 'src/app/services/packaging-services/create-update-handler';
import { MaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-composite-material-dialog',
  templateUrl: './composite-material-dialog.component.html',
  styleUrls: ['./composite-material-dialog.component.scss']
})
export class CompositeMaterialDialogComponent {

  @ViewChild(MultiMaterialTableComponent) private materialsTable!: MultiMaterialTableComponent;

  canEditForm = true;

  materialForm: FormGroup;
  compositeMaterial: MultiMaterialCompositeDto;
  private selectedMaterials: MultiMaterialLayerDto[] = [];
  allMaterialFunctions: MaterialFunctionDto[] = [];
  callerId: number;
  packagingUnitTypeId: number;
  selectedManufacturingCountry = '';

  constructor(
    private dialogRef: MatDialogRef<CompositeMaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: {
      compositeMaterial: MultiMaterialCompositeDto, canEditForm: boolean
    },
    private formBuilder: FormBuilder,
    protected createUpdateHandler: CreateUpdateHandler
  ) {
    this.compositeMaterial = this.data.compositeMaterial;
    this.materialForm = this.formBuilder.group({
      multiMaterialControl: [this.compositeMaterial, Validators.required]
    });
    this.canEditForm = this.data.canEditForm;
    this.callerId = PackagingComponentTypesEnum.MainBody;
    this.packagingUnitTypeId = 1;
    this.allMaterialFunctions = this.createUpdateHandler.getMaterialFunctions(this.packagingUnitTypeId, this.callerId);
  }

  isFormValid() {
    this.materialForm.markAllAsTouched();
    this.materialForm.updateValueAndValidity();
    const materialsValid = this.materialsTable.areMaterialLayersValid();
    return this.materialForm.valid && materialsValid;
  }

  doAction() {
    this.materialForm.markAllAsTouched();

    const isFormValid = this.isFormValid();

    if (!isFormValid) { return; }
    const returnMaterial: MultiMaterialCompositeDto = this.materialForm.controls.multiMaterialControl.value;
    returnMaterial.layers = this.selectedMaterials;
    let index = 0;
    returnMaterial.layers.forEach(x => x.index = index++);
    returnMaterial.subMultiMaterials = [];
    this.dialogRef.close({
      event: DialogActions.CONFIRM, data: returnMaterial
    });
  }

  onMaterialLayersSelected(selected: MultiMaterialLayerDto[]) {
    this.selectedMaterials = selected;
  }

  onManufacturingCountryChanged(countryCode: string) {
    this.selectedManufacturingCountry = countryCode;
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
