import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecyclingStreamTranslationsDto } from 'src/app/data-transfer/entities/evaluation-entities/recycling-stream-dto';
import { RecyclabilityResultComponent } from 'src/app/model/evaluations/recyclability-result-component';
import { RatingsHandler } from 'src/app/util/analyses-util/ratings-handler';
import { ComponentsTableColumn } from '../packaging-unit-components-table/packaging-unit-components-table.component';
import { ComponentMaterialsTableData } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { RowSpanComputer, Span } from 'src/app/util/analyses-util/row-span-computer';

@Component({
  selector: 'app-component-materials-table',
  templateUrl: './component-materials-table.component.html',
  styleUrls: ['./component-materials-table.component.scss']
})
export class ComponentMaterialsTableComponent implements OnChanges {
  @Input() dataSource!: RecyclabilityResultComponent;
  @Input() public recyclingStreams: RecyclingStreamTranslationsDto[] = [];

  dataColumnsMaterials: ComponentsTableColumn[] = [];
  displayedColumnsMaterials: string[] = [];
  rowSpansMaterialsTable: Array<Span[]> = [];
  materialsTableDataSource: ComponentMaterialsTableData[] = [];

  constructor(
    private translateService: TranslateService,
    private ratingsHandler: RatingsHandler
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue != null) {
      this.getDataSourceForVersion();
    }
  }

  private getDataSourceForVersion() {
    this.materialsTableDataSource = this.getMaterialsTableData(this.dataSource);
    this.dataColumnsMaterials = this.getMaterialsTableColumns();
    this.displayedColumnsMaterials = this.dataColumnsMaterials.map(x => x.columnDef);
    this.rowSpansMaterialsTable = RowSpanComputer.computeMaterials(this.materialsTableDataSource, this.displayedColumnsMaterials);
  }

  private getMaterialsTableData(dataSource: RecyclabilityResultComponent): ComponentMaterialsTableData[] {
    const result: ComponentMaterialsTableData[] = [];
    for (const material of dataSource.materialResults) {
      const currentLang = this.translateService.store.currentLang;
      const recyclingStreamTranslations = this.recyclingStreams.find(x => x.id === material.recyclingStreamId);
      const recyclingStream = recyclingStreamTranslations?.translations.find(x =>
        x.languageCode === currentLang &&
        x.countryCode === dataSource.evaluatedCountryCode)?.translatedName ??
        recyclingStreamTranslations?.fallbackName ?? '';
      result.push({
        index: material.analyzedMaterial.index ?? 0,
        material: material.analyzedMaterial.functionName +
          ' (' + material.analyzedMaterial.materialManifestationName + ')',
        color: material.analyzedMaterial.colorName ?? '',
        mass: material.totalWeight,
        rating: this.ratingsHandler.getRatingNameFromValue(material.rating),
        recyclingStream
      });

    }
    return result;
  }

  private getMaterialsTableColumns(): ComponentsTableColumn[] {
    const dataColumns: ComponentsTableColumn[] = [];
    const resultTableData = new ComponentMaterialsTableData();
    const columnNames = Object.keys(resultTableData);
    columnNames.shift();
    for (const columnName of columnNames) {
      dataColumns.push({
        columnDef: columnName,
        header: this.translateService.instant('analysis.recyclabilityAnalysis.tableColumnsUnits.' + columnName),
        cell: (element: ComponentMaterialsTableData) => `${element[columnName as keyof ComponentMaterialsTableData]}`,
        color: (element: ComponentMaterialsTableData) => `${this.ratingsHandler.getRatingCellColorRecyclability(element.rating)}`,
        fontColor: (element: ComponentMaterialsTableData) => `${this.ratingsHandler.getRatingFontColorRecyclability(element.rating)}`,
        tooltip: (element: ComponentMaterialsTableData) => `${this.ratingsHandler.getRatingTooltip(this.translateService, element.rating)}`,
        recyclingStreamTooltip: () => `${this.translateService.instant('analysis.recyclabilityAnalysis.missingRecyclingStream')}`
      });
    }
    return dataColumns;
  }
}
