<app-component-html-template [packagingComponent]="decoration" [form]="form" [componentSubtypes]="componentSubtypes"
  [callerId]="callerId" [isDecoration]="true" [isPreview]="isPreview" [isTracked]="isTracked"
  [isFormLocked]="isFormLocked" [historyVersions]="historyVersions" [spinnerActive]="spinnerActive"
  [canEditForm]="canEditForm" [isUserValidator]="isUserValidator" [isEditPermitted]="isEditPermitted"
  [imageSource]="imageSource" [componentTypeSelectionLabel]="'component.fields.ofDecoration' | translate"
  [changedMaterialsInfo]="changedMaterialsInfo" (submitted)="onComponentSubmit($event)"
  (decorationSubtypeChanged)="onDecorationTypeChanged($event)"
  (historicVersionClicked)="loadHistoryVersions(decoration.id)"
  (historicVersionChanged)="onHistoryVersionChange($event)" (versionRenamed)="changeDecorationVersionName($event)"
  (materialChangeHandled)="handleMaterialChange($event)">

  <h1 header>{{ 'component.decoration' | translate }}</h1>

  <div image>
    <app-image-upload [canEditForm]="canEditForm" [images]="decoration.images" (filesEdited)="onFilesEdited()">
    </app-image-upload>
  </div>

  <div pdf>
    <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="decoration.documents" (filesEdited)="onFilesEdited()">
    </app-pdf-upload>
  </div>

</app-component-html-template>