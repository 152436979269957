import { Component, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ClientDto } from 'src/app/data-transfer/entities/client-dto';
import { FileUploadComponent } from '../../shared-components/upload/file-upload/file-upload.component';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../simple-alert-dialog/simple-alert-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { CreateOrganizationSettingsDto } from 'src/app/data-transfer/entities/create-organization-settings-dto';
import { OrganizationDto } from 'src/app/data-transfer/entities/organization-dto';
import { UserCreationRowErrorDto } from 'src/app/data-transfer/entities/user-creation-row-error-dto';
import { ReportTemplateDto } from 'src/app/data-transfer/entities/report-template-dto';
import { ContractPackageDto } from 'src/app/data-transfer/entities/contract-package-dto';

@Component({
  selector: 'app-bulk-user-upload-dialog',
  templateUrl: './bulk-user-upload-dialog.component.html',
  styleUrls: ['./bulk-user-upload-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      }
    } }
  ]
})

export class BulkUserUploadDialogComponent extends FileUploadComponent implements OnDestroy {
  excelFile: File | null = null;

  creditsCount : CreditsDto = new CreditsDto();
  existingOrganizationsNames: Set<string>;
  maxPremiumUserCount: number | null = null;

  spinnerActive: boolean = false;
  createButtonDisabled: boolean = true;
  changePasswordsButtonDisabled: boolean = true;
  allClients: ClientDto[] = [];
  allContractPackages: ContractPackageDto[] = [];
  allOrganizations: OrganizationDto[] = [];
  allPackagingUnitReportTemplates: ReportTemplateDto[] = [];
  allPackagingComponentReportTemplates: ReportTemplateDto[] = [];

  selectedParentOrganizationId: number | null = null;
  selectedContractPackages: ContractPackageDto[] = [];
  selectedAllowedClients: ClientDto[] = [];
  selectedVisibleOrganizations: OrganizationDto[] = [];
  selectedPackagingUnitReportTemplate: ReportTemplateDto | null = null;
  selectedPackagingComponentReportTemplate: ReportTemplateDto | null = null;

  constructor(
    private dialogRef: MatDialogRef<BulkUserUploadDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: any,
    protected dialog: MatDialog,
    private translateService: TranslateService,
    private fileApiService: FileApiService
  ) {
    super(dialog);
    this.allClients = data.allClients;
    this.allOrganizations = data.allOrganizations;
    this.allContractPackages = data.allContractPackages;
    this.allPackagingUnitReportTemplates = data.allPackagingUnitReportTemplates;
    this.allPackagingComponentReportTemplates = data.allPackagingComponentReportTemplates;

    this.existingOrganizationsNames = new Set<string>(data.existingOrganizationsNames);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    throw new Error('Method not implemented.');
  }

  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  openExcelFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const excelFile = target.files[0];
      if (!excelFile) { return; }
      const fileType = excelFile.type;
      if (fileType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') === null) {
        const dialogData = this.getInvalidFormatDialogData(excelFile.name);
        this.showDialog(dialogData);
        return;
      }
      this.excelFile = excelFile;
    }

    this.refreshButtonDisabled();
  }

  private getErrorDialogData(errors: UserCreationRowErrorDto[]): SimpleDialogData {
    const errorMessages = [this.translateService.instant('dataManagement.manageOrganizations.bulkUpload.excelValidationErrorMessage')];
    const rowStr = this.translateService.instant('dataManagement.manageOrganizations.bulkUpload.row');
    const columnStr = this.translateService.instant('dataManagement.manageOrganizations.bulkUpload.column');

    for (const error of errors){
      let errorMessage = "• ";
      const containsRow = error.row >= 0;
      const containsColumn = error.column.trim() != "";

      if (containsRow) {
        errorMessage += rowStr + ": " + error.row;

        if (containsColumn) {
          errorMessage += ", ";
        }
      }

      if (containsColumn) {
        errorMessage += columnStr + ": " + error.column;
      }

      if (containsRow || containsColumn) {
        errorMessage += ": ";
      }

      errorMessage += error.description;

      errorMessages.push(errorMessage);
    }

    return {
      title: this.translateService.instant('common.text.error'),
      messages: errorMessages,
      icon: 'error'
    };
  }

  private getInvalidFormatDialogData(fileName: string): SimpleDialogData {
    return {
      title: fileName,
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  refreshButtonDisabled() {
    this.createButtonDisabled = this.spinnerActive ||  this.excelFile == null || this.selectedPackagingUnitReportTemplate == null ||
                                this.selectedPackagingComponentReportTemplate == null;
    this.changePasswordsButtonDisabled = this.spinnerActive || this.excelFile == null;
  }

  uploadCreate() {
    if (!this.excelFile) { return; }

    const settings: CreateOrganizationSettingsDto =  {
      allowedClientIds: this.selectedAllowedClients.map(x => x.id),
      visibleOrganizationIds: this.selectedVisibleOrganizations.map(x => x.id),
      packagingUnitReportTemplateId: this.selectedPackagingUnitReportTemplate?.id ?? -1,
      packagingComponentReportTemplateId: this.selectedPackagingComponentReportTemplate?.id ?? -1,
      initialCredits: this.creditsCount,
      contractPackageIds: this.selectedContractPackages.map(x => x.contractPackageId),
      parentOrganizationId: this.selectedParentOrganizationId,
      maxPremiumUserCount: this.maxPremiumUserCount
    };

    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'file');
    formData.append("settings", JSON.stringify(settings));

    this.spinnerActive = true;

    this.excelFile = null;

    this.fileApiService.putExcelUsers(formData, true).subscribe({
      next: excelFile => {
        const excelResultUrl = window.URL.createObjectURL(excelFile);
        window.open(excelResultUrl, '_blank');
        this.spinnerActive = false;
        this.refreshButtonDisabled();
      },
      error: e => {
        this.handleError(e);
      }
    });
  }

  private handleError(error: any) {
      if (Array.isArray(error)) {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getErrorDialogData(error as UserCreationRowErrorDto[])));
      } else {
        const errorMessages = [this.translateService.instant('dataManagement.manageOrganizations.bulkUpload.errorMessage')];

        if (error.status) {
          errorMessages.push("Status: " + error.status + " " + error.statusText);
          errorMessages.push("Error messages: " + error.message);
        }

        const data: SimpleDialogData = {
          title: this.translateService.instant('common.text.error'),
          messages: errorMessages, icon: 'error'
        };

        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
      }

      this.spinnerActive = false;
      this.refreshButtonDisabled();
  }

  uploadChangePasswords() {
    if (!this.excelFile) { return; }

    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'file');

    this.spinnerActive = true;

    this.excelFile = null;

    this.fileApiService.changePasswordsExcel(formData, true).subscribe({
      next: excelFile => {
        const excelResultUrl = window.URL.createObjectURL(excelFile);
        window.open(excelResultUrl, '_blank');
        this.spinnerActive = false;
        this.refreshButtonDisabled();
      },
      error: e => {
        this.handleError(e);
      }
    });
  }

  organizationDisplayFn(organization: OrganizationDto): string {
    return organization.name;
  }

  contractPackagingDisplayFn(contractPackage: ContractPackageDto): string {
    return contractPackage.name;
  }

  clientDisplayFn(client: ClientDto): string {
    return client.name;
  }

  reportTemplateDisplayFn(template: ReportTemplateDto): string {
    return template.name;
  }
}
