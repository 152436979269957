import { getDialogConfig } from 'src/app/util/dialog-util';
import { Component, Input, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserDto } from 'src/app/data-transfer/entities/user-dto';
import { UserApiService } from 'src/app/data-transfer/services/user-api-service';
import { AuthService } from 'src/app/services/auth-service';
import { ChangePasswordDialogComponent, PasswordDialogData } from '../../dialogs/change-password-dialog/change-password-dialog.component';
import { DeleteDialogData, DeleteItemDialogComponent } from '../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SimpleConfirmDialogComponent } from '../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';
import { ChangePasswordDto } from 'src/app/data-transfer/entities/change-password-dto';
import { ResetPasswordDto } from 'src/app/data-transfer/entities/reset-password-dto';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnDestroy {

  @ViewChild('usersTableSort') usersTableSort!: MatSort;
  @ViewChild('delUsersTableSort') delUsersTableSort!: MatSort;
  @ViewChild(MatSort) set matSort(_: any) { this.setSortForSubtables(); }

  @Input() usersDataSource: MatTableDataSource<UserDto>;
  @Input() deletedUsersDataSource: MatTableDataSource<UserDto>;
  @Input() usersColumns: string[] = [];

  isAdmin: boolean;

  private dialogSubscription?: Subscription;
  private deleteSubscription?: Subscription;
  private restoreSubscription?: Subscription;
  private passwordSubscription?: Subscription;

  sortingDataAccessor = (item: any, property: any) => {
    switch (property) {
      case 'organization': return item.organization.name;
      default: return item[property];
    }
  }

  constructor(
    private dialog: MatDialog,
    private userApiService: UserApiService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.usersDataSource = new MatTableDataSource<UserDto>();
    this.deletedUsersDataSource = new MatTableDataSource<UserDto>();
    this.isAdmin = this.authService.isUserAdmin();
  }

  setSortForSubtables() {
    this.usersDataSource.sort = this.usersTableSort;
    this.deletedUsersDataSource.sort = this.delUsersTableSort;
    this.usersDataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.deletedUsersDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  deleteUser(userToDelete: UserDto) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('dataManagement.userData.texts.removeUser.title', { name: userToDelete.name }),
      dialogText: this.translateService.instant('dataManagement.userData.texts.removeUser.confirmation')
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT || userToDelete.id == null) {
        return;
      }
      this.deleteSubscription = this.userApiService.deleteUser(userToDelete.id, true).subscribe({
        next: _ => {
          userToDelete.isDeleted = true;
          const deletedUserIndex = this.usersDataSource.data.findIndex(x => x.id === userToDelete.id);
          if (deletedUserIndex !== -1) {
            this.usersDataSource.data.splice(deletedUserIndex, 1);
            this.deletedUsersDataSource.data.push(userToDelete);
            this.usersDataSource._updateChangeSubscription();
            this.deletedUsersDataSource._updateChangeSubscription();
          }
        },
        error: e => {
          this.handleError(e.error);
        },
      });
    });
  }

  restoreUser(userToRestore: UserDto) {
    const dialogData: SimpleDialogData = {
      title: this.translateService.instant('dataManagement.userData.texts.restoreUser.title', { name: userToRestore.name }),
      messages: [this.translateService.instant('dataManagement.userData.texts.restoreUser.confirmation')], icon: 'info'
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.restoreSubscription = this.userApiService.restoreUser(userToRestore.id ?? -1, true).subscribe({
        next: _ => {
          userToRestore.isDeleted = false;
          const restoredUserIndex = this.deletedUsersDataSource.data.findIndex(x => x.id === userToRestore.id);
          if (restoredUserIndex !== -1) {
            this.deletedUsersDataSource.data.splice(restoredUserIndex, 1);
            this.usersDataSource.data.push(userToRestore);
            this.usersDataSource._updateChangeSubscription();
            this.deletedUsersDataSource._updateChangeSubscription();
          }
        },
        error: e => {
          this.handleError(e.error);
        },
      });
    });
  }

  resetPassword(user: UserDto) {
    if (user.id == null) { return; }
    const data = new PasswordDialogData(user.id, user.name, this.isAdmin);
    const dialogConfig = getDialogConfig(data, '600px');
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, dialogConfig);

    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.passwordSubscription = this.isAdmin ? this._resetPassword(result.data) : this._changePassword(result.data);
    });
  }
  private _changePassword(change : ChangePasswordDto) : Subscription {
    return this.userApiService.changePassword(change, true).subscribe({
      next: response => {
        console.log('Response is: ', response);
      },
      error: e => {
        this.handleError(e.error);
      },
    });
  }

  private _resetPassword(change : ResetPasswordDto) : Subscription {
    return this.userApiService.resetPassword(change, true).subscribe({
      next: response => {
        console.log('Response is: ', response);
      },
      error: e => {
        this.handleError(e.error);
      },
    });
  }

  handleError(error: any) {
    const dialogData: SimpleDialogData = { title: 'Error', messages: [error], icon: 'error' };
    const alertDialogConfig = getDialogConfig(dialogData);
    this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }

  onTogglePremiumUser(user: UserDto, isChecked: boolean){
    if (user.id) {
      user.isPremiumUser = isChecked;
    }
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
    this.restoreSubscription?.unsubscribe();
    this.passwordSubscription?.unsubscribe();
  }
}
