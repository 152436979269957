import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export interface DeleteDialogData {
  dialogHeader: string;
  dialogText: string;
}

@Component({
  selector: 'app-delete-item-dialog',
  templateUrl: './delete-item-dialog.component.html',
  styleUrls: ['./delete-item-dialog.component.scss']
})
export class DeleteItemDialogComponent {

  dialogHeader: string;
  dialogText: string;

  constructor(
    public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: DeleteDialogData
  ) {
    this.dialogHeader = this.data.dialogHeader;
    this.dialogText = this.data.dialogText;
  }

  deleteAction() {
    this.dialogRef.close({ event: DialogActions.DELETE });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
