<h1 mat-dialog-title>{{ targetOrganization.name }} (ID {{targetOrganization.id}})</h1>

<mat-dialog-content>
  <div id="visibility-label">
    {{ 'dataManagement.manageOrganizations.messages.visibilityLabel' | translate: {orgName: targetOrganization.name, orgId: targetOrganization.id} }}
  </div>
  <div id="button-container">
    <button (click)="selectAll()" mat-stroked-button type="button" color="accent" class="select-button">
      Select all
    </button>
    <button (click)="selectNone()" mat-stroked-button type="button" color="accent" class="select-button">
      Select none
    </button>
  </div>
  <div class="scrolling-wrapper">
    <!-- <mat-selection-list #list [(ngModel)]="selectedOrganizationsIds">
      <mat-list-option *ngFor="let organization of allOrganizations" [value]="organization.id">
        {{ organization.name }}
      </mat-list-option>
    </mat-selection-list> -->
    
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="acceptSelection()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.ok' | translate }}
  </button>
  <button (click)="discardSelection()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
