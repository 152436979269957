import { CompositeMaterialExportProfileDto } from '../material-entities/composite-material-export-profile-dto';
import { ExportProfileComponentParent, ExportProfileDocuments, ExportProfileImages, setValues } from '../packaging-unit-entities/packaging-unit-export-profile-dto';

export class PackagingComponentExportProfileDto extends ExportProfileComponentParent {
  id = -1;
  profileName = '';
  owningOrganizationId = -1;
  owningOrganizationName = '';
  isDefaultProfile = false;
  isRigidComponent = false;
  distributionCountries = false;
  multiMaterial!: CompositeMaterialExportProfileDto;
  images!: ExportProfileImages;
  documents!: ExportProfileDocuments;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    this.images = new ExportProfileImages(defaultBooleanValue);
    this.documents = new ExportProfileDocuments(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
    this.isDefaultProfile = false;
  }
}
