import { ImportHtmlComponent } from './../import-html/import-html.component';
import { CreditsService } from './../../../../../services/credits-service';
import { PackagingComponentTypesEnum } from './../../../../../model/packaging-component-types-enum';
import { ComponentTypeService } from './../../../../../navigation/services/component-type-service';
import { ComponentNavigationService } from 'src/app/navigation/services/navigation-services/component-navigation-service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { ImportExportApiService } from '../../../../../data-transfer/services/import-export-api-service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeComponentCandidateDto } from '../../../../../data-transfer/entities/component-entities/change-component-candidate-dto';
import { ImportComponentCandidateDto } from '../../../../../data-transfer/entities/component-entities/import-component-candidate-dto';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChangeType, ImportParentComponent, IMPORT_COST } from '../import-parent/import-parent.component';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-import-component',
  templateUrl: './import-component.component.html',
  styleUrls: ['../import-packaging-unit/import-packaging-unit.component.scss']
})
export class ImportComponentComponent extends ImportParentComponent implements OnInit {

  @ViewChild('importHtml') importHtmlComponent!: ImportHtmlComponent;
  @ViewChild('changeHtml') changeHtmlComponent!: ImportHtmlComponent;

  selectedCandidates: ImportComponentCandidateDto[] = [];
  selectedChanges: ChangeComponentCandidateDto[] = [];

  displayedColumns: string[] = [
    'itemId', 'exportingOrganization', 'packagingComponentCategoryName', 'packagingComponentSubtypeName',
    'articleName', 'articleNumber', 'action', 'select'
  ];

  constructor(
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected importExportApiService: ImportExportApiService,
    protected creditsService: CreditsService,
    private componentNavigationService: ComponentNavigationService,
    private componentTypeService: ComponentTypeService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, route, translateService);
  }

  ngOnInit(): void {
    this.loadAllDataSources();
  }

  private loadAllDataSources() {
    this.spinner.show();
    const obsrevableImports = this.importExportApiService.getComponentImportCandidates();
    const obsrevableChanges = this.importExportApiService.getComponentTrackedChanges();

    this.loadDataSourceSubscription = forkJoin([obsrevableImports, obsrevableChanges]).subscribe(result => {
      this.dataSourceImports.data = result[0];
      this.importHtmlComponent.setDataSource(this.dataSourceImports);
      this.selectedCandidates = [];
      this.dataSourceChanges.data = result[1];
      this.changeHtmlComponent.setDataSource(this.dataSourceChanges);
      this.selectedChanges = [];
      this.spinner.hide();
    });
  }

  navigateToCandidate(candidate: ImportComponentCandidateDto) {
    const componentTypeName = this.componentTypeService.getComponentTypeNameById(candidate.packagingComponent.packagingComponentTypeId);
    if (candidate.packagingComponent.packagingComponentCategoryId === PackagingComponentTypesEnum.Decoration) {
      this.componentNavigationService.navigateToDecorationPreview(componentTypeName, candidate.id);
    } else {
      this.componentNavigationService.navigateToComponentPreview(componentTypeName, candidate.id);
    }
  }

  navigateToChange(change: ChangeComponentCandidateDto, seeComponentBeforeChange: boolean) {
    const component = seeComponentBeforeChange ? change.existingPackagingComponent : change.updatedPackagingComponent;
    const id = seeComponentBeforeChange ? change.existingPackagingComponent.id : change.importCandidateId;
    if (id == null) { return; }
    const componentTypeName = this.componentTypeService.getComponentTypeNameById(component.packagingComponentTypeId);
    if (component.packagingComponentCategoryId === PackagingComponentTypesEnum.Decoration) {
      this.componentNavigationService.navigateToDecorationPreview(componentTypeName, id, true, seeComponentBeforeChange);
    } else {
      this.componentNavigationService.navigateToComponentPreview(componentTypeName, id, true, seeComponentBeforeChange);
    }
  }

  setSelectedImportCandidates(importCandidates: ImportComponentCandidateDto[]) {
    this.selectedCandidates = importCandidates;
  }

  setSelectedChanges(changeCandidates: ChangeComponentCandidateDto[]) {
    this.selectedChanges = changeCandidates;
  }

  removeCandidates() {
    const deleteFunction = (candidateId: number) => this.importExportApiService.deleteComponentImportCandidate(candidateId);
    super.removeCandidates(this.selectedCandidates, deleteFunction, this.importHtmlComponent);
  }

  removeChanges() {
    const deleteFunction = (changeId: number) => this.importExportApiService.ignoreComponentChanges(changeId);
    const idsToDelete: number[] = this.selectedChanges.map(x => x.existingPackagingComponent.id ?? -1).filter(x => x !== -1);
    super.removeChanges(this.selectedChanges, deleteFunction, idsToDelete, this.changeHtmlComponent);
  }

  async proceedWithImport() {
    await super.selectDirectory();
    if (this.selectedImportType == null || this.selectedCandidates.length < 1 || this.targetDirectory == null) { return; }
    const requiredCredits = this.selectedCandidates.length * IMPORT_COST;
    // TODO change to new credit type if import have a own credit type
    if (requiredCredits > this.creditsService.creditsCount.recyclabilityCredits) {
      this.componentNavigationService.stopWhenCreditsInsufficient();
      return;
    }
    this.spinner.show();
    this.doImport();
  }

  private async doImport() {
    const importFunction = (candidateId: number, targetDirId: number, isWithTracking: boolean) =>
      this.importExportApiService.importComponents(candidateId, targetDirId, isWithTracking);
    await super.importItems(this.selectedCandidates, importFunction);
    this.creditsService.setCreditsCount();
    this.loadAllDataSources();
  }

  async proceedWithChange() {
    let targetDirectoryRequired = false;
    if ([ChangeType.WithTrackingNew, ChangeType.WithoutTracking].includes(this.selectedChangeType)) {
      targetDirectoryRequired = true;
      await super.selectDirectory();
    }
    if (this.selectedChangeType == null || this.selectedChanges.length < 1 ||
      (this.targetDirectory == null && targetDirectoryRequired)) { return; }
    this.spinner.show();
    this.doChange();
  }

  private async doChange() {
    let changeFunction;
    if (this.selectedChangeType === this.changeTypeEnum.WithTrackingOverwrite) {
      changeFunction = (changeId: number) => this.importExportApiService.applyComponentChanges(changeId);
      const idsToChange: number[] = this.selectedChanges.map(x => x.existingPackagingComponent.id ?? -1).filter(x => x !== -1);
      await super.applyChangesExistingItems(idsToChange, changeFunction);
      this.selectedChanges = [];
    } else {
      const isWithTracking = this.selectedChangeType === this.changeTypeEnum.WithTrackingNew;
      changeFunction = (candidateId: number, targetDirectoryId: number, isTracking: boolean) =>
        this.importExportApiService.importComponents(candidateId, targetDirectoryId, isTracking);
      const idsToChange: number[] = this.selectedChanges.map(x => x.importCandidateId ?? -1);
      await super.applyChangesNewItems(idsToChange, isWithTracking, changeFunction);
    }
    this.creditsService.setCreditsCount();
    this.loadAllDataSources();
  }
}
