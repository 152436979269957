import { PackagingSystemApiService } from '../../../data-transfer/services/packaging-system-api-service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth-service';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { getPaginatorIntl } from 'src/app/util/paginator-translation.provider';
import { PackagingSystemsOverviewComponent } from '../../directory-tree/components/packaging-systems-overview/packaging-systems-overview.component';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';

@Component({
  selector: 'app-packaging-system-home',
  templateUrl: './packaging-system-home.component.html',
  styleUrls: ['./packaging-system-home.component.scss']
})
export class PackagingSystemHomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(PackagingSystemsOverviewComponent) packagingSystemsTable!: PackagingSystemsOverviewComponent;

  dataSource: MatTableDataSource<PackagingSystemInfoDto>;
  displayedColumns = ['id', 'tracking', 'brandName', 'productName', 'articleNumber', 'action', 'compare'];

  compareButtonDisabled = true;
  isUserValidator = false;
  imageSource = '';

  private listSubscription?: Subscription;
  private themeSubscription?: Subscription;
  private paginationSubscription?: Subscription;

  constructor(
    private packagingSystemApiService: PackagingSystemApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private colorThemeService: ColorThemeService
  ) {
    this.dataSource = new MatTableDataSource<PackagingSystemInfoDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      const isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = this.getImageSource(isDarkTheme);
    });
  }

  private getImageSource(isDarkTheme: boolean) {
    let path = `${PROJECT_IMAGES_PATH}elements/packaging_system`;
    if (isDarkTheme) { path = path.concat('_dark'); }
    return path.concat('.svg');
  }

  ngOnInit(): void {
    this.spinner.show();
    this.isUserValidator = this.authService.isUserValidator();
    this.listSubscription = this.packagingSystemApiService.getAllPackagingSystems().subscribe(packagingSystems => {
      this.dataSource.data = packagingSystems;
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = getPaginatorIntl(this.translateService);
    this.dataSource.paginator = this.paginator;
    this.paginationSubscription = this.paginator.page.subscribe(_ => {
      this.packagingSystemsTable.updateDisplayTrackedColumn();
    });
  }

  createPackagingSystem() {
    const path = PathResolveService.getCreatePackagingSystemPath(true);
    this.router.navigate([path]);
  }

  compare() {
    this.packagingSystemsTable.comparePackagingPart();
  }

  setSelectedForComparison(listToCompare: PackagingSystemInfoDto[]) {
    this.compareButtonDisabled = listToCompare.length > 1 && listToCompare.length <= 10 ? false : true;
  }

  ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
    this.paginationSubscription?.unsubscribe();
  }
}