export class MultiMaterialLayerDto {
    id?: number;
    version?: number;
    functionId?: number;
    functionName?: string;
    materialId?: number;
    materialName?: string;
    materialManifestationId?: number;
    materialManifestationName?: string;
    manufacturingTypeId?: number;
    manufacturingTypeName?: string;
    mass?: number;
    recyclingMaterialPercentage?: number;
    colorId?: number;
    colorName?: string;
    thickness?: number;
    grammage?: number;
    density?: number;
    massPercentage?: number;
    index?: number;
    materialOriginCountryCode?: string;
}
