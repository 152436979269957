import { Component, OnDestroy } from '@angular/core';
import { FileUploadComponent } from '../../shared-components/upload/file-upload/file-upload.component';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SimpleDialogData } from '../simple-alert-dialog/simple-alert-dialog.component';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-packaging-excel-upload-dialog',
  templateUrl: './packaging-excel-upload-dialog.component.html',
  styleUrls: ['./packaging-excel-upload-dialog.component.scss']
})
export class PackagingExcelUploadDialogComponent extends FileUploadComponent implements OnDestroy {
  excelFile: File | null = null;

  spinnerActive: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<PackagingExcelUploadDialogComponent>,
    protected dialog: MatDialog,
    private translateService: TranslateService,
    private fileApiService: FileApiService,
    private router: Router,
  ) {
    super(dialog);
  }

  uploadCreateUnit() {
    if (!this.excelFile) { return; }
    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'file');

    this.spinnerActive = true;
    this.excelFile = null;

    this.fileApiService.putExcelPackagingUnit(formData, true).subscribe(_ => {
      this.spinnerActive = false;
      this.dialogRef.close();
      this.router.navigate([PathResolveService.getDirectoryManagementPath(true, 'upload-root')]);
    }, error => {
      console.log(error);
      console.log(typeof(error));
      this.spinnerActive = false;
      const dialogData = this.getErrorDialogData(error);
      this.showDialog(dialogData, '500px');
    });
  }

  openExcelFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const excelFile = target.files[0];
      if (!excelFile) { return; }
      const fileType = excelFile.type;
      if (fileType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') === null) {
        const dialogData = this.getInvalidFormatDialogData(excelFile.name);
        this.showDialog(dialogData);
        return;
      }
      this.excelFile = excelFile;
    }
  }

  ngOnDestroy(): void {
    this.excelFile = null;
    super.ngOnDestroy();
  }

  private getInvalidFormatDialogData(fileName: string): SimpleDialogData {
    return {
      title: fileName,
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  private getErrorDialogData(error: HttpErrorResponse): SimpleDialogData {
    if (error.error == 'Packaging unit already exists!') {
      return {
        title: this.translateService.instant('errors.excelUpload.packagingUnitAlreadyExistsTitle'),
        messages: [this.translateService.instant('errors.excelUpload.packagingUnitAlreadyExistsMessage')],
        icon: 'error'
      };
    }
    return {
      title: this.translateService.instant('errors.excelUpload.defaultTitle'),
      messages: [this.translateService.instant('errors.excelUpload.defaultMessage')],
      icon: 'error'
    };
  }
}
