import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserDto } from 'src/app/data-transfer/entities/user-dto';
import { MatTableDataSource } from '@angular/material/table';
@Component({
  selector: 'app-user',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {

  usersDataSource: MatTableDataSource<UserDto>;
  deletedUsersDataSource: MatTableDataSource<UserDto>;
  usersColumns = ['name', 'email', 'organization', 'actions'];

  private routeDataSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute
  ) {
    this.usersDataSource = new MatTableDataSource<UserDto>();
    this.deletedUsersDataSource = new MatTableDataSource<UserDto>();
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const allUsers: UserDto[] = data.users ?? [];
      this.usersDataSource.data = allUsers.filter(user => !user.isDeleted);
      this.deletedUsersDataSource.data = allUsers.filter(user => user.isDeleted);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }

}
