import { FileApiService } from '../../../../data-transfer/services/file-api-service';
import { AuthService } from './../../../../services/auth-service';
import { MaterialApiService } from '../../../../data-transfer/services/material-api-service';
import { ComponentCanDeactivate } from './../../../../services/unsaved-changes-guard';
import { ActivatedRoute } from '@angular/router';
import { MultiMaterialCompositeDto } from '../../../../data-transfer/entities/material-entities/multi-material-composite-dto';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateUpdateHandler } from './../../../../services/packaging-services/create-update-handler';
import { Component, OnInit, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MaterialNavigationService } from 'src/app/navigation/services/navigation-services/material-navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateUpdatePackagingPartComponent } from 'src/app/components/shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';
import { ColorThemeService } from 'src/app/navigation/services/color-theme-service';
import { VersionDto } from 'src/app/data-transfer/entities/version-dto';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { MaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { MultiMaterialTableComponent } from 'src/app/components/shared-components/multi-material-table/multi-material-table.component';
import { CompositeMaterialSevice } from 'src/app/services/material-services/composite-material-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

@Component({
  selector: 'app-composite-material',
  templateUrl: './composite-material.component.html',
  styleUrls: ['./composite-material.component.scss']
})
export class CompositeMaterialComponent extends CreateUpdatePackagingPartComponent implements OnInit, ComponentCanDeactivate {

  @ViewChild(MultiMaterialTableComponent) private materialsTable!: MultiMaterialTableComponent;

  materialForm!: FormGroup;
  compositeMaterial!: MultiMaterialCompositeDto;

  callerId: number;
  packagingUnitTypeId: number;
  allMaterialFunctions: MaterialFunctionDto[] = [];
  selectedManufacturingCountry = '';
  maxCommentLength = 250;
  materialLayersUpdated = false;

  constructor(
    protected handler: CreateUpdateHandler,
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected colorThemeService: ColorThemeService,
    protected cdr: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected spinner: NgxSpinnerService,
    protected fileApiService: FileApiService,
    private formBuilder: FormBuilder,
    private materialApiService: MaterialApiService,
    private navigationService: MaterialNavigationService
  ) {
    super(handler, dialog, colorThemeService, authService, cdr, route, spinner, fileApiService);
    this.callerId = PackagingComponentTypesEnum.MainBody;
    this.packagingUnitTypeId = 1;
    this.allMaterialFunctions = this.handler.getMaterialFunctions(this.packagingUnitTypeId, this.callerId);
  }

  ngOnInit(): void {
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      if (params.candidateId) { this.isPreview = true; }
    });
    this.routeDataSubscription = this.route.data.subscribe(data => {
      if (data.compositeMaterial) {
        this.compositeMaterial = data.compositeMaterial;
        this.currentDirectoryId = data.compositeMaterial.directoryId;
        this.isTracked = this.compositeMaterial?.hasExternalTracking ?? false;
        this.isHistory = this.compositeMaterial?.id != null && !this.compositeMaterial.isCurrentVersion;
        if (this.materialForm) {
          window.location.reload();
        }
        this.selectedTags = this.allTags.filter(tag => this.compositeMaterial.associatedTagIdentifiers.includes(tag.id ?? -1) ?? false);
      } else {
        this.compositeMaterial = CompositeMaterialSevice.createEmptyCompositeMaterial();
      }
      if (data.permissions) {
        this.permissions = data.permissions;
        this.isEditPermitted = this.permissions?.write ?? true;
      }
      if (data.changedSubmaterialIds && data.changedSubmaterialIds.length > 0) {
        this.setChangedSubmaterials(this.compositeMaterial.subMultiMaterials, data.changedSubmaterialIds);
      }
    });
    this.materialForm = this.formBuilder.group({
      multiMaterialControl: [this.compositeMaterial],
      newVersionName: [''],
      comment: [this.compositeMaterial.comment],
      images: [this.compositeMaterial.images],
      documents: [this.compositeMaterial.documents]
    });
    const lockFunction = (materialId: number) => this.materialApiService.lockCompositeMaterial(materialId, true);
    this.setUpLockAndHeartbeat(this.materialForm, lockFunction, this.compositeMaterial?.id);
    this.disableFormIfRequired(this.materialForm);
    this.historyVersions = [{
      id: this.compositeMaterial.id,
      versionNumber: this.compositeMaterial.version,
      creationTimestamp: this.compositeMaterial.creationTimestamp,
      isCurrentVersion: this.compositeMaterial.isCurrentVersion
    }];
  }

  private setChangedSubmaterials(allSubmaterials: MultiMaterialCompositeDto[], changedSubmaterialIds: number[]) {
    changedSubmaterialIds.forEach(id => {
      const changedSubmaterial = allSubmaterials.find(x => x.id === id);
      if (changedSubmaterial) {
        this.changedPackagingPartsInfo.push({
          id: changedSubmaterial.id ?? -1,
          name: changedSubmaterial.articleName,
          packagingPart: PackagingPart.Material,
          type: '',
          subtype: ''
        });
      }
    });
  }

  onManufacturingCountryChanged(countryCode: string) {
    this.selectedManufacturingCountry = countryCode;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): boolean {
    return (this.materialForm && this.materialForm.pristine && !this.filesEdited)
      || this.isPreview || this.isUserValidator || this.isUserAdmin || this.isHistory;
  }

  async releaseComponent() {
    const releaseFunction = (materialId: number) => this.materialApiService.releaseCompositeMaterial(materialId, true);
    super.releaseComponent(this.compositeMaterial.id, releaseFunction);
  }

  loadHistoryVersions() {
    const versionsFunction = (materialId: number) => this.materialApiService.getCompositeMaterialHistoryVersions(materialId);
    super.loadHistoryVersions(this.compositeMaterial.id, versionsFunction);
  }

  onHistoryVersionChange(version: number) {
    const navigationFunction = (materialId: number, materialVersion?: number) =>
      this.navigationService.navigateToMaterial(materialId, materialVersion);
    super.onHistoryVersionChange(version, navigationFunction);
  }

  editVersionName(material: VersionDto) {
    const renameFunction = (id: number, version: number, name: string) =>
      this.materialApiService.renameCompositeMaterial(id, version, name, true);
    super.editVersionName(material, this.compositeMaterial, renameFunction);
  }

  totalWeightChanged(newTotalWeight: number): void {
    const compositeMaterial = this.materialForm.controls.multiMaterialControl.value;
    compositeMaterial.totalWeight = newTotalWeight;
    this.materialForm.controls.multiMaterialControl.patchValue(compositeMaterial);
    this.compositeMaterial.totalWeight = newTotalWeight;
    this.materialLayersUpdated = true;
  }

  private isFormValid() {
    this.materialForm.markAllAsTouched();
    this.materialForm.updateValueAndValidity();
    const materialsValid = this.materialsTable.areMaterialLayersValid();
    return this.materialForm.valid && materialsValid;
  }

  private formToDto(): MultiMaterialCompositeDto {
    const formValue = this.materialForm.controls.multiMaterialControl.value;
    if (!this.materialForm || !formValue) { throw new Error('CompositeMaterialComponent: form is undefined'); }
    const material = this.compositeMaterial;
    material.comment = this.materialForm.controls.comment.value;
    material.manufacturerName = formValue.manufacturerName;
    material.articleName = formValue.articleName;
    material.articleNumber = formValue.articleNumber;
    material.manufacturingCountry = formValue.manufacturingCountry;
    material.gtin = formValue.gtin;
    material.totalGrammage = formValue.totalGrammage;
    material.totalWeight = formValue.totalWeight;
    material.versionName = this.materialForm.value.newVersionName;
    return material;
  }

  getUpdatedMaterial(): MultiMaterialCompositeDto {
    return this.formToDto();
  }

  async onSubmit() {
    if (!this.isFormValid()) {
      super.openFormInvalidDialog();
    } else {
      const dirId = this.currentDirectoryId != null ? this.currentDirectoryId : await super.selectDirectory();
      if (dirId == null) { return; }
      this.saveCompositeMaterial(dirId);
    }
  }

  private async saveCompositeMaterial(directoryId: number) {
    const material = this.getUpdatedMaterial();
    material.directoryId = directoryId;
    await super.updateImagesAndDocuments(material);

    let shouldCreateNewVersion = true;
    if (material.id != null && (!this.materialForm.pristine || this.materialLayersUpdated)) {
      shouldCreateNewVersion = await super.shouldCreateNewVersion(false, PackagingPart.Material);
      if (shouldCreateNewVersion == null) { return; }
    }
    const saveFunction = (packagingItem: MultiMaterialCompositeDto, shouldCreateNewVersion: boolean) =>
      this.materialApiService.putCompositeMaterial(packagingItem, shouldCreateNewVersion);
    const navFunction = (id: number, version: number) => this.navigationService.navigateToMaterial(id, version);
    super.putPackagingItem(material, this.materialForm, saveFunction, navFunction, shouldCreateNewVersion);
  }

  setTags() {
    this.compositeMaterial.associatedTagIdentifiers = this.selectedTags.map(tag => tag.id ?? -1);
  }

  async acceptSubmaterialChange(submaterialId: number) {
    if (this.compositeMaterial.id == null) { return; }
    const shouldCreateNewVersion = await super.shouldCreateNewVersion(true, PackagingPart.Unit);
    if (shouldCreateNewVersion == null) { return; }
    const acceptFunction = (submaterialId: number, componentToAcceptId: number, shouldCreateNewVersion: boolean) =>
      this.materialApiService.acceptSubmaterialChange(submaterialId, componentToAcceptId, shouldCreateNewVersion);
    super.acceptChange(this.compositeMaterial.id, submaterialId, acceptFunction, shouldCreateNewVersion);
  }

  declineSubmaterialChange(submaterialId: number) {
    if (this.compositeMaterial.id == null) { return; }
    const declineFunction = (submaterialId: number, componentToAcceptId: number) =>
      this.materialApiService.declineSubmaterialChange(submaterialId, componentToAcceptId);
    super.declineChange(this.compositeMaterial.id, submaterialId, declineFunction);
  }
}
