import { AggregatedAnalysisApiService } from '../services/aggregated-analysis-api-service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AggregateEvaluationWrapper } from '../entities/aggregate-evaluation-recyclability-output-dto';


export const aggregatedLifecycleResolver: ResolveFn<AggregateEvaluationWrapper[]> = () => {
  return inject(AggregatedAnalysisApiService).getAllAggregateLifecycleEvaluations();
};

export const aggregatedRecyclabilityResolver: ResolveFn<AggregateEvaluationWrapper[]> = () => {
  return inject(AggregatedAnalysisApiService).getAllAggregateRecyclabilityEvaluations();
};