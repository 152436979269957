import { ComponentLifeCycleResultDto } from "src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-life-cycle-result-dto";
import { ComponentRecyclabilityResultDto } from "src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-recyclability-result-dto";
import { PackagingSystemLifeCycleResultDto, PackagingUnitEntryLifeCycleResultDto } from "src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto";
import { PackagingSystemRecyclabilityResultDto, PackagingUnitEntryRecyclabilityResultDto } from "src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto";

export class AnalysesParentService {

  findSystemResultRecursively(
    analysisResult: PackagingSystemRecyclabilityResultDto | PackagingSystemLifeCycleResultDto, systemIdToFind: number
  ): any {
    let returnRecResult = null;
    if (analysisResult.analyzedPackagingSystem.id === systemIdToFind) {
      returnRecResult = analysisResult;
    } else {
      for (const subRecEntryResult of analysisResult.packagingSystemResults) {
        const subRecResult = subRecEntryResult.packagingSystemResult;
        returnRecResult = this.findSystemResultRecursively(subRecResult, systemIdToFind);
      }
    }
    if (returnRecResult != null) {
      return returnRecResult;
    }
  }

  findUnitResultRecursively(
    analysisResult: PackagingSystemRecyclabilityResultDto | PackagingSystemLifeCycleResultDto, unitIdToFind: number
  ): any {
    let returnRecResult = null;
    const unitResults: (PackagingUnitEntryRecyclabilityResultDto | PackagingUnitEntryLifeCycleResultDto)[] =
      analysisResult.packagingUnitResults;
    const unit = unitResults.find(x => x.analyzedPackagingUnitEntry.underlyingPackagingUnit.id === unitIdToFind);
    if (unit) {
      returnRecResult = unit.packagingUnitResult;
    } else {
      for (const subRecEntryResult of analysisResult.packagingSystemResults) {
        const subRecResult = subRecEntryResult.packagingSystemResult;
        returnRecResult = this.findUnitResultRecursively(subRecResult, unitIdToFind);
      }
    }
    if (returnRecResult != null) {
      return returnRecResult;
    }
  }

  findComponentResultRecursively(
    analysisResult: PackagingSystemRecyclabilityResultDto | PackagingSystemLifeCycleResultDto, componentIdToFind: number
  ): any {
    let returnLcaResult = null;
    const units = analysisResult.packagingUnitResults;
    let matchingComponent = null;
    for (const unit of units) {
      const components: (ComponentRecyclabilityResultDto | ComponentLifeCycleResultDto)[] = unit.packagingUnitResult.componentResults;
      const component = components.find(x => x.analyzedPackagingComponent.id === componentIdToFind);
      if (component) { matchingComponent = component; }
    }
    if (matchingComponent) {
      returnLcaResult = matchingComponent;
    } else {
      for (const subRecEntryResult of analysisResult.packagingSystemResults) {
        const subRecResult = subRecEntryResult.packagingSystemResult;
        returnLcaResult = this.findComponentResultRecursively(subRecResult, componentIdToFind);
      }
    }
    if (returnLcaResult != null) {
      return returnLcaResult;
    }
  }
}
