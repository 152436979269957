<div class="outer-container">

  <div id="type-id-date-container">
    <h1>{{ 'material.compositeMaterial' | translate }}</h1>
    <span *ngIf="compositeMaterial?.id != null; else elseHeader">
      <span class="grey">({{ 'commonPackagingFields.id' | translate }}: {{ compositeMaterial.id }},
        {{ 'commonPackagingFields.creationDate' | translate }}:
        {{ getLocaleDate(this.compositeMaterial.creationTimestamp) }})
      </span>
    </span>
    <ng-template #elseHeader>
      <div class="grey">{{ 'material.newMaterial' | translate }}</div>
    </ng-template>
  </div>

  <div *ngIf="isFormLocked">
    <alert type="info">
      <div class="icon-text-container">
        <mat-icon class="gray-icon-18">info</mat-icon>
        {{ 'packagingUnit.messages.compositeMaterialLocked' | translate }}
      </div>
    </alert>
  </div>

  <div *ngIf="isTracked && !isPreview">
    <alert type="info">
      <div class="icon-text-container">
        <mat-icon class="gray-icon-18">info</mat-icon>
        {{ 'material.messages.materialTracked' | translate }}
      </div>
    </alert>
  </div>

  <div *ngIf="!isEditPermitted">
    <alert type="info">
      <div class="icon-text-container">
        <mat-icon class="gray-icon-18">info</mat-icon>
        {{ 'warnings.editNotPermitted' | translate }}
      </div>
    </alert>
  </div>

  <div [formGroup]="materialForm">

    <div class="flex-row flex-space-between version-tags-container">
      <div id="versions-container">
        <div (click)="loadHistoryVersions()" *ngIf="compositeMaterial.id != null && !isPreview && !isUserValidator">
          <mat-form-field class="form-field-versions">
            <mat-label class="label-large">{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
            <mat-select [(value)]="compositeMaterial.version" (selectionChange)="onHistoryVersionChange($event.value)">
              <mat-select-trigger>
                Version {{ compositeMaterial.version }}
                <span *ngIf="compositeMaterial.versionName">({{ compositeMaterial.versionName }}) </span>
                {{ getDateTimeString(compositeMaterial.lastModificationTimestamp) }}
                <strong *ngIf="compositeMaterial.isCurrentVersion">({{ 'common.text.current' | translate
                  }})</strong>
              </mat-select-trigger>
              <mat-option *ngFor="let historyVersion of historyVersions" [value]="historyVersion.versionNumber">
                Version {{ historyVersion.versionNumber }} <span *ngIf="historyVersion.versionName">({{
                  historyVersion.versionName }})</span>
                {{ getDateTimeString(historyVersion.creationTimestamp) }}
                <strong *ngIf="historyVersion.isCurrentVersion">({{ 'common.text.current' | translate
                  }})</strong>
                <span class="spinner-container">
                  <mat-spinner diameter="24" color="accent" *ngIf="spinnerActive"></mat-spinner>
                </span>
                <button mat-icon-button color="primary" (click)="editVersionName(historyVersion); $event.stopPropagation()">
                  <mat-icon class="version-edit-icon">edit</mat-icon>
                </button>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="packaging-tag-container">
        <app-select-tags [allTags]="allTags" [selectedTags]="selectedTags"
          *ngIf="!isPreview && !isUserValidator" (tagsChanged)="setTags()">
        </app-select-tags>
      </div>
    </div>

    <alert type="warning" *ngIf="changedPackagingPartsInfo.length > 0 && compositeMaterial.isCurrentVersion">
      <p>{{ 'packagingUnit.messages.componentsChanged' | translate }}</p>
      <div *ngFor="let submaterial of changedPackagingPartsInfo" class="changed-component">
        <span>{{ 'common.dictionary.' + submaterial.type | translate }} </span>
        <span>{{ submaterial.subtype }}, ID {{ submaterial.id }}</span>
        <span *ngIf="submaterial.name"> ({{ submaterial.name }})</span>
        <button mat-raised-button (click)="acceptSubmaterialChange(submaterial.id)" class="custom-button-side">
          {{ 'common.buttons.apply' | translate }}
        </button>
        <button mat-raised-button (click)="declineSubmaterialChange(submaterial.id)" class="custom-button-side">
          {{ 'common.buttons.discard' | translate }}
        </button>
      </div>
    </alert>

    <mat-form-field class="full-width comment-form-field" appearance="outline">
      <mat-label>{{ 'packagingUnit.fields.comment' | translate }}</mat-label>
      <textarea class="no-resize-textarea" matInput formControlName="comment" [maxlength]="maxCommentLength"
        placeholder="{{ 'packagingUnit.fields.commentMaxLength' | translate: {length: maxCommentLength} }}">
      </textarea>
    </mat-form-field>

    <app-composite-material-html-template formControlName="multiMaterialControl" [isUserValidator]="isUserValidator"
      [isPreview]="isPreview" [isTracked]="isTracked" [canEditForm]="canEditForm"
      (manufacturingCountryChanged)="onManufacturingCountryChanged($event)">
    </app-composite-material-html-template>

    <app-image-upload [canEditForm]="canEditForm" [images]="compositeMaterial.images" (filesEdited)="onFilesEdited()">
    </app-image-upload>

    <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="compositeMaterial.documents" (filesEdited)="onFilesEdited()">
    </app-pdf-upload>

    <mat-card>
      <mat-card-content class="table-container-wide">
        <div id="table-container">
          <app-multi-material-table #materialsTable [parentCompositeMaterial]="compositeMaterial"
            [allMaterialFunctions]="allMaterialFunctions" [allColors]="handler.allColors"
            [allManifestations]="handler.allManifestations" [callerId]="callerId"
            [manufacturingCountry]="selectedManufacturingCountry" [packagingUnitTypeId]="packagingUnitTypeId"
            [canEditForm]="canEditForm" [isIndependentMaterial]="true" [isMassRequired]="false"
            (totalWeightChanged)="totalWeightChanged($event)">
          </app-multi-material-table>
        </div>
      </mat-card-content>
    </mat-card>

    <div id="version-name-container" *ngIf="canEditForm">
      <div id="version-name-label" class="label-large">
        {{ 'packagingUnit.messages.nameVersionLabel' | translate }}
      </div>
      <div id="version-name-input">
        <mat-form-field class="form-field-medium">
          <mat-label class="label-large">{{ 'commonPackagingFields.versionName' | translate }}</mat-label>
          <input formControlName="newVersionName" matInput autocomplete="off">
        </mat-form-field>
      </div>
    </div>

    <button (click)="onSubmit()" mat-raised-button color="primary" class="custom-button" [disabled]="!canEditForm"
      type="button">
      <mat-icon>save_alt</mat-icon>
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>
