import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { ImportExportApiService } from '../services/import-export-api-service';
import { SystemExportInfoDto } from '../entities/system-export-info-dto';
import { UnitExportInfoDto } from '../entities/unit-export-info-dto';
import { ComponentExportInfoDto } from '../entities/component-export-info-dto';
import { MaterialExportInfoDto } from '../entities/material-export-info-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

export class ExportListCollection {
  systemExportList: SystemExportInfoDto[] = [];
  unitExportList: UnitExportInfoDto[] = [];
  componentExportList: ComponentExportInfoDto[] = [];
  materialExportList: MaterialExportInfoDto[] = [];
}

export const exportListResolver: ResolveFn<ExportListCollection> =
  () => {
    const importExportApiService = inject(ImportExportApiService);
    const exportListsObservable: Observable<any>[] = [];
    exportListsObservable.push(importExportApiService.getExportList(PackagingPart.System));
    exportListsObservable.push(importExportApiService.getExportList(PackagingPart.Unit));
    exportListsObservable.push(importExportApiService.getExportList(PackagingPart.Component));
    exportListsObservable.push(importExportApiService.getExportList(PackagingPart.Material));
    return forkJoin(exportListsObservable).pipe(
      map(exportListsArray => {
        const exportListCollection = new ExportListCollection();
        exportListCollection.systemExportList = exportListsArray[0];
        exportListCollection.unitExportList = exportListsArray[1];
        exportListCollection.componentExportList = exportListsArray[2];
        exportListCollection.materialExportList = exportListsArray[3];
        return exportListCollection;
      })
    );
  };
