import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { MomentLocaleUtil } from '../../../../util/moment-locale-util';
import { PackagingUnitsOverviewComponent } from '../../../directory-tree/components/packaging-units-overview/packaging-units-overview.component';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { SimpleConfirmDialogComponent } from './../../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { SimpleAlertDialogComponent } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { AggregateEvaluationMassOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-mass-output';
import { AggregateEvaluationLifecycleOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-lifecycle-output-dto';
import { AggregateEvaluationRecyclabilityOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-recyclability-output-dto';
import { CreditsService } from 'src/app/services/credits-service';
import { AuthService } from 'src/app/services/auth-service';
import { LicenseService} from 'src/app/services/licensing-service';
import { AggregateEvaluationInputDto, PackagingUnitIdVersion } from './../../../../data-transfer/entities/aggregate-evaluation-input-dto';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { Subscription, firstValueFrom, forkJoin, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, ChangeDetectorRef, ElementRef } from '@angular/core';
import {
  Validators, AbstractControl, ValidationErrors, AbstractControlOptions, FormGroup, FormBuilder, FormControl
} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';
import * as moment from 'moment';
import { AggregatedAnalysisApiService } from 'src/app/data-transfer/services/aggregated-analysis-api-service';
import { AggregatedEvaluationsTextDataUtil } from 'src/app/util/analyses-util/aggregated-evaluations-text-data-util';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { DialogActions } from 'src/app/model/dictionary';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import {BuyType} from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
import { ProblemInformationDto } from 'src/app/data-transfer/entities/evaluation-entities/problem-information-dto';
import { TranslateService } from '@ngx-translate/core';
import { AnalysisNotPossibleDialogComponent } from 'src/app/components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';

export const enum AGGREGATED_EVALUATION_TYPES {
  RECYCLABILITY,
  LIFE_CYCLE,
  MATERIAL_MASS
}

export function datesWithinRange(rangeMonths: number) {
  return (formGroup: FormGroup) => {
    const startDate = formGroup.controls.startDate;
    const endDate = formGroup.controls.endDate;

    const errors: ValidationErrors = {};
    /* diff of months in moment is designed this way that when selecting the same day of difference monts the value is always
       a full integer (28.3 - 28.2 = 1). When entering the Date manual then it will be always start of day and selecting it with
       Datepicker it will be the current time of the the selected day
    */
    const difference = moment(endDate.value).diff(moment(startDate.value), 'months', false);
    if (difference < 0) {
      errors.endBeforeStart = true;
    }
    if (difference > rangeMonths) {
      errors.outOfRange = true;
    }
    startDate.setErrors(Object.keys(errors).length > 0 ? errors : null);
    endDate.setErrors(Object.keys(errors).length > 0 ? errors : null);

    if (startDate.value == null && startDate.touched) { startDate.setErrors({ required: true }); }
    if (endDate.value == null && endDate.touched) { endDate.setErrors({ required: true }); }
  };
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

@Component({
  selector: 'app-perform-aggregate-evaluations',
  templateUrl: './perform-aggregate-evaluations.component.html',
  styleUrls: ['./perform-aggregate-evaluations.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class PerformAggregateEvaluationsComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('packagintUnitsTable') packagintUnitsTable!: PackagingUnitsOverviewComponent;
  @ViewChild('recContent') recyclabilityTab!: ElementRef;
  @ViewChild('massContent') massTab!: ElementRef;

  packagingUnitsFormGroup!: FormGroup;
  countriesFormGroup!: FormGroup;
  datesFormGroup!: FormGroup;
  evalTypesFormGroup!: FormGroup;
  evalNameFormControl!: FormControl;

  allPackagingUnits: PackagingUnitDto[] = [];
  allCountries: CountryDto[] = [];
  evaluationTypes: { key: number, value: string }[] = [];

  recEvaluation!: AggregateEvaluationRecyclabilityOutputDto;
  lcaEvaluation!: AggregateEvaluationLifecycleOutputDto;
  massEvaluation!: AggregateEvaluationMassOutputDto;

  showEvaluations = false;
  maxDatesRangeInMonths = 120;
  isDarkTheme = false;
  isRecScrollingEnabled = false;
  isMassScrollingEnabled = false;

  isUserValidator = false;

  buyType = BuyType;

  packagingUnitsDataSource: MatTableDataSource<PackagingUnitInfoDto>;
  displayedColumnsPackagingUnits: string[] =
    ['directoryName', 'id', 'packagingTypeName', 'brandName', 'productName', 'articleNumber', 'select'];

  private routeDataSubscription?: Subscription;
  private evaluationSubscription?: Subscription;
  private themeSubscription?: Subscription;

  get startDateControl() {
    return this.datesFormGroup.controls.startDate as FormControl;
  }

  get endDateControl() {
    return this.datesFormGroup.controls.endDate as FormControl;
  }

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private analysisApiService: AnalysisApiService,
    private aggAnalysisApiService: AggregatedAnalysisApiService,
    private creditsService: CreditsService,
    private dialog: MatDialog,
    private colorThemeService: ColorThemeService,
    private spinner: NgxSpinnerService,
    private textDataUtil: AggregatedEvaluationsTextDataUtil,
    private cd: ChangeDetectorRef,
    private licenseService: LicenseService,
    private translateService: TranslateService
  ) {
    this.packagingUnitsDataSource = new MatTableDataSource<PackagingUnitInfoDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data
      .subscribe(data => {
        this.packagingUnitsDataSource.data = data.allPackagingUnits ?? [];
        this.allPackagingUnits = data.allPackagingUnits ?? [];
        this.allCountries = data.allCountries ?? [];
        this.evaluationTypes = this.textDataUtil.getAnalysisTypes();
      });
    this.initForms();
  }

  isCountryAuthorized(countryCode : string):boolean {
    return this.licenseService.allowedCountries.find(
        (country) => country.code === countryCode) !== undefined
  }

   getTooltipName(countryName: string,countryCode : string):string {
    if (this.isCountryAuthorized(countryCode)) {
      return '';
    } else {
      return this.translateService.instant('analysis.lifecycleAnalysis.warnings.countryNotAuthorized',{name: countryName});
    }
  }

  ngAfterViewChecked() {
    if (this.recyclabilityTab) {
      const objDivElement = this.recyclabilityTab.nativeElement.parentNode;
      this.isRecScrollingEnabled = objDivElement?.scrollHeight > objDivElement?.clientHeight;
    }
    if (this.massTab) {
      const objDivElement = this.massTab.nativeElement.parentNode;
      this.isMassScrollingEnabled = objDivElement?.scrollHeight > objDivElement?.clientHeight;
    }
    this.cd.detectChanges();
  }

  scrollToRecTable() {
    if (this.recyclabilityTab) {
      const objDivElement = this.recyclabilityTab.nativeElement.parentNode;
      const scrollHeight = 510;
      this.scrollToTable(objDivElement, scrollHeight);
    }
  }

  scrollToMassTable() {
    if (this.massTab) {
      const objDivElement = this.massTab.nativeElement.parentNode;
      const scrollHeight = 525;
      this.scrollToTable(objDivElement, scrollHeight);
    }
  }

  private scrollToTable(objDivElement: HTMLDivElement, scrollHeight: number) {
    objDivElement.scroll({
      top: scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  private initForms() {
    this.packagingUnitsFormGroup = this.formBuilder.group({
      packagingUnitsList: [[], [Validators.required, Validators.minLength(1)]]
    });
    this.countriesFormGroup = this.formBuilder.group({
      countriesList: [[], [Validators.required, Validators.minLength(1)]]
    });
    this.datesFormGroup = this.formBuilder.group({
      startDate: [null],
      endDate: [null]
    }, { validator: datesWithinRange(this.maxDatesRangeInMonths) } as AbstractControlOptions);
    this.evalTypesFormGroup = this.formBuilder.group({
      evaluationsList: [[], [Validators.required, Validators.minLength(1)]]
    });
    this.evalNameFormControl = this.formBuilder.control('');
  }

  setMonthAndYear(monthAndYear: Moment, datepicker: MatDatepicker<Moment>, control: AbstractControl) {
    const ctrlValue = control.value ?? moment();
    ctrlValue.month(monthAndYear.month());
    ctrlValue.year(monthAndYear.year());
    control.setValue(ctrlValue);
    datepicker.close();
  }

  selectAllPackagingUnits() {
    this.packagingUnitsFormGroup.controls.packagingUnitsList.patchValue(this.allPackagingUnits);
    this.packagintUnitsTable.selectAllPackagingUnits();
  }

  selectNoPackagingUnits() {
    this.packagingUnitsFormGroup.controls.packagingUnitsList.patchValue([]);
    this.packagintUnitsTable.resetSelection();
  }

  selectAllCountries() { this.countriesFormGroup.controls.countriesList.patchValue(this.allCountries.filter(
    (ctry) => this.licenseService.allowedCountries.find(
      (country) => country.id === ctry.id) !== undefined))}

  selectNoCountries() { this.countriesFormGroup.controls.countriesList.patchValue([]); }

  resetForm(stepper: MatStepper) {
    stepper.reset();
    this.packagintUnitsTable.resetSelection();
  }

  async performEvaluations(stepper: MatStepper) {
    this.spinner.show();
    if (this.packagingUnitsFormGroup.invalid || this.countriesFormGroup.invalid ||
      this.datesFormGroup.invalid) {
      const dialogConfig = getDialogConfig(this.textDataUtil.getIncorrectInputDialogData());
      this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
      this.spinner.hide();
      return;
    }

    if (this.evalTypesFormGroup.invalid) {
      const dialogConfig = getDialogConfig(this.textDataUtil.getNoEvalTypeSelectedDialogData());
      this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
      this.spinner.hide();
      return;
    }

    const inputData = this.getInputDataFromForm();

    const selectedEvaluations: number[] =
      this.evalTypesFormGroup.controls.evaluationsList.value.map((x: { key: number, value: string }) => x.key);
    const performRecEval = selectedEvaluations.includes(AGGREGATED_EVALUATION_TYPES.RECYCLABILITY);
    const performLifecycleEval = selectedEvaluations.includes(AGGREGATED_EVALUATION_TYPES.LIFE_CYCLE);
    const performMassEval = selectedEvaluations.includes(AGGREGATED_EVALUATION_TYPES.MATERIAL_MASS);

    let observableProblemInformation;
    if (performLifecycleEval) {
      observableProblemInformation = this.aggAnalysisApiService.getLifeCycleAggregationProblemInformation(inputData, true);
    } else if (performRecEval) {
      observableProblemInformation = this.aggAnalysisApiService.getRecyclabilityAggregationProblemInformation(inputData, true);
    } else {
      observableProblemInformation = of([]);
    }

    observableProblemInformation.subscribe({
      next: async (problemInformation: ProblemInformationDto[]) => {
        if (problemInformation.length > 0) {
          this.stopWhenAnalysisNotPossible(problemInformation);
        } else {
          const enoughCredits = await this.areEnoughCreditsAvailable(inputData, performRecEval, performLifecycleEval, performMassEval);

          if (enoughCredits) {
            this.executeEvaluations(inputData, performRecEval, performLifecycleEval, performMassEval, stepper);
          }
        }
      },
      error: _ => {
        const dialogConfig = getDialogConfig(this.textDataUtil.getEvaluationErrorDialogData());
        this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
        this.spinner.hide();
        this.evalNameFormControl.patchValue('');
      },
    });
  }

  private async areEnoughCreditsAvailable(inputData: AggregateEvaluationInputDto,
                                          performRecEval: boolean,
                                          performLifecycleEval: boolean,
                                          performMassEval: boolean) : Promise<boolean> {
    const totalCreditsRequired : CreditsDto = new CreditsDto();
    totalCreditsRequired.recyclabilityCredits += await this.getTotalCreditsRecyclability(inputData);
    totalCreditsRequired.lcaCredits += await this.getTotalCreditsLifecycle(inputData);

    /* if (totalCreditsRequired === 0) {
      if (performLifecycleEval || performLifecycleEval) {
        const dialogConfigCredits = getDialogConfig(this.textDataUtil.getNoCountriesRelevantForEvaluationDialogData());
        const dialogRefCredits = this.dialog.open(SimpleAlertDialogComponent, dialogConfigCredits);
        await dialogRefCredits.afterClosed().toPromise();
        performRecEval = false;
        performLifecycleEval = false;
      }
    } else */
    //  Analysis that aren't LCA or Recyclbility dont have costs currently so it doesn't matter for which credits it will be checked right now
    if ((performLifecycleEval && this.creditsService.creditsCount.lcaCredits < totalCreditsRequired.lcaCredits) ||
        (!performLifecycleEval && this.creditsService.creditsCount.recyclabilityCredits < totalCreditsRequired.recyclabilityCredits)) {
      const dialogConfig = getDialogConfig(this.textDataUtil.getCreditsInsufficientDialogData());
      const dialogRefCredits = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
      await dialogRefCredits.afterClosed().toPromise();
      performRecEval = false;
      performLifecycleEval = false;
    }

    if (!performRecEval && !performLifecycleEval && !performMassEval) {
      const dialogConfig = getDialogConfig(this.textDataUtil.getNoEvaluationsPossibleDialogData());
      this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
      this.spinner.hide();
      return false;
    }
    //  Analysis that aren't LCA or Recyclbility dont have costs currently so it doesn't matter for which credits it will be checked right now
    if ((performLifecycleEval && this.creditsService.areLcaCreditsRunningLow()) ||
        (!performLifecycleEval && this.creditsService.areRecyclabilityCreditsRunningLow())) {
      const dialogConfig = getDialogConfig(this.textDataUtil.getEvaluationCostDialogData(totalCreditsRequired));
      const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult.event === DialogActions.REJECT) {
        this.spinner.hide();
        return false;
      }
    }

    return true;
  }

  private executeEvaluations(inputData: AggregateEvaluationInputDto,
    performRecEval: boolean,
    performLifecycleEval: boolean,
    performMassEval: boolean,
    stepper: MatStepper) {
      const observableRec = performRecEval ? this.aggAnalysisApiService.performAggregateEvalRecyclability(inputData, true) : of(null);
      const observableLca = performLifecycleEval ? this.aggAnalysisApiService.performAggregateEvalLifecycle(inputData, true) : of(null);
      const observableMass = performMassEval ? this.aggAnalysisApiService.performAggregateEvalMass(inputData, true) : of(null);

      this.evaluationSubscription = forkJoin([observableRec, observableLca, observableMass]).subscribe({
        next: (result: any[]) => {
          this.showEvaluations = true;
          this.recEvaluation = result[AGGREGATED_EVALUATION_TYPES.RECYCLABILITY];
          this.lcaEvaluation = result[AGGREGATED_EVALUATION_TYPES.LIFE_CYCLE];
          this.massEvaluation = result[AGGREGATED_EVALUATION_TYPES.MATERIAL_MASS];
          stepper.next();
          this.creditsService.setCreditsCount();
          this.spinner.hide();
          this.evalNameFormControl.patchValue('');
        },
        error: _ => {
          const dialogConfig = getDialogConfig(this.textDataUtil.getEvaluationErrorDialogData());
          this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
          this.spinner.hide();
          this.evalNameFormControl.patchValue('');
        },
      });
  }

  private stopWhenAnalysisNotPossible(problemInformation: ProblemInformationDto[]): void {
    this.spinner.hide();
    const alertDialogConfig = getDialogConfig({ packagingPart: PackagingPart.Unit, problemInformation: problemInformation }, '600px');
    this.dialog.open(AnalysisNotPossibleDialogComponent, alertDialogConfig);
  }

  private getInputDataFromForm(): AggregateEvaluationInputDto {
    const packagingUnits: PackagingUnitIdVersion[] =
      this.packagingUnitsFormGroup.controls.packagingUnitsList.value.map((x: PackagingUnitDto) => ({ id: x.id, version: x.version }));
    const countries = this.countriesFormGroup.controls.countriesList.value.map((x: CountryDto) => x.code);
    const yearFrom = (this.datesFormGroup.controls.startDate.value as Moment).year();
    const monthFrom = +(this.datesFormGroup.controls.startDate.value as Moment).format('M');
    const yearTo = (this.datesFormGroup.controls.endDate.value as Moment).year();
    const monthTo = +(this.datesFormGroup.controls.endDate.value as Moment).format('M');
    const evalName = this.evalNameFormControl.value;
    return { name: evalName, packagingUnits, countries, yearFrom, monthFrom, yearTo, monthTo };
  }

  private async getRecyclabilityImpossibleForPackagingUnits(packagingUnits: PackagingUnitIdVersion[]) {
    const observables = packagingUnits.map(x => {
      if (x.id != null) {
        return this.analysisApiService.isRecyclabilityAnalysisPossible(x.id, false, PackagingPart.Unit, x.version);
      }
      return of(false);
    });

    if (observables.length === 0) { return []; }

    const isPossibleRec: boolean[] = await firstValueFrom(forkJoin(observables));
    return this.getImpossibleIndices(isPossibleRec, packagingUnits);
  }

  private async getLifecycleImpossibleForPackagingUnits(packagingUnits: PackagingUnitIdVersion[]) {
    const observables = packagingUnits.map(x => {
      if (x.id != null) {
        return this.analysisApiService.isLifeCycleAnalysisPossible(x.id, false, PackagingPart.Unit, x.version);
      }
      return of(false);
    });

    if (observables.length === 0) { return []; }

    const isPossibleLca: boolean[] = await firstValueFrom(forkJoin(observables));
    return this.getImpossibleIndices(isPossibleLca, packagingUnits);
  }

  private getImpossibleIndices(isPossibleList: boolean[], packagingUnits: PackagingUnitIdVersion[]): number[] {
    const impossibleIndices: number[] = [];
    for (let index = 0; index < isPossibleList.length; index++) {
      const packagingIdAtIndex = packagingUnits[index].id;
      if (!isPossibleList[index] && packagingIdAtIndex) {
        impossibleIndices.push(packagingIdAtIndex);
      }
    }
    return impossibleIndices;
  }

  private async getTotalCreditsRecyclability(inputData: AggregateEvaluationInputDto) {
    if (inputData.packagingUnits.length === 0) { return 0; }
    const credits: number = await firstValueFrom(this.aggAnalysisApiService.getAggregateRecyclabilityCost(inputData));
    return credits;
  }

  private async getTotalCreditsLifecycle(inputData: AggregateEvaluationInputDto) {
    if (inputData.packagingUnits.length === 0) { return 0; }
    const credits: number = await firstValueFrom(this.aggAnalysisApiService.getAggregateLifecycleCost(inputData));
    return credits;
  }

  startNewEvaluation(stepper: MatStepper) {
    this.showEvaluations = false;
    stepper.reset();
    this.packagintUnitsTable.resetSelection();
  }

  get packagingUnitsCount() { return this.packagingUnitsFormGroup.controls.packagingUnitsList.value?.length ?? 0; }

  get countriesCount() { return this.countriesFormGroup.controls.countriesList.value?.length ?? 0; }

  get evaluationsCount() { return this.evalTypesFormGroup.controls.evaluationsList.value?.length ?? 0; }

  get startDate(): string { return MomentLocaleUtil.getTranslatedMoment(this.datesFormGroup.controls.startDate.value as Moment) ?? '?'; }

  get endDate(): string { return MomentLocaleUtil.getTranslatedMoment(this.datesFormGroup.controls.endDate.value as Moment) ?? '?'; }

  getSelectedEvalTypeNames(): string {
    const selectedEvals: { key: number, value: string }[] = this.evalTypesFormGroup.controls.evaluationsList.value;
    if (!selectedEvals || selectedEvals.length === 0) { return this.textDataUtil.getNoneSelectedLabel(); }
    return selectedEvals.map(x => x.value).join(', ');
  }

  packagingUnitsSelected(packagingUnit: PackagingUnitDto) {
    this.packagingUnitsFormGroup.controls.packagingUnitsList.patchValue(packagingUnit);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.evaluationSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  isEvaluationDeactivated(evaluationKey:AGGREGATED_EVALUATION_TYPES) : boolean {
    switch(evaluationKey) {
      case AGGREGATED_EVALUATION_TYPES.LIFE_CYCLE: {
        return !this.licenseService.isCradleToGraveAllowed;
      }
      default: {
        return false;
      }
    }
  }
}
