import { PackagingComponentExportProfileDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PackagingUnitExportProfileDto } from '../../../../../data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { Component, OnInit, Inject } from '@angular/core';
import { ExportProfileDialogData } from '../export-profiles/export-profiles.component';
import { DtoToFormService } from '../services/dto-to-form-service';
import { FormToDtoService } from '../services/form-to-dto-service';
import { DtoForEvaluationService } from '../services/dto-for-evaluation-service';
import { CompositeMaterialExportProfileDto } from 'src/app/data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { COLOR_THEME_DARK, COLOR_THEME_NAME } from 'src/app/navigation/services/color-theme-service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { AuthService } from 'src/app/services/auth-service';
import { MatTableDataSource } from '@angular/material/table';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { ExportProfileDto } from 'src/app/data-transfer/entities/export-profile-dto';
import { ExportProfilesDataSource } from '../export-profiles-data-source';

export class TreeNode {
  items: NodeItem[] = [];
  children: TreeNode[] = [];
  cards: TreeNode[] = [];
  path = '';
  formGroupName = '';
  name = '';
}

export class NodeItem {
  form!: AbstractControl | FormGroup | FormControl;
  label = '';
  name = '';
}

@Component({
  selector: 'app-export-profile-dialog',
  templateUrl: './export-profile-dialog.component.html',
  styleUrls: ['./export-profile-dialog.component.scss']
})
export class ExportProfileDialogComponent implements OnInit {

  isPackagingSystemExportProfile = false;
  isPackagingUnitExportProfile = false;
  isPackagingComponentExportProfile = false;
  isCompositeMaterialExportProfile = false;

  addEditProfileForm!: FormGroup;
  rootNode!: TreeNode;
  subProfilesDataSource?: ExportProfilesDataSource;
  selectedSubProfile?: ExportProfileDto;

  isReadOnly = false;
  filterApplied = false;
  isDarkTheme = false;

  private formBackup!: FormGroup;

  cardNames = ['productInfo', 'fillingGoodInfo', 'manufacturingInfo', 'materialInfo', 'massInfo'];

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: ExportProfileDialogData,
    private dialogRef: MatDialogRef<ExportProfileDialogComponent>,
    private translateService: TranslateService,
    private dtoToFormService: DtoToFormService,
    private formToDtoService: FormToDtoService,
    private dtoForEvaluationService: DtoForEvaluationService,
    private authService: AuthService,
    private importExportApiService: ImportExportApiService,
    private dialog: MatDialog
  ) {
    this.isDarkTheme = localStorage.getItem(COLOR_THEME_NAME) === COLOR_THEME_DARK;
  }

  ngOnInit(): void {
    this.dtoToFormService.setNamesUsed(this.dialogData.namesUsed);
    if (this.dialogData.packagingSystemProfile) {
      this.isPackagingSystemExportProfile = true;
      this.addEditProfileForm = this.dtoToFormService.createPackagingSystemFormGroup(this.dialogData.packagingSystemProfile);
      this.initPackagingUnitProfilesData(this.dialogData.packagingSystemProfile.defaultPackagingUnitExportProfileId);
    } else if (this.dialogData.packagingUnitProfile) {
      this.isPackagingUnitExportProfile = true;
      this.addEditProfileForm = this.dtoToFormService.createPackagingUnitFormGroup(this.dialogData.packagingUnitProfile);
    } else if (this.dialogData.componentProfile) {
      this.isPackagingComponentExportProfile = true;
      this.addEditProfileForm = this.dtoToFormService.createComponentFormGroup(this.dialogData.componentProfile);
    } else if (this.dialogData.materialProfile) {
      this.isCompositeMaterialExportProfile = true;
      this.addEditProfileForm = this.dtoToFormService.createMaterialFormGroup(this.dialogData.materialProfile);
    }
    if (!this.addEditProfileForm) { return; }
    this.formBackup = JSON.parse(JSON.stringify(this.addEditProfileForm.value));
    if (this.dialogData.isReadOnly) {
      this.isReadOnly = true;
      this.addEditProfileForm.disable();
    }
    this.buildTree();
  }

  private async initPackagingUnitProfilesData(defaultUnitProfileId?: number) {
    const allPackagingUnitProfiles = await this.importExportApiService.getPackagingUnitExportProfiles().toPromise();
    if (!allPackagingUnitProfiles || allPackagingUnitProfiles.length == 0) { return; }
    this.subProfilesDataSource = new MatTableDataSource<PackagingUnitExportProfileDto>() as ExportProfilesDataSource;
    this.subProfilesDataSource.data = allPackagingUnitProfiles;
    if (defaultUnitProfileId != null) {
      this.selectedSubProfile = allPackagingUnitProfiles.find(x => x.id === defaultUnitProfileId);
    }
  }

  private buildTree() {
    const rootNode = new TreeNode();
    rootNode.path = 'root';
    rootNode.formGroupName = '';
    rootNode.name =
      this.isPackagingSystemExportProfile ? this.translateService.instant('dataManagement.exportProfiles.dialog.packagingSystemInfo') :
        this.isPackagingUnitExportProfile ? this.translateService.instant('dataManagement.exportProfiles.dialog.packagingUnitInfo') :
          this.isPackagingComponentExportProfile ? this.translateService.instant('dataManagement.exportProfiles.dialog.componentInfo') :
            this.isCompositeMaterialExportProfile ? this.translateService.instant('dataManagement.exportProfiles.dialog.materialInfo') : '';
    this.createTreeChildren(rootNode, this.addEditProfileForm, 'root');
    this.rootNode = rootNode;
  }

  private createTreeChildren(parent: TreeNode, form: FormGroup, path: string) {
    const formControls = this.getFormControls(form);
    formControls.forEach(formControl => {
      parent.items.push(formControl);
    });

    let formGroups = this.getFormGroups(form);
    formGroups = formGroups.filter(group => group.label !== 'profileData');
    if (formGroups.length > 0) {
      formGroups.forEach(child => {
        const newName = path + '-' + child.label;
        const childNode = new TreeNode();
        childNode.path = newName;
        childNode.formGroupName = child.label;
        childNode.name = child.name;
        if (this.cardNames.includes(child.label)) {
          parent.cards.push(childNode);
        } else {
          parent.children.push(childNode);
        }
        this.createTreeChildren(childNode, child.form as FormGroup, newName);
      });
    }
  }

  getFormGroups(formGroup: FormGroup): NodeItem[] {
    const formGroups: NodeItem[] = [];
    const keys = Object.keys(formGroup.controls);
    keys.forEach(key => {
      if (formGroup.controls[key] instanceof FormGroup) {
        const name = this.translateService.instant(`fieldNamesForExport.${key}`);
        formGroups.push({ form: formGroup.controls[key], label: key, name });
      }
    });
    return formGroups;
  }

  private getFormControls(formGroup: FormGroup): NodeItem[] {
    const formControls: NodeItem[] = [];
    const keys = Object.keys(formGroup.controls);
    keys.forEach(key => {
      if (formGroup.controls[key] instanceof FormControl) {
        const name = this.translateService.instant(`fieldNamesForExport.${key}`);
        formControls.push({ form: formGroup.controls[key], label: key, name });
      }
    });
    return formControls;
  }

  viewSubProfile(profile: PackagingUnitExportProfileDto) {
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile: null,
      packagingUnitProfile: profile,
      componentProfile: null,
      materialProfile: null,
      namesUsed: new Set<string>(),
      isReadOnly: true
    };
    const dialogConfig = getDialogConfig(dialogData);
    this.dialog.open(ExportProfileDialogComponent, dialogConfig);
  }

  onSaveClicked() {
    this.addEditProfileForm.markAllAsTouched();
    this.addEditProfileForm.updateValueAndValidity();
    if (this.addEditProfileForm.invalid) { return; }
    let profileDto: ExportProfileDto | null = null;
    if (this.isPackagingSystemExportProfile) {
      const defaultUnitProfileId = this.selectedSubProfile?.id;
      profileDto = this.formToDtoService.getPackagingSystemDtoFromForm(this.addEditProfileForm, defaultUnitProfileId);
    } else if (this.isPackagingUnitExportProfile) {
      profileDto = this.formToDtoService.getPackagingUnitDtoFromForm(this.addEditProfileForm);
    } else if (this.isPackagingComponentExportProfile) {
      profileDto = this.formToDtoService.getComponentDtoFromForm(this.addEditProfileForm);
    } else if (this.isCompositeMaterialExportProfile) {
      profileDto = this.formToDtoService.getMaterialDtoFromForm(this.addEditProfileForm);
    }
    if (!profileDto) { return; }
    profileDto.isDefaultProfile = this.authService.isUserAdmin();
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: { profile: profileDto } });
  }

  setAllValues(value: boolean) {
    this.filterApplied = true;
    const profileData = this.addEditProfileForm.value.profileData;
    const form =
      this.isPackagingSystemExportProfile ?
        this.dtoToFormService.createPackagingSystemFormGroup(new PackagingSystemExportProfileDto(value)) :
        this.isPackagingUnitExportProfile ?
          this.dtoToFormService.createPackagingUnitFormGroup(new PackagingUnitExportProfileDto(value)) :
          this.isPackagingComponentExportProfile ?
            this.dtoToFormService.createComponentFormGroup(new PackagingComponentExportProfileDto(value)) :
            this.isCompositeMaterialExportProfile ?
              this.dtoToFormService.createMaterialFormGroup(new CompositeMaterialExportProfileDto(value)) : null;
    if (!form) { throw new Error('ExportProfileDialogComponent: failed to create a profile form'); }
    this.addEditProfileForm = form;
    this.addEditProfileForm.controls.profileData.patchValue(profileData);
    this.buildTree();
  }

  setEvaluationValues(isLca = false) {
    this.filterApplied = true;
    const profileData = this.addEditProfileForm.value.profileData;

    const profileDto = new PackagingUnitExportProfileDto(false);
    this.dtoForEvaluationService.setPackagingProfileRecValues(profileDto);
    if (isLca) {
      this.dtoForEvaluationService.setPackagingProfileLcaValues(profileDto);
    }
    this.addEditProfileForm = this.dtoToFormService.createPackagingUnitFormGroup(profileDto);

    this.addEditProfileForm.controls.profileData.patchValue(profileData);
    this.buildTree();
  }

  resetOriginalValues() {
    this.filterApplied = false;
    this.addEditProfileForm.patchValue(JSON.parse(JSON.stringify(this.formBackup)));
  }

  get profileNameControl() { return (this.addEditProfileForm.controls.profileData as FormGroup).controls.profileName; }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
