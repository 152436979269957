<h1 mat-dialog-title>{{ 'material.compositeMaterial' | translate }}</h1>

<mat-dialog-content>
  <app-composite-materials-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
    (packagingPartsSelected)="materialsSelected($event)">
  </app-composite-materials-overview>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="importMaterials(true)" mat-raised-button type="submit" color="primary"
    [disabled]="selectedMaterials.length === 0">
    {{ 'component.addWithTracking' | translate }}</button>
  <button (click)="importMaterials(false)" mat-raised-button type="submit" color="primary"
    [disabled]="selectedMaterials.length === 0">
    {{ 'component.addWithoutTracking' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
