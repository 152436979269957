import { PackagingSystemDto } from '../../packaging-system-entities/packaging-system-dto';
import { PackagingUnitDto } from '../../packaging-unit-entities/packaging-unit-dto';
import { RecyclabilityResultDto } from '../recyclability-result-dto';
import { PackagingUnitRecyclabilityResultDto } from './packaging-unit-recyclability-result-dto';

export class PackagingSystemRecyclabilityResultDto extends RecyclabilityResultDto {
  analyzedPackagingSystem!: PackagingSystemDto;
  packagingSystemResults!: PackagingSystemEntryRecyclabilityResultDto[];
  packagingUnitResults!: PackagingUnitEntryRecyclabilityResultDto[];
}

export class PackagingSystemEntryRecyclabilityResultDto extends RecyclabilityResultDto {
  analyzedPackagingSystemEntry!: PackagingSystemEntryDto;
  packagingSystemResult!: PackagingSystemRecyclabilityResultDto;
}

export class PackagingUnitEntryRecyclabilityResultDto extends RecyclabilityResultDto {
  analyzedPackagingUnitEntry!: PackagingUnitEntryDto;
  packagingUnitResult!: PackagingUnitRecyclabilityResultDto;
}

export class PackagingSystemEntryDto {
  quantity!: number;
  index!: number;
  underlyingPackagingSystem!: PackagingSystemDto;
}

export class PackagingUnitEntryDto {
  quantity!: number;
  index!: number;
  underlyingPackagingUnit!: PackagingUnitDto;
}