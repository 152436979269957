import { CustomFieldAssignmentsDto } from '../custom-field-assignments-dto';
import { MultiMaterialCompositeDto } from '../material-entities/multi-material-composite-dto';
import { PackagingPartBaseData } from '../packaging-part-base-data';

export class PackagingComponentDto extends PackagingPartBaseData {
    id?: number;
    articleName = '';
    articleNumber = '';
    distributionCountries: string[] = [];
    gtin = '';
    length?: number;
    width?: number;
    height?: number;
    manufacturer = '';
    manufacturingCountry?: string;
    multiMaterial!: MultiMaterialCompositeDto;
    packagingComponentCategoryId = -1;
    packagingComponentCategoryName = '';
    packagingComponentTypeId = -1;
    packagingComponentTypeName = '';
    packagingComponentSubtypeId = -1;
    packagingComponentSubtypeName = '';
    printingCoverage?: number;
    customFields: CustomFieldAssignmentsDto | null = null;

    comment?: string;
    hasRecyclabilityResult = false;
    hasLifeCycleResult = false;
    isRigidComponent: boolean | null = null;
    containsNearInfraredBarrier: boolean | null = null;
    index?: number;
}
