import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { Component, Input } from '@angular/core';
import { RecyclabilityService } from 'src/app/util/analyses-util/recyclability/recyclability-service';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { RecyclabilityResultComponent } from 'src/app/model/evaluations/recyclability-result-component';
import { AnalysisRecyclabilityPackagingPartComponent } from '../analysis-recyclability-packaging-part/analysis-recyclability-packaging-part.component';

@Component({
  selector: 'app-analysis-recyclability-packaging-unit',
  templateUrl: './analysis-recyclability-packaging-unit.component.html',
  styleUrls: ['../analysis-recyclability-packaging-system/analysis-recyclability-packaging-system.component.scss']
})
export class AnalysisRecyclabilityPackagingUnitComponent extends AnalysisRecyclabilityPackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingUnitRecyclabilityResultDto;
  @Input() packagingUnit!: PackagingUnitDto;

  dataSourceForCountry!: RecyclabilityResultPackagingUnit | RecyclabilityResultComponent;

  get dataSourceForCountryAsUnit(): RecyclabilityResultPackagingUnit {
    return this.dataSourceForCountry as RecyclabilityResultPackagingUnit;
  }

  get dataSourceForCountryAsComponent(): RecyclabilityResultComponent {
    return this.dataSourceForCountry as RecyclabilityResultComponent;
  }

  constructor(
    private recService: RecyclabilityService
  ) {
    super();
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
  }

  private onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    this.dataSourceForCountry = this.recService.getPackagingUnitRecyclabilityResultFromDto(this.selectedAnalysisVersion);
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResult = this.selectedAnalysisVersion.componentResults.find(x => x.analyzedPackagingComponent.id === componentId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.recService.getComponentRecyclabilityResultFromDto(selectedResult);
  }
}
