import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MaterialMassAggregatedEvaluationResult } from './../../../../util/analyses-util/material-mass/material-mass-aggregated-evaluation-result';
import { AggregateEvaluationMassOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-mass-output';
import { Component, Input, Output, SimpleChanges, OnChanges, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';

@Component({
  selector: 'app-view-mass-aggregate-evaluation',
  templateUrl: './view-mass-aggregate-evaluation.component.html',
  styleUrls: ['./view-mass-aggregate-evaluation.component.scss']
})
export class ViewMassAggregateEvaluationComponent implements OnChanges {

  @Input() massEvaluation: AggregateEvaluationMassOutputDto | null = null;
  @Input() isDarkTheme = false;
  @Input() isScrollingEnabled = false;

  @Output() scrollToTableEvent = new EventEmitter();

  displayedColumnsMassAnalysis = ['materialManifestationName', 'weight', 'weightPercentage'];
  massChartDataSource!: MaterialMassAggregatedEvaluationResult;
  showTable = true;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.massEvaluation && changes.massEvaluation.currentValue) {
      this.massChartDataSource = new MaterialMassAggregatedEvaluationResult(changes.massEvaluation.currentValue);
      if (this.massChartDataSource.dataSource.length === 0) {
        this.showTable = false;
        const dialogData: SimpleDialogData = {
          title: this.translateService.instant('analysis.massAnalysis.dataMissingTitle'),
          messages: [this.translateService.instant('analysis.massAnalysis.dataMissingText')], icon: 'warning'
        };
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(dialogData));
      }
    }
  }

  scrollToTable() {
    this.scrollToTableEvent.emit();
  }
}
