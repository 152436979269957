import { Authorization } from "./authorization";

export class EnvironmentalEffectAuthorizationDto implements Authorization {
    id: number = -1;
    environmentalEffectId: number = -1;
    authorizationStart: string = "";
    authorizationEnd: string = "";
    bought: boolean = true;
    analysisType: LCAAnalysisType = LCAAnalysisType.CradleToGrave;
}

export enum LCAAnalysisType {
  CradleToGrave = 0,
  CradleToGate = 1
}
