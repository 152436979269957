import { PackagingComponentTypesEnum } from './../../model/packaging-component-types-enum';
import { Injectable } from '@angular/core';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { ComponentTypeDto } from 'src/app/data-transfer/entities/component-type-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';

export const COMPONENT_CATEGORIES = [
  { key: PackagingComponentTypesEnum.MainBody, label: 'mainBody', value: 'Main Body' },
  { key: PackagingComponentTypesEnum.Closure, label: 'closure', value: 'Closure' },
  { key: PackagingComponentTypesEnum.Decoration, label: 'decoration', value: 'Decoration' },
  { key: PackagingComponentTypesEnum.Inlay, label: 'inlay', value: 'Inlay' },
  { key: PackagingComponentTypesEnum.PackagingAid, label: 'packagingAid', value: 'Other Packaging Aid' }
];

@Injectable({ providedIn: 'root' })
export class ComponentTypeService {

  private componentTypes: ComponentTypeDto[] = [];
  private componentTypesEnglish: ComponentTypeDto[] = [];
  private decorationSubtypesEnglish: ComponentSubtypeDto[] = [];

  setComponentTypes(componentTypes: ComponentTypeDto[]) {
    this.componentTypes = componentTypes;
  }

  setComponentTypesEnglish(componentTypesEnglish: ComponentTypeDto[]) {
    this.componentTypesEnglish = componentTypesEnglish;
  }

  setDecorationTypesEnglish(decorationSubtypes: ComponentSubtypeDto[]) {
    this.decorationSubtypesEnglish = decorationSubtypes;
  }

  getComponentTypeDtoByName(componentName: string): ComponentTypeDto {
    const id = this.componentTypesEnglish.find(x => x.name.toLowerCase() === componentName)?.id;
    const componentTypeDto = this.componentTypes.find(x => x.id === id);
    if (componentTypeDto == null) { throw new Error('ComponentTypeService: component type not found'); }
    return componentTypeDto;
  }

  getComponentTypeNameById(componentTypeId: number): string {
    const componentType = this.componentTypesEnglish.find(x => x.id === componentTypeId);
    if (componentType == null) { throw new Error('ComponentTypeService: component type not found'); }
    return componentType.name.toLowerCase().replace(' ', '').replace('-', '');
  }

  getDecorationTypeDtoBySubtypeId(decorationSubTypeId: number): ComponentTypeDto {
    const decorationTypeDto = this.componentTypes.find(x =>
      x.subtypes.map(y => y.id).includes(decorationSubTypeId)
    );
    if (decorationTypeDto == null) { throw new Error('ComponentTypeService: decoration type not found'); }
    return decorationTypeDto;
  }

  getDecorationSubtypeNameBySubtypeId(decorationSubtypeId: number): string {
    const decorationSubtype = this.decorationSubtypesEnglish.find(x => x.id === decorationSubtypeId);
    if (decorationSubtype == null) { throw new Error('ComponentTypeService: decoration subtype not found'); }
    return decorationSubtype.componentTypeName.toLowerCase();
  }

  isDecorationOfTypeSleeve(subtypeId: number): boolean {
    return this.componentTypesEnglish.find(x => x.name.toLowerCase() == 'sleeve')?.subtypes.map(y => y.id).includes(subtypeId) ?? false;
  }

  isDecorationOfTypeLabel(subtypeId: number): boolean {
    return this.componentTypesEnglish.find(x => x.name.toLowerCase() == 'label')?.subtypes.map(y => y.id).includes(subtypeId) ?? false;
  }

  isDecorationOfTypeInMold(subtypeId: number): boolean {
    return this.componentTypesEnglish.find(x =>
      x.name.toLowerCase() == 'in-mold label')?.subtypes.map(y => y.id).includes(subtypeId) ?? false;
  }

  getDecorationTypeNames(): string[] {
    const decorationTypeIds = [...new Set(this.decorationSubtypesEnglish.map(x => x.componentTypeId))];
    return this.componentTypesEnglish.filter(x => decorationTypeIds.includes(x.id)).map(y => y.name.toLowerCase());
  }

  getDecorationSubtypeIds(): number[] {
    return this.decorationSubtypesEnglish.map(x => x.id);
  }

  getComponentTypeImage(componentTypeId: number, isDark = false, isGray = false): string {
    const componentType = COMPONENT_CATEGORIES.find(x => x.key === componentTypeId)?.label;
    const baseImagePath = `${PROJECT_IMAGES_PATH}elements/component_types/${componentType}`;
    const darkAddition = isDark ? '_dark' : '';
    const grayAddition = isGray ? '_gray' : '';
    return baseImagePath + darkAddition + grayAddition + '.svg';
  }

  getComponentsImage(isDarkTheme = false) {
    const baseImagePath = `${PROJECT_IMAGES_PATH}elements/component`;
    const darkAddition = isDarkTheme ? '_dark' : '';
    return baseImagePath + darkAddition + '.svg';
  }
}
