import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlSegment } from '@angular/router';
import {
  CompareText, TypeParam, DecorationTypeParam, FolderNameParam, IdParam,
  PathsDirectory, CandidateIdParam, AnalysisVersionIdParam, VersionIdParam, PackagingTypeParam, PackagingUnitText, PackagingSystemText
} from '../model/path-building-blocks';

@Injectable({ providedIn: 'root' })
export class PathResolveService {

  public static getLoginPath() {
    return PathsDirectory.login.join('/');
  }

  public static getCockpitPath() {
    return PathsDirectory.cockpit.join('/');
  }

  public static getStartPath(isFullPath: boolean = false) {
    return PathsDirectory.start.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getPackagingSystemHomepagePath(isFullPath: boolean = false) {
    return PathsDirectory.packagingSystem.slice(isFullPath ? 0 : PathsDirectory.cockpitPackagingSystem.length).join('/');
  }

  public static getPackagingUnitHomepagePath(isFullPath: boolean = false) {
    return PathsDirectory.packagingUnit.slice(isFullPath ? 0 : PathsDirectory.cockpitPackagingUnit.length).join('/');
  }

  public static getComponentHomepagePath(isFullPath: boolean = false) {
    return PathsDirectory.component.slice(isFullPath ? 0 : PathsDirectory.cockpitComponent.length).join('/');
  }

  public static getMaterialHomepagePath(isFullPath: boolean = false) {
    return PathsDirectory.material.slice(isFullPath ? 0 : PathsDirectory.cockpitMaterial.length).join('/');
  }

  public static getPerformEvaluationPath(isFullPath: boolean = false) {
    return PathsDirectory.dataPerformEvaluation.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getViewEvaluationPath(isFullPath: boolean = false) {
    return PathsDirectory.dataViewEvaluation.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getCreatePackagingUnitReportTemplatePath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createPackagingUnitReportTemplate);
  }

  public static getUpdatePackagingUnitReportTemplatePath(isFullPath: boolean = false, id?: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updatePackagingUnitReportTemplate), id);
  }

  public static getCreateComponentReportTemplatePath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createComponentReportTemplate);
  }

  public static getUpdateComponentReportTemplatePath(isFullPath: boolean = false, id?: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updateComponentReportTemplate), id);
  }

  public static getCreateMaterialReportTemplatePath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createMaterialReportTemplate);
  }

  public static getUpdateMaterialReportTemplatePath(isFullPath: boolean = false, id?: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updateMaterialReportTemplate), id);
  }

  public static getPackagingUnitIdFromRoute(route: ActivatedRouteSnapshot): number {
    const id = route.paramMap.get('id');
    if (id == null) { throw new Error('PathResolveService: packaging unit ID not in route'); }
    return parseInt(id, 10);
  }

  public static getPackagingVersionIdFromRoute(route: ActivatedRouteSnapshot): number | undefined {
    const versionId = route.paramMap.get('versionId');
    if (versionId == null) { throw new Error('PathResolveService: packaging unit version ID not in route'); }
    const result = parseInt(versionId, 10);
    if (isNaN(result)) { return undefined; }
    return result;
  }

  public static getCandidateIdFromRoute(route: ActivatedRouteSnapshot): number {
    const candidateId = route.paramMap.get('candidateId');
    if (candidateId == null) { throw new Error('PathResolveService: packaging unit candidate ID not in route'); }
    return parseInt(candidateId, 10);
  }

  public static getPackagingUnitIdListFromRoute(route: ActivatedRouteSnapshot): number[] {
    const ids: number[] = [];
    for (let index = 3; index < route.url.length; index++) {
      ids.push(+route.url[index].path);
    }
    return ids;
  }

  public static getComponentTypeFromRoute(route: ActivatedRouteSnapshot): string {
    return route.paramMap.get('type') ?? route.url[0].toString(); // TODO
  }

  public static getComponentPreviewTypeFromRoute(route: ActivatedRouteSnapshot): string {
    return route.paramMap.get('type') ?? route.url[0].toString(); // TODO
  }

  public static getDecorationTypeFromRoute(route: ActivatedRouteSnapshot): string | undefined {
    const decorationType = route.paramMap.get('decorationType');
    return decorationType ?? undefined;
  }

  public static comparisonPathMatcherSystems(url: UrlSegment[]) {
    if (!url || url.length <= 2) { return null; }
    const isSystem = url[1].path === PackagingSystemText;
    const isCompare = url[2].path === CompareText;
    return (isSystem && isCompare) ? ({ consumed: url }) : null;
  }

  public static comparisonPathMatcherUnits(url: UrlSegment[]) {
    if (!url || url.length <= 2) { return null; }
    const isUnit = url[1].path === PackagingUnitText;
    const isCompare = url[2].path === CompareText;
    return (isUnit && isCompare) ? ({ consumed: url }) : null;
  }

  private static cutOffCockpitPath(isFullPath: boolean, splittedPath: string[]) {
    return splittedPath.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  private static cutOffCockpitLevelPath(isFullPath: boolean, splittedPath: string[]) {
    return splittedPath.slice(isFullPath ? 0 : PathsDirectory.cockpitPackagingUnit.length).join('/');
  }

  private static replaceTypeNameParameter(path: string, typeName: string) {
    return typeName ? path.replace(TypeParam, typeName) : path;
  }

  private static replacePackagingUnitTypeNameParameter(path: string, packagingUnitTypeName: string) {
    return packagingUnitTypeName ? path.replace(PackagingTypeParam, packagingUnitTypeName) : path;
  }

  private static replaceIdInPath(path: string, id?: number) {
    return id ? path.replace(IdParam, id.toString()) : path;
  }

  private static replaceDecorationTypeInPath(path: string, decorationType: string) {
    return decorationType ? path.replace(DecorationTypeParam, decorationType) : path;
  }

  private static replaceVersionIdInPath(path: string, id: number) {
    const newPath = (id != null) ? path.replace(VersionIdParam, id.toString()) : path;
    return newPath;
  }

  /* private static replaceVersionDateInPath(path: string, date: string) {
    const newPath = date ? path.replace(VersionDateParam, date.toString()) : path;
    return newPath;
  } */

  private static replaceAnalysisVersionIdInPath(path: string, analysisVersionId: string) {
    const newPath = analysisVersionId ? path.replace(AnalysisVersionIdParam, analysisVersionId.toString()) : path;
    return newPath;
  }

  private static replaceCandidateIdInPath(path: string, id: number) {
    return id ? path.replace(CandidateIdParam, id.toString()) : path;
  }

  private static appendMultipleIdsToPath(path: string, ids: number[]) {
    if (ids && ids.length > 1) {
      for (const id of ids) {
        path = path.concat('/' + id);
      }
    }
    return path;
  }

  // Must have a variable type name, otherwise page will not reload on new type
  public static getPackagingUnitTypePath(isFullPath: boolean = false, packagingUnitTypeName = '') {
    return this.replaceTypeNameParameter(
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.packagingUnitType), packagingUnitTypeName);
  }

  public static getComponentTypePath(isFullPath: boolean = false, componentTypeName = '') {
    return this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.componentType), componentTypeName);
  }

  public static getCreatePackagingSystemPath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createPackagingSystem);
  }

  public static getUpdatePackagingSystemPath(isFullPath: boolean = false, packagingSystemId: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updatePackagingSystem), packagingSystemId);
  }

  public static getCreatePackagingUnitPath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createPackagingUnit);
  }

  public static getUpdatePackagingUnitPath(isFullPath: boolean = false, packagingUnitId: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updatePackagingUnit), packagingUnitId);
  }

  public static getCreateComponentPath(isFullPath: boolean = false, componentTypeName: string) {
    return this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.createComponent), componentTypeName);
  }

  public static getCreateMainBodyComponentPath(isFullPath: boolean = false, componentTypeName: string, packagingUnitTypeName: string) {
    return this.replacePackagingUnitTypeNameParameter(this.replaceTypeNameParameter(
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.createMainBody), componentTypeName), packagingUnitTypeName);
  }

  public static getUpdateComponentPath(isFullPath: boolean = false, componentTypeName: string, componentId: number) {
    return this.replaceIdInPath(this.replaceTypeNameParameter(
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.updateComponent), componentTypeName), componentId);
  }

  public static getDecorationUpdatePath(isFullPath: boolean = false, componentType: string, componentId: number, decorationType: string) {
    return this.replaceDecorationTypeInPath(this.replaceIdInPath(
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(
        isFullPath, PathsDirectory.updateDecoration), componentType), componentId), decorationType);
  }

  public static getCreateMaterialPath(isFullPath: boolean = false) {
    return this.cutOffCockpitPath(isFullPath, PathsDirectory.createMaterial);
  }

  public static getUpdateMaterialPath(isFullPath: boolean = false, materialId: number) {
    return this.replaceIdInPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.updateMaterial), materialId);
  }

  public static getPackagingSystemPreviewPath(
    isFullPath: boolean = false, candidateId: number, isChange = false, seePackagingSystemBeforeChange = false
  ) {
    let basePath: string[];
    if (isChange) {
      basePath = seePackagingSystemBeforeChange ? PathsDirectory.previewSystemBeforeChange : PathsDirectory.previewSystemAfterChange;
    } else {
      basePath = PathsDirectory.previewSystem;
    }
    const path = this.replaceCandidateIdInPath(this.cutOffCockpitLevelPath(isFullPath, basePath), candidateId);
    return path;
  }

  public static getPackagingUnitPreviewPath(
    isFullPath: boolean = false, candidateId: number, isChange = false, seePackagingUnitBeforeChange = false
  ) {
    let basePath: string[];
    if (isChange) {
      basePath = seePackagingUnitBeforeChange ? PathsDirectory.previewUnitBeforeChange : PathsDirectory.previewUnitAfterChange;
    } else {
      basePath = PathsDirectory.previewUnit;
    }
    const path = this.replaceCandidateIdInPath(this.cutOffCockpitLevelPath(isFullPath, basePath), candidateId);
    return path;
  }

  public static getComponentPreviewPath(
    isFullPath: boolean = false, componentTypeName: string, candidateId: number, isChange = false, seeComponentBeforeChange = false) {
    let basePath: string[];
    if (isChange) {
      basePath = seeComponentBeforeChange ? PathsDirectory.previewComponentBeforeChange : PathsDirectory.previewComponentAfterChange;
    } else {
      basePath = PathsDirectory.previewComponent;
    }
    return this.replaceCandidateIdInPath(this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(
      isFullPath, basePath), componentTypeName), candidateId);
  }

  public static getDecorationPreviewPath(
    isFullPath: boolean = false, componentTypeName: string, candidateId: number, isChange = false,
    seeComponentBeforeChange = false, decorationTypeName: string
  ) {
    let basePath: string[];
    if (isChange) {
      basePath = seeComponentBeforeChange ? PathsDirectory.previewDecorationBeforeChange : PathsDirectory.previewDecorationAfterChange;
    } else {
      basePath = PathsDirectory.previewDecoration;
    }
    return this.replaceDecorationTypeInPath(this.replaceCandidateIdInPath(
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(
        isFullPath, basePath), componentTypeName), candidateId), decorationTypeName);
  }

  public static getMaterialPreviewPath(
    isFullPath: boolean = false, candidateId: number, isChange = false, seeMaterialBeforeChange = false
  ) {
    let basePath: string[];
    if (isChange) {
      basePath = seeMaterialBeforeChange ? PathsDirectory.previewMaterialBeforeChange : PathsDirectory.previewMaterialAfterChange;
    } else {
      basePath = PathsDirectory.previewMaterial;
    }
    const path = this.replaceCandidateIdInPath(this.cutOffCockpitLevelPath(isFullPath, basePath), candidateId);
    return path;
  }

  public static getPackagingSystemHistoryPath(isFullPath: boolean = false, packagingId: number, versionId: number) {
    return this.replaceVersionIdInPath(this.replaceIdInPath(
      this.cutOffCockpitPath(isFullPath, PathsDirectory.packagingSystemVersion), packagingId), versionId);
  }

  public static getPackagingUnitHistoryPath(isFullPath: boolean = false, packagingId: number, versionId: number) {
    return this.replaceVersionIdInPath(this.replaceIdInPath(
      this.cutOffCockpitPath(isFullPath, PathsDirectory.packagingUnitVersion), packagingId), versionId);
  }

  public static getComponentHistoryPath(isFullPath: boolean = false, componentTypeName: string, componentId: number, versionId: number
  ) {
    return this.replaceVersionIdInPath(this.replaceIdInPath(
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(
        isFullPath, PathsDirectory.componentVersion), componentTypeName), componentId), versionId);
  }

  public static getDecorationHistoryPath(
    isFullPath: boolean = false, componentTypeName: string, componentId: number, componentVersionId: number, decorationType: string
  ) {
    return this.replaceDecorationTypeInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(
        isFullPath, PathsDirectory.decorationVersion), componentTypeName), componentId), componentVersionId), decorationType);
  }

  public static getMaterialHistoryPath(isFullPath: boolean = false, materialId: number, versionId: number) {
    return this.replaceVersionIdInPath(this.replaceIdInPath(
      this.cutOffCockpitPath(isFullPath, PathsDirectory.materialVersion), materialId), versionId);
  }

  public static getRecyclabilityAnalysisHistoryPathUnit(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.recyclabilityUnitHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.recyclabilityUnitHistory);
    }
  }

  public static getLifecycleAnalysisHistoryPathUnit(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleUnitHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleUnitHistory);
    }
  }

  public static getCradleToGateLifecycleAnalysisHistoryPathUnit(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleCradleToGateUnitHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleCradleToGateUnitHistory);
    }
  }

  public static getPlasticTaxHistoryPathUnit(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.plasticTaxUnitHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.plasticTaxUnitHistory);
    }
  }

  public static getLicenseFeeHistoryPathUnit(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.licenseFeeUnitHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.licenseFeeUnitHistory);
    }
  }

  public static getCompareRecyclabilityAnalysisPathUnit(isFullPath: boolean = false, packagingUnitIds: number[]) {
    return this.appendMultipleIdsToPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.compareUnits), packagingUnitIds);
  }

  public static getRecyclabilityAnalysisHistoryPathSystem(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.recyclabilitySystemHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.recyclabilitySystemHistory);
    }
  }

  public static getLifecycleAnalysisHistoryPathSystem(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleSystemHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleSystemHistory);
    }
  }
  public static getCradleToGateLifecycleAnalysisHistoryPathSystem(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleCradleToGateSystemHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.lifecycleCradleToGateSystemHistory);
    }
  }



  public static getPlasticTaxHistoryPathSystem(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.plasticTaxSystemHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.plasticTaxSystemHistory);
    }
  }

  public static getLicenseFeeHistoryPathSystem(
    isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number, analysisVersionId?: string
  ) {
    if (packagingId != null && packagingVersionId != null && analysisVersionId != null) {
      return this.replaceAnalysisVersionIdInPath(this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.licenseFeeSystemHistory),
        packagingId), packagingVersionId), analysisVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.licenseFeeSystemHistory);
    }
  }

  public static getCompareRecyclabilityAnalysisPathSystem(isFullPath: boolean = false, packagingIds: number[]) {
    return this.appendMultipleIdsToPath(this.cutOffCockpitPath(isFullPath, PathsDirectory.compareSystems), packagingIds);
  }

  public static getErrorPath(isFullPath: boolean = false) {
    return PathsDirectory.error.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getUserManagementPath(isFullPath: boolean = false) {
    return PathsDirectory.userManagement.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getDependencyVersionsPath(isFullPath: boolean = false) {
    return PathsDirectory.dependencyVersions.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getUiChangelogPath(isFullPath: boolean = false) {
    return PathsDirectory.uiChangelog.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getExportProfilesPath(isFullPath: boolean = false) {
    return PathsDirectory.exportProfiles.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getExportListPath(isFullPath: boolean = false) {
    return PathsDirectory.exportList.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getOrganizationsManagementPath(isFullPath: boolean = false) {
    return PathsDirectory.organizationsManagement.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getLcaDataExcelImportPath(isFullPath: boolean = false) {
    return PathsDirectory.lcaDataExcelImport.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getTagsManagementPath(isFullPath: boolean = false) {
    return PathsDirectory.tagsManagement.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getInternationalInfosPath(isFullPath: boolean = false) {
    return PathsDirectory.internationalInfos.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getTempPlasticTaxSystemPath(isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number) {
    if (packagingId != null && packagingVersionId != null) {
      return this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.tempPlasticTaxSystemPath), packagingId), packagingVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.tempPlasticTaxSystemPath);
    }
  }

  public static getTempPlasticTaxUnitPath(isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number) {
    if (packagingId != null && packagingVersionId != null) {
      return this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.tempPlasticTaxUnitPath), packagingId), packagingVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.tempPlasticTaxUnitPath);
    }
  }

  public static getTempLicenseFeeSystemPath(isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number) {
    if (packagingId != null && packagingVersionId != null) {
      return this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.tempLicenseFeeSystemPath), packagingId), packagingVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.tempLicenseFeeSystemPath);
    }
  }

  public static getTempLicenseFeeUnitPath(isFullPath: boolean = false, packagingId?: number, packagingVersionId?: number) {
    if (packagingId != null && packagingVersionId != null) {
      return this.replaceVersionIdInPath(this.replaceIdInPath(
        this.cutOffCockpitPath(isFullPath, PathsDirectory.tempLicenseFeeUnitPath), packagingId), packagingVersionId);
    } else {
      return this.cutOffCockpitPath(isFullPath, PathsDirectory.tempLicenseFeeUnitPath);
    }
  }

  public static getValidatorViewSystems(isFullPath: boolean = false) {
    return PathsDirectory.validatorViewSystems.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getValidatorViewUnits(isFullPath: boolean = false) {
    return PathsDirectory.validatorViewUnits.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getDirectoryManagementPath(isFullPath: boolean = false, folderName?: string) {
    let basePath = PathsDirectory.directoryManagement.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
    if (folderName) {
      basePath = basePath.replace(FolderNameParam, folderName);
    }
    return basePath;
  }

  public static getPackagingSystemImportPath(isFullPath: boolean = false) {
    return PathsDirectory.packagingSystemImport.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getPackagingUnitImportPath(isFullPath: boolean = false) {
    return PathsDirectory.packagingUnitImport.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getComponentImportPath(isFullPath: boolean = false) {
    return PathsDirectory.componentImport.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getMaterialImportPath(isFullPath: boolean = false) {
    return PathsDirectory.materialImport.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getReportTemplatesHomePath(isFullPath: boolean = false) {
    return PathsDirectory.reportTemplatesHome.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

  public static getReportsHomePath(isFullPath: boolean = false) {
    return PathsDirectory.reportsHome.slice(isFullPath ? 0 : PathsDirectory.cockpit.length).join('/');
  }

 public static getAllPackagingSystemPreviewPaths(isFullPath: boolean = false): string[] {
    const paths = [
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewSystem),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewSystemBeforeChange),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewSystemAfterChange)
    ];
    return paths;
  }

  public static getAllPackagingUnitPreviewPaths(isFullPath: boolean = false): string[] {
    const paths = [
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewUnit),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewUnitBeforeChange),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewUnitAfterChange)
    ];
    return paths;
  }

  public static getAllComponentPreviewPaths(typeName: string, isFullPath: boolean = false): string[] {
    const type = typeName.toLowerCase();
    const paths = [
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewComponent), type),
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewComponentBeforeChange), type),
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewComponentAfterChange), type)
    ];
    return paths;
  }

  public static getAllDecorationPreviewPaths(typeName: string, isFullPath: boolean = false): string[] {
    const type = typeName.toLowerCase();
    const paths = [
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewDecoration), type),
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewDecorationBeforeChange), type),
      this.replaceTypeNameParameter(this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewDecorationAfterChange), type)
    ];
    return paths;
  }

  public static getAllMaterialPreviewPaths(isFullPath: boolean = false): string[] {
    const paths = [
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewMaterial),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewMaterialBeforeChange),
      this.cutOffCockpitLevelPath(isFullPath, PathsDirectory.previewMaterialAfterChange)
    ];
    return paths;
  }
}
