import { FileUploadComponent, MAX_FILES_COUNT } from './../file-upload/file-upload.component';
import { Subscription } from 'rxjs';
import { DeleteDialogData, DeleteItemDialogComponent } from '../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { ImageDto } from 'src/app/data-transfer/entities/image-dto';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';

class NewImagesAlbum implements IAlbum {
  src = '';
  caption?: string;
  thumb = '';
  newImageId = -1;
  downloadUrl = '';
}

class ExistingImagesAlbum implements IAlbum {
  src = '';
  caption?: string;
  thumb = '';
  existingImageId = -1;
  downloadUrl = '';
}

export class ImagePreview {
  image: any;
  imageFile: any;
  existingImageId?: number;
  newImageId?: number;
  isMain = false;
  name = '';
  fileExtension = '';
  fullName = '';
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent extends FileUploadComponent implements OnInit, OnChanges, OnDestroy {

  @Input() images: ImageDto[] = [];

  imagePreviews: ImagePreview[] = [];

  private newImagesFullscreenPreviews: NewImagesAlbum[] = [];

  private thumbnailSubscriptionList: Subscription[] = [];
  private fullscreenSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    private fileApiService: FileApiService,
    private translateService: TranslateService,
    private lightbox: Lightbox,
    private cdr: ChangeDetectorRef
  ) {
    super(dialog);
    this.minFieldWidth = 200;
    this.maxFieldWidth = 350;
    super.setInitialNamesLength();
  }

  ngOnInit(): void {
    this.loadImagePreviewForAllIds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.images && !changes.images.isFirstChange()) {
      this.loadImagePreviewForAllIds();
    }
  }

  public getImagePreviews(): ImagePreview[] {
    return this.imagePreviews;
  }

  setImagePreviews(imagePreviews: ImagePreview[]) {
    if (!imagePreviews) { return; }
    for (let index = 0; index < imagePreviews.length; index++) {
      this.loadImagePreview(imagePreviews[index].imageFile, undefined, imagePreviews[index].fullName, index, imagePreviews[index].isMain);
    }
  }

  private loadImagePreviewForAllIds() {
    if (!this.images) { return; }
    this.prefillPreviewLists();
    this.images.sort((a, b) => (a.index > b.index) ? 1 : -1);

    // Cannot use forkJoin here because need index, id etc. Alternatives?
    for (let index = 0; index < this.images.length; index++) {
      this.thumbnailSubscriptionList.push(
        this.fileApiService.getImageThumbnailById(this.images[index].imageId).subscribe(image => {
          this.loadImagePreview(image, this.images[index].imageId, this.images[index].name,
            index, this.images[index].isMainImage);
        })
      );
    }
  }

  private prefillPreviewLists() {
    this.imagePreviews = [];
    this.newImagesFullscreenPreviews = [];
    for (const _ of this.images) {
      this.imagePreviews.push(new ImagePreview());
    }
  }

  onDeleteImageClick(image: ImagePreview) {
    if (!image) { return; }
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('image.deleteImageHeader'),
      dialogText: this.translateService.instant('image.deleteImageText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.deleteImage(image);
      }
    });
  }

  private deleteImage(image: ImagePreview) {
    if (image.existingImageId != null) {
      this.imagePreviews = this.imagePreviews.filter(a => a?.existingImageId !== image.existingImageId);
      const imageDto = this.images.find(x => x.imageId === image.existingImageId);
      if (!imageDto) { return; }
      const index = this.images.indexOf(imageDto);
      this.images.splice(index, 1);
    } else if (image.newImageId != null) {
      this.imagePreviews = this.imagePreviews.filter(a => a.newImageId !== image.newImageId);
      this.newImagesFullscreenPreviews = this.newImagesFullscreenPreviews.filter(a => a.newImageId !== image.newImageId);
      this.recalculateNewIds();
    }
    if (this.imagePreviews.length < MAX_FILES_COUNT) {
      this.maxCountReached = false;
    }
    super.setExistingNamesLength(this.imagePreviews);
    this.filesEdited.emit(true);
  }

  private recalculateNewIds() {
    for (let index = 0; index < this.imagePreviews.length; index++) {
      this.imagePreviews[index].newImageId = index;
    }
    for (let index = 0; index < this.newImagesFullscreenPreviews.length; index++) {
      this.newImagesFullscreenPreviews[index].newImageId = index;
    }
  }

  onUploadImagesClick(images: FileList | null) {
    if (images && images.length > 0) {
      for (const image of images) {
        const fileType = image.type;
        if (fileType.match(/image\/*/) === null) {
          const dialogData = this.getInvalidImageDialogData(image.name);
          this.showDialog(dialogData);
          continue;
        }
        if (this.imagePreviews.length >= MAX_FILES_COUNT) {
          this.maxCountReached = true;
          const dialogData = this.getMaxImagesDialogData();
          this.showDialog(dialogData);
          return;
        }
        this.imagePreviews.push(new ImagePreview());
        const position = this.imagePreviews.length - 1;
        this.loadImagePreview(image, undefined, image.name, position, false);
        this.filesEdited.emit(true);
      }
    }
  }

  private loadImagePreview(image: Blob, existingImageId: number | undefined, existingImageName: string, index: number, isMain: boolean) {
    if (!image) { return; }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      if (existingImageId == null) {
        this.newImagesFullscreenPreviews.push({
          src: event.target.result,
          thumb: event.target.result,
          newImageId: index,
          downloadUrl: ''
        });
      }
      let assignedName = existingImageName;
      const listOfNames = this.imagePreviews.map(x => x?.fullName);
      if (listOfNames.includes(existingImageName)) {
        assignedName = this.appendTextToDuplicateNameRecursively(listOfNames, existingImageName, 0);
      }
      this.imagePreviews[index] = {
        image: event.target.result,
        imageFile: image,
        existingImageId,
        newImageId: index,
        isMain,
        name: super.getNameNoExtension(assignedName),
        fileExtension: super.getExtension(assignedName),
        fullName: assignedName
      };
      this.maxCountReached = this.imagePreviews.length >= MAX_FILES_COUNT;
      super.setExistingNamesLength(this.imagePreviews);
      this.cdr.detectChanges();
    };
    reader.readAsDataURL(image);
  }

  onMakeImageMainClick(imagePreview: ImagePreview) {
    if (!imagePreview) { return; }
    for (const image of this.imagePreviews) {
      image.isMain = false;
    }
    imagePreview.isMain = true;
  }

  private getInvalidImageDialogData(imageName: string): SimpleDialogData {
    return {
      title: imageName,
      messages: [this.translateService.instant('errors.notImage')], icon: 'error'
    };
  }

  private getMaxImagesDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('errors.maxFilesReached')], icon: 'error'
    };
  }

  private getDuplicateImageNamesDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.warning'),
      messages: [this.translateService.instant('warnings.duplicateImageNames')], icon: 'warning'
    };
  }

  openImageFullscreen(image: ImagePreview): void {
    if (image.existingImageId != null) {
      const imageId: number = image.existingImageId;
      const reader = new FileReader();
      this.fullscreenSubscription = this.fileApiService.getImageById(imageId)
        .subscribe(resultImage => {
          reader.readAsDataURL(resultImage);
          reader.onload = this.showPreviewOnLoad(imageId);
        });
    } else if (image.newImageId != null) {
      const imgIndex = this.newImagesFullscreenPreviews.findIndex(x => x.newImageId === image.newImageId);
      if (imgIndex !== -1) {
        this.showPreview(this.newImagesFullscreenPreviews, imgIndex);
      }
    }
  }

  private showPreviewOnLoad(existingImageId: number) {
    const album: ExistingImagesAlbum[] = [];
    return (event: any) => {
      album.push({
        src: event.target.result,
        thumb: event.target.result,
        existingImageId,
        downloadUrl: ''
      });
      this.showPreview(album, 0);
    };
  }

  private showPreview(album: IAlbum[], index: number) {
    this.lightbox.open(album, index, {
      centerVertically: 'true',
      wrapAround: 'true',
      showImageNumberLabel: 'true',
      fitImageInViewPort: 'true'
    });
  }

  close(): void {
    this.lightbox.close();
  }

  public getNewImagesFormData() {
    const newImagesList = this.imagePreviews.filter(x => x.existingImageId == null).map(x => x.imageFile);
    return super.getNewFilesFormData(newImagesList, 'files');
  }

  public recalculateImageIndices() {
    let runningIndex = 0;
    for (const image of this.images) {
      image.index = runningIndex++;
    }
  }

  public setMainImage() {
    const mainImageIndex = this.imagePreviews.findIndex(x => x.isMain === true);
    if (mainImageIndex !== -1 && this.images[mainImageIndex]) {
      this.images.forEach(x => x.isMainImage = false);
      this.images[mainImageIndex].isMainImage = true;
    }
  }

  public setImageNames() {
    for (const image of this.images) {
      image.name = `${this.imagePreviews[image.index].name}.${this.imagePreviews[image.index].fileExtension}`;
    }
  }

  onNameEditClicked(index: number) {
    super.onNameEdited(this.imagePreviews, index, this.getDuplicateImageNamesDialogData());
    this.imagePreviews[index].fullName = `${this.imagePreviews[index].name}.${this.imagePreviews[index].fileExtension}`;
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.fullscreenSubscription?.unsubscribe();
    for (const subscr of this.thumbnailSubscriptionList) {
      subscr.unsubscribe();
    }
  }
}
