import { PackagingPartBaseData } from '../packaging-part-base-data';
import { MultiMaterialLayerDto } from './multi-material-layer-dto';

export class MultiMaterialCompositeDto extends PackagingPartBaseData {
    id?: number;
    underlyingMultiMaterialId?: number | null;
    comment?: string;
    articleNumber?: string;
    articleName = '';
    gtin = '';
    manufacturerName = '';
    manufacturingCountry?: string;
    totalWeight?: number;
    totalGrammage?: number;
    index?: number;
    hasInternalTracking?: boolean;
    layers: MultiMaterialLayerDto[] = [];
    subMultiMaterials: MultiMaterialCompositeDto[] = [];
}
