import { PackagingSystemRecyclabilityResultDto } from './packaging-part-results/packaging-system-recyclability-result-dto';
import { PackagingUnitRecyclabilityResultDto } from './packaging-part-results/packaging-unit-recyclability-result-dto';

export class HistoryWrapperRecyclability {
    id!: number;
    analysisId!: string;
    version!: number;
    recyclabilityDtos: (PackagingUnitRecyclabilityResultDto | PackagingSystemRecyclabilityResultDto)[][] = [];
}

