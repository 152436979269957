import { getDialogConfig } from 'src/app/util/dialog-util';
import { ColorThemeService, COLOR_THEME_DARK } from './../../../navigation/services/color-theme-service';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { SelectLocationDialogComponent } from 'src/app/components/directory-tree/dialogs/select-location-dialog/select-location-dialog.component';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { CompositeMaterialDialogComponent } from './../../dialogs/composite-material-dialog/composite-material-dialog.component';
import { FlatMaterialLayersList, SelectionDialogMaterialsComponent } from '../../dialogs/selection-dialog-materials/selection-dialog-materials.component';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { MaterialApiService } from '../../../data-transfer/services/material-api-service';
import { firstValueFrom, forkJoin, Subscription } from 'rxjs';
import { MaterialDialogComponent } from '../../dialogs/material-dialog/material-dialog.component';
import { MaterialFunctionDto } from '../../../data-transfer/entities/material-function-dto';
import { ColorDto } from '../../../data-transfer/entities/color-dto';
import { MaterialManifestationDto } from '../../../data-transfer/entities/material-manifestation-dto';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MaterialDataHandler, SHOULDER_FUNCTION_ID } from 'src/app/services/material-services/material-data-handler';
import { DeleteItemDialogComponent } from '../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { SimpleAlertDialogComponent } from '../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { CompositeMaterialSevice, MappedMaterialLayer } from 'src/app/services/material-services/composite-material-service';
import { TUBE_PACKAGING_TYPE_ID } from 'src/app/navigation/services/packaging-unit-type-service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogActions } from 'src/app/model/dictionary';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';

@Component({
  selector: 'app-multi-material-table',
  templateUrl: './multi-material-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./multi-material-table.component.scss']
})
export class MultiMaterialTableComponent implements OnInit, OnChanges, OnDestroy {

  @Input() parentCompositeMaterial!: MultiMaterialCompositeDto;
  @Input() canEditForm = true;
  @Input() isIndependentMaterial = false;
  @Input() isPartOfMaterialImport = false;
  @Input() isFirstOpen = true;
  @Input() public allMaterialFunctions!: MaterialFunctionDto[];
  @Input() public allManifestations!: MaterialManifestationDto[];
  @Input() public allColors!: ColorDto[];
  @Input() public packagingUnitTypeId = -1;
  @Input() public callerId = -1;
  @Input() public manufacturingCountry? = '';
  @Input() public isMassRequired = true;

  @Output() totalWeightChanged = new EventEmitter();
  @Output() layersSelected = new EventEmitter();

  private isDarkTheme = false;
  private isDetectionLayerSet = false;
  shoulderFunctionId = SHOULDER_FUNCTION_ID;

  dataSource: MatTableDataSource<MappedMaterialLayer>;
  displayedColumns = ['functionName', 'materialManifestationName', 'mass', 'colorName'];
  selectedMaterials: MultiMaterialLayerDto[] = [];
  displayTrackedColumn = false;
  private allMaterialsSelected = false;
  private calculatorTotalWeight: number | null = null;

  private dialogSubscription?: Subscription;
  private saveMaterialSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    private materialApiService: MaterialApiService,
    private directoryApiService: DirectoryApiService,
    private colorThemeService: ColorThemeService,
    private materialDataHandler: MaterialDataHandler,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.dataSource = new MatTableDataSource<MappedMaterialLayer>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.assignTableRowColors();
    });
  }

  ngOnInit(): void {
    if (this.parentCompositeMaterial.layers.length == 0 &&
      this.parentCompositeMaterial.id == null &&
      !this.isPartOfMaterialImport && this.isFirstOpen) {
      this.parentCompositeMaterial.layers.push(this.getDefaultMaterialLayer());
    }
    this.setTableDataSource();
    if (this.isPartOfMaterialImport) {
      this.displayedColumns.push('select');
    } else {
      this.displayedColumns.unshift('tracking', 'moveLayer', 'isDetectionLayer');
      this.displayedColumns.push('action');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.packagingUnitTypeId && changes.packagingUnitTypeId.currentValue != null && !changes.packagingUnitTypeId.firstChange) {
      if (changes.packagingUnitTypeId.currentValue === TUBE_PACKAGING_TYPE_ID ||
        changes.packagingUnitTypeId.previousValue === TUBE_PACKAGING_TYPE_ID) {
        this.dataSource.data.forEach(material => {
          material.isValidMaterial = this.checkMaterialValidity(material);
        });
      }
    }
  }

  private getDefaultMaterialLayer() {
    const layer = new MultiMaterialLayerDto();
    layer.functionId = 1;
    layer.functionName = this.getFunctionName(layer.functionId);
    layer.materialId = -1;
    layer.materialManifestationId = -1;
    layer.index = 0;
    layer.recyclingMaterialPercentage = undefined;
    return layer;
  }

  private setTableDataSource() {
    const allMaterialLayers = this.getMaterialLayers(this.parentCompositeMaterial);
    this.dataSource.data = allMaterialLayers;
    this.resetDetectionLayer();
    this.assignTableRowColors();
    this.displayTrackedColumn = this.dataSource.data.filter(x => x.isTracked).length > 0;
  }

  private getMaterialLayers(material: MultiMaterialCompositeDto): MappedMaterialLayer[] {
    const parentLayers: MappedMaterialLayer[] = material.layers;
    parentLayers.forEach(x => this.fillInternalData(x, material.id, material.index, true));

    let allSubMaterialsLayers: MappedMaterialLayer[] = [];
    material.subMultiMaterials.forEach(subMaterial => {
      if (subMaterial.id == null || subMaterial.index == null) { return; }
      const subMaterialLayers = CompositeMaterialSevice.getMaterialLayersRecursively(subMaterial);
      const isTracked = subMaterial.hasExternalTracking || subMaterial.hasInternalTracking;
      for (let index = 0; index < subMaterialLayers.length; index++) {
        const layer = subMaterialLayers[index];
        const isFirst = index === 0;
        this.fillInternalData(layer, subMaterial.id, subMaterial.index, isFirst, isTracked);
      }
      allSubMaterialsLayers = allSubMaterialsLayers.concat(subMaterialLayers);
    });
    allSubMaterialsLayers.sort((a, b) => a.index != null && b.index != null ? a.index - b.index : 0);

    const allItemsList = parentLayers.concat(allSubMaterialsLayers);
    allItemsList.sort((a, b) => {
      const indexA = a.parentMaterialId === this.parentCompositeMaterial.id ? a.index : a.parentMaterialIndex;
      const indexB = b.parentMaterialId === this.parentCompositeMaterial.id ? b.index : b.parentMaterialIndex;
      return indexA != null && indexB != null ? indexA - indexB : 0;
    });
    let id = 0;
    allItemsList.forEach(x => x.internalId = id++);
    return allItemsList;
  }

  private fillInternalData(
    layer: MappedMaterialLayer, parentId?: number, parentIndex?: number, isFirst = false, isTracked = false
  ) {
    if (parentId != null) { layer.parentMaterialId = parentId; }
    if (parentIndex != null) { layer.parentMaterialIndex = parentIndex; }
    layer.isValidMaterial = this.checkMaterialValidity(layer);
    layer.isFirstLayerInGroup = isFirst;
    layer.isTracked = isTracked;
  }

  deleteMaterial(layerToDelete: MappedMaterialLayer) {
    const dialogConfig = getDialogConfig(this.materialDataHandler.getDeleteMaterialDialogData(), '500px');
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event !== DialogActions.DELETE) { return; }
      if (layerToDelete.parentMaterialId === this.parentCompositeMaterial.id) {
        const layersList: MappedMaterialLayer[] = this.parentCompositeMaterial.layers;
        const indexToDelete = layersList.findIndex(x => x.internalId === layerToDelete.internalId);
        layersList.splice(indexToDelete, 1);
      } else {
        const indexToDelete = this.parentCompositeMaterial.subMultiMaterials.findIndex(x =>
          x.id === layerToDelete.parentMaterialId && x.index === layerToDelete.parentMaterialIndex);
        this.parentCompositeMaterial.subMultiMaterials.splice(indexToDelete, 1);
      }
      if (layerToDelete.index != null) {
        this.reassignIndicesOnDelete(this.parentCompositeMaterial.layers, layerToDelete.index);
        this.reassignIndicesOnDelete(this.parentCompositeMaterial.subMultiMaterials, layerToDelete.index);
      }
      this.setTableDataSource();
      this.totalWeightChanged.emit(this.calculateTotalWeight());
    });
  }

  private reassignIndicesOnDelete(items: MultiMaterialLayerDto[] | MultiMaterialCompositeDto[], deletedIndex: number) {
    items.forEach(item => {
      if (item.index && item.index > deletedIndex) { item.index = item.index - 1; }
    });
  }

  private reassignIndicesOnPaste(items: MultiMaterialLayerDto[] | MultiMaterialCompositeDto[], pastedIdx: number, pastedCount: number) {
    items.forEach(item => {
      if (item.index && item.index > pastedIdx) { item.index = item.index + pastedCount - 1; }
    });
  }

  private dissolveMaterial(parentLayers: MappedMaterialLayer[], parentIndex: number): void {
    let newIndex = parentIndex;
    parentLayers.forEach(layer => {
      layer.index = newIndex++;
      layer.parentMaterialId = this.parentCompositeMaterial.id;
      layer.parentMaterialIndex = this.parentCompositeMaterial.index;
    });
  }

  private findCompositeMaterialByIdRecursively(
    compositeMaterial: MultiMaterialCompositeDto, id: number, index: number
  ): MultiMaterialCompositeDto | undefined {
    if (compositeMaterial.id === id && compositeMaterial.index === index) {
      return compositeMaterial;
    }
    const returnSub = compositeMaterial.subMultiMaterials.find(subMaterial => {
      const sub = this.findCompositeMaterialByIdRecursively(subMaterial, id, index);
      return sub?.id === id && sub?.index === index;
    });
    return returnSub;
  }

  private findCompositeMaterialByUnderlyingIdRecursively(
    compositeMaterial: MultiMaterialCompositeDto, id: number
  ): MultiMaterialCompositeDto | undefined {
    if (compositeMaterial.underlyingMultiMaterialId === id) {
      return compositeMaterial;
    }
    const returnSub = compositeMaterial.subMultiMaterials.find(subMaterial => {
      const sub = this.findCompositeMaterialByUnderlyingIdRecursively(subMaterial, id);
      return sub?.underlyingMultiMaterialId === id;
    });
    return returnSub;
  }

  addMaterial() {
    this.addEditMaterial(DialogActions.ADD, this.getDefaultMaterialLayer());
  }

  editMaterial(materialLayer: MappedMaterialLayer) {
    if (!materialLayer) { return; }
    this.addEditMaterial(DialogActions.EDIT, materialLayer);
  }

  private async addEditMaterial(action: number, materialLayer: MappedMaterialLayer) {
    const layersInfo: MultiMaterialLayerDto[] = this.dataSource.data.slice();
    if (action === DialogActions.EDIT) {
      const index = this.dataSource.data.findIndex(x => x.internalId === materialLayer.internalId);
      layersInfo.splice(index, 1);
    }

    if (materialLayer.functionId == null ||
      materialLayer.materialId == null ||
      materialLayer.materialManifestationId == null) {
      throw new Error('MultiMaterialTableComponent: function ID or material ID or manifestation ID undefined');
    }
    const materialsObservable = this.materialApiService.getValidMaterials(
      materialLayer.functionId, this.callerId, this.packagingUnitTypeId);
    const manifestationsObservable = this.materialApiService.getValidManifestations(
      materialLayer.materialId, materialLayer.functionId, this.callerId, this.packagingUnitTypeId);
    const manufTypesObservable = this.materialApiService.getValidManufacturingTypes(
      materialLayer.materialManifestationId, materialLayer.functionId, this.callerId, this.packagingUnitTypeId);

    const apiResult = await firstValueFrom(forkJoin(
      [materialsObservable, manufTypesObservable, manifestationsObservable]));
    const validMaterials = apiResult[0];
    const validManufacturingTypes = apiResult[1];
    const validManifestations = apiResult[2];

    const compositeMaterial = this.findCompositeMaterialByIdRecursively(
      this.parentCompositeMaterial, materialLayer.parentMaterialId ?? -1, materialLayer.parentMaterialIndex ?? -1);
    const isTracked = compositeMaterial?.hasExternalTracking || compositeMaterial?.hasInternalTracking;
    if (!this.allMaterialFunctions.map(x => x.id).includes(materialLayer.functionId)) {
      materialLayer = this.getDefaultMaterialLayer();
    }
    const dialogData = {
      action, materialLayer, validMaterials, validManifestations, validManufacturingTypes, layersInfo, isTracked,
      callerId: this.callerId,
      totalWeight: this.parentCompositeMaterial?.totalWeight,
      calculatorTotalWeight: this.calculatorTotalWeight,
      totalGrammage: this.parentCompositeMaterial?.totalGrammage,
      packagingUnitTypeId: this.packagingUnitTypeId,
      canEditForm: this.canEditForm,
      allMaterialFunctions: this.allMaterialFunctions,
      isMassRequired: this.isMassRequired,
    };
    const dialogConfig = getDialogConfig(dialogData, '1300px');

    const dialogRef = this.dialog.open(MaterialDialogComponent, dialogConfig);

    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }
      this.calculatorTotalWeight = dialogResult.data.totalWeight;
      const returnMaterial: MappedMaterialLayer = dialogResult.data.layer;
      returnMaterial.functionName = this.getFunctionName(returnMaterial.functionId);
      returnMaterial.materialManifestationName = this.getManifestationName(returnMaterial.materialManifestationId);
      returnMaterial.colorName = this.getColorName(returnMaterial.colorId);
      if (dialogResult.event === DialogActions.ADD) {
        returnMaterial.index = this.getLatestIndex() + 1;
        this.parentCompositeMaterial.layers.push(returnMaterial);
        (returnMaterial as any)['recyclingMaterialPercentage']  = returnMaterial['recyclatePercentage' as keyof MappedMaterialLayer]
      } else if (dialogResult.event === DialogActions.EDIT) {
        let parentMaterial = this.findCompositeMaterialByIdRecursively(this.parentCompositeMaterial,
          materialLayer.parentMaterialId ?? -1, materialLayer.parentMaterialIndex ?? -1);
        if (!parentMaterial) { parentMaterial = this.parentCompositeMaterial; }
        const layersList: MappedMaterialLayer[] = CompositeMaterialSevice.getMaterialLayersRecursively(parentMaterial);
        const layerToEdit = layersList.find(x => x.internalId === materialLayer.internalId);
        if (!layerToEdit) { return; }
        Object.keys(returnMaterial).forEach(key => {
          key === 'recyclatePercentage' ?
            (layerToEdit as any)['recyclingMaterialPercentage' as keyof MappedMaterialLayer] =
            returnMaterial[key as keyof MappedMaterialLayer] :
            (layerToEdit as any)[key as keyof MappedMaterialLayer] = returnMaterial[key as keyof MappedMaterialLayer]
        });
      }
      this.setTableDataSource();
      this.totalWeightChanged.emit(this.calculateTotalWeight());
    });
  }

  private calculateTotalWeight(): number {
    let totalWeight = 0;
    this.dataSource.data.forEach(materialLayer => totalWeight += materialLayer.mass ?? 0);
    return +totalWeight.toFixed(3);
  }

  private checkMaterialValidity(material: MultiMaterialLayerDto) {
    return material.manufacturingTypeId != null && (this.isIndependentMaterial || material.mass != null) &&
      (material.functionId !== this.shoulderFunctionId || this.packagingUnitTypeId === TUBE_PACKAGING_TYPE_ID);
  }

  private getColorName(colorId: number | undefined): string {
    return colorId != null ? this.allColors.find(x => x.id === colorId)?.name ?? '' : '';
  }

  private getManifestationName(manifestationId: number | undefined): string {
    return manifestationId != null ? this.allManifestations.find(x => x.id === manifestationId)?.name ?? '' : '';
  }

  private getFunctionName(functionId: number | undefined): string {
    return functionId != null ? this.allMaterialFunctions.find(x => x.id === functionId)?.name ?? '' : '';
  }

  private moveMaterial(layer: MappedMaterialLayer, moveUp: boolean) {
    let indexOfItemToMove: number;
    let subMaterial: MultiMaterialCompositeDto | undefined;
    if (layer.parentMaterialId === this.parentCompositeMaterial.id) {
      indexOfItemToMove = layer.index ?? -1;
    } else {
      subMaterial = this.findCompositeMaterialByIdRecursively(
        this.parentCompositeMaterial, layer.parentMaterialId ?? -1, layer.parentMaterialIndex ?? -1);
      if (!subMaterial) { return; }
      indexOfItemToMove = subMaterial.index ?? -1;
    }
    if (moveUp) {
      if (indexOfItemToMove === 0) { return; }
      const layerOnDesiredPosition = this.parentCompositeMaterial.layers.find(x => x.index === indexOfItemToMove - 1);
      if (layerOnDesiredPosition) {
        layerOnDesiredPosition.index = indexOfItemToMove;
      } else {
        const submaterialOnDesiredPosition = this.parentCompositeMaterial.subMultiMaterials.find(x => x.index === indexOfItemToMove - 1);
        if (submaterialOnDesiredPosition) { submaterialOnDesiredPosition.index = indexOfItemToMove; }
      }
      if (subMaterial != null) {
        subMaterial.index = indexOfItemToMove - 1;
      } else {
        layer.index = indexOfItemToMove - 1;
      }
    } else {
      const latestIndex = this.getLatestIndex();
      if (indexOfItemToMove === latestIndex) { return; }
      const layerOnDesiredPosition = this.parentCompositeMaterial.layers.find(x => x.index === indexOfItemToMove + 1);
      if (layerOnDesiredPosition) {
        layerOnDesiredPosition.index = indexOfItemToMove;
      } else {
        const submaterialOnDesiredPosition = this.parentCompositeMaterial.subMultiMaterials.find(x => x.index === indexOfItemToMove + 1);
        if (submaterialOnDesiredPosition) { submaterialOnDesiredPosition.index = indexOfItemToMove; }
      }
      if (subMaterial) {
        subMaterial.index = indexOfItemToMove + 1;
      } else {
        layer.index = indexOfItemToMove + 1;
      }
    }
    this.setTableDataSource();
    this.totalWeightChanged.emit(this.calculateTotalWeight()); // Needed to detect that material was changed
  }

  moveMaterialOneDown(layer: MappedMaterialLayer) {
    this.moveMaterial(layer, false);
  }

  moveMaterialOneUp(layer: MappedMaterialLayer) {
    this.moveMaterial(layer, true);
  }

  private resetDetectionLayer() {
    this.isDetectionLayerSet = false;
    for (const material of this.dataSource.data) {
      material.isDetectionLayer = this.isDetectionLayer(material.functionId);
    }
  }

  private assignTableRowColors() {
    const colorOdd = this.isDarkTheme ? '#2d2d2d' : 'white';
    const colorEven = this.isDarkTheme ? '#3d3d3d' : '#eeeeee';
    this.dataSource.data.forEach(x => {
      const index = x.parentMaterialId === this.parentCompositeMaterial.id ? x.index : x.parentMaterialIndex;
      if ((index ?? 0) % 2 === 0) {
        x.color = colorOdd;
      } else {
        x.color = colorEven;
      }
    });
  }

  private isDetectionLayer(functionId: number | undefined) {
    if (functionId == null) { return false; }
    const isCandidate = this.materialDataHandler.isDetectionLayerCandidate(functionId);
    if (isCandidate && (this.isDetectionLayerSet == null || !this.isDetectionLayerSet)) {
      this.isDetectionLayerSet = true;
      return true;
    }
    return false;
  }

  async importCompositeMaterial() {
    this.spinner.show();
    const materials = await firstValueFrom(this.materialApiService.getAllCompositeMaterials());

    const dataSource = new MatTableDataSource<MultiMaterialCompositeDto>();
    dataSource.data = materials.filter((x: any) => {
      const isMaterialParent = x.id !== this.parentCompositeMaterial.id;
      const isParentImportedInMaterial = this.findCompositeMaterialByUnderlyingIdRecursively(
        x, this.parentCompositeMaterial.id ?? -1) != null;
      return isMaterialParent && !isParentImportedInMaterial;
    });

    const flatMaterialsList: FlatMaterialLayersList[] = [];
    dataSource.data.forEach(x => {
      if (x.id == null) { return; }
      const allMaterialLayers = CompositeMaterialSevice.getMaterialLayersRecursively(x);
      flatMaterialsList.push({ materialId: x.id, allMaterialLayers });
    });

    const displayedColumns = ['id', 'articleName', 'articleNumber', 'manufacturerName', 'select'];

    const dialogData = {
      dataSource, displayedColumns, callerId: this.callerId, packagingUnitTypeId: this.packagingUnitTypeId, flatMaterialsList
    };
    const dialogConfig = getDialogConfig(dialogData, '1100px');
    this.spinner.hide();
    const dialogRef = this.dialog.open(SelectionDialogMaterialsComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      let latestIndex = this.getLatestIndex();
      const importedMaterials: MultiMaterialCompositeDto[] = result.data;
      importedMaterials.forEach(x => x.index = ++latestIndex);
      this.parentCompositeMaterial.subMultiMaterials = this.parentCompositeMaterial.subMultiMaterials.concat(importedMaterials);
      this.setTableDataSource();
      this.totalWeightChanged.emit(this.calculateTotalWeight());
    });
  }

  private getLatestIndex(): number {
    if (this.parentCompositeMaterial.layers?.length === 0 && this.parentCompositeMaterial.subMultiMaterials?.length === 0) {
      return -1;
    }
    const a: number[] = this.parentCompositeMaterial.layers.map(x => x.index ?? -1);
    const maxLayerIndex = a.length > 0 ? Math.max(...a) : -1;
    const b: number[] = this.parentCompositeMaterial.subMultiMaterials.map(x => x.index ?? -1);
    const maxSubMaterialIndex = b.length > 0 ? Math.max(...b) : -1;
    return Math.max(maxLayerIndex, maxSubMaterialIndex);
  }

  async breakUpSubmaterial(materialLayer: MappedMaterialLayer) {
    const dialogConfig = getDialogConfig(this.materialDataHandler.getDissolveMaterialDialogData());
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    const subMaterialToBreakUp = this.parentCompositeMaterial.subMultiMaterials.find(x =>
      x.id === materialLayer.parentMaterialId && x.index === materialLayer.parentMaterialIndex);
    if (!subMaterialToBreakUp) { return; }
    const subMaterialLayers = CompositeMaterialSevice.getMaterialLayersRecursively(subMaterialToBreakUp);
    const materialToBreakUpIndex = subMaterialToBreakUp.index;
    if (materialToBreakUpIndex == null) { return; }
    this.dissolveMaterial(subMaterialLayers, materialToBreakUpIndex);
    this.reassignIndicesOnPaste(this.parentCompositeMaterial.layers, materialToBreakUpIndex, subMaterialLayers.length);
    this.reassignIndicesOnPaste(this.parentCompositeMaterial.subMultiMaterials, materialToBreakUpIndex, subMaterialLayers.length);
    this.parentCompositeMaterial.layers = this.parentCompositeMaterial.layers.concat(subMaterialLayers);
    this.parentCompositeMaterial.layers.sort((a, b) => a.index && b.index ? a.index - b.index : 0);

    const subMaterialIndexToDelete = this.parentCompositeMaterial.subMultiMaterials.findIndex(x =>
      x.id === subMaterialToBreakUp.id && x.index === subMaterialToBreakUp.index);
    this.parentCompositeMaterial.subMultiMaterials.splice(subMaterialIndexToDelete, 1);
    this.setTableDataSource();
  }

  saveCompositeMaterial() {
    const compositeMaterial = new MultiMaterialCompositeDto();
    const validParentMaterials: MultiMaterialLayerDto[] = [];
    this.parentCompositeMaterial.layers.forEach(x => {
      if (this.checkMaterialValidity(x)) { validParentMaterials.push(x); }
    });
    compositeMaterial.layers = validParentMaterials;
    compositeMaterial.subMultiMaterials = ([] as MultiMaterialCompositeDto[]).concat(this.parentCompositeMaterial.subMultiMaterials);
    const dialogData = {
      compositeMaterial,
      canEditForm: this.canEditForm
    };
    const dialogConfig = getDialogConfig(dialogData, '1300px');
    const dialogRef = this.dialog.open(CompositeMaterialDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(async dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }

      this.spinner.show();
      const rootDirDto = await firstValueFrom(this.directoryApiService.getDirectories());
      this.spinner.hide();
      const targetDirectoryId = await this.selectDirectory(rootDirDto);
      if (targetDirectoryId == null) { return; }

      const returnMaterial = dialogResult.data;
      returnMaterial.directoryId = targetDirectoryId;

      this.saveMaterialSubscription = this.materialApiService.putCompositeMaterial(returnMaterial).subscribe({
        next: response => {
          console.log('Material ID: ', response);
          this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.materialDataHandler.getMaterialSucessDialogData(), '350px'));
        },
        error: e => {
          console.log('Error has occured when saving material.', e);
        },
      });
    });
  }

  private selectDirectory(rootFolder: DirectoryDto): Promise<number> {
    const dialogData = {
      rootFolder,
      unreachableFolders: []
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    return dialogRef.afterClosed()
      .toPromise().then(result => {
        if (result.event !== DialogActions.REJECT) {
          return Promise.resolve(result.data.id);
        }
      });
  }

  isMaterialSelected(item: MultiMaterialLayerDto): boolean {
    if (this.selectedMaterials && this.selectedMaterials.length > 0) {
      return this.selectedMaterials.includes(item);
    }
    return false;
  }

  setMaterialSelected(checked: boolean, item: MultiMaterialLayerDto) {
    if (checked) {
      this.selectedMaterials.push(item);
      if (this.selectedMaterials.length === this.dataSource.data.length) { this.allMaterialsSelected = true; }
    } else {
      const index = this.selectedMaterials.indexOf(item);
      this.selectedMaterials.splice(index, 1);
      this.allMaterialsSelected = false;
    }
    this.layersSelected.emit(this.selectedMaterials);
  }

  selectAllMaterials() {
    this.allMaterialsSelected = !this.allMaterialsSelected;
    this.selectedMaterials = [];
    if (this.allMaterialsSelected) {
      this.selectedMaterials = this.dataSource.data.filter(x => x.functionId !== this.shoulderFunctionId);
    }
    this.layersSelected.emit(this.selectedMaterials);
  }

  areMaterialLayersValid() {
    const materials = this.dataSource.data;
    return materials && materials.filter(x => x.isValidMaterial === false).length === 0;
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.saveMaterialSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
