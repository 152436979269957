<h1 mat-dialog-title>
  <span matDialogTitle class="centered">
    {{'contactInfo.contactLabel' | translate}}
  </span>
</h1>
<mat-dialog-content>
  <div >
    <p>{{'contactInfo.contactStartText' | translate}}</p>
    <p>
      <strong class="wholeLine">{{'contactInfo.emailUpgradeLabel'| translate}}</strong>
      <span class="wholeLine"> {{'contactInfo.emailUpgradeText'| translate}}</span>
      <a [href]="getEmailText('contactInfo.emailUpgrade')"> {{'contactInfo.emailUpgrade'| translate}}</a>
    </p>
    <p>
      <strong class="wholeLine">{{'contactInfo.emailSupportLabel'| translate}}</strong>
      <span class="wholeLine"> {{'contactInfo.emailSupportText'| translate}}</span>
      <a [href]="getEmailText('contactInfo.emailSupport')"> {{'contactInfo.emailSupport'| translate}}</a>
    </p>
    <p>
      <strong class="wholeLine">{{'contactInfo.packoOfficeLabel'| translate}}</strong>
      <span class="wholeLine"> {{'contactInfo.packoOfficeText'| translate}}</span>
      <a [href]="getEmailText('contactInfo.packoOffice')"> {{'contactInfo.packoOffice' | translate}}</a>
    </p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button class="rightAligned" (click)="doConfirm()" mat-raised-button type="button"
    color="primary">{{ 'common.buttons.ok' | translate }}</button>
</mat-dialog-actions>
