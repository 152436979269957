<app-packaging-unit-tree [packagingUnit]="packagingUnit" [isDarkTheme]="isDarkTheme"
  (nodeSelected)="onNodeSelected($event)">
</app-packaging-unit-tree>

<mat-sidenav-container class="inner-container">
  <mat-sidenav opened mode="side" class="left-sidenav">
    <mat-button-toggle-group name="environmentalEffect" #group="matButtonToggleGroup" vertical
      [value]="selectedEffectIdx" (change)="onEffectChanged($event.value)">
      <mat-button-toggle *ngFor="let effect of dataSourceForCountry.effectRelevances; let effectIndex = index"
        [value]="effectIndex" [ngClass]="{'relevant-dark' : effect.isRelevant && isDarkTheme,
        'relevant-light' : effect.isRelevant && !isDarkTheme,
        'selected-dark': effectIndex === selectedEffectIdx && isDarkTheme,
        'selected-light': effectIndex === selectedEffectIdx && !isDarkTheme}"
        [matTooltip]="this.getTooltipName(effectIndex)"
        matTooltipPosition = "right">
        <mat-icon *ngIf="!this.isEffectAuthorized(effectIndex)">lock</mat-icon>
        {{effect.name}}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-sidenav>

  <mat-card *ngIf="this.selectedEffectIdx >= 0" class="card-container">
    <mat-card-content>
      <div *ngIf="this.isEffectAuthorized(this.selectedEffectIdx)" class="total">
        {{ effectName }}: {{ effectValue }} {{ effectUnit }}
      </div>
      <div *ngIf="!this.isEffectAuthorized(this.selectedEffectIdx)" class="total">
        {{ effectName }}:
      </div>
      <div>{{ effectDescription }}</div>
      <div>
        <strong>{{ 'analysis.lifecycleAnalysis.measurementUnit' | translate }}</strong>{{ effectUnitDescription }}
      </div>
      <div *ngIf="this.isEffectAuthorized(this.selectedEffectIdx)" class="buttons-container">
        <button mat-raised-button class="button-margin" color="primary" type="button" (click)="openTable()">{{
          'analysis.lifecycleAnalysis.table' | translate }}
        </button>
        <button mat-raised-button class="button-margin" *ngIf="selectedNodeType !== packagingPart.Component"
          color="primary" (click)="generateExcel()" type="button">
          <mat-icon>description</mat-icon>
          {{ 'analysis.lifecycleAnalysis.excelExportButtonLabel' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-container">
    <app-buying-from-shop  *ngIf="!this.isEffectAuthorized(this.selectedEffectIdx)" [buyType]="buyType.EnvironmentallEffect"
      [contentValue]="this.effectName" >
    </app-buying-from-shop>
    <mat-card-content [class]="this.isEffectAuthorized(this.selectedEffectIdx)? 'single-chart' : 'single-chart hide-chart'">
      <app-life-cycle-chart [chartRawData]="dataSourceForCountry.stagesChartDataSource[selectedEffectIdx]"
        isLcaStagesChart="true" [unit]="effectUnit" [chartTitle]="chartTitle" [chartSubtitle]="chartSubtitle"
        [chartExportCaption]="effectName + ', ' + effectUnit" [isDarkTheme]="isDarkTheme">
      </app-life-cycle-chart>
    </mat-card-content>
  </mat-card>
</mat-sidenav-container>
