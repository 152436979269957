<h1 mat-dialog-title>
  {{ 'dataManagement.quantities.multiTitle' | translate }}
</h1>

<mat-dialog-content>
  <div [formGroup]="form">
    <div formGroupName="selectGroup">
      <div id="settings-container">
        <div>
          <button class="change-button-left" mat-icon-button (click)="onMonthChanged(-1)"
            [disabled]="form.dirty || isLoading">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <mat-form-field>
            <mat-label>{{ 'dataManagement.quantities.monthAndYear' | translate }}</mat-label>
            <input matInput readonly [matDatepicker]="dp" formControlName="selectedMonthAndYear">
            <mat-datepicker-toggle matSuffix [for]="dp" class="date-toggle"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (monthSelected)="onMonthAndYearSelected($event, dp)" panelClass="month-picker">
            </mat-datepicker>
          </mat-form-field>
          <button class="change-button-right" mat-icon-button (click)="onMonthChanged(1)"
            [disabled]="form.dirty || isLoading">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <mat-form-field  class="form-field-country">
          <mat-label>{{ 'dataManagement.quantities.country' | translate }}</mat-label>
          <mat-select formControlName="countryCode" id="name" (selectionChange)="onCountryChanged()">
            <mat-option *ngFor="let country of conutries" [value]="country.code">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="table-container">
      <app-packaging-units-overview
        *ngIf="isPackagingUnit && !isLoading && selectGroup.errors?.['allItemsRequired'] == null" [dataSource]="unitDataSource"
        [displayedColumns]="displayedColumnsUnit" [form]="form"
        (distributionCountryAdded)="addDistributionCountry($event)">
      </app-packaging-units-overview>
      <app-packaging-systems-overview
        *ngIf="isPackagingSystem && !isLoading && selectGroup.errors?.['allItemsRequired'] == null" [dataSource]="systemDataSource"
        [displayedColumns]="displayedColumnsSystem" [form]="form"
        (distributionCountryAdded)="addDistributionCountry($event)">
      </app-packaging-systems-overview>
      <div class="quantity-spinner-container" *ngIf="isLoading">
        <mat-spinner diameter="48" color="accent">
        </mat-spinner>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onSubmit()" disabled [disabled]="form.invalid">
    {{ 'common.buttons.apply' |translate }} </button>
  <button mat-dialog-close mat-raised-button> {{ 'common.buttons.close' |translate }} </button>
</mat-dialog-actions>
