import {environments} from './environments';

export const environment = {
  name: environments.test,
  production: false,
  webApiAddress: 'https://test.cockpit.packaging-cockpit.com',
  appInsightsConfig: {
    instrumentationKey: '',
  }
};
