<table mat-table [dataSource]="mappedDataSource" id="my-table" matSort>

  <ng-container matColumnDef="itemId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.import.id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.itemId}} </td>
  </ng-container>

  <ng-container matColumnDef="exportingOrganization">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.import.exportingOrganization' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.exportingOrganization.name}} </td>
  </ng-container>

  <ng-container matColumnDef="packagingTypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'packagingUnit.packagingUnitType' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.packagingTypeName }} </td>
  </ng-container>

  <ng-container matColumnDef="brandName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'packagingUnit.fields.brandName' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.brandName }} </td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'packagingUnit.fields.productName' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
  </ng-container>

  <ng-container matColumnDef="packagingComponentCategoryName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'component.componentType' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.packagingComponentCategoryName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="packagingComponentSubtypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'component.componentSpecificType' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.packagingComponentSubtypeName }} </td>
  </ng-container>

  <ng-container matColumnDef="articleName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'component.fields.articleName' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.articleName }} </td>
  </ng-container>

  <ng-container matColumnDef="articleNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'component.fields.articleNumber' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.articleNumber }} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'dataManagement.actions' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button type="button" (click)="navigateToCandidate(element)" matTooltip="{{
        (isPackagingSystem ? 'packagingSystem.viewPackagingSystem' :
        isPackagingUnit ? 'packagingUnit.viewPackagingUnit' :
        isComponent ? 'component.viewComponent' :
        isMaterial ? 'material.viewMaterial' : '') | translate }}" *ngIf="!isChange; else viewChanges">
        <mat-icon>preview</mat-icon>
      </button>
      <ng-template #viewChanges>
        <button mat-icon-button type="button" (click)="navigateToCandidate(element, true)" matTooltip="{{
          (isPackagingSystem ? 'packagingSystem.viewPackagingSystemBeforeChange' :
          isPackagingUnit ? 'packagingUnit.viewPackagingUnitBeforeChange' :
          isComponent ? 'component.viewComponentBeforeChange' :
          isMaterial ? 'material.viewMaterialBeforeChange' : '') | translate }}">
          <mat-icon>access_time</mat-icon>
        </button>
        <button mat-icon-button type="button" (click)="navigateToCandidate(element, false)" matTooltip="{{
          (isPackagingSystem ? 'packagingSystem.viewPackagingSystemAfterChange' :
          isPackagingUnit ? 'packagingUnit.viewPackagingUnitAfterChange' :
          isComponent ? 'component.viewComponentAfterChange' :
          isMaterial ? 'material.viewMaterialAfterChange' : '') | translate }}">
          <mat-icon>access_time_filled</mat-icon>
        </button>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" (click)="selectAll()" type="button">
          <div>{{ 'common.buttons.selectAll' | translate }} <br> ({{selectedCandidates.length}}
            {{ 'common.text.selected' | translate }})</div>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [checked]="isSelectedCandidate(element)" (change)="setSelectedCandidates($event.checked, element)"
        class="margin-checkbox">
      </mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
