<div class="full-height">
  <div class="margin-container">
    <input type="file" accept=".xlsx" (change)="uploadExcelFile($event)" #hiddenFileInput
      onclick="value=null" style="display: none" />

    <button mat-raised-button (click)="hiddenFileInput.click()" color="primary" type="button">
      <mat-icon class="material-icons left">add</mat-icon>
      {{ 'excel.uploadButton' | translate }}
    </button>
    <span *ngIf="excelFile">
      {{ excelFile.name }}
    </span>
  </div>

  <div class="margin-container">
    <button mat-raised-button (click)="importExcelFile()" color="primary" type="button">
      {{ 'excel.importFile' | translate }}
    </button>
  </div>
</div>
