<h1 mat-dialog-title>
  <span matDialogTitle class="align-center">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{title}}
  </span>
</h1>
<mat-dialog-content>
  <div *ngFor="let message of messages">
    <p>{{message}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="doConfirm()" mat-raised-button type="button"
    color="primary">{{ 'common.buttons.ok' | translate }}</button>
  <button (click)="doReject()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
