<mat-card id="outer-card">
  <mat-card-content id="outer-card-content">
    <div id="countries-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" id="countries-toggle" vertical [value]="selectedCountryCode"
        (change)="onCountrySelection($event.value)">
        <mat-button-toggle *ngFor="let country of allCountries" [value]="country.code">
          {{country.name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div id="infos-container">
      <mat-tab-group class="full-height" *ngIf="selectedCountryCode != null">
        <mat-tab label="Tab 1">
          <div class="tab-content">
            {{selectedCountryCode}}
          </div>
        </mat-tab>
        <mat-tab label="Tab 2">
          <div class="tab-content">
            {{selectedCountryCode}}
          </div>
        </mat-tab>
        <mat-tab label="Tab 3">
          <div class="tab-content">
            {{selectedCountryCode}}
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card-content>
</mat-card>