import { PackagingSystemLifeCycleResultDto } from './packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingUnitLifeCycleResultDto } from './packaging-part-results/packaging-unit-life-cycle-result-dto';
import { PackagingSystemLifeCycleCradleToGateResultDto } from './packaging-part-results/packaging-system-life-cycle-cradle-to-gate-result-dto';
import { PackagingUnitLifeCycleCradleToGateResultDto } from './packaging-part-results/packaging-unit-life-cycle-cradle-to-gate-result-dto';

export class HistoryWrapperLifeCycle {
    id!: number;
    analysisId!: string;
    version!: number;
    lifeCycleDtos: (PackagingUnitLifeCycleResultDto | PackagingSystemLifeCycleResultDto)[][] = [];
}

export class HistoryWrapperLifeCycleCradleToGate {
  id!: number;
  analysisId!: string;
  version!: number;
  lifeCycleDtos: (PackagingUnitLifeCycleCradleToGateResultDto | PackagingSystemLifeCycleCradleToGateResultDto)[][] = [];
}
