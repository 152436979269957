import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { NormalizationData } from '../../shared-components/normalization/normalization.component';
import { TOTAL_VALUE_INDEX } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { LifeCycleResultComponent } from 'src/app/model/evaluations/life-cycle-result-component';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';
import { LifeCycleResultPackagingUnit } from 'src/app/model/evaluations/life-cycle-result-packaging-unit';
import { downloadDocument } from 'src/app/services/document-download';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { TranslateService } from '@ngx-translate/core';
import { LifeCycleCradleToGateTableDialogComponent } from '../../dialogs/life-cycle-cradle-to-gate-table-dialog/life-cycle-cradle-to-gate-table-dialog.component';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { MatDialog } from '@angular/material/dialog';
import {LicenseService} from 'src/app/services/licensing-service'
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';
import {DomSanitizer} from '@angular/platform-browser';
import { ChartSeries, EffectInfosDictionary } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { BuyType } from '../../shared-components/shop/buying-from-shop/buying-from-shop.component';
import { LCAAnalysisType } from 'src/app/data-transfer/entities/environmental-effect-authorization-dto';
@Component({
  selector: 'app-analysis-cradle-to-gate-life-cycle-packaging-part',
  template: '',
  styles: []
})

export class AnalysisCradleToGateLifeCyclePackagingPartComponent implements OnChanges, OnDestroy {

  @Input() normalizationData!: NormalizationData | null;
  @Input() isDarkTheme = false;
  @Input() analyseTime!: string;
  @Input() allowedEffects : EnvironmentalEffectDto[] = [];

  @Output() normalizeLcaResult = new EventEmitter();
  @Output() normalizationDataReset = new EventEmitter(true);

  analysisType = LCAAnalysisType;

  stagesChartDataSource: ChartSeries[][] = [];
  dataSourceForCountryDefault!: LifeCycleResultPackagingSystem | LifeCycleResultPackagingUnit | LifeCycleResultComponent;
  originalLcaDataSourceDefault!: LifeCycleResultPackagingSystem | LifeCycleResultPackagingUnit | LifeCycleResultComponent;
  dataSourceForCountryFullAllocation!: LifeCycleResultPackagingSystem | LifeCycleResultPackagingUnit | LifeCycleResultComponent;
  originalLcaDataSourceFullAllocation!: LifeCycleResultPackagingSystem | LifeCycleResultPackagingUnit | LifeCycleResultComponent;

  selectedEffectIdx = 0;
  selectedNodeType!: number;
  packagingPart = PackagingPart;
  chartTitle!: string;
  chartSubtitle!: string;

  effectName!: string;
  effectValue!: number;
  effectUnit!: string;
  effectDescription!: string;
  effectUnitDescription!: string;
  showBuyText: boolean = false;
  emailString!: string;

  volume: number | null = null;
  weight: number | null = null;

  buyType = BuyType;


  protected formatValue = ((x: any) => (x !== 0 ? x?.toExponential(2) : x) ?? '???');
  private excelGenerationSubscription?: Subscription;

  constructor(
    protected translateService: TranslateService,
    protected analysisApiService: AnalysisApiService,
    protected dialog: MatDialog,
    protected licenseService: LicenseService,
    protected sanitizer : DomSanitizer
  ) {
    this.emailString = "mailto:"+this.translateService.instant('shop.emailWorkaround.email')
      +'?Subject='+this.translateService.instant('shop.emailWorkaround.environmentalEffects.subjectContent')+'&body='
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.normalizationData && !changes.normalizationData.firstChange) {
      this.doNormalize();
    }
  }

  private doNormalize() {
    this.resetLcaDataToOriginal();
    this.chartSubtitle = this.getNormalizationChartSubtitle();
    if (this.normalizationData?.normalizeByQuantity ||
      this.normalizationData?.normalizeByVolume ||
      this.normalizationData?.normalizeByWeight
    ) {
      this.normalizeLcaResult.emit(this.dataSourceForCountryDefault);
      this.normalizeLcaResult.emit(this.dataSourceForCountryFullAllocation);
    }
  }

  protected setOriginalLcaData() {
    if (this.dataSourceForCountryDefault) {
      this.originalLcaDataSourceDefault = JSON.parse(JSON.stringify(this.dataSourceForCountryDefault));
    }
    if (this.dataSourceForCountryFullAllocation) {
      this.originalLcaDataSourceFullAllocation = JSON.parse(JSON.stringify(this.dataSourceForCountryFullAllocation));
    }
    this.updateStagesChartDataSource()
  }

  private resetLcaDataToOriginal() {
    this.dataSourceForCountryDefault = JSON.parse(JSON.stringify(this.originalLcaDataSourceDefault));
    this.dataSourceForCountryFullAllocation = JSON.parse(JSON.stringify(this.originalLcaDataSourceFullAllocation));
    this.updateStagesChartDataSource()
  }

  onEffectChanged(effectIndex: number) {
    this.selectedEffectIdx = effectIndex;
    this.setEnvironmentalEffectData();
  }

  onNodeChanged() {
    this.normalizationData = null;
    this.chartTitle = this.getChartTitle();
    this.chartSubtitle = this.getNormalizationChartSubtitle();
    this.setEnvironmentalEffectData();
    this.normalizationDataReset.emit({volume: this.volume, weight: this.weight});
  }

  protected setEnvironmentalEffectData() {
    const effect = this.dataSourceForCountryDefault.effectRelevances[this.selectedEffectIdx];
    this.effectName = effect.name;
    this.effectUnit = effect.unit;
    this.effectDescription = effect.description;
    this.effectUnitDescription = effect.unitDescription;
    const effectValue = this.dataSourceForCountryDefault.stagesChartDataSource[this.selectedEffectIdx][TOTAL_VALUE_INDEX].value;

    this.effectValue = this.formatValue(effectValue);
  }

  protected generateExcel() {
    if (this.selectedNodeType === PackagingPart.Component || this.dataSourceForCountryDefault.packagingPartId == null
      || !this.dataSourceForCountryDefault.analysisId) { return; }
    this.excelGenerationSubscription = this.analysisApiService.generateLcaExcel(
      this.dataSourceForCountryDefault.packagingPartId,
      this.dataSourceForCountryDefault.packagingPartVersion,
      this.dataSourceForCountryDefault.analysisId,
      this.selectedNodeType)
      .subscribe(fileToSave => { downloadDocument(fileToSave); });
      //TODO change ExcelGeneration
  }

  protected getTooltipName(efftectIndex: number) {
    const effect = this.dataSourceForCountryDefault.effectRelevances[efftectIndex];
    if (this.isEffectAuthorizedIntern(effect.key)) {
      return this.translateService.instant('analysis.lifecycleAnalysis.impact') + ': ' +(effect.impact * 100).toFixed(2) + '%';
    } else {
      return this.translateService.instant('analysis.lifecycleAnalysis.warnings.impactNotAuthorized',{name: ''});
    }
  }

  updateStagesChartDataSource() {
    this.stagesChartDataSource = [];
    for (let i=0; i < this.dataSourceForCountryDefault.stagesChartDataSource.length; i++){
      let defaultChart = this.dataSourceForCountryDefault.stagesChartDataSource[i];
      let fullChart = this.dataSourceForCountryFullAllocation.stagesChartDataSource[i];
      this.stagesChartDataSource.push(this.createMergedChartData(fullChart,defaultChart));
    }

  }

  private createMergedChartData(fullAllocationChartData: ChartSeries[], defaultChartData: ChartSeries[]) : ChartSeries[] {
    let newChartData : ChartSeries[] = [];

    let newDefaultChart : ChartSeries = new ChartSeries();
    newDefaultChart.label = this.translateService.instant('analysis.lifecycleAnalysis.cradleToGateAnalysis.defaultTitle')
    newDefaultChart.value = defaultChartData[0].value
    newDefaultChart.series = defaultChartData[0].series
    newDefaultChart.name = this.translateService.instant('analysis.lifecycleAnalysis.cradleToGateAnalysis.defaultTitle')
    newChartData.push(newDefaultChart);

    let newFullChart : ChartSeries = new ChartSeries();
    newFullChart.label = this.translateService.instant('analysis.lifecycleAnalysis.cradleToGateAnalysis.fullAllocation')
    newFullChart.value = fullAllocationChartData[0].value
    newFullChart.series = fullAllocationChartData[0].series
    newFullChart.name = this.translateService.instant('analysis.lifecycleAnalysis.cradleToGateAnalysis.fullAllocation')
    newChartData.push(newFullChart);

    return newChartData;
  }

  private getChartTitle(): string {
    const packagingPartName = this.dataSourceForCountryDefault?.packagingPartName ?? '';
    const chartTranslation = this.translateService.instant('analysis.lifecycleAnalysis.cradleToGateAnalysis.chartTitle');
    const returnTitle = `${chartTranslation}</br>${packagingPartName}`;
    return returnTitle;
  }

  private getNormalizationChartSubtitle(): string {
    if (!this.normalizationData) { return ''; }
    const normalizationFactor = this.normalizationData.normalizationFactor.toString();
    const normalizationUnits = this.normalizationData.normalizeByQuantity ?
      this.translateService.instant('analysis.lifecycleAnalysis.quantityUnit') :
      this.normalizationData.displayUnit ? this.normalizationData.displayUnit : this.normalizationData.normalizationUnit;
    const parameterString = `${normalizationFactor} ${normalizationUnits}`;
    return this.translateService.instant('analysis.lifecycleAnalysis.normalizedFor', { factor: parameterString });
  }

  protected openTable() {
    const dialogData = {
      lifeCycleEvaluationResult: this.stagesChartDataSource,
      effectsList: this.dataSourceForCountryDefault.effectRelevances,
      normalizationChartSubtitle: this.getNormalizationChartSubtitle()
    };
    this.dialog.open(LifeCycleCradleToGateTableDialogComponent, getDialogConfig(dialogData, '95%'));
  }

  protected isEffectAuthorized(efftectIndex: number) : boolean {
    const effect = this.dataSourceForCountryDefault.effectRelevances[efftectIndex];
    return this.isEffectAuthorizedIntern(effect.key);
  }

  private isEffectAuthorizedIntern(efftectName: string) : boolean {
    return this.allowedEffects.some((effect: EnvironmentalEffectDto) => effect.name == efftectName);
  }

  protected invertBuyText () {
    this.showBuyText = !this.showBuyText;
  }
  protected getEmailString (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + this.translateService.instant('shop.emailWorkaround.environmentalEffects.cradleToGatePostfix') + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.sanitize(this.emailString+this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{effect:namestring}));
  }

  protected getEmailContentStringForBrowser (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + this.translateService.instant('shop.emailWorkaround.environmentalEffects.cradleToGatePostfix') + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{effect:namestring}).replace(/%0D%0A/g,'\n');
  }

  protected sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  protected filterRelevantEffects (relevantEffects : EffectInfosDictionary[]) : EffectInfosDictionary[] {
    return relevantEffects.filter((effect) => effect.name != '');
  }

  ngOnDestroy(): void {
    this.excelGenerationSubscription?.unsubscribe();
  }
}
