<div class="outer-container">
  <mat-card>
    <mat-card-title class="grid-item-title">{{ 'dataManagement.exportList.title' | translate }}</mat-card-title>
    <mat-card-content>
      <mat-tab-group class="mat-tab-fill-height export-list-group">
        <mat-tab label="{{ 'dataManagement.exportList.titleSystems' | translate }}">
          <div class="export-list-table-container">
            <div *ngIf="systemOrganizationIds.length === 0">{{ 'dataManagement.exportList.noExports' | translate }}</div>
            <div class="export-list-org" *ngFor="let orgId of systemOrganizationIds">
              <h4>{{ exportedSystemsByOrganization.get(orgId)!.data[0].exportedToOrganization.name }}</h4>
              <div class="export-table">
                <app-export-list-table
                  [dataSource]="exportedSystemsByOrganization.get(orgId)!"
                  [displayedColumns]="systemDisplayedColumns"
                  (changeTrackingEvent)="changeTracking($event, packagingPartEnum.System)"
                  (deleteImportEvent)="deleteImport($event, packagingPartEnum.System)"></app-export-list-table>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportList.titleUnits' | translate }}">
          <div class="export-list-table-container">
            <div *ngIf="unitOrganizationIds.length === 0">{{ 'dataManagement.exportList.noExports' | translate }}</div>
            <div class="export-list-org" *ngFor="let orgId of unitOrganizationIds">
              <h4>{{ exportedUnitsByOrganization.get(orgId)!.data[0].exportedToOrganization.name }}</h4>
              <div class="export-table">
                <app-export-list-table
                  [dataSource]="exportedUnitsByOrganization.get(orgId)!"
                  [displayedColumns]="unitDisplayedColumns"
                  (changeTrackingEvent)="changeTracking($event, packagingPartEnum.Unit)"
                  (deleteImportEvent)="deleteImport($event, packagingPartEnum.Unit)"></app-export-list-table>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportList.titleComponents' | translate }}">
          <div class="export-list-table-container">
            <div *ngIf="componentOrganizationIds.length === 0">{{ 'dataManagement.exportList.noExports' | translate }}</div>
            <div class="export-list-org" *ngFor="let orgId of componentOrganizationIds">
              <h4>{{ exportedComponentsByOrganization.get(orgId)!.data[0].exportedToOrganization.name }}</h4>
              <div class="export-table">
                <app-export-list-table
                  [dataSource]="exportedComponentsByOrganization.get(orgId)!"
                  [displayedColumns]="componentDisplayedColumns"
                  (changeTrackingEvent)="changeTracking($event, packagingPartEnum.Component)"
                  (deleteImportEvent)="deleteImport($event, packagingPartEnum.Component)"></app-export-list-table>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.exportList.titleMaterials' | translate }}">
          <div class="export-list-table-container">
            <div *ngIf="materialOrganizationIds.length === 0">{{ 'dataManagement.exportList.noExports' | translate }}</div>
            <div class="export-list-org" *ngFor="let orgId of materialOrganizationIds">
              <h4>{{ exportedMaterialsByOrganization.get(orgId)!.data[0].exportedToOrganization.name }}</h4>
              <div class="export-table">
                <app-export-list-table
                  [dataSource]="exportedMaterialsByOrganization.get(orgId)!"
                  [displayedColumns]="materialDisplayedColumns"
                  (changeTrackingEvent)="changeTracking($event, packagingPartEnum.Material)"
                  (deleteImportEvent)="deleteImport($event, packagingPartEnum.Material)"></app-export-list-table>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
