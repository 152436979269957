<div class="landing-background" [ngClass]="isDarkTheme ? 'dark' : 'light'">
  <div class="logo-container">
    <img [src]="imageSource" class="logo-class" />
  </div>
  <div class="background-image" [ngClass]="isDarkTheme ? 'background-image-dark' : 'background-image-light'">
    <div class="landing-container">
      <div class="mat-elevation-z8 landing-card">
        <mat-toolbar id="landing-toolbar">
          <span> {{ 'landingPage.welcomeTitle' | translate }} </span>
        </mat-toolbar>
        <mat-card>
          <mat-card-header>
            <mat-card-title [ngClass]="isDarkTheme ? 'text-dark-theme' : 'text-light-theme'">
              <strong> {{ 'landingPage.subtitle' | translate }} </strong>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <span *ngIf="!isContractSigned">
              <p >
                {{ 'contactInfo.ElearningText' | translate }}
                <a [href]="'contactInfo.Elearning' | translate">{{ 'contactInfo.Elearning' | translate }}</a>
              </p>
              <p >
                {{ 'landingPage.contractNotSigned' | translate }}
              </p>
              <div *ngFor="let contract of contracts">
                <div class="collapsible contractBox" (click)="contract.isExpanded=!contract.isExpanded">{{contract.label}}
                  <mat-icon *ngIf="!contract.isExpanded">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="contract.isExpanded">keyboard_arrow_down</mat-icon>
                </div>
                <object [hidden]="!contract.isExpanded" class="inPagePdf" [data]="contract.pdfUrl"
                  type="application/pdf">
                </object>
              </div>
            </span>
            <span *ngIf="!isUserActivated">
              <p>
                {{ 'landingPage.maxUser' | translate }}
              </p>
              <p>
                {{ 'landingPage.contactSales' | translate }}
                <a href=mailto:upgrade@packaging-cockpit.com>upgrade&#64;packaging-cockpit.com</a>
              </p>
            </span>
            <blockquote *ngIf="!isContractSigned">
              <div>
                {{  (contracts.length > 1 ?  'landingPage.signContracts': 'landingPage.signContract') | translate}}
                <mat-checkbox labelPosition="before" [checked]="contractSignedCheck" (change)="contractSignedCheck=!contractSignedCheck"></mat-checkbox>
              </div>
              <p>
                <button type="button" class="floatRight" mat-raised-button color="primary" (click)="signContract()" [disabled]="!contractSignedCheck">{{ 'landingPage.accept' | translate }}</button>
              </p>
            </blockquote>
            <p >
              {{ 'landingPage.welcomeEndText' | translate }}
            </p>
            <strong >
              {{ 'landingPage.yourPacoTeam' | translate }}
            </strong>
          </mat-card-content>
          <mat-card-actions class="flex-row-space-around"> </mat-card-actions>
        </mat-card>
      </div>
      <div class="footer-text">
        <span class="mat-headline-6" [ngClass]="isDarkTheme ? 'text-dark-theme' : 'text-light-theme'">
          <a [href]="'contactInfo.packoHomepageContact' | translate" target="_blank">{{ 'login.contactUs' | translate }}</a>
          {{ 'login.moreInfo' | translate }}
        </span>
      </div>
    </div>
    <app-version data-automation-id="app-version"></app-version>
  </div>
</div>

