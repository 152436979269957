<div class="outer-container">
  <mat-card>
    <mat-card-title class="grid-item-title">{{ 'dataManagement.userData.title' | translate }}</mat-card-title>
    <mat-card-content class="table-container-wide">
      <div class="table-container">
        <app-users-table [usersDataSource]="usersDataSource" [deletedUsersDataSource]="deletedUsersDataSource"
          [usersColumns]="usersColumns">
        </app-users-table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
