<mat-card class="files-card">
  <mat-card-title class="grid-item-title">
    {{ 'pdf.productPdfs' | translate }}
  </mat-card-title>

  <mat-card-content class="table-container-wide">

    <div *ngIf="pdfs && pdfs.length > 0">
      <div class="pdfs-container">
        <div *ngFor="let pdf of pdfs; let i = index" class="file-container">
          <!-- <div class="hidden">
            <span #invisibleNames id="invisible-input">
              <mat-icon matPrefix class="edit-name-icon">edit</mat-icon>
              ...{{ pdf.name }}
            </span>
          </div> -->
          <mat-form-field #formFieldInput [ngStyle]="{'width.px': nameFieldWidth[i]}" appearance="outline"
            class="name-input-container">
            <input matInput class="name-input" [(ngModel)]="pdf.name" (paste)="resizeInput(i)" (cut)="resizeInput(i)"
              (input)="resizeInput(i)" (change)="onNameEditClicked(i)"
              (click)="setCurrentNameBeforeChange(pdfs, i)" [disabled]="!canEditForm">
            <mat-icon matPrefix class="edit-name-icon">edit</mat-icon>
            <mat-icon matSuffix class="error-name-icon" *ngIf="pdf.name.length === 0">error</mat-icon>
          </mat-form-field>
          <button mat-icon-button (click)="onViewPdfClick(pdf)" color="primary" type="button"
            matTooltip="{{ 'pdf.tooltips.viewPdf' | translate }}">
            <mat-icon>preview</mat-icon>
          </button>
          <button mat-icon-button (click)="onDeletePdfClick(pdf)" color="primary" [disabled]="!canEditForm"
            matTooltip="{{ 'pdf.tooltips.deletePdf' | translate }}" type="button">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <input type="file" accept=".pdf" multiple (change)="onUploadPdfsClick(hiddenFileInput.files)" #hiddenFileInput
      onclick="value=null" style="display: none" />

    <button mat-raised-button (click)="hiddenFileInput.click()" color="primary" class="custom-button-top"
      [disabled]="!canEditForm || maxCountReached" type="button"
      matTooltip="{{ 'pdf.tooltips.uploadPdf' | translate }}">
      <mat-icon class="material-icons left">add</mat-icon>
      {{ 'pdf.uploadPdfButton' | translate }}
    </button>

  </mat-card-content>
</mat-card>
