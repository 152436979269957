import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RatingsHandler {

  getRatingNameFromValue(ratingValue: number) {
    switch (ratingValue) {
      case 1: return 'A';
      case 2: return 'B';
      case 3: return 'C';
      case 4: return 'D';
      case 5: return 'X';
      default: return '?';
    }
  }

  getRatingCellColorRecyclability(ratingName: string) {
    switch (ratingName) {
      case 'A': return '#158f58';
      case 'B': return '#ADCE52';
      case 'C': return '#FD7B2A';
      case 'D': return '#AB0730';
      case 'X': return '#000000';
      default: return 'lightgrey';
    }
  }

  getRatingColorRecyclabilityMap(ratingName: string) {
    switch (ratingName) {
      case 'A': return '#158f58';
      case 'B': return '#ADCE52';
      case 'C': return '#FFD121';
      case 'NotRecyclable': return '#AB0730';
      default: return 'lightgrey';
    }
  }

  getRatingFontColorRecyclability(ratingName: string) {
    switch (ratingName) {
      case 'X': return 'white';
      default: return 'black';
    }
  }

  getRatingTooltip(translateService: TranslateService, ratingLabel: string) {
    const ratingName = ratingLabel === '?' ? 'Unknown' : ratingLabel;
    return translateService.instant('analysis.recyclabilityAnalysis.percentageRating' + ratingName);
  }
}
