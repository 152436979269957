export interface CountriesExpenseAvailabilityDict {
  id: number;
  code: string;
  hasTax: boolean
}

export const PLASTIC_TAX_COUNTRIES: CountriesExpenseAvailabilityDict[] = [
  { id: 1, code: 'AT', hasTax: false },
  { id: 2, code: 'DE', hasTax: false },
  { id: 3, code: 'NL', hasTax: false },
  { id: 4, code: 'UK', hasTax: true },
  { id: 5, code: 'DK', hasTax: true },
  { id: 6, code: 'SE', hasTax: false },
  { id: 7, code: 'FI', hasTax: false },
  { id: 8, code: 'BE', hasTax: false },
  { id: 9, code: 'BR', hasTax: false },
  { id: 10, code: 'BG', hasTax: true },

  { id: 11, code: 'CA', hasTax: false },
  { id: 12, code: 'CN', hasTax: false },
  { id: 13, code: 'HR', hasTax: false },
  { id: 14, code: 'CY', hasTax: false },
  { id: 15, code: 'CZ', hasTax: false },
  { id: 16, code: 'EE', hasTax: true },
  { id: 17, code: 'EUR', hasTax: false },
  { id: 18, code: 'FR', hasTax: false },
  { id: 19, code: 'GR', hasTax: false },
  { id: 20, code: 'HU', hasTax: true },

  { id: 21, code: 'IN', hasTax: false },
  { id: 22, code: 'IE', hasTax: false },
  { id: 23, code: 'IT', hasTax: false },
  { id: 24, code: 'JP', hasTax: false },
  { id: 25, code: 'LV', hasTax: true },
  { id: 26, code: 'LT', hasTax: true },
  { id: 27, code: 'LU', hasTax: false },
  { id: 28, code: 'MT', hasTax: false },
  { id: 29, code: 'NO', hasTax: false },
  { id: 30, code: 'PL', hasTax: false },

  { id: 31, code: 'RO', hasTax: true },
  { id: 32, code: 'RU', hasTax: false },
  { id: 33, code: 'SK', hasTax: false },
  { id: 34, code: 'SI', hasTax: true },
  { id: 35, code: 'ES', hasTax: true },
  { id: 36, code: 'CH', hasTax: false },
  { id: 37, code: 'US', hasTax: false },
  { id: 38, code: 'GLO', hasTax: false },
  { id: 39, code: 'RoW', hasTax: false },
  { id: 40, code: 'PT', hasTax: true },

  { id: 41, code: 'AU', hasTax: true },
  { id: 42, code: 'STEU', hasTax: true }
];

export const LICENSE_FEE_COUNTRIES: CountriesExpenseAvailabilityDict[] = [
  { id: 1, code: 'AT', hasTax: true },
  { id: 2, code: 'DE', hasTax: true },
  { id: 3, code: 'NL', hasTax: true },
  { id: 4, code: 'UK', hasTax: false },
  { id: 5, code: 'DK', hasTax: false },
  { id: 6, code: 'SE', hasTax: false },
  { id: 7, code: 'FI', hasTax: false },
  { id: 8, code: 'BE', hasTax: true },
  { id: 9, code: 'BR', hasTax: false },
  { id: 10, code: 'BG', hasTax: true },

  { id: 11, code: 'CA', hasTax: false },
  { id: 12, code: 'CN', hasTax: false },
  { id: 13, code: 'HR', hasTax: false },
  { id: 14, code: 'CY', hasTax: false },
  { id: 15, code: 'CZ', hasTax: false },
  { id: 16, code: 'EE', hasTax: false },
  { id: 17, code: 'EUR', hasTax: false },
  { id: 18, code: 'FR', hasTax: false },
  { id: 19, code: 'GR', hasTax: false },
  { id: 20, code: 'HU', hasTax: false },

  { id: 21, code: 'IN', hasTax: false },
  { id: 22, code: 'IE', hasTax: false },
  { id: 23, code: 'IT', hasTax: true },
  { id: 24, code: 'JP', hasTax: false },
  { id: 25, code: 'LV', hasTax: false },
  { id: 26, code: 'LT', hasTax: false },
  { id: 27, code: 'LU', hasTax: true },
  { id: 28, code: 'MT', hasTax: false },
  { id: 29, code: 'NO', hasTax: false },
  { id: 30, code: 'PL', hasTax: false },

  { id: 31, code: 'RO', hasTax: false },
  { id: 32, code: 'RU', hasTax: false },
  { id: 33, code: 'SK', hasTax: false },
  { id: 34, code: 'SI', hasTax: false },
  { id: 35, code: 'ES', hasTax: true },
  { id: 36, code: 'CH', hasTax: false },
  { id: 37, code: 'US', hasTax: false },
  { id: 38, code: 'GLO', hasTax: false },
  { id: 39, code: 'RoW', hasTax: false },
  { id: 40, code: 'PT', hasTax: false },

  { id: 41, code: 'AU', hasTax: false },
  { id: 42, code: 'STEU', hasTax: false }
];
