<mat-toolbar class="toolbar-row">
  <app-main-navigation [isMinimalLayout]="isMinimalLayout"></app-main-navigation>
</mat-toolbar>

<mat-sidenav-container id="global_sidenav">

  <div id="main-container">
    <router-outlet></router-outlet>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.6)" type="ball-spin" [fullScreen]="true" size="medium">
    <p style="color:white" *ngIf="syncronizing">{{ 'dataManagement.synchronizeDb.syncInProgress' | translate }}</p>
    <p style="color:white" *ngIf="!syncronizing">{{ 'common.text.loading' | translate }}</p>
  </ngx-spinner>

</mat-sidenav-container>