import { Injectable } from '@angular/core';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';

export class ComparisonVersionMappingEntry {
  id?: number;
  version?: number;
}

@Injectable({ providedIn: 'root' })
export class ComparisonDataService {

  setPackagingVersionMapping(packagingParts: (PackagingUnitInfoDto | PackagingSystemInfoDto)[]) {
    const versionMapping: ComparisonVersionMappingEntry[] = [];
    if (!packagingParts || packagingParts.length === 0) { return; }
    packagingParts.forEach(x => versionMapping.push({ id: x.id, version: x.version }));
    localStorage.setItem('versionMappingForComparison', JSON.stringify(versionMapping));
  }

  getPackagingVersionMapping(): ComparisonVersionMappingEntry[] {
    const versionMapping = localStorage.getItem('versionMappingForComparison');
    return versionMapping ? JSON.parse(versionMapping) : '';
  }
}
