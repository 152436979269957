import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpXsrfTokenExtractor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private xsrfTokenExtractor: HttpXsrfTokenExtractor) {  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // todo: check if this can be removed
    const lcUrl = req.url.toLowerCase();
    if (lcUrl.startsWith('http://') || lcUrl.startsWith('https://')) {
      const xsrfToken = this.xsrfTokenExtractor.getToken();
      let headers: HttpHeaders = req.headers;
      if (xsrfToken) {
        headers = headers.append('X-XSRF-TOKEN', xsrfToken);
      }
      req = req.clone({ headers });
    }
    req = req.clone({
      withCredentials: true
    });
    return next.handle(req);
  }
}
