import { CreateUserDto } from './../data-transfer/entities/create-user-dto';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

export class UserDetailsDialogData {

  private namesUsed: Set<string>;
  private user: CreateUserDto | null = null;
  action!: number;

  constructor(namesUsed: Set<string>, action: number, user?: CreateUserDto) {
    this.namesUsed = namesUsed;
    this.action = action;
    if (user) { this.user = user; }
  }
  
  getNamesUsed() { return this.namesUsed; }

  getNamesUsedLowercase() {
    const namesUsedLowercase = new Set<string>();
    this.namesUsed.forEach(name => namesUsedLowercase.add(name.toLowerCase()));
    return namesUsedLowercase;
  }

  getUser() { return this.user; }
}

@Injectable({ providedIn: 'root' })
export class UserDialogHandler {

  /**
   * Checks if two password inputs are identical
   */
  public mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
