<table mat-table [dataSource]="dataSource" id="materials-table" matSort>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'id' }" type="button">
          {{ 'commonPackagingFields.id' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('id')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="tracking">
    <th mat-header-cell *matHeaderCellDef [hidden]="!displayTrackedColumn"></th>
    <td mat-cell *matCellDef="let element" [hidden]="!displayTrackedColumn">
      <mat-icon *ngIf="element.hasExternalTracking">track_changes</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.tagFilterMenu"
          [matMenuTriggerData]="{ caller: 'associatedTagIdentifiers', possibleTags: getPossibleTags(dataSource) }"
          type="button">
          Tags
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('associatedTagIdentifiers')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <app-tag-directive [tags]="getTagsFromIds(element.associatedTagIdentifiers)" [maxDisplayed]="2">
      </app-tag-directive>
    </td>
  </ng-container>

  <ng-container matColumnDef="articleName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'articleName' }" type="button">
          {{ 'component.fields.articleName' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('articleName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.articleName}} </td>
  </ng-container>

  <ng-container matColumnDef="articleNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'articleNumber' }" type="button">
          {{ 'component.fields.articleNumber' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('articleNumber')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.articleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="creationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'creationTimestamp' }" type="button">
          {{ 'commonPackagingFields.creationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('creationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.creationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastModificationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'lastModificationTimestamp' }" type="button">
          {{ 'commonPackagingFields.lastModificationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('lastModificationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.lastModificationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="manufacturerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'manufacturerName' }" type="button">
          {{ 'material.fields.manufacturer' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('manufacturerName')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.manufacturerName}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'dataManagement.actions' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="!isRecyclingBinSelected" mat-icon-button (click)="navigateToMaterial(element)"
        matTooltip="{{ 'material.editMaterial' | translate }}" type="button">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="deletePackagingPart([element])"
        matTooltip="{{ 'material.deleteMaterial' | translate }}" type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" (click)="selectAllMaterials()" type="button">
          {{ 'common.buttons.selectAll' | translate }} <br> ({{selectedMaterials.length}}
          {{ 'common.text.selected' | translate }})
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"
      [matTooltipDisabled]="element.isValidForComponent == null || element.isValidForComponent === true"
      matTooltip="{{ 'material.tooltips.materialNotValidForComponent' | translate }}">
      <mat-checkbox [checked]="isMaterialSelected(element)" (change)="setMaterialSelected($event.checked, element)"
        [disabled]="element.isValidForComponent != null && element.isValidForComponent === false">
      </mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <app-filter-menu #filterComponent="columnFilterComponent" [dataSource]="dataSource"></app-filter-menu>
</table>