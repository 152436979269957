import { Component, Input } from '@angular/core';
import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { AnalysisCradleToGateLifeCyclePackagingPartComponent } from '../analysis-cradle-to-gate-life-cycle-packaging-part/analysis-cradle-to-gate-life-cycle-packaging-part.component';
import {LicenseService} from 'src/app/services/licensing-service'
import {DomSanitizer} from '@angular/platform-browser';
import {BuyType} from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
import { PackagingUnitLifeCycleCradleToGateResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-cradle-to-gate-result-dto';

@Component({
  selector: 'app-analysis-cradle-to-gate-life-cycle-packaging-unit',
  templateUrl: './analysis-cradle-to-gate-life-cycle-packaging-unit.component.html',
  styleUrls: ['../analysis-cradle-to-gate-life-cycle-packaging-system/analysis-cradle-to-gate-life-cycle-packaging-system.component.scss']

})
export class AnalysisCradleToGateLifeCyclePackagingUnitComponent  extends AnalysisCradleToGateLifeCyclePackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingUnitLifeCycleCradleToGateResultDto;
  @Input() packagingUnit!: PackagingUnitDto;

  buyType = BuyType;

  constructor(
    private lcaService: LifeCycleService,
    protected translateService: TranslateService,
    protected analysisApiService: AnalysisApiService,
    protected dialog: MatDialog,
    protected licenseService : LicenseService,
    protected sanitizer : DomSanitizer
  ) {
    super(translateService, analysisApiService, dialog, licenseService, sanitizer);
    this.selectedNodeType = PackagingPart.Unit;
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
    super.onNodeChanged();
  }

  private onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    const selectedResultDefault = this.selectedAnalysisVersion.defaultAllocationResult;
    const selectedResultFull = this.selectedAnalysisVersion.fullAllocationResult;
    if (!selectedResultDefault || !selectedResultFull) { return; }
    this.dataSourceForCountryDefault = this.lcaService.getPackagingUnitLcaResultFromDto(selectedResultDefault);
    this.dataSourceForCountryDefault.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryDefault.effectRelevances);
    this.dataSourceForCountryFullAllocation = this.lcaService.getPackagingUnitLcaResultFromDto(selectedResultFull);
    this.dataSourceForCountryFullAllocation.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryFullAllocation.effectRelevances);
    super.setOriginalLcaData();
    this.volume = selectedResultDefault.analyzedPackagingUnit.packagingVolume ?? null;
    this.weight = selectedResultDefault.analyzedPackagingUnit.packagingQuantity ?? null;
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResultDefault = this.selectedAnalysisVersion.defaultAllocationResult.componentResults.find(x => x.analyzedPackagingComponent.id === componentId);
    const selectedResultFull = this.selectedAnalysisVersion.fullAllocationResult.componentResults.find(x => x.analyzedPackagingComponent.id === componentId);
    if (!selectedResultDefault || !selectedResultFull) { return; }
    this.dataSourceForCountryDefault = this.lcaService.getComponentLcaResultFromDto(selectedResultDefault);
    this.dataSourceForCountryDefault.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryDefault.effectRelevances);
    this.dataSourceForCountryFullAllocation = this.lcaService.getComponentLcaResultFromDto(selectedResultFull);
    this.dataSourceForCountryFullAllocation.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryFullAllocation.effectRelevances);
    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }
}
