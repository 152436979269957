import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';

@Component({
  selector: 'app-material-layers-view',
  templateUrl: './material-layers-view.component.html',
  styleUrls: ['./material-layers-view.component.scss']
})
export class MaterialLayersViewComponent {
  @Input() dataSource!: MatTableDataSource<MultiMaterialLayerDto>;
  displayedColumns = ['materialManifestationName', 'mass', 'grammage', 'thickness', 'density', 'massPercentage'];
}
