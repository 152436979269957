import { getDialogConfig } from 'src/app/util/dialog-util';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';
import { Router } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { AuthService } from './../../../../services/auth-service';
import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatMenu } from '@angular/material/menu';
import { PackagingExcelUploadDialogComponent } from 'src/app/components/dialogs/packaging-excel-upload-dialog/packaging-excel-upload-dialog.component';

@Component({
  selector: 'app-data-menu',
  templateUrl: './data-menu.component.html',
  styleUrls: ['./data-menu.component.scss']
})
export class DataMenuComponent implements OnDestroy {

  @ViewChild(MatMenu, { static: true }) menu!: MatMenu;

  isAdmin: boolean;
  isValidator: boolean;

  private synchronizeDbSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router,
    private baseDataApiService: BaseDataApiService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    this.isAdmin = this.authService.isUserAdmin();
    this.isValidator = this.authService.isUserValidator();
  }

  clickDirectoryTree() {
    this.router.navigate([PathResolveService.getDirectoryManagementPath(true, 'root')]);
  }

  clickPackagingSystemImport() {
    const path = PathResolveService.getPackagingSystemImportPath(true);
    this.router.navigate([path]);
  }

  clickPackagingUnitImport() {
    const path = PathResolveService.getPackagingUnitImportPath(true);
    this.router.navigate([path]);
  }

  clickPackagingComponentImport() {
    const path = PathResolveService.getComponentImportPath(true);
    this.router.navigate([path]);
  }

  clickCompositeMateiralImport() {
    const path = PathResolveService.getMaterialImportPath(true);
    this.router.navigate([path]);
  }

  clickUsers() {
    this.router.navigate([PathResolveService.getUserManagementPath(true)]);
  }

  clickSync() {
    this.spinner.show();
    this.synchronizeDbSubscription = this.baseDataApiService.synchronizeDatabase().subscribe(response => {
      this.spinner.hide();
      this.showSyncResultDialog(response.status);
    });
  }

  clickVersions() {
    const path = PathResolveService.getDependencyVersionsPath(true);
    this.router.navigate([path]);
  }

  clickUiChangelog() {
    const path = PathResolveService.getUiChangelogPath(true);
    this.router.navigate([path]);
  }

  clickExportProfiles() {
    const path = PathResolveService.getExportProfilesPath(true);
    this.router.navigate([path]);
  }

  clickExportList() {
    const path = PathResolveService.getExportListPath(true);
    this.router.navigate([path]);
  }

  clickManageOrganizations() {
    const path = PathResolveService.getOrganizationsManagementPath(true);
    this.router.navigate([path]);
  }

  clickImportLcaExcelData() {
    const path = PathResolveService.getLcaDataExcelImportPath(true);
    this.router.navigate([path]);
  }

  clickTags() {
    const path = PathResolveService.getTagsManagementPath(true);
    this.router.navigate([path]);
  }

  clickInternationalInfos() {
    const path = PathResolveService.getInternationalInfosPath(true);
    this.router.navigate([path]);
  }

  private showSyncResultDialog(status: number) {
    let data: SimpleDialogData;
    if (status === 200) {
      data = {
        title: this.translateService.instant('common.text.success'),
        messages: [this.translateService.instant('dataManagement.synchronizeDb.successMessage')],
        icon: 'sync'
      };
    } else {
      data = {
        title: this.translateService.instant('common.text.error'),
        messages: [this.translateService.instant('dataManagement.synchronizeDb.errorMessage')],
        icon: 'error'
      };
    }
    const alertDialogConfig = getDialogConfig(data, '250px');
    this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }

  clickValidatorSystems() {
    const path = PathResolveService.getValidatorViewSystems(true);
    this.router.navigate([path]);
  }

  clickValidatorUnits() {
    const path = PathResolveService.getValidatorViewUnits(true);
    this.router.navigate([path]);
  }

  clickExcelUpload() {
    this.dialog.open(PackagingExcelUploadDialogComponent);
  }

  ngOnDestroy(): void {
    this.synchronizeDbSubscription?.unsubscribe();
  }
}
