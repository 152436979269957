import { Injectable } from '@angular/core';
import { ExportProfileComponentParent, PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';

@Injectable({ providedIn: 'root' })
export class DtoForEvaluationService {

  setPackagingProfileRecValues(profileDto: PackagingUnitExportProfileDto) {
    profileDto.brandName = true;
    profileDto.productName = true;
    profileDto.gtin = true;
    profileDto.distributionCountries = true;
    profileDto.length = true;
    profileDto.width = true;
    profileDto.height = true;

    this.setComponentProfileRecValues(profileDto.mainBody);
    this.setComponentProfileRecValues(profileDto.closures);
    profileDto.closures.removedByConsumer = true;
    profileDto.closures.removedBeforeUsage = true;
    this.setComponentProfileRecValues(profileDto.inlays);
    profileDto.inlays.isAdhesiveUsed = true;
    profileDto.inlays.removabilityCondition = true;
    profileDto.inlays.isPhysicallyAttached = true;
    this.setComponentProfileRecValues(profileDto.packagingAids);
    profileDto.packagingAids.removedByConsumer = true;
    profileDto.packagingAids.removedBeforeUsage = true;
    this.setComponentProfileRecValues(profileDto.labels);
    profileDto.labels.removedByConsumer = true;
    profileDto.labels.removedBeforeUsage = true;
    profileDto.labels.surfaceRatio = true;
    profileDto.labels.isAdhesiveUsed = true;
    profileDto.labels.removabilityCondition = true;
    this.setComponentProfileRecValues(profileDto.sleeves);
    profileDto.sleeves.removedByConsumer = true;
    profileDto.sleeves.removedBeforeUsage = true;
    profileDto.sleeves.surfaceRatio = true;
    this.setComponentProfileRecValues(profileDto.inMoldLabels);
    profileDto.inMoldLabels.removedByConsumer = true;
    profileDto.inMoldLabels.removedBeforeUsage = true;
    profileDto.inMoldLabels.surfaceRatio = true;
  }

  private setComponentProfileRecValues(componentDto: ExportProfileComponentParent) {
    componentDto.printingCoverage = true;
    componentDto.length = true;
    componentDto.width = true;
    componentDto.height = true;
    componentDto.isRigidComponent = true;
    componentDto.multiMaterial.layers.function = true;
    componentDto.multiMaterial.layers.material = true;
    componentDto.multiMaterial.layers.materialManifestation = true;
    componentDto.multiMaterial.layers.mass = true;
    componentDto.multiMaterial.layers.density = true;
    componentDto.multiMaterial.layers.color = true;
  }

  setPackagingProfileLcaValues(profileDto: PackagingUnitExportProfileDto) {
    this.setComponentProfileLcaValues(profileDto.mainBody);
    this.setComponentProfileLcaValues(profileDto.closures);
    this.setComponentProfileLcaValues(profileDto.inlays);
    this.setComponentProfileLcaValues(profileDto.packagingAids);
    this.setComponentProfileLcaValues(profileDto.labels);
    this.setComponentProfileLcaValues(profileDto.sleeves);
    this.setComponentProfileLcaValues(profileDto.inMoldLabels);
  }

  private setComponentProfileLcaValues(componentDto: ExportProfileComponentParent) {
    componentDto.manufacturingCountry = true;
    componentDto.multiMaterial.layers.recyclingMaterialPercentage = true;
    componentDto.multiMaterial.layers.manufacturingType = true;
  }
}
