import { ValidationState } from '../../../various-components/validator-view/validator-view.component';
import { VersionDto } from '../../../../data-transfer/entities/version-dto';
import { HistoryWrapperLifeCycle,HistoryWrapperLifeCycleCradleToGate } from '../../../../data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { AnalysisMenuCaller, MenuItem, PackagingValidationMapping } from '../analyses-menu-parent/analyses-menu-parent.component';
import { Observable, of } from 'rxjs';
import { Component, Input, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { AnalysesMenuParentComponent } from '../analyses-menu-parent/analyses-menu-parent.component';
import { MatMenu } from '@angular/material/menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingSystemNavigationService } from 'src/app/navigation/services/navigation-services/packaging-system-navigation.service';
import { PackagingSystemApiService } from 'src/app/data-transfer/services/packaging-system-api-service';
import { AnalysisType } from 'src/app/navigation/services/navigation-services/parent-navigation-service';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { EvaluationResultDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-result-dto';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { LicenseService } from 'src/app/services/licensing-service';
import { ProblemInformationDto } from 'src/app/data-transfer/entities/evaluation-entities/problem-information-dto';

@Component({
  selector: 'app-analyses-menu-packaging-systems',
  templateUrl: './analyses-menu-packaging-systems.component.html',
  styleUrls: ['./analyses-menu-packaging-systems.component.scss']
})
export class AnalysesMenuPackagingSystemsComponent extends AnalysesMenuParentComponent implements OnInit {

  @Input() packagingSystemId = -1;
  @Input() analysisWrappersRec?: HistoryWrapperRecyclability;
  @Input() analysisWrappersLca?: HistoryWrapperLifeCycle;
  @Input() analysisWrappersLcaCradleToGate?: HistoryWrapperLifeCycleCradleToGate;

  @ViewChild(MatMenu, { static: true }) analysesMenu!: MatMenu;
  @ViewChild('analysesRecyclability', { static: true }) analysesRecyclability!: MatMenu;
  @ViewChild('analysesLca', { static: true }) analysesLca!: MatMenu;
  @ViewChild('analysesPlasticTax', { static: true }) analysesPlasticTax!: MatMenu;
  @ViewChild('analysesLicenseFee', { static: true }) analysesLicenseFee!: MatMenu;

  packagingSystemLoaded!: Promise<boolean>;
  versionsWithRecCount = 0;
  versionsWithLcaCount = 0;
  versionsWithCradleToGateCount = 0;
  versionsWithPlasticTaxCount = 0;
  versionsWithLicenseFeeCount = 0;

  constructor(
    private navigationService: PackagingSystemNavigationService,
    private analysisApiService: AnalysisApiService,
    private packagingSystemApiService: PackagingSystemApiService,
    private cdr: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    licenseService: LicenseService
  ) {
    super(licenseService);
  }

  ngOnInit(): void {
    if (this.isUserValidator) { this.loadAll(); }
    this.menuDirectionX = this.caller === AnalysisMenuCaller.Homepage ? 'before' : 'after';
  }

  loadAnalysesForPackagingSystem() {
    if (!this.isUserValidator) {
      this.recyclabilityAnalysisMenu.menuItems = [];
      this.lcaAnalysisMenu.menuItems = [];
      this.lcaCradleToGateAnalysisMenu.menuItems = [];
      this.plasticTaxMenu.menuItems = [];
      this.licenseFeeMenu.menuItems = [];
      this.loadAll();
    }
  }

  private loadAll() {
    if (this.packagingSystemId === undefined) { return; }

    this.packagingSystemLoaded = Promise.resolve(false);
    this.recMenuItemsLoaded = Promise.resolve(false);
    this.lcaMenuItemsLoaded = Promise.resolve(false);
    this.lcaCradleToGateMenuItemsLoaded = Promise.resolve(false);

    if (!this.isUserValidator) {
      this.permissionsSubscription =
        this.packagingSystemApiService.getPackagingSystemPermissions(this.packagingSystemId).subscribe(permissions => {
          this.isAnalysisAllowed = permissions.analyze;
          this.packagingSystemLoaded = Promise.resolve(true);
          this.cdr.detectChanges();
        });
    } else {
      this.packagingSystemLoaded = Promise.resolve(true);
      this.cdr.detectChanges();
    }

    if (this.isUserValidator && this.analysisWrappersRec) {
      this.versionsWithRecCount = this.analysisWrappersRec.recyclabilityDtos.length;
      const recMenuItem: MenuItem = this.getMenuItemFromAnalysis(
        this.analysisWrappersRec.recyclabilityDtos[0][0] as PackagingSystemRecyclabilityResultDto);
      recMenuItem.analysisType = AnalysisType.Recyclability;
      this.recyclabilityAnalysisMenu.menuItems.push(recMenuItem);
      this.fillMenuItemData(this.analysisWrappersRec.recyclabilityDtos, recMenuItem);
      this.recMenuItemsLoaded = Promise.resolve(true);
      this.cdr.detectChanges();
    } else if (this.isUserValidator && this.analysisWrappersLca) {
      this.versionsWithLcaCount = this.analysisWrappersLca.lifeCycleDtos.length;
      const lcaMenuItem: MenuItem = this.getMenuItemFromAnalysis(
        this.analysisWrappersLca.lifeCycleDtos[0][0] as PackagingSystemLifeCycleResultDto);
      lcaMenuItem.analysisType = AnalysisType.Lifecycle;
      this.lcaAnalysisMenu.menuItems.push(lcaMenuItem);
      this.fillMenuItemData(this.analysisWrappersLca.lifeCycleDtos, lcaMenuItem);
      this.lcaMenuItemsLoaded = Promise.resolve(true);
      this.cdr.detectChanges();
    } else if (this.isUserValidator && this.analysisWrappersLcaCradleToGate) {
      this.versionsWithCradleToGateCount = this.analysisWrappersLcaCradleToGate.lifeCycleDtos.length;
      const lcaMenuItem: MenuItem = this.getMenuItemFromAnalysis(
        this.analysisWrappersLcaCradleToGate.lifeCycleDtos[0][0].defaultAllocationResult as PackagingSystemLifeCycleResultDto);
      lcaMenuItem.analysisType = AnalysisType.CradleToGateLifecycle;
      this.lcaCradleToGateAnalysisMenu.menuItems.push(lcaMenuItem);
      this.fillMenuItemData(this.analysisWrappersLcaCradleToGate.lifeCycleDtos, lcaMenuItem);
      this.lcaCradleToGateMenuItemsLoaded = Promise.resolve(true);
      this.cdr.detectChanges();
    }
    else {
      this.versionsSubscription = this.packagingSystemApiService.getPackagingSystemHistoryVersions(this.packagingSystemId)
        .subscribe((versions: VersionDto[]) => {
          this.versionsWithRecCount = versions.filter(x => x.hasRecyclabilityResult).length;
          this.versionsWithLcaCount = versions.filter(x => x.hasLifeCycleResult).length;
          this.versionsWithCradleToGateCount = versions.length;
          let recCount = 0;
          let lcaCount = 0;
          let cradleToGateCount = 0;
          for (const version of versions) {
            const recMenuItem: MenuItem = this.getMenuItem(version);
            recMenuItem.analysisType = AnalysisType.Recyclability;
            this.recyclabilityAnalysisMenu.menuItems.push(recMenuItem);
            if (version.hasRecyclabilityResult && version.versionNumber) {
              this.recSubscription = this.analysisApiService
                .getPackagingSystemRecyclabilityHistoryResults(this.packagingSystemId, version.versionNumber)
                .subscribe(analyses => {
                  this.fillMenuItemData(analyses, recMenuItem);
                  if (++recCount === this.versionsWithRecCount) {
                    this.recMenuItemsLoaded = Promise.resolve(true);
                    this.cdr.detectChanges();
                  }
                });
            }
            const lcaMenuItem: MenuItem = this.getMenuItem(version);
            lcaMenuItem.analysisType = AnalysisType.Lifecycle;
            this.lcaAnalysisMenu.menuItems.push(lcaMenuItem);
            if (version.hasLifeCycleResult && version.versionNumber) {
              this.lcaSubscription = this.analysisApiService
                .getPackagingSystemLifeCycleHistoryResults(this.packagingSystemId, version.versionNumber)
                .subscribe(analyses => {
                  this.fillMenuItemData(analyses, lcaMenuItem);
                  if (++lcaCount === this.versionsWithLcaCount) {
                    this.lcaMenuItemsLoaded = Promise.resolve(true);
                    this.cdr.detectChanges();
                  }
                });
            }
            const cradleToGateMenuItem: MenuItem = this.getMenuItem(version);
            cradleToGateMenuItem.analysisType = AnalysisType.CradleToGateLifecycle
            this.lcaCradleToGateAnalysisMenu.menuItems.push(cradleToGateMenuItem);
            if(version.versionNumber) {
              this.lcaCradleToGateSubscription = this.analysisApiService
              .getPackagingSystemLifeCycleCradleToGateHistoryResults(this.packagingSystemId, version.versionNumber)
              .subscribe(analyses => {
                this.fillMenuItemData(analyses, cradleToGateMenuItem);
                if (++cradleToGateCount === this.versionsWithCradleToGateCount) {
                  this.lcaCradleToGateMenuItemsLoaded = Promise.resolve(true);
                  this.cdr.detectChanges();
                }
              });
            }
            const taxMenuItem: MenuItem = this.getMenuItem(version);
            taxMenuItem.analysisType = AnalysisType.PlasticTax;
            this.plasticTaxMenu.menuItems.push(taxMenuItem);

            const feeMenuItem: MenuItem = this.getMenuItem(version);
            feeMenuItem.analysisType = AnalysisType.LicenseFee;
            this.licenseFeeMenu.menuItems.push(feeMenuItem);
          }
        });
    }
  }

  private fillMenuItemData(evaluations: RecyclabilityResultDto[][] | LifeCycleResultDto[][] , menuItem: MenuItem) {
    menuItem.analysisVersions = evaluations.map(x => {
      return {
        displayDate: super.getDateTimeString(x[0].evaluationReferenceTimestamp),
        underlyingDate: new Date(x[0].evaluationReferenceTimestamp),
        analysisVersionId: x[0].id ?? ''
      };
    });
    if (this.isUserValidator && this.caller === AnalysisMenuCaller.Homepage) {
      const mapping = this.getPackagingValidationMapping(evaluations[0]);
      this.validationData.emit(mapping);
    }
  }

  private getPackagingValidationMapping(countriesAnalyses: EvaluationResultDto[]): PackagingValidationMapping {
    let isEveryCountryValidated = true;
    let isEveryAnalysisValid = true;
    let isUntouched = false;
    const isCurrent = countriesAnalyses.length > 0 ? countriesAnalyses[0].isCurrent : false;

    for (const country of countriesAnalyses) {
      if (country.validationState === ValidationState.Unknown) {
        isEveryCountryValidated = false;
        isUntouched = country.lastAccessTimestamp == null;
        break;
      } else if (country.validationState === ValidationState.Negative) {
        isEveryAnalysisValid = false;
      }
    }
    return {
      id: this.packagingSystemId,
      isFullyValidatedAllValid: isEveryCountryValidated && isEveryAnalysisValid,
      isFullyValidatedNotAllValid: isEveryCountryValidated && !isEveryAnalysisValid,
      isNotFullyValidated: !isEveryCountryValidated,
      isCurrent,
      isUntouched
    };
  }

  private getMenuItem(version: VersionDto): MenuItem {
    return {
      versionId: version.versionNumber,
      date: version.creationTimestamp ? new Date(version.creationTimestamp).toLocaleDateString() : '',
      time: version.creationTimestamp ? new Date(version.creationTimestamp).toLocaleTimeString() : '',
      analysisVersions: []
    };
  }

  private getMenuItemFromAnalysis(version: PackagingSystemRecyclabilityResultDto | PackagingSystemLifeCycleResultDto): MenuItem {
    return {
      versionId: version.analyzedPackagingSystem.version,
      date: new Date(version.evaluationReferenceTimestamp).toLocaleDateString(),
      time: new Date(version.evaluationReferenceTimestamp).toLocaleTimeString(),
      analysisVersions: []
    };
  }

  createAnalysis(analysisType: AnalysisType, versionId: number) {
    this.spinner.show();
    let observableCredits: Observable<number>;
    let observableProblemInformation: Observable<ProblemInformationDto[]>;

    if (analysisType === AnalysisType.Recyclability) {
      observableCredits = this.analysisApiService.getRecyclabilityCreditsRequirement(
        this.packagingSystemId, PackagingPart.System, versionId);
        observableProblemInformation = this.analysisApiService.getRecyclabilityAnalysisProblemInformation(
          this.packagingSystemId, PackagingPart.System, versionId);
    } else if (analysisType === AnalysisType.Lifecycle) {
      observableCredits = this.analysisApiService.getLifeCycleCreditsRequirement(
        this.packagingSystemId, PackagingPart.System, versionId);
        observableProblemInformation = this.analysisApiService.getLifeCycleAnalysisProblemInformation(
          this.packagingSystemId, PackagingPart.System, versionId);
    } else if (analysisType === AnalysisType.CradleToGateLifecycle) {
      observableCredits = this.analysisApiService.getLifeCycleCreditsRequirement(
        this.packagingSystemId, PackagingPart.System, versionId);
        observableProblemInformation = this.analysisApiService.getLifeCycleAnalysisProblemInformation(
          this.packagingSystemId, PackagingPart.System, versionId);
    } else if (analysisType === AnalysisType.PlasticTax || analysisType === AnalysisType.LicenseFee) {
      observableCredits = of(0);
      observableProblemInformation = this.analysisApiService.getExpenseAnalysisProblemInformation(
        this.packagingSystemId, PackagingPart.System, versionId);
    } else { return; }

    this.analysisPossibleSubscription = observableProblemInformation.subscribe(problemInformation => {
      this.creditsSubscription = observableCredits.subscribe(creditsRequired => {
        this.navigationService.navigateToAnalysisWithCostConfirmation(
          analysisType, this.packagingSystemId, versionId, problemInformation, creditsRequired);
      });
    });
  }

  navigateToAnalysis(analysisVersionId: string, analysisType: AnalysisType, versionId: number) {
    this.spinner.show();
    if (!this.isUserValidator) {
      this.navigate(analysisVersionId, analysisType, versionId);
    } else {
      this.timestampSubscription = this.analysisApiService.
        setAnalysisViewedTimestamp(analysisVersionId).subscribe(_ => {
          this.navigate(analysisVersionId, analysisType, versionId);
        });
    }
  }

  private navigate(analysisVersionId: string, analysisType: AnalysisType, versionId: number) {
    this.navigationService.navigateToHistoryAnalysis(
      analysisType, this.packagingSystemId, versionId, analysisVersionId);
  }

  hasCradleToGateEvaluations() :boolean {
    return this.lcaCradleToGateAnalysisMenu.menuItems.filter(x => x.analysisVersions.length > 0).length > 0
  }
}
