<h1 mat-dialog-title>{{ 'analysis.lifecycleAnalysis.table' | translate }}</h1>
<mat-dialog-content>

  <div class="scrolling-wrapper">
    <mat-table [dataSource]="getTableRows()" class="mat-elevation-z8" id="life-cycle-table">

      <ng-container matColumnDef="lifeCycleStep">
        <mat-header-cell *matHeaderCellDef [ngClass]="isDarkTheme ? 'dark-column-header' : 'column-header'">
          {{ 'analysis.lifecycleAnalysis.impactCategory' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" [ngClass]="isDarkTheme ? 'dark-row-header' : 'row-header'"
          matTooltip="{{row.description}}">
          <div>
            <div>{{row.name}}</div>
            <div *ngIf="row.unit">[{{row.unit}}]</div>
          </div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="{{column.key}}" *ngFor="let column of getTableColumns(); let i = index">
        <mat-header-cell *matHeaderCellDef [ngClass]="isDarkTheme ? 'dark-column-header' : 'column-header'"
          matTooltip="{{column.description}}">
          <div>
            <div>{{column.name}}</div>
          </div>
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let j = index" class="{{getTableElementType(i)}}">
          {{getTableElement(lifeCycleEvaluationResult, column, row)}}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="getTableDisplayedColumns()"></mat-header-row>
      <mat-row *matRowDef="let element; columns: getTableDisplayedColumns();"> </mat-row>

    </mat-table>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="closeDialog()" mat-raised-button color="primary" type="button">
    {{ 'common.buttons.close' | translate }}</button>
</mat-dialog-actions>
