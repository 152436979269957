import { DirectoryDto } from './../../../data-transfer/entities/directory-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DtoFolderService {

  constructor() { }

  renameDtoFolder(rootDtoFolder: DirectoryDto, folderId: number, newFolderName: string) {
    let dtoFolder: DirectoryDto;
    if (rootDtoFolder.id === folderId) {
      rootDtoFolder.name = newFolderName;
    } else {
      dtoFolder = this.findDtoFolderRecursively(folderId, rootDtoFolder.childDirectories);
      if (dtoFolder) {
        dtoFolder.name = newFolderName;
      }
    }
  }

  public findDtoFolderRecursively(folderId: number, children: DirectoryDto[]): any {
    let returnChild = null;
    for (const child of children) {
      if (folderId === child.id) {
        returnChild = child;
      } else {
        returnChild = this.findDtoFolderRecursively(folderId, child.childDirectories);
      }
      if (returnChild != null) {
        return returnChild;
      }
    }
  }

  public dtoFolderNameExistsInParent(folderName: string, parent: DirectoryDto) {
    if (!folderName || !parent) { return false; }

    for (const child of parent.childDirectories) {
      if (folderName === child.name) {
        return true;
      }
    }
    return false;
  }

  public addDtoFolder(parentItem: DirectoryDto, folderName: string, folderId: number) {
    const newFolder: DirectoryDto = {
      id: folderId,
      name: folderName,
      ownerPrincipalId: -1,
      childDirectories: []
    };
    if (parentItem) {
      parentItem.childDirectories.push(newFolder);
    }
  }

  public getDtoFolderParent(rootFolder: DirectoryDto, folderId: number): any {
    let parent = null;
    for (const child of rootFolder.childDirectories) {
      if (folderId === child.id) {
        parent = rootFolder;
      } else {
        parent = this.getDtoFolderParent(child, folderId);
      }
      if (parent != null) {
        return parent;
      }
    }
  }

  getAllChildren(parentFolder: DirectoryDto) {
    let children: DirectoryDto[] = [];
    parentFolder.childDirectories.forEach(childDir => {
      children.push(childDir);
      if (childDir.childDirectories.length > 0) {
        children = children.concat(this.getAllChildren(childDir));
      }
    });
    return children;
  }

  public moveDtoFolder(oldParent: DirectoryDto, newParent: DirectoryDto, movedFolder: DirectoryDto) {
    if (!oldParent || !newParent || !movedFolder) { return; }

    newParent.childDirectories.push(movedFolder);
    const index = oldParent.childDirectories.findIndex(x => x === movedFolder);
    oldParent.childDirectories.splice(index, 1);
  }
}
