<mat-menu #menu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item [matMenuTriggerFor]="templatesMenu" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'downloads.file.templates.title' | translate }}</span>
  </button>
  <!-- All items with sub menus shall be before the link section for correct alignment of the sub menus-->
  <div *ngFor="let document of documents">
    <a [href]="document.path" mat-menu-item target="_blank">
      <mat-icon>text_snippet</mat-icon>{{ document.label }}
    </a>
  </div>
  <button *ngIf="isAdmin" mat-menu-item (click)="downloadUsageReport()">
    <mat-icon>text_snippet</mat-icon> {{'downloads.admin.file.userReport' | translate}}
  </button>
</mat-menu>

<mat-menu #templatesMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="downloadPUImportTemplate()" type="button">
    <mat-icon>text_snippet</mat-icon>
    <span>{{ 'downloads.file.templates.excelimport' | translate}}</span>
  </button>
</mat-menu>
<!-- Exclude Api documentation
<button mat-menu-item (click)="clickExternalHtml()" type="button">
  <mat-icon>text_snippet</mat-icon>
  <span>API Documentation</span>
</button>
-->
