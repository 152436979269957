<h1 mat-dialog-title>
  <span *ngIf="isPackagingSystemsExport">
    {{ 'dataManagement.directory.dialog.export.titlePackagingSystem' | translate }}
  </span>
  <span *ngIf="isPackagingUnitsExport">
    {{ 'dataManagement.directory.dialog.export.titlePackagingUnit' | translate }}
  </span>
  <span *ngIf="isComponentsExport">
    {{ 'dataManagement.directory.dialog.export.titleComponent' | translate }}
  </span>
  <span *ngIf="isMaterialExport">
    {{ 'dataManagement.directory.dialog.export.titleMaterial' | translate }}
  </span>
</h1>

<mat-dialog-content>
  <form [formGroup]="exportForm">
    <div class="export-card">
      <mat-label>{{ 'dataManagement.directory.dialog.export.principalsLabel' | translate }}</mat-label>
      <mat-form-field class="chip-list" appearance="outline">
        <mat-chip-grid #chipGrid>
          <mat-chip-row *ngFor="let principal of selectedPrincipals" (removed)="removePrincipal(principal)">
            {{principal.name}}
            <button matChipRemove [attr.aria-label]="'remove ' + principal">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        </mat-chip-grid>
        <input placeholder="{{ 'dataManagement.directory.dialog.export.addPrincipal' | translate }}" #userGroupInput
          [formControl]="principalCtrl" [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addPrincipal($event.option.value)">
          <mat-option *ngFor="let principal of filteredPrincipals | async" [value]="principal">
            {{ principal.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div>
        <mat-label class="label-hint">
          {{ 'dataManagement.directory.dialog.export.selectedOrgCount' | translate: {orgCount:
          selectedPrincipals.length} }}
        </mat-label>
      </div>
    </div>

    <div class="export-card" *ngIf="profilesDataSource.data.length > 0">
      <mat-label class="label-large">
        {{ 'dataManagement.directory.dialog.export.exportProfileLabel' | translate }}
      </mat-label>
      <div class="scrolling-wrapper export-selection export-profile-table">
        <app-export-profiles-table [dataSource]="profilesDataSource" [displayedColumns]="displayedColumns"
          [selectedProfile]="selectedProfile" [canSelectRows]="true" (viewProfileEvent)="viewProfile($event)"
          (selectedProfileChange)="selectedProfileChanged($event)">
        </app-export-profiles-table>
      </div>
      <mat-label class="label-hint">
        {{ 'dataManagement.directory.dialog.export.selectedProfile' | translate: {profileName: selectedProfile?.profileName ?? '--'} }}
      </mat-label>
    </div>

    <div class="export-card" *ngIf="isPackagingSystemsExport && (subProfilesDataSource?.data?.length ?? 0) > 0">
      <mat-label class="label-large">
        {{ 'dataManagement.directory.dialog.export.unitExportProfileLabel' | translate }}
      </mat-label>
      <div class="scrolling-wrapper export-selection export-profile-table">
        <app-export-profiles-table [dataSource]="subProfilesDataSource" [displayedColumns]="displayedColumns"
          [(selectedProfile)]="selectedSubProfile" [canSelectRows]="true" (viewProfileEvent)="viewSubProfile($event)"
          (selectedProfileChange)="selectedSubprofileChanged($event)">
        </app-export-profiles-table>
      </div>
      <mat-label class="label-hint">
        {{ 'dataManagement.directory.dialog.export.selectedProfile' | translate: {profileName:
        selectedSubProfile?.profileName ?? '--'} }}
      </mat-label>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="export()" [disabled]="selectedPrincipals.length === 0"
    type="submit">
    {{ 'common.buttons.export' | translate }}
  </button>
  <button mat-raised-button (click)="cancel()" type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
