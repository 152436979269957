import { TranslateService } from '@ngx-translate/core';
import { ColorThemeService } from '../../../navigation/services/color-theme-service';
import { LifeCycleAnalysisInformation, LcaStageInfo, LcaEffectInfo } from '../../../model/life-cycle-data-information';
import { Component, Inject } from '@angular/core';
import { ChartSeries, EffectInfosDictionary, TOTAL_VALUE_INDEX } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';


const IMPACT_KEY = 'impact';

@Component({
  selector: 'app-life-cycle-cradle-to-gate-table-dialog',
  templateUrl: './life-cycle-cradle-to-gate-table-dialog.component.html',
  styleUrl: './life-cycle-cradle-to-gate-table-dialog.component.scss'
})

export class LifeCycleCradleToGateTableDialogComponent {

  formatValue = ((x: number) => x?.toExponential(4) ?? '???');
  formatPercentageValue = ((x: number) => (x * 100).toFixed(2) + '%');
  isDarkTheme = false;
  lifeCycleEvaluationResult!: ChartSeries[][];
  normalizationChartSubtitle: String;
  private effectsWithImpact: EffectInfosDictionary[];
  private columns : Entry []= [];

  constructor(
    private dialogRef: MatDialogRef<LifeCycleCradleToGateTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private colorThemeService: ColorThemeService,
    private lifeCycleAnalysisInformation: LifeCycleAnalysisInformation,
    private translateService: TranslateService
  ) {
    this.lifeCycleEvaluationResult = this.data.lifeCycleEvaluationResult;
    this.effectsWithImpact = this.data.effectsList;
    this.normalizationChartSubtitle = this.data.normalizationChartSubtitle??'';
    this.columns = this.createTableColumns();
    this.isDarkTheme = this.colorThemeService.isDarkTheme();
  }

  createTableColumns() : Entry[] {
    let columns= []
    for ( const col of this.lifeCycleEvaluationResult[0]) {
      columns.push(new Entry(col.name,col.label,''));
    }
    columns.push(new Entry(IMPACT_KEY, this.translateService.instant('analysis.lifecycleAnalysis.impactHeader'), '' ));
    return columns;
  }

  getTableRows(): LcaEffectInfo[] {
    return this.effectsWithImpact;
  }

  getTableColumns(): LcaStageInfo[] {
    return this.columns;
  }

  getTableDisplayedColumns() {
    const displayedColumns = ['lifeCycleStep'];
    this.getTableColumns().forEach(x => displayedColumns.push(x.key));
    return displayedColumns;
  }

  getTableElement(result: ChartSeries[][], column: LcaStageInfo, row: LcaEffectInfo) {
    return this.getEnvironmentalEffectValue(result, column.key, row.key);
  }

  private getEnvironmentalEffectValue(
    result: ChartSeries[][], column: string, effect: string
  ): string {
    if (column === IMPACT_KEY) {
      const effectWithImpact = this.effectsWithImpact.find(x => x.key === effect);
      const impact = effectWithImpact ? effectWithImpact.impact : 0;
      return this.formatPercentageValue(impact);
    } else {
      for (let i = 0 ; i < this.effectsWithImpact.length;i++) {
        if(this.effectsWithImpact[i].key === effect) {
          for( const col of result[i]) {
             if (col.name === column) {
              return this.formatValue(col.value)
             }
          }
        }
      }
      return this.formatValue(0);
    }
  }

  getTableElementType(columnIndex: number) {
    return columnIndex === TOTAL_VALUE_INDEX ?
      (this.isDarkTheme ? 'dark-highlighted-cell' : 'highlighted-cell') : 'default-cell';
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }


}

class Entry  {
  key: string;
  name: string;
  description: string;

  constructor (key: string, name: string, description: string) {
    this.key = key;
    this.name = name;
    this.description = description;
  }
}
