<div class="login-background" [ngClass]="isDarkTheme ? 'dark': 'light'">
  <div class="logo-container">
    <img [src]="imageSource" class="logo-class">
  </div>
  <div class="background-image"
    [ngClass]="isDarkTheme ? 'background-image-dark' : 'background-image-light'">
    <div class="login-container">
      <form [formGroup]="loginForm" data-automation-id="login-form">
        <div class="mat-elevation-z8">
          <mat-toolbar id="login-toolbar">
            <span data-automation-id="login-form-title">{{ 'login.title' | translate }}</span>
            <button class="blue" type="button" mat-icon-button
              [matMenuTriggerFor]="menuLanguage">
              <mat-icon>language</mat-icon>
              <mat-menu #menuLanguage>
                <button *ngFor="let countryCode of countryCodes" mat-menu-item (click)="setLanguage(countryCode)"
                  id="language-{{countryCode}}">
                  <span class="flag-icon flag-icon-{{countryCode}} space-right"></span>
                  {{'language.'+ countryCode | translate}}
                </button>
              </mat-menu>
            </button>
          </mat-toolbar>
          <mat-card>
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon class="material-icons md-48" id="user-icon">account_circle</mat-icon>
              </div>
              <mat-card-title [ngClass]="isDarkTheme ? 'text-dark-theme': 'text-light-theme'">
                <strong>{{ 'login.signIn' | translate }}</strong>
              </mat-card-title>
              <mat-card-subtitle>{{ 'login.subtitle' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'login.fields.user' | translate }}</mat-label>
                  <input matInput placeholder="User" type="text" formControlName="login" data-automation-id="login"
                    required class="small-input">
                  <mat-icon matPrefix>person</mat-icon>
                  <mat-error *ngIf="field.login.hasError('required')" data-automation-id="login-required-error">
                    {{ 'login.errors.userRequired' | translate }}</mat-error>
                  <mat-error *ngIf="field.login.hasError('unknownUser')" data-automation-id="login-unknownUser-error">
                    {{ 'login.errors.userUnknown' | translate }}</mat-error>
                </mat-form-field>
              </p>
              <p>
                <mat-form-field appearance="outline">
                  <mat-label>{{ 'login.fields.password' | translate }}</mat-label>
                  <input matInput type="password" placeholder="Password" formControlName="password"
                    data-automation-id="password" required class="small-input">
                  <mat-icon matPrefix>lock</mat-icon>
                  <mat-error *ngIf="field.password.hasError('required')" data-automation-id="password-required-error">
                    {{ 'login.errors.passwordRequired' | translate }}</mat-error>
                  <mat-error *ngIf="field.password.hasError('wrongPassword')" data-automation-id="password-wrong-error">
                    {{ 'login.errors.passwordWrong' | translate }}</mat-error>
                </mat-form-field>
              </p>
            </mat-card-content>
            <mat-card-actions class="flex-row-space-around">
              <button class="login-button" mat-raised-button [disabled]="buttonDisabled" (click)="signIn()"
                color="primary">{{"login.signIn" | translate}}
                <div class="spinner-container" *ngIf="spinnerActive">
                  <mat-spinner diameter="24" color="accent"></mat-spinner>
                </div>
              </button>
            </mat-card-actions>
            <div *ngIf="externalLogins == null || (externalLogins != null && externalLogins.length > 0)">
              <div class="login-divider">
                <mat-divider></mat-divider>
                <span [ngClass]="isDarkTheme ? 'text-dark-theme': 'text-light-theme'">{{ 'login.or' | translate }}</span>
                <mat-divider></mat-divider>
              </div>
              <div class="flex-row-space-around">
                <mat-form-field class="login-select">
                  <mat-label>{{ 'login.externalLogin' | translate }}</mat-label>
                  <mat-select name="externalLogin" (selectionChange)="signInAzureAd($event.value)">
                    <mat-option *ngFor="let externalLogin of externalLogins" [value]="externalLogin">
                      {{ externalLogin }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-card>
        </div>
      </form>
      <div class="footer-text">
        <span class="mat-headline-6" [ngClass]="isDarkTheme ? 'text-dark-theme': 'text-light-theme'">{{ 'login.noAccount' | translate }}
          <a [href]="'contactInfo.packoHomepageContact' | translate" target="_blank">{{ 'login.contactUs' | translate }}</a>
          {{ 'login.moreInfo' | translate }}</span>
      </div>
    </div>
    <app-version data-automation-id="app-version"></app-version>
  </div>
</div>
