<div class="parent-div">
  <mat-card #lastEdited id="last-edited-card" class="resize-element" *ngIf="!isValidator && !isAdmin">
    <mat-accordion id="tables-accordion">
      <mat-expansion-panel [expanded]="true" class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'packagingSystem.lastEdited' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
          <app-packaging-systems-overview [dataSource]="packagingSystemsDataSource"
            [displayedColumns]="displayedColumnsPackagingSystems" [isUserValidator]="isValidator">
          </app-packaging-systems-overview>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false" class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'packagingUnit.lastEdited' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
          <app-packaging-units-overview [dataSource]="packagingUnitsDataSource"
            [displayedColumns]="displayedColumnsPackagingUnits" [isUserValidator]="isValidator">
          </app-packaging-units-overview>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false" class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'component.lastEdited' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
          <app-packaging-components-overview [dataSource]="componentsDataSource"
            [displayedColumns]="displayedColumnsComponents" [isUserValidator]="isValidator">
          </app-packaging-components-overview>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false" class="expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'material.lastEdited' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
          <app-composite-materials-overview [dataSource]="materialsDataSource"
            [displayedColumns]="displayedColumnsMaterials" [isUserValidator]="isValidator">
          </app-composite-materials-overview>
        </mat-expansion-panel-body>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>

  <app-splitter class="splitter resize-element" (splitterMoved)="resize($event)" *ngIf="!isValidator && !isAdmin">
  </app-splitter>

  <mat-card #quickLinks id="quick-links-card" [ngClass]="{'resize-element': !isValidator && !isAdmin }">
    <div id="links-container">
      <div *ngIf="!isValidator && !isAdmin" class="single-link">
        <button mat-raised-button class="button" (click)="packagingSystemsClicked()" type="button">
          <img class="icon" [src]="packagingSystemsIconSource">
          <div>{{ 'home.packagingSystemHome.title' | translate }}</div>
        </button>
      </div>
      <div *ngIf="!isValidator && !isAdmin" class="single-link">
        <button mat-raised-button class="button" (click)="packagingUnitsClicked()" type="button">
          <img class="icon" [src]="packagingUnitsIconSource">
          <div>{{ 'home.packagingUnitHome.title' | translate }}</div>
        </button>
      </div>
      <div *ngIf="!isValidator && !isAdmin" class="single-link">
        <button mat-raised-button class="button" (click)="componentsClicked()" type="button">
          <img class="icon" [src]="componentsIconSource">
          <div>{{ 'home.componentHome.title' | translate }}</div>
        </button>
      </div>
      <div *ngIf="!isValidator && !isAdmin" class="single-link">
        <button mat-raised-button class="button" (click)="materialsClicked()" type="button">
          <img class="icon" [src]="materialsIconSource">
          <div>{{ 'home.materialHome.title' | translate }}</div>
        </button>
      </div>
      <div class="single-link">
        <button mat-raised-button class="button" [matMenuTriggerFor]="dataMenu.menu" type="button">
          <img class="icon" [src]="dataManagementIconSource">
          <div>{{ 'dataManagement.title' | translate }}</div>
        </button>
      </div>
      <div *ngIf="!isValidator && !isAdmin" class="single-link">
        <button mat-raised-button class="button" [matMenuTriggerFor]="evaluationsMenu" type="button">
          <img class="icon" [src]="dataEvaluationIconSource">
          <div>{{ 'dataEvaluation.title' | translate }}</div>
        </button>
      </div>
      <div class="single-link">
        <button mat-raised-button class="button" [matMenuTriggerFor]="downloadMenu.menu" type="button">
          <img class="icon" [src]="downloadsIconSource">
          <div>{{ 'downloads.title' | translate }}</div>
        </button>
      </div>
      <div class="single-link">
        <button mat-raised-button class="button" (click)="websiteClicked()" type="button">
          <img class="icon" [src]="websiteIconSource">
          <div>{{ 'home.shortcuts.website'| translate }}</div>
        </button>
      </div>
    </div>
  </mat-card>
</div>

<app-downloads-menu #downloadMenu></app-downloads-menu>

<app-data-menu #dataMenu></app-data-menu>

<mat-menu #evaluationsMenu="matMenu" [overlapTrigger]="false">
  <app-evaluations-menu></app-evaluations-menu>
</mat-menu>
