import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { COLOR_THEME_DARK, ColorThemeService } from 'src/app/navigation/services/color-theme-service';
import { AuthService } from 'src/app/services/auth-service';
import { ContractApiService } from 'src/app/data-transfer/services/contract-api-service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { InBrowserFile }  from 'src/app/model/in-browser-file';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})

export class LandingPageComponent implements OnInit {

  isDarkTheme = false;
  imageSource = '';
  isContractSigned = true;
  isUserActivated = true;
  contractSignedCheck = true;
  selectedIds : number[] = [];
  contracts : InBrowserFile[] = [];

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private colorThemeService: ColorThemeService,
    private sanitize: DomSanitizer,
    private contractApiService: ContractApiService,
    private dialog: MatDialog,

  ) {
  }

  ngOnInit() {
    this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = `${PROJECT_IMAGES_PATH}logo/logo_paco${this.isDarkTheme ? '_light' : '_dark'}.svg`;
    });
    this.isContractSigned = this.authService.isContractSigned();
    this.contractSignedCheck = this.isContractSigned
    this.isUserActivated = !this.authService.isUserInactive();
    this.contractApiService.getunsignedContracts().subscribe(data => {
      if(data.length>0) {
        let expanded : boolean =true;
        for (const contract of data) {
          this.selectedIds.push(contract.documentId);
          const url = this.base64PDFToFileUrl(contract.fileStreamResult, contract.label)
          const pdfUrl : SafeUrl = this.sanitize.bypassSecurityTrustResourceUrl(url);
          const el : InBrowserFile = {
            id: contract.documentId,
            label: contract.label,
            pdfUrl: pdfUrl,
            isExpanded: expanded
          }
          expanded = false;
          this.contracts.push(el)
        }
      }
    })
  }

  signContract() {
    if(this.selectedIds.length > 0 ) {
      this.contractApiService.signContracts(this.selectedIds).subscribe(
        data=> {
          if(data) {
            window.location.reload();
          }else{
          const dialogData: SimpleDialogData = {
            title: this.translateService.instant('common.text.error'),
            messages: [this.translateService.instant('errors.codeSpecific.400_title')], icon: 'warning'
          };
          const dialogConfig = getDialogConfig(dialogData);
          this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
        }
        }
      )
    }else{
      const dialogData: SimpleDialogData = {
        title: this.translateService.instant('common.text.error'),
        messages: [this.translateService.instant('errors.codeSpecific.400_title')], icon: 'warning'
      };
      const dialogConfig = getDialogConfig(dialogData);
      this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
    }
  }

  base64PDFToFileUrl( base64:string , label: string ) {
    const binStr = atob( base64 );
    const len = binStr.length;
    const arr = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      arr[ i ] = binStr.charCodeAt( i );
    }
    const blob =  new Blob( [ arr ], { type: 'application/pdf' } );
    const file =  new File([blob], label,  { type: 'application/pdf' });
    const url = URL.createObjectURL( file );
    return url;
  }

}
