import { TranslateService } from '@ngx-translate/core';
import { Injectable, OnDestroy } from '@angular/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { Subscription } from 'rxjs/internal/Subscription';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { CreditsService } from 'src/app/services/credits-service';

export enum AnalysisType {
  MasterData,
  Recyclability,
  Lifecycle,
  PlasticTax,
  LicenseFee,
  CradleToGateLifecycle
}

@Injectable({ providedIn: 'root' })
export class ParentNavigationService implements OnDestroy {

  protected dialogSubscription?: Subscription;
  protected evaluationSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected creditsService: CreditsService
  ) { }

  stopWhenCreditsInsufficient(): void {
    const data = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('warnings.insufficientCredits')], icon: 'info'
    };
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
  }

  stopWhenAnalysisNotPermitted(): void {
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('warnings.analysisNotPermitted')], icon: 'info'
    };
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data));
  }
  areSpecificCreditsRunningLow(analysisType: AnalysisType): boolean {
    switch(analysisType) {
      case AnalysisType.Lifecycle: return this.creditsService.areLcaCreditsRunningLow();
      case AnalysisType.Recyclability: return this.creditsService.areRecyclabilityCreditsRunningLow();
      //currently other analysises doesn't have own credits
      default : return this.creditsService.areRecyclabilityCreditsRunningLow();
    }
  }

  areInsufficientCreditsAvailable(analysisType: AnalysisType, amount:number) {
    switch(analysisType) {
      case AnalysisType.Lifecycle: return this.creditsService.creditsCount.lcaCredits<amount;
      case AnalysisType.Recyclability: return this.creditsService.creditsCount.recyclabilityCredits<amount;
      //currently other analysises doesn't have own credits
      default : return this.creditsService.creditsCount.recyclabilityCredits<amount;
    }
  }
  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.evaluationSubscription?.unsubscribe();
  }
}
