import { AggregateEvaluationMassOutputDto, MassAggrMaterialResultDto } from './../../../data-transfer/entities/aggregate-evaluation-mass-output';

export class MaterialMassAggregatedEvaluationResult {

    dataSource: MassAggrMaterialResultDto[];

    constructor(
        private massOutput: AggregateEvaluationMassOutputDto
    ) {
        this.dataSource = this.massOutput.materialResults;
    }
}
