import { Component, Input } from '@angular/core';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { RecyclabilityService } from 'src/app/util/analyses-util/recyclability/recyclability-service';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { RecyclabilityResultComponent } from 'src/app/model/evaluations/recyclability-result-component';
import { AnalysisRecyclabilityPackagingPartComponent } from '../analysis-recyclability-packaging-part/analysis-recyclability-packaging-part.component';

@Component({
  selector: 'app-analysis-recyclability-packaging-system',
  templateUrl: './analysis-recyclability-packaging-system.component.html',
  styleUrls: ['./analysis-recyclability-packaging-system.component.scss']
})
export class AnalysisRecyclabilityPackagingSystemComponent extends AnalysisRecyclabilityPackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingSystemRecyclabilityResultDto;
  @Input() packagingSystem!: PackagingSystemDto;

  dataSourceForCountry?: RecyclabilityResultPackagingSystem | RecyclabilityResultPackagingUnit | RecyclabilityResultComponent;

  get dataSourceForCountryAsSystem(): RecyclabilityResultPackagingSystem {
    return this.dataSourceForCountry as RecyclabilityResultPackagingSystem;
  }

  get dataSourceForCountryAsUnit(): RecyclabilityResultPackagingUnit {
    return this.dataSourceForCountry as RecyclabilityResultPackagingUnit;
  }

  get dataSourceForCountryAsComponent(): RecyclabilityResultComponent {
    return this.dataSourceForCountry as RecyclabilityResultComponent;
  }

  constructor(
    private recService: RecyclabilityService
  ) {
    super();
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.System) {
      this.onSystemNodeChanged(node.id);
    } else if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
  }

  onSystemNodeChanged(systemId: number | null) {
    if (systemId == null) { return; }
    const selectedResult = this.recService.findSystemResultRecursively(this.selectedAnalysisVersion, systemId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.recService.getPackagingSystemRecyclabilityResultFromDto(selectedResult);
  }

  onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    const selectedResult = this.recService.findUnitResultRecursively(this.selectedAnalysisVersion, unitId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.recService.getPackagingUnitRecyclabilityResultFromDto(selectedResult);
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResult = this.recService.findComponentResultRecursively(this.selectedAnalysisVersion, componentId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.recService.getComponentRecyclabilityResultFromDto(selectedResult);
  }
}
