<table mat-table [dataSource]="dataSource" class="export-list-table" [fixedLayout]="true" matSort matSortDisableClear matSortDirection="asc" matSortActive="packagingId">

  <ng-container matColumnDef="importCandidateId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'importCandidateId' }" type="button">
          {{ 'dataManagement.exportList.columns.importCandidateId' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('importCandidateId')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.importCandidateId}} </td>
  </ng-container>

  <ng-container matColumnDef="exportTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" type="button">
          {{ 'dataManagement.exportList.columns.exportTimestamp' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('exportTimestamp')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.exportTimestamp.getTime() < 0">-</ng-container>
      <ng-container *ngIf="element.exportTimestamp.getTime() >= 0">{{element.exportTimestamp | date:'short' }}</ng-container>
    </td>

  </ng-container>

  <ng-container matColumnDef="exportProfile">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'exportProfile.profileName' }" type="button">
          {{ 'dataManagement.exportList.columns.exportProfile' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('exportProfile.profileName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.exportProfile?.profileName ?? '-'}}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'status' }" type="button">
          {{ 'dataManagement.exportList.columns.status' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('status')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ 'dataManagement.exportList.status.' + element.status | translate }}</td>
  </ng-container>

  <ng-container matColumnDef="tracking">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'tracking' }" type="button">
          {{ 'dataManagement.exportList.columns.tracking' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('tracking')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ 'dataManagement.exportList.tracking.' + (element.tracking ? 'on' : 'off') | translate }}</td>
  </ng-container>

  <ng-container matColumnDef="packagingId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'packaging.id' }" type="button">
          {{ 'dataManagement.exportList.columns.packagingId' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packaging.id')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.exportedPackaging.id}} </td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'packaging.productName' }" type="button">
          {{ 'dataManagement.exportList.columns.name' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packaging.productName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.exportedPackaging.productName }}</td>
  </ng-container>

  <ng-container matColumnDef="articleName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'packaging.articleName' }" type="button">
          {{ 'dataManagement.exportList.columns.name' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packaging.articleName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.exportedPackaging.articleName }}</td>
  </ng-container>

  <ng-container matColumnDef="version">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'packaging.version' }" type="button">
          {{ 'dataManagement.exportList.columns.version' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packaging.version')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.exportedPackaging.version }}</td>
  </ng-container>

  <!-- Action -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'dataManagement.actions' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button type="button" (click)="changeTrackingClicked(element)">
        <mat-icon>track_changes</mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="deleteImportClicked(element)" [disabled]="element.status!=='Shared' && element.status!=='Imported'">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></tr>

  <app-filter-menu #filterComponent="columnFilterComponent" [dataSource]="dataSource"></app-filter-menu>
</table>
