import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-simple-confirm-dialog',
  templateUrl: './simple-confirm-dialog.component.html',
  styleUrls: ['./simple-confirm-dialog.component.scss']
})
export class SimpleConfirmDialogComponent {

  title: string;
  messages: string[];
  icon: string;

  constructor(
    public dialogRef: MatDialogRef<SimpleConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = data.title;
    this.messages = data.messages;
    this.icon = data.icon;
  }

  doConfirm() {
    this.dialogRef.close({ event: DialogActions.CONFIRM });
  }

  doReject() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
