import { ColorThemeService, COLOR_THEME_DARK } from './../../../../navigation/services/color-theme-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ReportApiService } from 'src/app/data-transfer/services/report-api-service';
import { MatTableDataSource } from '@angular/material/table';
import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { ComponentReportDto } from 'src/app/data-transfer/entities/component-entities/component-report-dto';
import { Subscription } from 'rxjs';

class MappedPackagingUnitReportDto extends PackagingUnitReportDto {
  creationDateString?: string;
}

class MappedComponentReportDto extends ComponentReportDto {
  creationDateString?: string;
}

@Component({
  selector: 'app-report-templates-overview',
  templateUrl: './report-templates-overview.component.html',
  styleUrls: ['./report-templates-overview.component.scss']
})
export class ReportTemplatesOverviewComponent implements OnInit, OnDestroy {

  @Input() reportTemplates!: PackagingUnitReportDto[] | ComponentReportDto[];
  @Input() displayedColumns!: string[];
  @Input() canSelectRows = false;
  @Input() callerLevel!: number;

  @Output() selectedTemplateChange = new EventEmitter<PackagingUnitReportDto>();

  dataSource!: MatTableDataSource<MappedPackagingUnitReportDto | MappedComponentReportDto>;

  selectedTemplate?: PackagingUnitReportDto;
  isDarkTheme = false;
  
  private themeSubscription?: Subscription;
  private deletionSubscription?: Subscription;

  constructor(
    private router: Router,
    private reportApiService: ReportApiService,
    private colorThemeService: ColorThemeService
  ) {
    this.dataSource = new MatTableDataSource<PackagingUnitReportDto | MappedComponentReportDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit(): void {
    this.dataSource.data = this.reportTemplates;
    this.dataSource.data.forEach(x => x.creationDateString = x.creationTimestamp ? this.formatDateToString(x.creationTimestamp) : '');
  }

  editReportTemplate(template: MappedPackagingUnitReportDto | MappedComponentReportDto) {
    let path: string;
    switch (this.callerLevel) {
      case PackagingPart.Unit:
        path = PathResolveService.getUpdatePackagingUnitReportTemplatePath(true, template.id);
        break;
      case PackagingPart.Component:
        path = PathResolveService.getUpdateComponentReportTemplatePath(true, template.id);
        break;
      default: return;
    }
    this.router.navigate([path]);
  }

  deleteReportTemplate(template: MappedPackagingUnitReportDto | MappedComponentReportDto) {
    // TODO add dialogs for deletion confirmation and for success/error
    if (template.id == null) { return; }
    switch (this.callerLevel) {
      case PackagingPart.Unit:
        this.deletionSubscription = this.reportApiService.deletePackagingUnitReportTemplate(template.id).subscribe(_ => {
          this.dataSource.data = this.dataSource.data.filter(x => x.id !== template.id);
        });
        break;
      case PackagingPart.Component:
        this.deletionSubscription = this.reportApiService.deleteComponentReportTemplate(template.id).subscribe(_ => {
          this.dataSource.data = this.dataSource.data.filter(x => x.id !== template.id);
        });
        break;
      default: return;
    }
  }

  onTemplateClicked(element: any) {
    if (!this.canSelectRows) { return; }
    if (element !== this.selectedTemplate) {
      this.selectedTemplate = element;
      this.selectedTemplateChange.emit(this.selectedTemplate);
    } else {
      this.selectedTemplate = undefined;
      this.selectedTemplateChange.emit(this.selectedTemplate);
    }
  }

  formatDateToString(timestamp: string) {
    if (timestamp) {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return '';
    }
  }
  
  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
    this.deletionSubscription?.unsubscribe();
  }
}
