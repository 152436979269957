import { PackagingPartBaseData } from './packaging-part-base-data';

export class PackagingParentDto extends PackagingPartBaseData {
    brandName = '';
    productName = '';
    articleNumber = '';
    gtin = '';
    length?: number;
    width?: number;
    height?: number;
    comment?: string;
    organizationId?: number;
    hasRecyclabilityResult = false;
    hasLifeCycleResult = false;
    assemblyCountry?: string;
    distributionCountries: string[] = [];
}
