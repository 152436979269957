<mat-table id="materials-table" [dataSource]="dataSource">
  <div *ngFor="let column of displayedColumns">
    <div *ngIf="column !== 'actions'; else actionColumn">
      <ng-container [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>{{ "report.tableColumn." + column | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element" [ngClass]="{'selectable-row': canSelectRows}"
          (click)="onTemplateClicked(element)">{{element[column]}}</mat-cell>
      </ng-container>
    </div>
    <ng-template #actionColumn>
      <ng-container [matColumnDef]="column">
        <mat-header-cell *matHeaderCellDef>{{ "report.tableColumn." + column | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" (click)="editReportTemplate(element)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="deleteReportTemplate(element)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    </ng-template>
  </div>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="selectedTemplate === row"
    [ngClass]="isDarkTheme ? 'background-highlight-dark' : 'background-highlight'"></mat-row>
</mat-table>
