import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { BooleanDictionary, DialogActions, DictionaryHandler } from 'src/app/model/dictionary';
import { CustomFieldProfileDto } from 'src/app/data-transfer/entities/custom-field-profile-dto';
import { CustomFieldAssignmentsDto } from 'src/app/data-transfer/entities/custom-field-assignments-dto';
import { CustomDropdownFieldOptionDto } from 'src/app/data-transfer/entities/custom-dropdown-field-option-dto';
import { CustomFieldDto } from 'src/app/data-transfer/entities/custom-field-dto';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { CustomFieldAssignmentDto } from 'src/app/data-transfer/entities/custom-field-assignment-dto';

export interface CustomFieldsDialogResult {
  event: DialogActions,
  assignments: CustomFieldAssignmentsDto
}

export enum CustomFieldType {
  Boolean,
  String,
  Integer,
  Decimal,
  Dropdown
}

export interface CustomField {
  id: number,
  name: string,
  options: CustomDropdownFieldOptionDto[],
  type: CustomFieldType,
  control: FormControl
}

export interface CustomFieldProfile {
  id: number,
  name: string,
  fields: CustomField[]
}

@Component({
  selector: 'app-custom-fields-dialog',
  templateUrl: './custom-fields-dialog.component.html',
  styleUrls: ['./custom-fields-dialog.component.scss']
})
export class CustomFieldsDialogComponent {
  profiles: CustomFieldProfile[] = [];
  assignments: CustomFieldAssignmentsDto;
  booleanValuesDictionary: BooleanDictionary[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CustomFieldsDialogComponent, CustomFieldsDialogResult>,
    private dictionaryHandler: DictionaryHandler,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { profiles: CustomFieldProfileDto[],
                                                           assignments: CustomFieldAssignmentsDto,
                                                           canEditForm: boolean },
    protected dialog: MatDialog
  ) {
      this.assignments = data.assignments;
      this.profiles = data.profiles.map(x => this.getProfileFromDto(x, data.canEditForm));
      this.booleanValuesDictionary = this.dictionaryHandler.getYesNoDictionary();
    }

  private getFieldFromDto(dto: CustomFieldDto, fieldType: CustomFieldType, control: FormControl): CustomField {
    return {
      id: dto.id,
      name: dto.name,
      type: fieldType,
      options: [],
      control: control
    };
  }

  get fieldType(): typeof CustomFieldType{
    return CustomFieldType;
  }

  private isFormValid(): boolean {
    for (const profile of this.profiles) {
      for (const field of profile.fields){
        if (!field.control.valid) {
          return false;
        }
      }
    }

    return true;
  }

  private integerFieldValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value != null && !Number.isInteger(control.value)) {
        return {
          notInteger: { value: control.value }
        }
      }

      return null;
    };
  }

  private createControl<T>(fieldValue: T, validators: ValidatorFn[], canEditForm: boolean): FormControl<T | null> {
    const control = this.formBuilder.control<T>(fieldValue, validators);

    if (!canEditForm) {
      control.disable();
    }

    return control;
  }

  getProfileFromDto(profile: CustomFieldProfileDto, canEditForm: boolean): CustomFieldProfile {
    const fields: CustomField[] = [];

    for (const field of profile.booleanFields) {
      const fieldValue = this.assignments.booleanFields.find(x => x.fieldId == field.id)?.fieldValue;
      const control = this.createControl(fieldValue, [], canEditForm);
      fields.push(this.getFieldFromDto(field, CustomFieldType.Boolean, control));
    }

    for (const field of profile.stringFields) {
      const fieldValue = this.assignments.stringFields.find(x => x.fieldId == field.id)?.fieldValue;
      const control = this.createControl(fieldValue, [], canEditForm);
      fields.push(this.getFieldFromDto(field, CustomFieldType.String, control));
    }

    for (const field of profile.decimalFields) {
      const fieldValue = this.assignments.decimalFields.find(x => x.fieldId == field.id)?.fieldValue;
      const control = this.createControl(fieldValue, [], canEditForm);
      fields.push(this.getFieldFromDto(field, CustomFieldType.Decimal, control));
    }

    for (const field of profile.integerFields) {
      const fieldValue = this.assignments.integerFields.find(x => x.fieldId == field.id)?.fieldValue;
      const control = this.createControl(fieldValue, [this.integerFieldValidator()], canEditForm);
      fields.push(this.getFieldFromDto(field, CustomFieldType.Integer, control));
    }

    for (const field of profile.dropdownFields) {
      const fieldValue = this.assignments.dropdownFields.find(x => x.fieldId == field.id)?.fieldValue;
      const control = this.createControl(fieldValue?.id, [], canEditForm);

      fields.push({
        id: field.id,
        name: field.name,
        type: CustomFieldType.Dropdown,
        options: field.options,
        control: control
      });
    }

    return {
      id: profile.id,
      name: profile.name,
      fields: this.sortFields(fields)
    };
  }

  private sortFields(fields: CustomField[]): CustomField[] {
    return fields.sort((f1, f2) => f1.name.toLocaleLowerCase().localeCompare(f2.name.toLocaleLowerCase()));
  }

  private buildAssignments(): CustomFieldAssignmentsDto {
    const assignments = new CustomFieldAssignmentsDto();

    for (const profile of this.profiles) {
      for (const field of profile.fields){
        if (field.control.value == null) {
          continue;
        }

        switch (field.type) {
          case CustomFieldType.Boolean:
            assignments.booleanFields.push(new CustomFieldAssignmentDto<boolean>(field.id, field.control.value));
            break;
          case CustomFieldType.Decimal:{
            assignments.decimalFields.push(new CustomFieldAssignmentDto<number>(field.id, field.control.value));
            break;
          }
          case CustomFieldType.Dropdown: {
            const selectedOption = field.options.find(x => x.id == field.control.value);
            if (selectedOption){
              assignments.dropdownFields.push(new CustomFieldAssignmentDto<CustomDropdownFieldOptionDto>(field.id, selectedOption));
            }
            break;
          }
          case CustomFieldType.Integer:
            assignments.integerFields.push(new CustomFieldAssignmentDto<number>(field.id, field.control.value));
            break;
          case CustomFieldType.String:
            if (field.control.value && field.control.value.trim() != "") {
              assignments.stringFields.push(new CustomFieldAssignmentDto<string>(field.id, field.control.value));
            }

            break;
        }
      }
    }

    return assignments;
  }

  onCancelButtonClicked() {
    this.dialogRef.close(
      {
        event: DialogActions.REJECT,
        assignments: this.assignments
      });
  }

  onSaveButtonClicked() {
    if (!this.isFormValid()){
      return;
    }

    this.dialogRef.close(
      {
        event: DialogActions.CONFIRM,
        assignments: this.buildAssignments()
      });
  }
}
