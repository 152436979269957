import { EnvironmentalEffectsEvaluationResultDto } from './environmental-effects-evaluation-result-dto';
import { RelevantEnvironmentalEffect } from './relevant-environmental-effect-dto';
import { EvaluationResultDto } from './evaluation-result-dto';

export class LifeCycleResultDto extends EvaluationResultDto {
  totalWeight = -1;
  totalRecyclateWeight = -1;
  recyclingRate = -1;
  landfillRate = -1;
  environmentalEffectsTotal!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsPrimaryMaterials!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsSecondaryMaterials!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsManufacturing!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsTransport!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsDistribution!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsEndOfLife!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsWasteToEnergyBurdens!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsWasteToEnergyCredits!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsIndustrialSolidWasteIncineration!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsRecycling!: EnvironmentalEffectsEvaluationResultDto;
  environmentalEffectsLandfill!: EnvironmentalEffectsEvaluationResultDto;
  relevantEnvironmentalEffects!: RelevantEnvironmentalEffect[];
}