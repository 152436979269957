<h1 mat-dialog-title>{{ 'dataManagement.directory.dialog.permissions.title' | translate }}</h1>

<mat-dialog-content>

  <form [formGroup]="permissionsForm">
    <div>

      <mat-card class="user-select-card">
        <mat-card-header>
          <mat-card-title>
            {{ 'dataManagement.directory.dialog.permissions.selectUserLabel' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <mat-selection-list #usersSelectionList [multiple]="false" formControlName="principalsControl">
            <mat-list-option *ngFor="let principal of allPrincipals" [value]="principal">
              {{ principal.name }}
            </mat-list-option>
          </mat-selection-list>
        </mat-card-content>
      </mat-card>

      <mat-card class="rights-select-card">
        <mat-card-header>
          <mat-card-title>
            {{ 'dataManagement.directory.dialog.permissions.selectRightsLabel' | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="full-height-row">
            <div class="permissions-column">
              <mat-label>Allow</mat-label>
              <div>
                <mat-checkbox formControlName="readAllow" (click)="setOppositeValueOnClick('readAllow', 'readDeny')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.read' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="writeAllow" (click)="setOppositeValueOnClick('writeAllow', 'writeDeny')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.write' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="analyzeAllow"
                  (click)="setOppositeValueOnClick('analyzeAllow', 'analyzeDeny')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.analyze' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="deleteAllow"
                  (click)="setOppositeValueOnClick('deleteAllow', 'deleteDeny')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.delete' | translate }}</mat-checkbox>
              </div>
            </div>
            <div class="permissions-column">
              <mat-label>Deny</mat-label>
              <div>
                <mat-checkbox formControlName="readDeny" (click)="setOppositeValueOnClick('readDeny', 'readAllow')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.read' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="writeDeny" (click)="setOppositeValueOnClick('writeDeny', 'writeAllow')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.write' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="analyzeDeny"
                  (click)="setOppositeValueOnClick('analyzeDeny', 'analyzeAllow')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.analyze' | translate }}</mat-checkbox>
              </div>
              <div>
                <mat-checkbox formControlName="deleteDeny"
                  (click)="setOppositeValueOnClick('deleteDeny', 'deleteAllow')">
                  {{ 'dataManagement.directory.dialog.permissions.rights.delete' | translate }}</mat-checkbox>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="saveSettings()"
    type="button">{{ 'common.buttons.save' | translate }}</button>
  <button mat-raised-button color="primary" (click)="applySettings()"
    type="button">{{ 'common.buttons.apply' | translate }}</button>
  <button mat-raised-button (click)="closeDialog()" type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
