import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { RecyclabilityTableData } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { RowSpanComputer, Span } from 'src/app/util/analyses-util/row-span-computer';

class RecyclabilityTableColumn {
  columnDef = '';
  header = '';
  cell = (_: RecyclabilityTableData) => '';
}

@Component({
  selector: 'app-packaging-unit-ratings-table',
  templateUrl: './packaging-unit-ratings-table.component.html',
  styleUrls: ['../packaging-unit-components-table/packaging-unit-components-table.component.scss']
})
export class PackagingUnitRatingsTableComponent implements OnChanges {

  @Input() dataSource!: RecyclabilityResultPackagingUnit;

  dataColumnsRecyclability: RecyclabilityTableColumn[] = [];
  displayedColumnsRecyclability: string[] = [];
  rowSpansRecyclabilityTable: Array<Span[]> = [];
  recyclabilityTableDataSource: RecyclabilityTableData[] = [];

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue != null) {
      this.getDataSourceForVersion();
    }
  }

  private getDataSourceForVersion() {
    this.recyclabilityTableDataSource = this.getRecyclabilityTableData(this.dataSource);
    this.dataColumnsRecyclability = this.getRecyclabilityTableColumns();
    this.displayedColumnsRecyclability = this.dataColumnsRecyclability.map(x => x.columnDef);
    this.rowSpansRecyclabilityTable = RowSpanComputer.computeRecyclability(
      this.recyclabilityTableDataSource, this.displayedColumnsRecyclability);
  }

  private getRecyclabilityTableData(dataSource: RecyclabilityResult): RecyclabilityTableData[] {
      const result: RecyclabilityTableData[] = [];
      for (const ratingType of dataSource.dataSource) {
        for (const ratingComponent of ratingType.components) {
          result.push({
            ratingName: ratingType.name,
            ratingPercentage: ratingType.value,
            component: ratingComponent.name,
            componentPercentage: ratingComponent.weightPercentage
          });
        }
      }
      return result;
  }

  private getRecyclabilityTableColumns(): RecyclabilityTableColumn[] {
    const dataColumns: RecyclabilityTableColumn[] = [];
    const resultTableData = new RecyclabilityTableData();
    const columnNames = Object.keys(resultTableData);
    for (const columnName of columnNames) {
      dataColumns.push({
        columnDef: columnName,
        header: this.translateService.instant('analysis.recyclabilityAnalysis.tableColumnsUnits.' + columnName),
        cell: (element: RecyclabilityTableData) => `${element[columnName as keyof RecyclabilityTableData]}`
      });
    }
    return dataColumns;
  }
}
