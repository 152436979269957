<div *ngIf="!isRecyclingBinSelected; else recyclingBinToolbar">
  <button *ngIf="isAddEditAllowed" mat-button class="folder-button" [matMenuTriggerFor]="createMenu" type="button">
    {{ 'dataManagement.directory.toolbar.create.button' | translate }}
  </button>
  <button *ngIf="isUserAdmin" mat-button class="folder-button" [matMenuTriggerFor]="settingsMenu" type="button">
    {{ 'dataManagement.directory.toolbar.settings.button' | translate }}
  </button>

  <span>
    <button mat-button class="folder-button" (click)="comparePackagingPart()"
      *ngIf="selectedPackagingPartTab === packagingPartEnum.System || selectedPackagingPartTab === packagingPartEnum.Unit"
      [disabled]="selectedEntriesCount < 2 || selectedEntriesCount > 10 || !isComparisonAllowed || isGenericSelected"
      type="button">
      {{ 'dataManagement.directory.toolbar.compare' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="movePackagingPart()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.move' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="copyPackagingPart()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.copy' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="deletePackagingPart()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.delete' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="exportPackagingPart()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.export' | translate }}
    </button>
    <button mat-button class="folder-button" [matMenuTriggerFor]="downloadMenu"
      *ngIf="selectedPackagingPartTab === packagingPartEnum.Unit" [disabled]="selectedEntriesCount < 1" type="button">
      {{ 'dataManagement.directory.toolbar.download' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="editPackagingPartQuantity()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.enterQuantities' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="editPackagingPartTags()" [disabled]="selectedEntriesCount !== 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.selectTags' | translate }}
    </button>
    <button mat-button class="folder-button" (click)="addPackagingPartTags()" [disabled]="selectedEntriesCount < 1"
      type="button">
      {{ 'dataManagement.directory.toolbar.addTags' | translate }}
    </button>
  </span>
</div>

<ng-template #recyclingBinToolbar>
  <button mat-button class="folder-button" (click)="deletePackagingPart()" [disabled]="selectedEntriesCount < 1"
    type="button">
    {{ 'dataManagement.directory.toolbar.delete' | translate }}
  </button>
  <button mat-button class="folder-button" (click)="restorePackagingPart()" [disabled]="selectedEntriesCount < 1"
    type="button">
    {{ 'dataManagement.directory.toolbar.restore' | translate }}
  </button>
</ng-template>

<mat-menu #createMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="addFolder()" type="button">
    <span>{{ 'dataManagement.directory.toolbar.create.folder' | translate }}</span>
  </button>
  <button mat-menu-item (click)="createPackagingSystem()" type="button">
    <span>{{ 'dataManagement.directory.toolbar.create.packagingSystem' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="packagingUnitMenu" type="button">
    <span>{{ 'dataManagement.directory.toolbar.create.packagingUnit' | translate }}</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="componentMenu" type="button">
    <span>{{ 'dataManagement.directory.toolbar.create.packagingComponent' | translate }}</span>
  </button>
  <button mat-menu-item (click)="createCompositeMaterial()" type="button">
    <span>{{ 'dataManagement.directory.toolbar.create.compositeMaterial' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #packagingUnitMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="createPackagingUnit()" type="button">
    {{ 'home.packagingUnitHome.addUnitButton' | translate }}
  </button>
</mat-menu>

<mat-menu #componentMenu="matMenu" [overlapTrigger]="false">
  <span *ngFor="let componentType of componentCategories; let i = index">
    <button mat-menu-item (click)="createPackagingComponent(componentType.key)" type="button">
      {{ 'common.dictionary.' + componentType.label | translate }}
    </button>
  </span>
</mat-menu>

<mat-menu #settingsMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="openPermissionsDialog()" type="button">
    <span>{{ 'dataManagement.directory.toolbar.settings.permissions' | translate }}</span>
  </button>
</mat-menu>

<mat-menu #downloadMenu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item (click)="downloadPackagingPart()" type="button">
    JSON
  </button>
</mat-menu>