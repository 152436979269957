import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { InlayEntryDto } from '../../data-transfer/entities/component-entities/inlay-entry-dto';
import { RemovabilityConditionDto } from 'src/app/data-transfer/entities/removability-condition-dto';
import { DictionaryHandler } from './../dictionary';
import { PackagingComponentPrototype, ComponentTableInfo } from './packaging-component-prototype';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InlayDialogComponent, InlayDialogData } from 'src/app/components/dialogs/inlay-dialog/inlay-dialog.component';
import { PackagingComponentTypesEnum } from '../packaging-component-types-enum';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { InlayDto } from 'src/app/data-transfer/entities/component-entities/inlay-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';

export const INLAY_NAME = 'inlay';

export class InlayComponent extends PackagingComponentPrototype {

  constructor(
    protected dictionaryHandler: DictionaryHandler,
    private inlaySubtypes: ComponentSubtypeDto[],
    private removabilityConditions: RemovabilityConditionDto[],
    private countriesService: CountriesService
  ) {
    super(dictionaryHandler);
  }

  getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo {
    return {
      id: PackagingComponentTypesEnum.Inlay,
      label: INLAY_NAME,
      text: translate('component.inlay'),
      columns: [
        {
          id: 'packagingComponentSubtypeName',
          text: translate('component.fields.type', { ofComponent: translate('component.fields.ofInlay') })
        },
        { id: 'manufacturer', text: translate('component.fields.manufacturer') },
        { id: 'articleName', text: translate('component.fields.articleNumber') },
        { id: 'manifestationOverview', text: translate('component.fields.mainMaterialLayer') },
        { id: 'attachedToMainBodyValue', text: translate('component.fields.attachedToMainBody') }
      ]
    };
  }

  wrapComponentInComponentEntry(componentDto: InlayDto): InlayEntryDto {
    const returnEntry = new InlayEntryDto();
    returnEntry.removedBeforeUsage = false;
    returnEntry.removedByConsumer = false;
    returnEntry.underlyingComponent = componentDto;
    returnEntry.isAdhesiveUsed = false;
    returnEntry.isPhysicallyAttached = false;
    return returnEntry;
  }

  getFormFromComponentEntries(
    inlayEntryDtos: InlayEntryDto[], handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    return formBuilder.group({
      packagingComponents: formBuilder.array(inlayEntryDtos.map(inlayEntryDto =>
        this.getFormFromSingleComponentEntry(inlayEntryDto, handler, formBuilder, isTracked)))
    });
  }

  getFormFromSingleComponentEntry(
    inlayEntryDto: InlayEntryDto, handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    const inlayDto = inlayEntryDto.underlyingComponent;
    const baseForm = super.createBaseForm(inlayDto, formBuilder, this.inlaySubtypes, isTracked || inlayEntryDto.hasInternalTracking);
    const inlayFormFroup: FormGroup = formBuilder.group({
      ...baseForm.controls,
      entryId: [inlayEntryDto.entryId],
      isAdhesiveUsed: [inlayEntryDto.isAdhesiveUsed ?? null, [Validators.required]],
      removabilityConditionId: [inlayEntryDto.removabilityConditionId],
      attachedToMainBody: [inlayEntryDto.isPhysicallyAttached ?? null, Validators.required],
      manifestationOverview: [super.createManifestationOverview(inlayDto.multiMaterial?.layers ?? [])],
      manufacturingCountryName: [this.countriesService.getCountryNameByCode(inlayDto.manufacturingCountry ?? '')],
      isAdhesiveUsedValue: [super.booleanDictionaryKeyToValue(inlayEntryDto.isAdhesiveUsed ?? false)],
      removabilityConditionName: inlayEntryDto.removabilityConditionId ?
        [super.getRemovabilityConditionName(inlayEntryDto.removabilityConditionId, this.removabilityConditions)] : '',
      attachedToMainBodyValue: [super.booleanDictionaryKeyToValue(inlayEntryDto.isPhysicallyAttached ?? false)],
      hasInternalTracking: [inlayEntryDto.hasInternalTracking],
      errorCount: [0],
      multiMaterial: inlayDto.multiMaterial,
      printingCoverage: [inlayDto.printingCoverage != null ? +(inlayDto.printingCoverage * 100).toFixed(2) : null,
      [Validators.required, Validators.min(0), Validators.max(100)]],
      isRigidComponent: [inlayDto.isRigidComponent ?? null, [Validators.required]],
      customFields: [inlayEntryDto.customFields]
    });
    super.setErrorCountInPackagingComponent(inlayFormFroup);
    return inlayFormFroup;
  }

  getEmptyComponentEntry(): InlayEntryDto {
    const inlayEntry = new InlayEntryDto();
    inlayEntry.underlyingComponent = new InlayDto();
    inlayEntry.underlyingComponent.packagingComponentSubtypeId = this.inlaySubtypes[0].id;
    return inlayEntry;
  }

  getComponentEntriesFromForm(formValues: any): InlayEntryDto[] {
    const inlays: InlayEntryDto[] = [];
    formValues.forEach((inlayGroup: any) => {
      inlays.push(this.getSingleComponentEntryFromForm(inlayGroup, false));
    });
    return inlays;
  }

  getSingleComponentEntryFromForm(inlayFormValue: any, forInternalUse: boolean): InlayEntryDto {
    const inlayEntry = new InlayEntryDto();
    this.setComponentEntryDataFromForm(inlayEntry, inlayFormValue);
    inlayEntry.underlyingComponent = this.getSingleComponentFromForm(inlayFormValue, forInternalUse);
    return inlayEntry;
  }

  private setComponentEntryDataFromForm(inlayEntry: InlayEntryDto, inlayFormValue: any) {
    inlayEntry.entryId = inlayFormValue?.entryId ?? -1;
    inlayEntry.hasInternalTracking = inlayFormValue?.hasInternalTracking;
    inlayEntry.isAdhesiveUsed = inlayFormValue?.isAdhesiveUsed ?? null;
    inlayEntry.removabilityConditionId = inlayFormValue?.removabilityConditionId;
    inlayEntry.isPhysicallyAttached = inlayFormValue?.attachedToMainBody ?? false;
    inlayEntry.customFields = inlayFormValue?.customFields ?? null;
  }

  getSingleComponentFromForm(inlayFormValue: any, forInternalUse: boolean, component?: InlayDto): InlayDto {
    const inlayDto = component ?? new InlayDto();
    inlayDto.id = inlayFormValue?.id;
    inlayDto.versionName = inlayFormValue?.newVersionName;
    inlayDto.packagingComponentSubtypeId = inlayFormValue?.packagingComponentSubtypeId;
    inlayDto.comment = inlayFormValue?.comment;
    inlayDto.manufacturer = inlayFormValue?.manufacturer;
    inlayDto.manufacturingCountry = inlayFormValue?.manufacturingCountry;
    inlayDto.articleName = inlayFormValue?.articleName;
    inlayDto.articleNumber = inlayFormValue?.articleNumber;
    inlayDto.gtin = inlayFormValue?.gtin;
    inlayDto.length = inlayFormValue?.length;
    inlayDto.width = inlayFormValue?.width;
    inlayDto.height = inlayFormValue?.height;
    inlayDto.printingCoverage = forInternalUse ? inlayFormValue?.printingCoverage :
      inlayFormValue?.printingCoverage / 100;
    inlayDto.isRigidComponent = inlayFormValue?.isRigidComponent ?? null;
    inlayDto.multiMaterial = inlayFormValue?.multiMaterial;
    inlayDto.containsNearInfraredBarrier = inlayFormValue?.containsNearInfraredBarrier ?? null;
    inlayDto.customFields = inlayFormValue?.customFields ?? null;
    if (inlayDto.multiMaterial) {
      inlayDto.multiMaterial.totalWeight = inlayFormValue?.totalWeight;
      inlayDto.multiMaterial.totalGrammage = inlayFormValue?.totalGrammage;
    }
    return inlayDto;
  }

  getDialogType() { return InlayDialogComponent; }

  getComponentSubtypes(): ComponentSubtypeDto[] { return this.inlaySubtypes; }

  setComponentSpecificDialogData(dialogData: InlayDialogData): void {
    dialogData.removabilityConditions = this.removabilityConditions;
  }

  setComponentSpecificTableData(formGroup: FormGroup): void {
    formGroup.controls.attachedToMainBodyValue.patchValue(
      super.booleanDictionaryKeyToValue(formGroup.controls.attachedToMainBody.value) ?? false);
    formGroup.controls.manifestationOverview.patchValue(
      super.createManifestationOverview((formGroup.controls.multiMaterial as FormGroup).value.layers ?? []));
  }
}
