import { CompositeMaterialsOverviewComponent } from './../../directory-tree/components/composite-materials-overview/composite-materials-overview.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './../../../services/auth-service';
import { Router } from '@angular/router';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { Subscription } from 'rxjs';
import { MaterialApiService } from '../../../data-transfer/services/material-api-service';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { getPaginatorIntl } from 'src/app/util/paginator-translation.provider';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { MultiMaterialCompositeImportWrapper } from '../../dialogs/selection-dialog-materials/selection-dialog-materials.component';

@Component({
  selector: 'app-material-home',
  templateUrl: './material-home.component.html',
  styleUrls: ['./material-home.component.scss']
})
export class MaterialHomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(CompositeMaterialsOverviewComponent) table!: CompositeMaterialsOverviewComponent;

  materialsDataSource: MatTableDataSource<MultiMaterialCompositeImportWrapper>;
  displayedColumnsMaterials = ['id', 'tracking', 'articleName', 'articleNumber', 'manufacturerName', 'action'];

  isUserValidator = false;
  imageSource = `${PROJECT_IMAGES_PATH}elements/composite_material.svg`;

  private materialsListSubscription?: Subscription;
  private paginationSubscription?: Subscription;

  constructor(
    private materialApiService: MaterialApiService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.materialsDataSource = new MatTableDataSource<MultiMaterialCompositeImportWrapper>();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.isUserValidator = this.authService.isUserValidator();
    this.materialsListSubscription = this.materialApiService.getAllCompositeMaterials().subscribe(materials => {
      const wrapper = materials as MultiMaterialCompositeImportWrapper[];
      this.materialsDataSource.data = wrapper;
      this.spinner.hide();
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = getPaginatorIntl(this.translateService);
    this.materialsDataSource.paginator = this.paginator;
    this.paginationSubscription = this.paginator.page.subscribe(_ => {
      this.table.updateDisplayTrackedColumn();
    });
  }

  createCompositeMaterial() {
    const path = PathResolveService.getCreateMaterialPath(true);
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.materialsListSubscription?.unsubscribe();
    this.paginationSubscription?.unsubscribe();
  }
}
