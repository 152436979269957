import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class SnackbarService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone,
    private translateService: TranslateService) { }

  private translate(text: string): string {
    return this.translateService.instant(text);
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button.
      // In the third, we send in the css class for the snack bar.
      this.snackBar.open(this.translate(message), 'X', { panelClass: ['app-snackbar', 'app-error'] });
    });
  }

  showInfo(message: string, duration: number = 5000): void {
    this.zone.run(() => {
      this.snackBar.open(message, undefined, { panelClass: ['app-snackbar', 'app-info'], duration });
    });
  }

  showInfoWithCloseButton(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, 'x', { panelClass: ['app-snackbar', 'app-info'] });
    });
  }
}
