import {
  ExportProfileDocuments, ExportProfileImages, ExportProfileMultiMaterialLayerDto, setValues
} from '../packaging-unit-entities/packaging-unit-export-profile-dto';

export class CompositeMaterialExportProfileDto {
  id = -1;
  profileName = '';
  owningOrganizationId = -1;
  owningOrganizationName = '';
  isDefaultProfile = false;
  articleName = false;
  articleNumber = false;
  manufacturerName = false;
  manufacturingCountry = false;
  gtin = false;
  totalGrammage = false;
  layers!: ExportProfileMultiMaterialLayerDto;
  images!: ExportProfileImages;
  documents!: ExportProfileDocuments;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    this.images = new ExportProfileImages(defaultBooleanValue);
    this.documents = new ExportProfileDocuments(defaultBooleanValue);
    this.layers = new ExportProfileMultiMaterialLayerDto(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
    this.isDefaultProfile = false;
  }
}
