import { Component, Inject, Optional, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { AbstractControlOptions, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { EnvironmentalAuthorization } from 'src/app/data-transfer/entities/authorization';
import { formatDate } from '@angular/common';
import { LCAAnalysisType } from 'src/app/data-transfer/entities/environmental-effect-authorization-dto';
import { TranslateService } from '@ngx-translate/core';


export interface EditEnvironmentalAuthorizationDialogResult<TObject extends {id: number, name: string}> {
  event: DialogActions,
  result: {
    authorizationType?: TObject,
    analysisType?: EnvironmentalAuthorizationDropdownEntry,
    authorizationStart: string,
    authorizationEnd: string
  }
}
class EnvironmentalAuthorizationDropdownEntry {
  id: LCAAnalysisType = LCAAnalysisType.CradleToGrave;
  name: String = '';
}

@Component({
  selector: 'app-edit-environmental-authorizations',
  templateUrl: './edit-environmental-authorizations.component.html',
  styleUrl: './edit-environmental-authorizations.component.scss',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD.MM.YYYY',
      },
      display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      }
    } }
  ]
})

export class EditEnvironmentalAuthorizationsComponent <TAuthorization extends EnvironmentalAuthorization,
                                              TObject extends {id: number, name: string}> implements OnInit {
  authorization: TAuthorization;
  columns: string[] = ["name", "type" ,"authorizationStart", "authorizationEnd"];
  datesFormGroup!: FormGroup;
  authorizationsDropdown!: FormControl<TObject | null>;
  typeDropdown!: FormControl<EnvironmentalAuthorizationDropdownEntry | null>;
  allAuthorizations: TObject[];
  AllTypes: EnvironmentalAuthorizationDropdownEntry[] = [{id: LCAAnalysisType.CradleToGrave, name: this.translateService.instant('analysis.lifecycleAnalysis.lifecycleTypes.cradleToGraveShort')},
                                                         {id: LCAAnalysisType.CradleToGate, name: this.translateService.instant('analysis.lifecycleAnalysis.lifecycleTypes.cradleToGateShort')}]
  private persistedStartDate: string;
  private persistedEndDate: string;
  private edit: boolean;

  constructor(
    private dialogRef: MatDialogRef<EditEnvironmentalAuthorizationsComponent<TAuthorization, TObject>, EditEnvironmentalAuthorizationDialogResult<TObject>>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { authorization: TAuthorization,
                                                           allAuthorizations: [TObject],
                                                           initialAuthorizationType: TObject
                                                           edit: boolean,
                                                           persistedStartDate: string,
                                                           persistedEndDate: string},
    protected dialog: MatDialog,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
      this.authorization = data.authorization;
      this.allAuthorizations = data.allAuthorizations;
      this.edit = data.edit;
      this.persistedStartDate = data.persistedStartDate;
      this.persistedEndDate = data.persistedEndDate;

      const initialAuthorizationType = data.initialAuthorizationType;
      this.authorizationsDropdown = new FormControl(initialAuthorizationType);
      let initialType: EnvironmentalAuthorizationDropdownEntry|undefined = undefined;
      if(this.authorization !== null && this.authorization !== undefined) {
        initialType = this.AllTypes.find(x => x.id === this.authorization.analysisType )
      }
      this.typeDropdown = new FormControl(initialType??null);
    }

  ngOnInit(): void {
    this.initForms();
  }

  isSaveButtonDisabled() {
    const touched = this.datesFormGroup.touched || this.authorizationsDropdown.touched || this.typeDropdown.touched;

    return !this.datesFormGroup.valid ||
           this.datesFormGroup.controls.startDate.value == "" ||
           this.datesFormGroup.controls.endDate.value == "" ||
           this.authorizationsDropdown.value == null ||
           this.typeDropdown.value == null ||
           !touched;
  }

  get startDateControl() {
    return this.datesFormGroup.controls.startDate as FormControl;
  }

  get endDateControl() {
    return this.datesFormGroup.controls.endDate as FormControl;
  }

  private initForms() {
    this.datesFormGroup = this.formBuilder.group({
      startDate: [this.authorization.authorizationStart],
      endDate: [this.authorization.authorizationEnd]
    }, { validator: this.validateDates() } as AbstractControlOptions);

    if (this.edit) {
      this.authorizationsDropdown.disable();
      this.typeDropdown.disable();

      const now = moment();

      if (moment(this.persistedStartDate).utc().isBefore(now.utc())) {
        this.datesFormGroup.controls.startDate.disable();
      }

      if (moment(this.persistedEndDate).utc().isBefore(now.utc())) {
        this.datesFormGroup.controls.endDate.disable();
      }
    }
  }

  private validateDates() {
    return (formGroup: FormGroup) => {
      const startDate = formGroup.controls.startDate;
      const endDate = formGroup.controls.endDate;

      const startDateErrors: ValidationErrors = {};
      const endDateErrors: ValidationErrors = {};

      const endDateMoment = moment(endDate.value);
      const startDateMoment = moment(startDate.value);

      if (endDateMoment.isBefore(startDateMoment, 'day')) {
        startDateErrors.endBeforeStart = true;
        endDateErrors.endBeforeStart = true;
      }

      if (startDate.value == null && startDate.touched) {
        startDate.setErrors({ required: true });
      }

      if (endDate.value == null && endDate.touched) {
        endDate.setErrors({ required: true });
      }

      const now = moment();
      const persistedStartDate = moment(this.persistedStartDate);
      const persistedEndDate = moment(this.persistedEndDate);

      if (startDateMoment.isBefore(now, 'day') && (this.edit || !startDateMoment.isSame(persistedStartDate))) {
        startDateErrors.changedPastStartDate = true;
      }

      if (endDateMoment.isBefore(now, 'day') && (this.edit || !endDateMoment.isSame(persistedEndDate))) {
        endDateErrors.changedPastEndDate = true;
      }

      startDate.setErrors(Object.keys(startDateErrors).length > 0 ? startDateErrors : null);
      endDate.setErrors(Object.keys(endDateErrors).length > 0 ? endDateErrors : null);
    };
  }

  closeDialog() {
    this.dialogRef.close({
      event: DialogActions.REJECT,
      result: {
        authorizationType: undefined,
        analysisType: undefined,
        authorizationStart: "",
        authorizationEnd: ""
      }
    });
  }

  onSuccess() {
    if (this.authorizationsDropdown.value == null || this.typeDropdown.value == null) {
      return;
    }

    let startDate: string = this.datesFormGroup.controls.startDate.value;
    let endDate: string = this.datesFormGroup.controls.endDate.value;

    if (this.datesFormGroup.controls.startDate.touched) {
      startDate = formatDate(startDate, "YYYY-MM-ddT00:00:00+02:00", "en")
    }

    if (this.datesFormGroup.controls.endDate.touched) {
      endDate = formatDate(endDate, "YYYY-MM-ddT23:59:59+02:00", "en");
    }

    this.dialogRef.close({
      event: DialogActions.CONFIRM,
      result: {
        authorizationType: this.authorizationsDropdown.value,
        analysisType: this.typeDropdown.value,
        authorizationStart: startDate,
        authorizationEnd: endDate
      }
    });
  }
}
