import { AbstractControl } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-month-table',
  templateUrl: './month-table.component.html',
  styleUrls: ['./month-table.component.scss']
})
export class MonthTableComponent {

  @Input() dataSource!: MatTableDataSource<AbstractControl>;
  @Input() displayedColumns = ['month', 'quantity'];
}
