import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
ExportData(Highcharts);
import Accessibility from 'highcharts/modules/accessibility';
import {
  DARK_THEME_BACKGROUND, DARK_THEME_BORDER, DARK_THEME_FONT, LIGHT_THEME_BACKGROUND,
  LIGHT_THEME_BORDER, LIGHT_THEME_FONT, MAP_DARK_THEME_DEFAULT, MAP_DARK_THEME_OUTLINE,
  MAP_LIGHT_THEME_DEFAULT, MAP_LIGHT_THEME_OUTLINE
} from 'src/app/model/charts-color-collection';
import { TranslateService } from '@ngx-translate/core';
import { CountriesService } from 'src/app/navigation/services/countries-service';
Accessibility(Highcharts);

const europeMap = require('@highcharts/map-collection/custom/europe.geo.json');

export interface CustomPoint extends Highcharts.Point {
  code2: string;
  z: number;
}

export interface ExpenseCalculationMapPoint {
  name: string;
  code2: string;
  z: number;
}

@Component({
  selector: 'app-map-expense-calculation',
  templateUrl: './map-expense-calculation.component.html',
  styleUrls: ['./map-expense-calculation.component.scss']
})
export class MapExpenseCalculationComponent implements OnChanges {

  @Input() chartDataSource!: ExpenseCalculationMapPoint[];
  @Input() isDarkTheme = false;

  @Output() countryClicked = new EventEmitter();

  updateFlag = false;
  highcharts = Highcharts;
  chartConstructor = 'mapChart';
  chartOptions: Highcharts.Options = {
    chart: {
      map: europeMap,
      height: '700px'
    },
    legend: { enabled: false },
    credits: { enabled: false },
    title: { text: undefined },
    plotOptions: {
      series: {
        point: { events: {} }
      }
    },
    tooltip: {
      useHTML: true,
      outside: true,
      style: {}
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF'],
        }
      }
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        theme: {},
        style: {}
      }
    },
    navigation: {
      buttonOptions: {
        width: 28,
        height: 28,
        symbolX: 14,
        symbolY: 14,
        theme: {}
      },
      menuStyle: {},
      menuItemStyle: {},
      menuItemHoverStyle: {}
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf'),
    }
  };

  chartRef!: Highcharts.Chart;
  @ViewChild('chart') componentRef: any;
  chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
    this.chartRef = chart;
  }

  constructor(
    private translateService: TranslateService,
    private countriesService: CountriesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartDataSource && changes.chartDataSource.currentValue ||
      (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
        changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue)) {
      this.destroyChart();
      this.setOriginDependentChartOptions();
      this.setSeriesData()
      this.setPointClickEvent();
      this.updateFlag = true;
    }
  }

  /**
   * If the old chart is not destroyed, and new chart data is loaded through sidenav navigation,
   * the bubble sizes are not readjusted correctly, which may lead to grotesquely large bubbles
   */
  private destroyChart() {
    this.chartRef?.destroy();
    if (this.componentRef) { this.componentRef.chart = null; }
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.chart) {
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.navigation) {
      if (this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
        this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.navigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.navigation.menuStyle) {
        this.chartOptions.navigation.menuStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      }
      if (this.chartOptions.navigation.menuItemStyle) {
        this.chartOptions.navigation.menuItemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
      if (this.chartOptions.navigation.menuItemHoverStyle) {
        this.chartOptions.navigation.menuItemHoverStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.mapNavigation && this.chartOptions.mapNavigation.buttonOptions) {
      if (this.chartOptions.mapNavigation.buttonOptions.theme) {
        this.chartOptions.mapNavigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.mapNavigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.mapNavigation.buttonOptions.style) {
        this.chartOptions.mapNavigation.buttonOptions.style.color = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parentScope = this;
    if (this.chartOptions.tooltip) {
      this.chartOptions.tooltip.formatter = function () {
        return `<span style="font-size: 18px">
          ${parentScope.countriesService.translateCountryName(this.point.name)}: <b>${this.point.options.z}</b></span>`;
      };
      this.chartOptions.tooltip.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      if (this.chartOptions.tooltip.style) {
        this.chartOptions.tooltip.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
    }
  }

  private setSeriesData() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parentScope = this;
    const ukEntry = this.chartDataSource.find(x => x.code2 === 'UK');
    if (ukEntry) { ukEntry.code2 = 'GB'; }
    this.chartOptions.series = [
      {
        type: "map",
        enableMouseTracking: false,
        nullColor: this.isDarkTheme ? MAP_DARK_THEME_DEFAULT : MAP_LIGHT_THEME_DEFAULT,
        borderColor: this.isDarkTheme ? MAP_DARK_THEME_OUTLINE : MAP_LIGHT_THEME_OUTLINE
      },
      {
        type: "mapbubble",
        joinBy: ["iso-a2", "code2"],
        data: this.chartDataSource,
        color: this.isDarkTheme ? '#d4b36e' : "#80cfc4",
        minSize: "5%",
        maxSize: "12%",
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter() {
            return `<div style="text-align: center; font-size: 15px">${parentScope.countriesService.translateCountryName(this.point.name)}
            <br/>${(this.point as CustomPoint).z}</div>`
          },
          style: {
            color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
          }
        },
      }
    ]
  }

  private setPointClickEvent() {
    if (this.chartOptions.plotOptions && this.chartOptions.plotOptions.series && this.chartOptions.plotOptions.series.point) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const parentScope = this;
      this.chartOptions.plotOptions.series.point.events = {
        click() {
          const point = this as CustomPoint;
          let pointClicked = point.code2;
          if (pointClicked === 'GB') { pointClicked = 'UK'; }
          parentScope.countryClicked.emit(pointClicked);
        }
      };
    }
  }
}
