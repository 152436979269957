<h1 mat-dialog-title>
  {{ 'dataManagement.quantities.singleTitle' | translate: {productName: packagingUnit.productName ?? packagingSystem.productName } }}
</h1>

<mat-dialog-content>
  <form [formGroup]="form" id="quantities-form">
    <div formGroupName="selectGroup">
      <div id="version-container">
        <div (click)="loadHistoryVersions(packaging.id)">
          <mat-form-field class="form-field-versions">
            <mat-label>{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
            <mat-select formControlName="version" (selectionChange)="onHistoryVersionChange($event.value)">
              <mat-option *ngFor="let historyVersion of historyVersions" [value]="historyVersion.versionNumber">
                Version {{ historyVersion.versionNumber }} <span
                  *ngIf="historyVersion.versionName">({{ historyVersion.versionName }})</span>:
                {{ getDateTimeString(historyVersion.creationTimestamp) }}
                <strong
                  *ngIf="historyVersion.isCurrentVersion">({{ 'common.text.current' | translate }})</strong>
                <span class="spinner-container">
                  <mat-spinner diameter="24" color="accent" *ngIf="versionSpinnerActive">
                  </mat-spinner>
                </span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div id="settings-container">
        <div>
          <button class="change-button-left" mat-icon-button (click)="onYearChanged(-1)"
            [disabled]="!quantityForms.pristine || isLoading">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
          <mat-form-field>
            <mat-label>{{ 'dataManagement.quantities.year'| translate }}</mat-label>
            <input matInput readonly [matDatepicker]="dp" formControlName="selectedYear">
            <mat-datepicker-toggle matSuffix [for]="dp" class="date-toggle"></mat-datepicker-toggle>
            <mat-datepicker #dp startView="multi-year" (yearSelected)="onYearSelected($event, dp)" panelClass="month-picker">
            </mat-datepicker>
          </mat-form-field>
          <button class="change-button-right" mat-icon-button (click)="onYearChanged(1)"
            [disabled]="!quantityForms.pristine || isLoading">
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
        <mat-form-field class="form-field-country">
          <mat-label>{{ 'dataManagement.quantities.country'| translate }}</mat-label>
          <mat-select formControlName="countryCode" (selectionChange)="loadQuantities()">
            <mat-option *ngFor="let country of countriesToSelect" [value]="country.code">
              {{country.name}}
              <span *ngIf="!countries.includes(country)">
                <mat-icon id="add-country-icon">add_location_alt</mat-icon>
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="table-container">
      <div *ngIf="!isLoading && selectGroup.errors?.['allItemsRequired'] == null; else elseBlock" style="display: flex; justify-content: space-around;">
        <app-month-table [dataSource]="dataSourceLeft"></app-month-table>
        <app-month-table [dataSource]="dataSourceRight"></app-month-table>
      </div>
      <ng-template #elseBlock>
        <div class="quantity-spinner-container" *ngIf="isLoading">
          <mat-spinner diameter="48" color="accent"></mat-spinner>
        </div>
      </ng-template>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onSubmit()" disabled [disabled]="form.invalid">
    {{ 'common.buttons.apply' |translate }} </button>
  <button mat-dialog-close mat-raised-button> {{ 'common.buttons.close' |translate }} </button>
</mat-dialog-actions>
