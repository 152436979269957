import { MatSort } from '@angular/material/sort';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { ExportInfoDto } from './../../../../data-transfer/entities/export-info-dto';
import { MatTableDataSource } from '@angular/material/table';
import { Component, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';
import { PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-export-list-table',
  templateUrl: './export-list-table.component.html',
  styleUrls: ['./export-list-table.component.scss'],
  animations: [],
})
export class ExportListTableComponent implements AfterViewInit, OnChanges {

  @Input() displayedColumns: string[] = [];
  @Input() dataSource!: MatTableDataSource<any>;

  @Output() changeTrackingEvent = new EventEmitter<ExportInfoDto<any, any>>();
  @Output() deleteImportEvent = new EventEmitter<ExportInfoDto<any, any>>();

  @ViewChild(MatSort) sort!: MatSort;

  filteredColumns: string[] = [];

  sortingDataAccessor = (data: any, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case 'status': return this.translateService.instant('dataManagement.exportList.status.' + data[sortHeaderId]);
      case 'tracking': return this.translateService.instant('dataManagement.exportList.tracking.' + (data[sortHeaderId] ? 'on' : 'off'));
      case 'packagingId': return data.exportedPackaging?.id;
      case 'productName': return data.exportedPackaging?.productName?.toLowerCase();
      case 'articleName': return data.exportedPackaging?.articleName?.toLowerCase();
      case 'version': return data.exportedPackaging?.version;
      default: return data[sortHeaderId];
    }
  }

  constructor(
    private packagingTypeService: PackagingUnitTypeService,
    private componentTypeService: ComponentTypeService,
    private translateService: TranslateService
  ) {}

  ngAfterViewInit() {
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource !== undefined && changes.dataSource !== null) {
      this.setDataSourceFilter(this.dataSource);
    }
  }

  changeTrackingClicked(element: ExportInfoDto<any, any>) {
    this.changeTrackingEvent.emit(element);
  }

  deleteImportClicked(element: ExportInfoDto<any, any>) {
    this.deleteImportEvent.emit(element);
  }

  private setDataSourceFilter(dataSource: MatTableDataSource<any>) {
    dataSource.filterPredicate = (data: ExportInfoDto<any, any>, filter: any) => {
      let result = true;
      const keys = Object.keys(data);
      let exportedPackagingKeys: string[] = [];
      if (data.exportedPackaging) {
        exportedPackagingKeys = Object.keys(data.exportedPackaging);
      }
      let exportProfileKeys: string[] = ['profileName'];
      if (data.exportProfile) {
        exportProfileKeys = Object.keys(data.exportProfile);
      }

      this.filteredColumns = Object.keys(filter.criterion);

      if (filter.criterion) {
        for (const key of keys) {
          const searchCondition = filter.criterion[key];

          if (searchCondition) {
            if (key === 'status') {
              const status = this.translateService.instant('dataManagement.exportList.status.' + data[key]);
              if (filter.allCriteria[searchCondition](status, filter.value[key]) === false) {
                result = false;
                break;
              }
            } else if (key === 'tracking') {
              const tracking = this.translateService.instant('dataManagement.exportList.tracking.' + (data[key] ? 'on' : 'off'));
              if (filter.allCriteria[searchCondition](tracking, filter.value[key]) === false) {
                result = false;
                break;
              }
            } else {
              if (filter.allCriteria[searchCondition]((data as any)[key], filter.value[key]) === false) {
                result = false;
                break;
              }
            }
          }
        }

        for (const key of exportedPackagingKeys) {
          const packagingKey = 'packaging.' + key;
          const searchCondition = filter.criterion[packagingKey];
          if (searchCondition) {
            const element = data?.exportedPackaging;
            let value = null;
            if (element) {
              value = (element as any)[key];
            }
            if (filter.allCriteria[searchCondition](value, filter.value[packagingKey]) === false) {
              result = false;
              break;
            }
          }
        }

        for (const key of exportProfileKeys) {
          const exportProfileKey = 'exportProfile.' + key;
          const searchCondition = filter.criterion[exportProfileKey];
          if (searchCondition) {
            const element = data?.exportProfile;
            let value = null;
            if (element) {
              value = (element as any)[key];
            }
            if (filter.allCriteria[searchCondition](value, filter.value[exportProfileKey]) === false) {
              result = false;
              break;
            }
          }
        }
      }
      return result;
    };
  }

}
