import { PackagingUnitApiService } from './../../../../../data-transfer/services/packaging-unit-api-service';
import { PackagingSystemApiService } from './../../../../../data-transfer/services/packaging-system-api-service';
import { BaseDataApiService } from './../../../../../data-transfer/services/base-data-api-service';
import { PackagingSystemInfoDto } from './../../../../../data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleConfirmDialogComponent } from './../../../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { VersionDto } from './../../../../../data-transfer/entities/version-dto';
import { CountryDto } from '../../../../../data-transfer/entities/country-dto';
import { forkJoin, of, Subject } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { PackagingUnitDto } from '../../../../../data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { Validators, AbstractControl, ValidatorFn, ValidationErrors, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { Moment } from 'moment';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-edit-quantity-dialog',
  templateUrl: './edit-quantity-dialog.component.html',
  styleUrls: ['./edit-quantity-dialog.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS
    },
  ]
})
export class EditQuantityDialogComponent implements OnInit {

  allCountries: CountryDto[] = [];
  countries: CountryDto[] = [];
  dataSourceLeft!: MatTableDataSource<AbstractControl>;
  countriesToSelect: CountryDto[] = [];
  dataSourceRight!: MatTableDataSource<AbstractControl>;
  displayedColumns = ['month', 'quantity'];
  form: FormGroup;
  isLoading = true;
  isLoading$ = new Subject<boolean>();
  versionSpinnerActive = false;

  packagingSystem!: VersionDto;
  packagingUnit!: VersionDto;
  historyVersions: VersionDto[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private formBuilder: FormBuilder,
    protected baseDataApiService: BaseDataApiService,
    protected packagingUnitApiService: PackagingUnitApiService,
    protected packagingSystemApiService: PackagingSystemApiService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      quantityForms: this.formBuilder.array([]),
      selectGroup: this.formBuilder.group({
        countryCode: [null, Validators.required],
        selectedYear: [moment(), Validators.required],
        version: [null, Validators.required],
      })
    });
  }

  get countryCode(): string {
    return this.form.getRawValue().selectGroup.countryCode;
  }

  get quantityForms(): FormArray {
    return this.form.controls.quantityForms as FormArray;
  }

  get selectedYear(): Moment {
    return this.form.getRawValue().selectGroup.selectedYear;
  }

  get selectGroup(): FormGroup {
    return this.form.controls.selectGroup as FormGroup;
  }

  get isPackagingUnit(): boolean {
    return this.packagingUnit != null;
  }

  get isPackagingSystem(): boolean {
    return this.packagingSystem != null;
  }

  get packaging(): VersionDto {
    return this.packagingUnit ?? this.packagingSystem;
  }

  ngOnInit(): void {
    if (this.data.packagingUnits) {
      const packagingUnit: PackagingUnitDto = this.data.packagingUnits[0];
      this.packagingUnit = {
        id: packagingUnit.id,
        versionNumber: packagingUnit.version,
        distributionCountries: packagingUnit.distributionCountries,
        creationTimestamp: packagingUnit.creationTimestamp,
        isCurrentVersion: packagingUnit.isCurrentVersion,
        productName: packagingUnit.productName
      };
      this.historyVersions = [{
        id: packagingUnit.id,
        versionNumber: packagingUnit.version,
        versionName: packagingUnit.versionName,
        creationTimestamp: packagingUnit.creationTimestamp,
        isCurrentVersion: packagingUnit.isCurrentVersion
      }];
      const countryCode = this.packagingUnit.distributionCountries ? this.packagingUnit.distributionCountries[0] : '';
      this.selectGroup.controls.countryCode.setValue(countryCode);
      this.selectGroup.controls.version.setValue(this.packagingUnit.versionNumber);
      this.createForm();
    }

    if (this.data.packagingSystems) {
      const packagingSystem: PackagingSystemInfoDto = this.data.packagingSystems[0];
      this.packagingSystem = {
        id: packagingSystem.id,
        versionNumber: packagingSystem.version,
        distributionCountries: packagingSystem.distributionCountries,
        creationTimestamp: packagingSystem.creationTimestamp,
        isCurrentVersion: packagingSystem.isCurrentVersion,
        productName: packagingSystem.productName
      };
      this.historyVersions = [{
        id: packagingSystem.id,
        versionNumber: packagingSystem.version,
        versionName: packagingSystem.versionName,
        creationTimestamp: packagingSystem.creationTimestamp,
        isCurrentVersion: packagingSystem.isCurrentVersion
      }];
      const countryCode = this.packagingSystem.distributionCountries ? this.packagingSystem.distributionCountries[0] : -1;
      this.selectGroup.controls.countryCode.setValue(countryCode);
      this.selectGroup.controls.version.setValue(this.packagingSystem.versionNumber);
      this.createForm();
    }

    this.baseDataApiService.getDistributionCountries(true).subscribe(distrCountries => {
      if (distrCountries.length === 0) { return; }
      this.allCountries = distrCountries;
      this.countries = this.allCountries.filter(country =>
        this.packaging.distributionCountries && this.packaging.distributionCountries.includes(country.code));
      if (this.countries.length === 0) {
        this.countriesToSelect = this.allCountries;
      } else {
        this.countriesToSelect = this.countries;
      }
      this.loadQuantities();
    });

    this.isLoading$.subscribe(_ => {
      this.isLoading = _;
      this.setEnableDisable();
    });

    this.quantityForms.valueChanges.subscribe(() => this.setEnableDisable());
  }

  getDateTimeString(date?: Date) {
    if (date == null) { return ''; }
    return `${new Date(date).toLocaleDateString()}: ${new Date(date).toLocaleTimeString()}`;
  }

  loadHistoryVersions(packagingId: number | undefined) {
    if (packagingId == null) { return; }
    if (this.isPackagingUnit) {
      if (this.historyVersions.length <= 1) {
        this.versionSpinnerActive = true;
        this.packagingUnitApiService.getPackagingUnitHistoryVersions(packagingId)
          .subscribe(versions => {
            this.historyVersions = versions.sort((a, b) =>
              b.versionNumber != null && a.versionNumber != null ? b.versionNumber - a.versionNumber : 0);
            this.versionSpinnerActive = false;
          });
      }
    }
    if (this.isPackagingSystem) {
      if (this.historyVersions.length <= 1) {
        this.versionSpinnerActive = true;
        this.packagingSystemApiService.getPackagingSystemHistoryVersions(packagingId)
          .subscribe(versions => {
            this.historyVersions = versions.sort((a, b) =>
              b.versionNumber != null && a.versionNumber != null ? b.versionNumber - a.versionNumber : 0);
            this.versionSpinnerActive = false;
          });
      }
    }
  }

  loadQuantities() {
    this.isLoading$.next(true);

    if (this.countryCode == null) {
      this.isLoading$.next(false);
      return;
    }

    if (!this.countries.map(x => x.code).includes(this.countryCode)) {
      this.addDistributionCountry();
      return;
    }

    const resultsLeft = this.dataSourceLeft.data.map(control => {
      if (this.packagingUnit?.id != null) {
        return this.packagingUnitApiService.getPackagingUnitQuantity(
          this.packagingUnit.id,
          this.selectedYear.year(),
          +(control.value.month as Moment).format('M'),
          this.countryCode,
          this.packagingUnit.versionNumber,
          true
        );
      }
      if (this.packagingSystem?.id != null) {
        return this.packagingSystemApiService.getPackagingSystemQuantity(
          this.packagingSystem.id,
          this.selectedYear.year(),
          +(control.value.month as Moment).format('M'),
          this.countryCode,
          this.packagingSystem.versionNumber,
          true
        );
      }
      return of(null);
    });
    const resultsRight = this.dataSourceRight.data.map(control => {
      if (this.packagingUnit?.id != null) {
        return this.packagingUnitApiService.getPackagingUnitQuantity(
          this.packagingUnit.id,
          this.selectedYear.year(),
          +(control.value.month as Moment).format('M'),
          this.countryCode,
          this.packagingUnit.versionNumber,
          true
        );
      }
      if (this.packagingSystem?.id != null) {
        return this.packagingSystemApiService.getPackagingSystemQuantity(
          this.packagingSystem.id,
          this.selectedYear.year(),
          +(control.value.month as Moment).format('M'),
          this.countryCode,
          this.packagingSystem.versionNumber,
          true
        );
      }
      return of(null);
    });

    const left = forkJoin(resultsLeft);
    const right = forkJoin(resultsRight);

    forkJoin({ left, right }).subscribe(result => {
      this.dataSourceLeft.data.forEach((data, idx) => {
        data.patchValue({ quantity: result.left[idx] });
      });
      this.dataSourceRight.data.forEach((data, idx) => {
        data.patchValue({ quantity: result.right[idx] });
      });
      this.isLoading$.next(false);
    });
  }

  onHistoryVersionChange(version: number) {
    if (this.isPackagingUnit) {
      this.packagingUnit = this.historyVersions.find(x => x.versionNumber === version) ?? new VersionDto();
      this.countries = this.allCountries.filter(country =>
        this.packagingUnit.distributionCountries && this.packagingUnit.distributionCountries.includes(country.code));
    }
    if (this.isPackagingSystem) {
      this.packagingSystem = this.historyVersions.find(x => x.versionNumber === version) ?? new VersionDto();
      this.countries = this.allCountries.filter(country =>
        this.packagingSystem.distributionCountries && this.packagingSystem.distributionCountries.includes(country.code));
    }
    this.loadQuantities();
  }

  onSubmit() {
    const quantityForms = this.quantityForms.controls.filter(control => !control.pristine);
    this.isLoading$.next(true);

    const responeses = quantityForms.map(form => {
      if (this.packagingUnit?.id != null) {
        return this.packagingUnitApiService.setPackagingUnitQuantity
          (this.packagingUnit.id, this.selectedYear.year(), +(form.value.month as Moment).format('M'),
            form.value.quantity, this.countryCode, this.packagingUnit.versionNumber, true);
      }
      if (this.packagingSystem?.id != null) {
        return this.packagingSystemApiService.setPackagingSystemQuantity
          (this.packagingSystem.id, this.selectedYear.year(), +(form.value.month as Moment).format('M'),
            form.value.quantity, this.countryCode, this.packagingSystem.versionNumber, true);
      }
      return of(null);
    });
    if (responeses.length > 0) {
      forkJoin(responeses).subscribe(() => this.isLoading$.next(false));
    } else {
      this.isLoading$.next(false);
    }

    this.form.markAsPristine();
    this.setEnableDisable();
  }

  onYearChanged(amount: number) {
    const ctrlValue = this.selectedYear;
    ctrlValue.add(amount, 'y');
    this.selectGroup.controls.selectedYear.setValue(ctrlValue);
    this.loadQuantities();
  }

  onYearSelected(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.selectedYear;
    ctrlValue.year(normalizedYear.year());
    this.selectGroup.controls.selectedYear.setValue(ctrlValue);
    datepicker.close();
    this.loadQuantities();
  }

  private createForm() {
    for (let i = 0; i < 12; i++) {
      const monthDate = moment();
      monthDate.set('month', i);

      this.quantityForms.push(
        this.formBuilder.group({
          month: monthDate,
          quantity: [null, [Validators.required, Validators.min(0)]]
        })
      );
    }
    this.dataSourceLeft = new MatTableDataSource(this.quantityForms.controls.slice(0, 6));
    this.dataSourceRight = new MatTableDataSource(this.quantityForms.controls.slice(6, 12));
    this.selectGroup.setValidators(this.selectFormValidator());
  }

  private selectFormValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const countryCode = (control as FormGroup).controls.countryCode.value;
      const version = (control as FormGroup).controls.version.value;
      const selectedYear = (control as FormGroup).controls.selectedYear.value;
      if (countryCode == null || version == null || selectedYear == null) {
        return { allItemsRequired: true };
      }
      return null;
    };
  }

  private setEnableDisable() {
    if (!this.quantityForms.pristine || this.isLoading) {
      if (this.selectGroup.controls.countryCode.enabled || this.selectGroup.controls.selectedYear.enabled
        || this.selectGroup.controls.version.enabled) {
        this.selectGroup.controls.countryCode.disable();
        this.selectGroup.controls.selectedYear.disable();
        this.selectGroup.controls.version.disable();
      }
    } else {
      if (this.selectGroup.controls.countryCode.disabled || this.selectGroup.controls.selectedYear.disabled
        || this.selectGroup.controls.version.disabled) {
        this.selectGroup.controls.countryCode.enable();
        this.selectGroup.controls.selectedYear.enable();
        this.selectGroup.controls.version.enable();
      }
    }
  }

  private async addDistributionCountry() {
    const countryName = this.allCountries.find(country => country.code === this.countryCode)?.name ?? '';
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataManagement.quantities.addDistrCountryConfirmation',
        { country: countryName, id: this.packaging.id })],
      icon: 'info'
    };
    const dialogConfig = getDialogConfig(data);
    const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) {
        (this.form.controls.selectGroup as FormGroup).controls.countryCode.setValue(null);
        this.isLoading$.next(false);
        return;
      }
      if (this.packagingUnit?.id != null && this.packagingUnit?.versionNumber != null) {
        this.baseDataApiService.addDistributionCountry(this.packagingUnit.id, this.packagingUnit.versionNumber, this.countryCode)
          .subscribe(_ => {
            this.packagingUnit.distributionCountries?.push(this.countryCode);
            this.countries = this.allCountries.filter(country => this.packagingUnit.distributionCountries?.includes(country.code));
            this.loadQuantities();
          });
      }
      if (this.packagingSystem?.id != null && this.packagingSystem?.versionNumber != null) {
        this.packagingSystemApiService.addDistributionCountry(this.packagingSystem.id, this.packagingSystem.versionNumber, this.countryCode)
          .subscribe(_ => {
            this.packagingSystem.distributionCountries?.push(this.countryCode);
            this.countries = this.allCountries.filter(country => this.packagingSystem.distributionCountries?.includes(country.code));
            this.loadQuantities();
          });
      }
    });
  }
}
