import { map } from 'rxjs/operators';
import { UiVersion } from './../model/ui-version';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { environments } from '../../environments/environments';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
const { version : appVersion } = require('../../../package.json');
const { feBuildNumber : feBuild } = require('../../../package.json');
const { dependencies : appDependencies } = require('../../../package.json');

@Injectable()
export class EnvironmentHelperService {

  constructor(private http: HttpClient) { }

  public get version(): string {
    return appVersion;
  }

  public get feBuild(): string {
    return feBuild;
  }

  public get dependencies(): string {
    return appDependencies;
  }

  public getApiVersion(): Observable<string> {
    const url = `${environment.webApiAddress}/api/details/version`;
    return this.http.get(url, { responseType: 'text' });
  }

  public isTestEnvironment(): boolean {
    return environment.name === environments.test;
  }

  public isProdEnvironment(): boolean {
    return environment.name === environments.prod;
  }

  public getUiVersions(): Observable<UiVersion[]> {
    const jsonURL = 'assets/changelog/ui-versions.json';
    return this.http.get<any[]>(jsonURL).pipe(
      map(versions => versions.map(version => {
        const uiVersion: UiVersion = {
          id: version.id,
          releaseDate:  new Date(version.releaseDate)
        };
        return uiVersion;
      }))
    );
  }
}
