import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { RemovabilityConditionDto } from 'src/app/data-transfer/entities/removability-condition-dto';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';

export class ComponentRoutingData {
  mainBodySubtypes: ComponentSubtypeDto[] = [];
  closureSubtypes: ComponentSubtypeDto[] = [];
  decorationSubtypes: ComponentSubtypeDto[] = [];
  inlaySubtypes: ComponentSubtypeDto[] = [];
  packagingAidSubtypes: ComponentSubtypeDto[] = [];
  removabilityConditions: RemovabilityConditionDto[] = [];
}

@Component({
  selector: 'app-component-routing-wrapper',
  templateUrl: './component-routing-wrapper.component.html',
  styleUrls: ['./component-routing-wrapper.component.scss']
})
export class ComponentRoutingWrapperComponent implements OnInit, OnDestroy {

  private routeDataSubscription?: Subscription;

  constructor(
    public componentHandler: ComponentHandler,
    private route: ActivatedRoute,
    private countriesService: CountriesService,
    private componentTypeService: ComponentTypeService
  ) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const componentData = new ComponentRoutingData();
      componentData.mainBodySubtypes = data.mainBodySubtypes ?? [];
      componentData.closureSubtypes = data.closureSubtypes ?? [];
      componentData.decorationSubtypes = data.decorationSubtypes ?? [];
      componentData.inlaySubtypes = data.inlaySubtypes ?? [];
      componentData.packagingAidSubtypes = data.packagingAidSubtypes ?? [];
      componentData.removabilityConditions = data.removabilityConditions ?? [];

      this.componentHandler.setComponentsData(componentData, this.countriesService, this.componentTypeService);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
