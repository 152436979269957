<div class="outer-container">
  <h1 class="mat-headline-3">{{ 'home.packagingSystemHome.title' | translate }}</h1>

  <div id="image-container">
    <div id="ps-button-container">
      <button id="ps-button" mat-raised-button (click)="createPackagingSystem()" color="primary" type="button">
        <mat-icon>add</mat-icon>
        {{ 'home.packagingSystemHome.addSystemButton' | translate }}
      </button>
    </div>
    <div id="ps-image-container">
      <img id="ps-image" [src]="imageSource">
    </div>
  </div>

  <div class="inner-container">
    <div class="table-container">
      <app-packaging-systems-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
        [isUserValidator]="isUserValidator" (packagingPartsSelected)="setSelectedForComparison($event)">
      </app-packaging-systems-overview>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
    <button *ngIf="!isUserValidator" (click)="compare()" mat-raised-button color="primary" id="compare-button"
      class="custom-button" [disabled]="compareButtonDisabled" type="button">
      <mat-icon>compare</mat-icon>
      {{ 'home.packagingSystemHome.compareButton' | translate }}
    </button>
  </div>
</div>