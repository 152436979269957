import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ProblemInformationDto } from 'src/app/data-transfer/entities/evaluation-entities/problem-information-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { ColorThemeService, COLOR_THEME_DARK, COLOR_THEME_NAME } from 'src/app/navigation/services/color-theme-service';
import { NestedTreeControl } from '@angular/cdk/tree';

@Component({
  selector: 'app-analysis-not-possible-dialog',
  templateUrl: './analysis-not-possible-dialog.component.html',
  styleUrls: ['./analysis-not-possible-dialog.component.scss']
})
export class AnalysisNotPossibleDialogComponent implements OnInit, OnDestroy {
  @ViewChild('nestedTree') nestedTree!: MatTree<any>;

  generalExplanation!: string;
  sectionsWithBulletpoints: { title: string, items: string[] }[] = [];
  sections!: { title: string, text: string }[];
  hints!: { text: string }[];

  errorMessageList: string[] = [];

  private packagingPart: number;
  private problemInformation: ProblemInformationDto[];

  nestedTreeControl = new NestedTreeControl<ProblemInformationDto>((node: ProblemInformationDto) => node.reasons);
  treeDataSource: MatTreeNestedDataSource<ProblemInformationDto>;
  isDarkTheme = false;

  private themeSubscription?: Subscription;

  tr = (key: string, params?: object | undefined) => this.translateService.instant(key, params);

  constructor(
    public dialogRef: MatDialogRef<AnalysisNotPossibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: { packagingPart: PackagingPart, problemInformation: ProblemInformationDto[] },
    private translateService: TranslateService,
    private colorThemeService: ColorThemeService
  ) {
    this.packagingPart = data.packagingPart;
    this.problemInformation = data.problemInformation;

    this.getProblemReasonsRecursively(this.problemInformation);

    this.treeDataSource = new MatTreeNestedDataSource<ProblemInformationDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit(): void {
    switch (this.packagingPart) {
      case PackagingPart.System:
        this.setPackagingSystemInfo();
        break;
      case PackagingPart.Unit:
        this.setPackagingUnitInfo();
        break;
      default:
        break;
    }

    this.treeDataSource.data = this.problemInformation;
    this.nestedTreeControl.dataNodes = this.problemInformation;
    this.nestedTreeControl.collapseAll();
    this.isDarkTheme = localStorage.getItem(COLOR_THEME_NAME) === COLOR_THEME_DARK;
  }

  getTreeLabel(problemInformation: ProblemInformationDto){
    let label = "";

    if (problemInformation.reasons && problemInformation.reasons.length > 0) {
      label += problemInformation.fieldName;
    } else {
      label += problemInformation.errorMessage;
    }

    if (problemInformation.name && problemInformation.name.trim() != "") {
      label += ": " + problemInformation.name;
    }

    if (problemInformation.index != null){
      label += " (" + "Position: " + (problemInformation.index + 1) + ")";
    }

    return label;
  }

  getProblemReasonsRecursively(problemInformationList: ProblemInformationDto[], errorMessage: string = '') {
    for (const problemInformation of problemInformationList) {
      const newErrorMessage = errorMessage.concat(problemInformation.fieldName);

      if (problemInformation.reasons.length != 0) {
        this.getProblemReasonsRecursively(problemInformation.reasons, newErrorMessage);
      } else {
        this.errorMessageList.push(newErrorMessage);
      }
    }
  }

  private setPackagingSystemInfo() {
    this.generalExplanation = this.tr('analysisNotPossibleInfo.generalConditionsSystem');
    this.sectionsWithBulletpoints = [
      {
        title: this.tr('analysisNotPossibleInfo.sectionTitle.reasons'),
        items: [
          this.tr('analysisNotPossibleInfo.distributionCountry'),
          this.tr('analysisNotPossibleInfo.unitInSystem'),
          this.tr('analysisNotPossibleInfo.subSystems')
        ]
      }
    ];
    this.sections = [];
    this.hints = [];
  }

  private setPackagingUnitInfo() {
    this.generalExplanation = this.tr('analysisNotPossibleInfo.generalConditionsUnit');
    this.sectionsWithBulletpoints = [
      {
        title: this.tr('analysisNotPossibleInfo.sectionTitle.reasons'),
        items: [
          this.tr('analysisNotPossibleInfo.mainBody'),
          this.tr('analysisNotPossibleInfo.distributionCountry'),
          this.tr('analysisNotPossibleInfo.materialLayerAvailableComponent'),
          this.tr('analysisNotPossibleInfo.LwhAvailableComponent')
        ]
      }
    ];
    this.sections = [];
    this.hints = [{ text: this.tr('analysisNotPossibleInfo.additionalInformation') }];
  }

  hasChild(node: ProblemInformationDto): boolean {
    return node.reasons && node.reasons.length > 0;
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
