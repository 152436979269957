import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class SortMatTableDataSource<T> extends MatTableDataSource<T> {

  locale: string;

  constructor(locale: string) {
    super();
    this.locale = locale;
  }

  // https://github.com/angular/components/blob/13.1.x/src/material/table/table-data-source.ts#L180
  sortData: (data: T[], sort: MatSort) => T[] = (data: T[], sort: MatSort): T[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active);
      let valueB = this.sortingDataAccessor(b, active);

      const valueAType = typeof valueA;
      const valueBType = typeof valueB;

      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }

      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        if (typeof(valueA) === 'string' && typeof(valueB) === 'string') {
          comparatorResult = valueA.localeCompare(valueB, this.locale);
        } else {
          if (valueA > valueB) {
            comparatorResult = 1;
          } else if (valueA < valueB) {
            comparatorResult = -1;
          }
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }

      return comparatorResult * (direction === 'asc' ? 1 : -1);
    });
  }
}
