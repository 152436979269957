import { Subscription } from 'rxjs';
import { EnvironmentHelperService } from '../../../services/environment-helper.service';
import { UiVersion } from '../../../model/ui-version';
import { Component, OnInit, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit, OnDestroy {

  versions: UiVersion[] = [];

  private versionsSubscription?: Subscription;

  constructor(
    private environmentService: EnvironmentHelperService
  ) { }

  ngOnInit(): void {
    this.versionsSubscription = this.environmentService.getUiVersions().subscribe(versions => {
      this.versions = versions.sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime());
    });
  }

  onError(event: any) {
    console.log(event);
  }

  getFileName(version: string) {
    const isGerman = localStorage.getItem('language') === 'de';
    if (isGerman) {
      return `assets/changelog/de/changelog-${version}.md`;
    } else {
      return `assets/changelog/en/changelog-${version}.md`;
    }
  }
  
  ngOnDestroy(): void {
    this.versionsSubscription?.unsubscribe();
  }
}
