<table mat-table [dataSource]="dataSource" id="layers-table">
  <ng-container matColumnDef="materialManifestationName">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.manifestation' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.materialManifestationName }} </td>
  </ng-container>
  <ng-container matColumnDef="mass">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.mass' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.mass }} </td>
  </ng-container>
  <ng-container matColumnDef="grammage">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.grammage' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.grammage }} </td>
  </ng-container>
  <ng-container matColumnDef="thickness">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.thickness' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.thickness }} </td>
  </ng-container>
  <ng-container matColumnDef="density">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.density' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.density }} </td>
  </ng-container>
  <ng-container matColumnDef="massPercentage">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'material.layer.percentage' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.massPercentage }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
