import { TagDto } from './../../../../data-transfer/entities/tag-dto';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tag-directive',
  templateUrl: './tag-directive.component.html',
  styleUrls: ['./tag-directive.component.scss']
})
export class TagDirectiveComponent implements OnInit {

  @Input() tags: TagDto[] = [];
  @Input() maxDisplayed?: number;

  constructor() { }

  get tagsToDisplay() {
    return this.maxDisplayed == null ? this.tags : this.tags.slice(0, this.maxDisplayed);
  }

  get showMore() {
    return this.maxDisplayed == null ? false : this.tags.length > this.maxDisplayed;
  }

  ngOnInit(): void {
  }

}
