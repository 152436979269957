import { PermissionTypeDto } from '../entities/permission-type-dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { PackagingComponentDto } from '../entities/component-entities/packaging-component-dto';
import { environment } from 'src/environments/environment';
import { DeletedEntitiesDto } from '../entities/deleted-entities-dto';
import { ComponentReportDto } from '../entities/component-entities/component-report-dto';
import { PackagingPartTypeDto } from '../entities/packaging-part-type-dto';
import { VersionComponentDto } from '../entities/version-component-dto';
import { ComponentSubtypeDto } from '../entities/component-subtype-dto';
import { catchError } from 'rxjs/operators';
import { CustomFieldProfileDto } from '../entities/custom-field-profile-dto';

@Injectable({ providedIn: 'root' })
export class ComponentApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  public getPackagingComponentTypes(): Observable<PackagingPartTypeDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-types`;
    return this.http.get<PackagingPartTypeDto[]>(url);
  }

  public getPackagingComponentTypesEnglish(): Observable<PackagingPartTypeDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-types`;
    return this.http.get<PackagingPartTypeDto[]>(url, { headers: this.acceptEnglishHeader });
  }

  public getClosureSubtypes(): Observable<ComponentSubtypeDto[]> {
    const url = `${environment.webApiAddress}/api/closure-subtypes`;
    return this.http.get<ComponentSubtypeDto[]>(url);
  }

  public getDecorationSubtypes(): Observable<ComponentSubtypeDto[]> {
    const url = `${environment.webApiAddress}/api/decoration-subtypes`;
    return this.http.get<ComponentSubtypeDto[]>(url);
  }

  public getDecorationSubtypesEnglish(): Observable<ComponentSubtypeDto[]> {
    const url = `${environment.webApiAddress}/api/decoration-subtypes`;
    return this.http.get<ComponentSubtypeDto[]>(url, { headers: this.acceptEnglishHeader });
  }

  public getInlayTypes(): Observable<ComponentSubtypeDto[]> {
    const url = `${environment.webApiAddress}/api/inlay-subtypes`;
    return this.http.get<ComponentSubtypeDto[]>(url);
  }

  public getPackagingAidTypes(): Observable<ComponentSubtypeDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-aid-subtypes`;
    return this.http.get<ComponentSubtypeDto[]>(url);
  }

  public getPackagingComponent(componentType: string, componentId: number, versionId?: number): Observable<PackagingComponentDto> {
    const componentSpecificUrl = this.getApiStringFromComponentTypeName(componentType);
    let url = `${environment.webApiAddress}${componentSpecificUrl}/by-id?id=${componentId}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    const data = this.http.get<PackagingComponentDto>(url);
    return data;
  }

  public getPackagingComponentsByType(
    packagingComponentType: string): Observable<PackagingComponentDto[]> {
    const componentSpecificUrl = this.getApiStringFromComponentTypeName(packagingComponentType);
    const url = `${environment.webApiAddress}${componentSpecificUrl}`;
    return this.http.get<PackagingComponentDto[]>(url);
  }

  public putPackagingComponent(
    packagingComponent: PackagingComponentDto, packagingComponentType: string, shouldCreateNewVersion = true, stayOnPageIfError?: boolean
  ): Observable<any> {
    const componentSpecificUrl = this.getApiStringFromComponentTypeName(packagingComponentType);
    const url = `${environment.webApiAddress}${componentSpecificUrl}?overwriteExistingVersion=${!shouldCreateNewVersion}`;
    console.log(JSON.stringify(packagingComponent));
    return this.http.put<any>(url, packagingComponent,
      { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public putRenamePackagingComponent(id: number, version: number, name: string, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/rename-version`;
    let params = new HttpParams().set('id', id);
    params = params.set('version', version);
    params = params.set('name', name);
    return this.http.put<any>(url, {}, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public setComponentTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/set-tags`;
    const params = new HttpParams().set('packagingComponentId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public addComponentTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/add-tags`;
    const params = new HttpParams().set('packagingComponentId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public deletePackagingComponent(
    idsToDelete: DeletedEntitiesDto, deletePermanently: boolean, stayOnPageIfError?: boolean
  ): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components?deletePermanently=${deletePermanently}`;
    return this.http.request('delete', url, {
      body: idsToDelete,
      headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader
    });
  }

  public lockPackagingComponent(packagingComponentId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/${packagingComponentId}/lock`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public releasePackagingComponent(packagingComponentId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/${packagingComponentId}/release`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader })
      .pipe(catchError(this.handleError));
  }

  public getComponentHistoryVersions(componentId: number) {
    const url = `${environment.webApiAddress}/api/packaging-components/history?id=${componentId}`;
    return this.http.get<VersionComponentDto[]>(url);
  }

  public getLastViewedComponents(count: number): Observable<PackagingComponentDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/latest?count=${count}`;
    return this.http.get<PackagingComponentDto[]>(url);
  }

  public getComponentPermissions(componentId: number): Observable<PermissionTypeDto> {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingComponentId', componentId);
    const url = `${environment.webApiAddress}/api/permissions/effective-packaging-component-permissions`;
    return this.http.get<PermissionTypeDto>(url, { params });
  }

  public getAllComponentReportTemplates(): Observable<ComponentReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-system-report-profiles`;
    return this.http.get<ComponentReportDto[]>(url);
  }

  public getComponentPreview(candidateId: number, componentType: string): Observable<PackagingComponentDto> {
    const componentSpecificUrl = this.getApiStringFromComponentTypeName(componentType);
    const url = `${environment.webApiAddress}${componentSpecificUrl}/by-import-candidate?importCandidateId=${candidateId}`;
    return this.http.get<PackagingComponentDto>(url);
  }

  public getMaterialChanges(componentId: number): Observable<number[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/multi-material-changes?packagingComponentId=${componentId}`;
    return this.http.get<number[]>(url);
  }

  public acceptMaterialChange(componentId: number, materialId: number, shouldCreateNewVersion = true, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingComponentId', componentId);
    params = params.set('multiMaterialId', materialId);
    params = params.set('overwriteExistingVersion', !shouldCreateNewVersion);
    const url = `${environment.webApiAddress}/api/packaging-components/adopt-multi-material-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public declineMaterialChange(componentId: number, materialId: number, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingComponentId', componentId);
    params = params.set('multiMaterialId', materialId);
    const url = `${environment.webApiAddress}/api/packaging-components/ignore-multi-material-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getCustomFieldProfiles(packagingComponentSubtypeId: number): Observable<CustomFieldProfileDto[]> {
    let params = new HttpParams();
    params = params.set('packagingComponentSubtypeId', packagingComponentSubtypeId);

    const url = `${environment.webApiAddress}/api/packaging-components/custom-field-profiles?`;
    return this.http.get<CustomFieldProfileDto[]>(url, { params });
  }
}
