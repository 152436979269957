import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ValidatorApiService extends ParentApiService {

    constructor(private http: HttpClient) {
        super();
    }

    public validateAnalysis(analysisId: string, validationState: number, stayOnPageIfError?: boolean) {
        const url = `${environment.webApiAddress}/api/validation/set-validation-state`;
        let params = new HttpParams().set('analysisId', analysisId);
        params = params.set('validationState', validationState);
        return this.http.put(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader }, { params });
    }
}
