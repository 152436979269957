
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParentApiService } from './parent-api-service';


@Injectable({ providedIn: 'root' })
export class TemplateApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  public getPUImporterTemplate() {
    const url = `${environment.webApiAddress}/api/templates/PackagingUnitImporterTemplate`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
