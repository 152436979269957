import { TranslateService } from '@ngx-translate/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit, ViewChild, TemplateRef, Output, EventEmitter, ViewContainerRef, OnDestroy } from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { Subscription } from 'rxjs';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { ColorThemeService, COLOR_THEME_DARK, COLOR_THEME_NAME } from 'src/app/navigation/services/color-theme-service';
import { ContextMenuService } from '../../services/context-menu-service';

export const RECYCLING_BIN_ID = -1;

@Component({
  selector: 'app-directory-tree',
  templateUrl: './directory-tree.component.html',
  styleUrls: ['./directory-tree.component.scss']
})
export class DirectoryTreeComponent implements OnInit, OnDestroy {

  @ViewChild('nestedTree') nestedTree!: MatTree<any>;

  @Input() rootFolder!: DirectoryDto;
  @Input() includeRecyclingBin = true;
  @Input() preselectFolder = false;
  @Input() contextMenu!: TemplateRef<any>;
  @Output() folderSelected = new EventEmitter();
  @Output() contextMenuOpened = new EventEmitter();

  nestedTreeControl = new NestedTreeControl<DirectoryDto>((node: DirectoryDto) => node.childDirectories);
  treeDataSource: MatTreeNestedDataSource<DirectoryDto>;

  selectedFolder?: DirectoryDto;
  recyclingBinDtoFolder!: DirectoryDto;
  isDarkTheme = false;

  private themeSubscription?: Subscription;

  constructor(
    private contextMenuService: ContextMenuService,
    private viewContainerRef: ViewContainerRef,
    private translateService: TranslateService,
    private colorThemeService: ColorThemeService
  ) {
    this.treeDataSource = new MatTreeNestedDataSource<DirectoryDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit(): void {
    this.treeDataSource.data = [this.rootFolder];
    this.recyclingBinDtoFolder = this.getRecyclingBinFolder();
    if (this.includeRecyclingBin && this.treeDataSource) {
      this.treeDataSource.data.push(this.recyclingBinDtoFolder);
    }
    if (this.preselectFolder) {
      this.selectedFolder = this.rootFolder;
    }
    this.nestedTreeControl.dataNodes = [this.rootFolder];
    this.nestedTreeControl.expandAll();
    this.isDarkTheme = localStorage.getItem(COLOR_THEME_NAME) === COLOR_THEME_DARK;
  }

  private getRecyclingBinFolder() {
    const recyclingBinDtoFolder = new DirectoryDto();
    recyclingBinDtoFolder.id = RECYCLING_BIN_ID;
    recyclingBinDtoFolder.name = this.translateService.instant('dataManagement.directory.recyclingBin');
    return recyclingBinDtoFolder;
  }

  hasChild(node: DirectoryDto): boolean {
    return node.childDirectories && node.childDirectories.length > 0;
  }

  openContextMenu(event: MouseEvent, folder: DirectoryDto) {
    this.setSelectedFolder(folder);
    this.contextMenuService.openContextMenu(this.contextMenu, this.viewContainerRef, event, folder);
  }

  setSelectedFolder(folder: DirectoryDto) {
    if (!this.selectedFolder || folder.id !== this.selectedFolder.id) {
      this.selectedFolder = folder;
      this.folderSelected.emit(folder);
    }
  }

  updateDirectoryTreeData(updatedRootFolder: DirectoryDto) {
    this.rootFolder = updatedRootFolder;
    this.treeDataSource.data = [];
    let allFoldersList = [this.rootFolder];
    allFoldersList = allFoldersList.concat(this.recyclingBinDtoFolder);
    this.treeDataSource.data = allFoldersList;
    this.nestedTreeControl.dataNodes = allFoldersList;
    this.nestedTreeControl.expandAll();
    this.nestedTree.renderNodeChanges(this.treeDataSource.data);
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
