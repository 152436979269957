import { startWith, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { TagDto } from './../../../../../data-transfer/entities/tag-dto';
import { FormControl } from '@angular/forms';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ViewChild, ElementRef, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TagApiService } from 'src/app/data-transfer/services/tag-api-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { TagsDialogComponent } from 'src/app/components/various-components/tags/tags-dialog/tags-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-select-tags',
  templateUrl: './select-tags.component.html',
  styleUrls: ['./select-tags.component.scss'],
})
export class SelectTagsComponent implements OnInit, OnDestroy {
  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  @Input() allTags: TagDto[] = [];
  @Input() selectedTags: TagDto[] = [];

  @Output() tagsChanged = new EventEmitter();

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl!: FormControl;

  allTagNames: string[] = [];

  filteredTags!: Observable<TagDto[]>;

  private saveSubscription?: Subscription;

  constructor(private tagApiService: TagApiService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.tagCtrl = new FormControl('');
    this.allTagNames = this.allTags.map((tag: TagDto) => tag.name);

    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(searchValue => {
        const filtered = searchValue
          ? this._filter(searchValue)
          : this.allTags.filter(pr => this.selectedTags.find(tag => tag.name === pr.name) == null).slice();
        const unusedTags = this.allTags.filter(principal => this.selectedTags.find(tag => tag.name === principal.name) == null);
        if(filtered.length === 0 && unusedTags.length > 0) {
          this.tagCtrl.setErrors({'noResult': true});
        }
        return filtered;
      })
    );
  }

  async createTag(event: Event) {
    event.stopPropagation();
    const dialogConfig = getDialogConfig({ allTagNames: this.allTagNames });
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(TagsDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) {
      return;
    }
    const tagDto: TagDto = dialogResult.data;
    tagDto.isDefault = false;
    this.saveSubscription = this.tagApiService.saveTag(tagDto).subscribe(newId => {
      tagDto.id = newId;
      this.allTags.push(tagDto);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
      this.allTagNames = this.allTags.map((tag: TagDto) => tag.name);
    });
  }

  removeTag(tag: TagDto): void {
    const index = this.selectedTags.indexOf(tag);
    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
      this.tagsChanged.emit();
    }
  }

  addTag(tag: TagDto): void {
    this.selectedTags.push(tag);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('');
    this.tagsChanged.emit();
  }

  private _filter(value: string): TagDto[] {
    const filterValue = value.toLowerCase();
    const filteredTags = this.allTags.filter(principal => this.selectedTags.find(tag => tag.name === principal.name) == null);
    return filteredTags.filter(tag => tag.name.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.saveSubscription?.unsubscribe();
  }
}
