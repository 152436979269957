import { forkJoin } from 'rxjs';
import { ChangeMaterialCandidateDto } from '../../../../../data-transfer/entities/material-entities/change-material-candidate-dto';
import { ImportMaterialCandidateDto } from '../../../../../data-transfer/entities/material-entities/import-material-candidate-dto';
import { MaterialNavigationService } from './../../../../../navigation/services/navigation-services/material-navigation.service';
import { ChangeType, ImportParentComponent, IMPORT_COST } from './../import-parent/import-parent.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { ImportHtmlComponent } from '../import-html/import-html.component';
import { CreditsService } from 'src/app/services/credits-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-import-material',
  templateUrl: './import-material.component.html',
  styleUrls: ['../import-packaging-unit/import-packaging-unit.component.scss']
})
export class ImportMaterialComponent extends ImportParentComponent implements OnInit {

  @ViewChild('importHtml') importHtmlComponent!: ImportHtmlComponent;
  @ViewChild('changeHtml') changeHtmlComponent!: ImportHtmlComponent;

  selectedCandidates: ImportMaterialCandidateDto[] = [];
  selectedChanges: ChangeMaterialCandidateDto[] = [];

  displayedColumns: string[] = [
    'itemId', 'exportingOrganization', 'articleName', 'articleNumber', 'action', 'select'
  ];

  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected importExportApiService: ImportExportApiService,
    protected translateService: TranslateService,
    protected creditsService: CreditsService,
    private navigationService: MaterialNavigationService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, route, translateService);
  }

  ngOnInit(): void {
    this.loadAllDataSources();
  }

  private loadAllDataSources() {
    this.spinner.show();
    const obsrevableImports = this.importExportApiService.getMaterialImportCandidates();
    const obsrevableChanges = this.importExportApiService.getMaterialTrackedChanges();

    this.loadDataSourceSubscription = forkJoin([obsrevableImports, obsrevableChanges]).subscribe(result => {
      this.dataSourceImports.data = result[0];
      this.importHtmlComponent.setDataSource(this.dataSourceImports);
      this.selectedCandidates = [];
      this.dataSourceChanges.data = result[1];
      this.changeHtmlComponent.setDataSource(this.dataSourceChanges);
      this.selectedChanges = [];
      this.spinner.hide();
    });
  }

  async proceedWithImport() {
    await super.selectDirectory();
    if (this.selectedImportType == null || this.selectedCandidates.length < 1 || this.targetDirectory == null) { return; }
    this.spinner.show();
    const requiredCredits = this.selectedCandidates.length * IMPORT_COST;
    // TODO change to new credit type if import have a own credit type
    if (requiredCredits > this.creditsService.creditsCount.recyclabilityCredits) {
      this.navigationService.stopWhenCreditsInsufficient();
      this.spinner.hide();
      return;
    }
    const importFunction = (candidateId: number, targetDirId: number, isWithTracking: boolean) =>
      this.importExportApiService.importMaterials(candidateId, targetDirId, isWithTracking);
    await super.importItems(this.selectedCandidates, importFunction);
    this.creditsService.setCreditsCount();
    this.loadAllDataSources();
  }

  removeCandidates() {
    const deleteFunction = (candidateId: number) => this.importExportApiService.deleteMaterialImportCandidate(candidateId);
    super.removeCandidates(this.selectedCandidates, deleteFunction, this.importHtmlComponent);
  }

  setSelectedImportCandidates(importCandidates: ImportMaterialCandidateDto[]) {
    this.selectedCandidates = importCandidates;
  }

  navigateToCandidate(candidate: ImportMaterialCandidateDto) {
    this.navigationService.navigateToMaterialPreview(candidate.id);
  }

  async proceedWithChange() {
    let targetDirectoryRequired = false;
    if ([ChangeType.WithTrackingNew, ChangeType.WithoutTracking].includes(this.selectedChangeType)) {
      targetDirectoryRequired = true;
      await super.selectDirectory();
    }
    if (this.selectedChangeType == null || this.selectedChanges.length < 1 ||
      (this.targetDirectory == null && targetDirectoryRequired)) { return; }
    this.spinner.show();
    this.doChange();
  }

  private async doChange() {
    let changeFunction;
    if (this.selectedChangeType === this.changeTypeEnum.WithTrackingOverwrite) {
      changeFunction = (changeId: number) => this.importExportApiService.applyMaterialChanges(changeId);
      const idsToChange: number[] = this.selectedChanges.map(x => x.existingMultiMaterial.id ?? -1).filter(x => x !== -1);
      await super.applyChangesExistingItems(idsToChange, changeFunction);
      this.selectedChanges = [];
    } else {
      const isWithTracking = this.selectedChangeType === this.changeTypeEnum.WithTrackingNew;
      changeFunction = (candidateId: number, targetDirectoryId: number, isTracking: boolean) =>
        this.importExportApiService.importMaterials(candidateId, targetDirectoryId, isTracking);
      const idsToChange: number[] = this.selectedChanges.map(x => x.importCandidateId);
      await super.applyChangesNewItems(idsToChange, isWithTracking, changeFunction);
    }
    this.loadAllDataSources();
  }

  removeChanges() {
    const deleteFunction = (changeId: number) => this.importExportApiService.ignoreMaterialChanges(changeId);
    const idsToDelete: number[] = this.selectedChanges.map(x => x.existingMultiMaterial.id ?? -1).filter(x => x !== -1);
    super.removeChanges(this.selectedChanges, deleteFunction, idsToDelete, this.changeHtmlComponent);
  }

  setSelectedImportChanges(changes: ChangeMaterialCandidateDto[]) {
    this.selectedChanges = changes;
  }

  navigateToChange(candidate: ChangeMaterialCandidateDto, seePackagingUnitBeforeChange: boolean) {
    const id = seePackagingUnitBeforeChange ? candidate.existingMultiMaterial.id : candidate.importCandidateId;
    if (id == null) { return; }
    this.navigationService.navigateToMaterialPreview(id, true, seePackagingUnitBeforeChange);
  }
}
