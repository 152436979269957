<mat-form-field>
  <mat-label>{{label}}</mat-label>
  <mat-select [value]="this.selectedOptions" [multiple]="multiple" [formControl]="dropdownControl" (openedChange)="openedChange($event)" (selectionChange)="onSelectionChange($event)" >

  <mat-select-trigger *ngIf="multiple">
    {{ this.displayText }}
  </mat-select-trigger>

  <mat-form-field class="filter-form-field" appearance="outline">
    <input #search autocomplete="off" matInput type="text" placeholder="Filter" [formControl]="inputControl" (keydown)="$event.stopPropagation()">
    <button mat-icon-button [disableRipple]="true" *ngIf="inputControl.value !== ''" matSuffix (click)="inputControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-optgroup *ngIf="!optional && ((filteredOptions | async)?.length ?? 0) === 0">
    <div>{{ 'common.text.noResults' | translate }}</div>
  </mat-optgroup>

  <mat-option *ngIf="!multiple && optional">{{ 'common.text.noSelection' | translate }}</mat-option>
  <mat-option [value]="dummyOption" [disabled]="true" style="display: none;"></mat-option>
  <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (onSelectionChange)="onOptionSelectionChanges($event)" >
    {{option.name}}
  </mat-option>

  </mat-select>
</mat-form-field>
