import { MainBodyReportDto, ClosureReportDto, DecorationReportDto, InlayReportDto, PackagingAidReportDto } from '../component-entities/component-report-dto';
import { setValues } from './packaging-unit-export-profile-dto';

export class PackagingUnitReportDto {
  id?: number;
  profileName?: string;
  owningOrganizationId?: number;
  creationTimestamp?: string;
  isDeleted = false;
  isDefaultProfile = false;
  images = false;
  documents = false;
  componentsSummary = false;
  recyclateDetails = false;

  masterData: PackagingUnitFieldsDto;
  mainBody: MainBodyReportDto;
  closures: ClosureReportDto;
  decorations: DecorationReportDto;
  inlays: InlayReportDto;
  packagingAids: PackagingAidReportDto;
  recyclabilityResult: RecyclabilityReportDto;
  lifeCycleResult: LifeCycleReportDto;

  constructor(defaultBooleanValue?: boolean) {
    this.masterData = new PackagingUnitFieldsDto(defaultBooleanValue);
    this.mainBody = new MainBodyReportDto(defaultBooleanValue);
    this.closures = new ClosureReportDto(defaultBooleanValue);
    this.decorations = new DecorationReportDto(defaultBooleanValue);
    this.inlays = new InlayReportDto(defaultBooleanValue);
    this.packagingAids = new PackagingAidReportDto(defaultBooleanValue);
    this.recyclabilityResult = new RecyclabilityReportDto(defaultBooleanValue);
    this.lifeCycleResult = new LifeCycleReportDto(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    this.images = defaultBooleanValue;
    this.documents = defaultBooleanValue;
    this.componentsSummary = defaultBooleanValue;
    this.recyclateDetails = defaultBooleanValue;
  }
}

export class PackagingUnitFieldsDto {
  packagingUnitId = false;
  version = false;
  comment = false;
  brandName = false;
  productName = false;
  articleNumber = false;
  gtin = false;
  fillingGood = false;
  fillingGoodCategory = false;
  fillingGoodType = false;
  dimensions = false;
  packagingQuantity = false;
  packagingVolume = false;
  distributionCountries = false;
  assemblyCountry = false;
  isTransportPackaging = false;
  isPackagingPartOfProduct = false;
  isMultiUsePackaging = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
  }
}

export class RecyclabilityReportDto {
  id?: number;
  diagram = false;
  ratingTable = false;
  percentageTable = false;
  methodology = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
  }
}

export class LifeCycleReportDto {
  id?: number;
  table: LifeCycleEffectsReportDto;
  lifeCycleStageDiagram: LifeCycleEffectsReportDto;
  componentsDiagram: LifeCycleEffectsReportDto;
  methodology = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
    this.table = new LifeCycleEffectsReportDto(defaultBooleanValue);
    this.lifeCycleStageDiagram = new LifeCycleEffectsReportDto(defaultBooleanValue);
    this.componentsDiagram = new LifeCycleEffectsReportDto(defaultBooleanValue);
    this.methodology = defaultBooleanValue ?? false;
  }
}

class LifeCycleEffectsReportDto {
  climateChange = false;
  acidification = false;
  freshwaterEcotoxicity = false;
  freshwaterEutrophication = false;
  marineEutrophication = false;
  terrestrialEutrophication = false;
  carcinogenicEffects = false;
  ionizingRadiation = false;
  nonCarcinogenicEffects = false;
  ozoneLayerDepletion = false;
  photochemicalOzoneCreationPotential = false;
  respiratoryEffects = false;
  waterScarcity = false;
  fossils = false;
  landUse = false;
  mineralsAndMetals = false;

  constructor(defaultBooleanValue?: boolean) {
    setValues(this, defaultBooleanValue);
  }
}
