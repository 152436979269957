<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.bulkUpload.bulkUserUpload' | translate }}</h1>

<mat-dialog-content>
  <app-filter-select [optional]="true"
                     [options]="allOrganizations"
                     [displayFn]="organizationDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.parentOrganization' | translate"
                     (selectionChange)="selectedParentOrganizationId = $event.value?.id">
  </app-filter-select>

  <app-filter-select [multiple]="true"
                     [optional]="true"
                     [options]="allContractPackages"
                     [displayFn]="contractPackagingDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.contractPackage' | translate"
                     (selectionChange)="selectedContractPackages = $event.value ?? []">
  </app-filter-select>

  <app-filter-select [multiple]="true"
                     [optional]="true"
                     [options]="allClients"
                     [displayFn]="clientDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.allowedClients' | translate"
                     (selectionChange)="selectedAllowedClients = $event.value ?? []">
  </app-filter-select>

  <app-filter-select [multiple]="true"
                     [optional]="true"
                     [options]="allOrganizations"
                     [displayFn]="organizationDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.visibleOrganizations' | translate"
                     (selectionChange)="selectedVisibleOrganizations = $event.value ?? []">
  </app-filter-select>

  <app-filter-select [options]="allPackagingUnitReportTemplates"
                     [displayFn]="reportTemplateDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.packagingUnitReportTemplate' | translate"
                     (selectionChange)="this.selectedPackagingUnitReportTemplate = $event.value; refreshButtonDisabled()">
  </app-filter-select>

  <app-filter-select [options]="allPackagingComponentReportTemplates"
                     [displayFn]="reportTemplateDisplayFn"
                     [label]="'dataManagement.manageOrganizations.bulkUpload.packagingComponentReportTemplate' | translate"
                     (selectionChange)="selectedPackagingComponentReportTemplate = $event.value; refreshButtonDisabled()">
  </app-filter-select>

  <mat-form-field id="credits-field-recyclability">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.creditsRecyclability' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.recyclabilityCredits" type="number">
  </mat-form-field>

  <mat-form-field id="credits-field-lca">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.creditsLca' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.lcaCredits" type="number">
  </mat-form-field>

  <mat-form-field id="max-premium-users">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.maxPremiumUserCount' | translate }}</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="maxPremiumUserCount" type="number">
  </mat-form-field>

  <input type="file" accept=".xlsx" (change)="openExcelFile($event)" #hiddenFileInput
  onclick="value=null" style="display: none" />

  <button mat-raised-button (click)="hiddenFileInput.click()" color="primary" type="button">
    <mat-icon class="material-icons left">upload</mat-icon>
    {{ 'dataManagement.manageOrganizations.bulkUpload.loadExcelFileButton' | translate }}
  </button>
  <span *ngIf="excelFile">
    {{ excelFile.name }}
  </span>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="uploadCreate()" [disabled]="createButtonDisabled">
    {{ 'dataManagement.manageOrganizations.bulkUpload.createButton' | translate }}
    <div class="spinner-container" *ngIf="spinnerActive">
      <mat-spinner diameter="24" color="accent"></mat-spinner>
    </div>
  </button>

  <button mat-raised-button type="submit" color="primary" (click)="uploadChangePasswords()" [disabled]="changePasswordsButtonDisabled">
    {{ 'dataManagement.manageOrganizations.bulkUpload.changePasswordsButton' | translate }}
    <div class="spinner-container" *ngIf="spinnerActive">
      <mat-spinner diameter="24" color="accent"></mat-spinner>
    </div>
  </button>

  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'dataManagement.manageOrganizations.bulkUpload.closeButton' | translate }} </button>

</mat-dialog-actions>

