import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-copy-with-tracking',
  templateUrl: './copy-with-tracking.component.html',
  styleUrls: ['./copy-with-tracking.component.scss']
})
export class CopyWithTrackingComponent {

  @ViewChild ('withTracking') withTracking!: MatSelectionList;

  packagingUnits: PackagingUnitDto[] = [];
  components: PackagingComponentDto[] = [];

  constructor(
    public dialogRef: MatDialogRef<CopyWithTrackingComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    if (this.data.packagingUnits) {
      this.packagingUnits = data.packagingUnits;
    }
    if (this.data.components) {
      this.components = data.components;
    }
  }

  acceptSelection() {
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: this.withTracking.selectedOptions.selected.map(x => x.value) });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
