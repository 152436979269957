import * as moment from 'moment';

export class MomentLocaleUtil {

  public static getTranslatedMonthYear(year: number, month: number) {
    const isGerman = localStorage.getItem('language') === 'de';
    return moment().locale(isGerman ? 'de' : 'en').year(year).month(month).format('MMMM YYYY');
  }

  public static getTranslatedMoment(date: moment.Moment) {
    if (!date) { return null; }
    const isGerman = localStorage.getItem('language') === 'de';
    return date.locale(isGerman ? 'de' : 'en').format('MMMM YYYY');
  }
}
