<h1 mat-dialog-title>
  {{ dialogHeader }}
</h1>

<div mat-dialog-content>
  {{ dialogText }}
</div>

<div mat-dialog-actions>
  <button (click)="deleteAction()" mat-raised-button type="submit"
    color="warn">{{ 'common.buttons.delete' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</div>
