import { ColorThemeService, COLOR_THEME_DARK } from '../../../navigation/services/color-theme-service';
import { PackagingUnitInfoDto } from '../../../data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { PackagingUnitsOverviewComponent } from '../../directory-tree/components/packaging-units-overview/packaging-units-overview.component';
import { GENERIC_PACKAGING_TYPE_ID, PackagingUnitTypeService } from '../../../navigation/services/packaging-unit-type-service';
import { AuthService } from '../../../services/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { getPaginatorIntl } from 'src/app/util/paginator-translation.provider';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';

@Component({
  selector: 'app-packaging-unit-home',
  templateUrl: './packaging-unit-home.component.html',
  styleUrls: ['./packaging-unit-home.component.scss']
})
export class PackagingUnitHomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(PackagingUnitsOverviewComponent) private packagingUnitsTable!: PackagingUnitsOverviewComponent;

  dataSource: MatTableDataSource<PackagingUnitInfoDto>;
  selectedPackagingUnitTypeId = -1;
  displayedColumns: string[] = ['id', 'tracking', 'brandName', 'productName', 'articleNumber', 'action'];

  compareButtonDisabled = true;
  isUserValidator = false;
  genericTypeId = GENERIC_PACKAGING_TYPE_ID;
  packagingUnitImageSource = '';
  orderedPackagingUnitTypes: PackagingPartTypeDto[] = [];

  private routeParamsSubscription?: Subscription;
  private byTypeSubscription?: Subscription;
  private paginationSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    private router: Router,
    private packagingUnitApiService: PackagingUnitApiService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private authService: AuthService,
    private packagingUnitTypeService: PackagingUnitTypeService,
    private spinner: NgxSpinnerService,
    private colorThemeService: ColorThemeService
  ) {
    this.dataSource = new MatTableDataSource<PackagingUnitInfoDto>();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      const isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.packagingUnitImageSource = this.packagingUnitTypeService.getPackagingUnitImage(isDarkTheme);
    });
  }

  ngOnInit() {
    this.spinner.show();
    this.orderedPackagingUnitTypes = this.packagingUnitTypeService.getOrderedPackagingUnitTypes();
    this.isUserValidator = this.authService.isUserValidator();

    this.routeParamsSubscription = this.route.params.subscribe(_ => {
      this.dataSource = new MatTableDataSource<PackagingUnitInfoDto>();
      this.dataSource.paginator = this.paginator;
      this.selectedPackagingUnitTypeId = this.packagingUnitTypeService.getLastSelectedTypeId() ??
        this.packagingUnitTypeService.getPackagingUnitTypes()[0].id;

      this.byTypeSubscription = this.packagingUnitApiService.getPackagingUnitsByType(this.selectedPackagingUnitTypeId)
        .subscribe(async packagingUnits => {
          this.dataSource.data = packagingUnits;
          if (!this.isUserValidator) {
            this.addRemoveCompareColumn();
          }
          this.spinner.hide();
        });
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = getPaginatorIntl(this.translateService);
    this.dataSource.paginator = this.paginator;
    this.paginationSubscription = this.paginator.page.subscribe(_ => {
      this.packagingUnitsTable.updateDisplayTrackedColumn();
    });
  }

  getImageForPackagingUnitType(packagingUnitTypeId: number) {
    const isGray = packagingUnitTypeId !== this.selectedPackagingUnitTypeId;
    return this.packagingUnitTypeService.getPackagingUnitTypeImage(packagingUnitTypeId, isGray);
  }

  private addRemoveCompareColumn(): void {
    const compareColumnName = 'compare';
    const isCompareColumnPresent = this.displayedColumns.includes(compareColumnName);
    const isGeneric = this.packagingUnitTypeService.isPackagingUnitTypeGeneric(this.selectedPackagingUnitTypeId);
    if (!isGeneric) {
      if (!isCompareColumnPresent) {
        this.displayedColumns.push(compareColumnName);
      }
    } else {
      if (isCompareColumnPresent) {
        this.displayedColumns.splice(this.displayedColumns.findIndex(x => x === compareColumnName), 1);
      }
    }
  }

  setSelectedForComparison(listToCompare: PackagingUnitInfoDto[]) {
    this.compareButtonDisabled = listToCompare.length > 1 && listToCompare.length <= 10 ? false : true;
  }

  compare() {
    this.packagingUnitsTable.comparePackagingPart();
  }

  createPackagingUnit() {
    this.packagingUnitTypeService.setLastSelectedTypeId(this.selectedPackagingUnitTypeId);
    const path = PathResolveService.getCreatePackagingUnitPath(true);
    this.router.navigate([path]);
  }

  /**
   * If a new type of packaging is selected, navigate to packaging unit with this type
   * @param newPackagingUnitType newly selected packaging type
   */
  onPackagingUnitTypeChange(newPackagingUnitTypeId: number) {
    if (this.selectedPackagingUnitTypeId !== newPackagingUnitTypeId) {
      this.packagingUnitTypeService.setLastSelectedTypeId(newPackagingUnitTypeId);
      const name = this.packagingUnitTypeService.getPackagingUnitTypeName(newPackagingUnitTypeId);
      this.router.navigate([PathResolveService.getPackagingUnitTypePath(true, name)]);
    }
    this.compareButtonDisabled = true;
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    this.byTypeSubscription?.unsubscribe();
    this.paginationSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }
}
