<h1 mat-dialog-title>
  <span matDialogTitle class="align-center">
    <mat-icon class="margin-icon">{{icon}}</mat-icon>{{title}}
  </span>
</h1>
<mat-dialog-content>
  <div *ngFor="let message of messages">
    <p>{{message}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button matDialogClose="confirm" mat-raised-button color="primary" type="submit">
    {{ 'common.buttons.ok' | translate }}
  </button>
</mat-dialog-actions>
