import { ImageDto } from '../../../../data-transfer/entities/image-dto';
import { VersionDto } from '../../../../data-transfer/entities/version-dto';
import { PermissionTypeDto } from '../../../../data-transfer/entities/permission-type-dto';
import { PdfUploadComponent } from '../../upload/pdf-upload/pdf-upload.component';
import { Subscription, Observable, forkJoin, firstValueFrom } from 'rxjs';
import { CreateUpdateHandler } from '../../../../services/packaging-services/create-update-handler';
import { FormGroup } from '@angular/forms';
import { ImageUploadComponent } from '../../upload/image-upload/image-upload.component';
import { Component, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { AuthService } from 'src/app/services/auth-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { RenameDialogType, RenameDialogComponent } from 'src/app/components/dialogs/rename-dialog/rename-dialog.component';
import { PackagingPartBaseData } from 'src/app/data-transfer/entities/packaging-part-base-data';
import { ActivatedRoute } from '@angular/router';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SelectLocationDialogComponent } from 'src/app/components/directory-tree/dialogs/select-location-dialog/select-location-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { TagDto } from 'src/app/data-transfer/entities/tag-dto';
import { AnalysisMenuCaller } from 'src/app/components/shared-components/analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { DialogActions } from 'src/app/model/dictionary';
import { interval } from 'rxjs';
import { mergeMap, startWith } from 'rxjs/operators';
import { OptionalVersionDialogComponent } from 'src/app/components/dialogs/optional-version-dialog/optional-version-dialog.component';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { ChangedComponentDisplayInfo } from 'src/app/components/packaging-unit/create-update/create-update-packaging-unit/create-update-packaging-unit.component';

export const TEN_MINUTES = 1_000 * 60 * 10;
export const FOUR_MINUTES = 1_000 * 60 * 4;

export class ChangedPackagingPartDisplayInfo {
  id = -1;
  name = '';
  packagingPart!: number;
  type = '';
}

@Component({
  selector: 'app-create-update-packaging-part',
  templateUrl: './create-update-packaging-part.component.html',
  styleUrls: ['./create-update-packaging-part.component.scss']
})
export class CreateUpdatePackagingPartComponent implements OnDestroy {

  @ViewChild(ImageUploadComponent) private imageUploadComponent!: ImageUploadComponent;
  @ViewChild(PdfUploadComponent) private pdfUploadComponent!: PdfUploadComponent;

  callerPageId = AnalysisMenuCaller.Packaging;
  callerLevelId = PackagingPart.Unit;
  currentDirectoryId?: number;
  isUserValidator = false;
  isUserAdmin = false;
  canEditForm = true;
  isFormLocked = false;
  isHistory = false;
  isPreview = false;
  isTracked = false;
  isGeneric = false;
  isEditPermitted = true;
  filesEdited = false;
  submitted = false;
  isDarkTheme = false;
  spinnerActive = false;

  packagingPart = PackagingPart;
  changedPackagingPartsInfo: ChangedComponentDisplayInfo[] = [];

  protected permissions!: PermissionTypeDto;

  protected historyVersions: VersionDto[] = [];
  protected allTags: TagDto[] = [];
  protected selectedTags: TagDto[] = [];

  protected heartBeatSubscription?: Subscription;
  protected routeDataSubscription?: Subscription;
  protected routeParamsSubscription?: Subscription;
  protected dialogSubscription?: Subscription;
  protected quantityChangesSubscription?: Subscription;
  private putSubscription?: Subscription;
  private lockSubscription?: Subscription;
  private releaseSubscription?: Subscription;
  private changeSubscription?: Subscription;
  private renameSubscription?: Subscription;
  private versionSubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    protected createUpdateHandler: CreateUpdateHandler,
    protected dialog: MatDialog,
    protected colorThemeService: ColorThemeService,
    protected authService: AuthService,
    protected cdr: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected spinner: NgxSpinnerService,
    protected fileApiService: FileApiService
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
    this.isUserValidator = this.authService.isUserValidator();
    this.isUserAdmin = this.authService.isUserAdmin();
    this.allTags = this.createUpdateHandler.allTags;
  }

  setUpLockAndHeartbeat(form: FormGroup, lockFunction: (packagingId: number) => Observable<any>, packagingId?: number) {
    this.heartBeatSubscription?.unsubscribe();
    if (this.isUserValidator || this.isPreview) {
      return;
    }
    if (packagingId == null) {
      this.heartBeatSubscription = interval(TEN_MINUTES)
        .pipe(
          startWith(this.authService.heartbeat()),
          mergeMap(() => this.authService.heartbeat())
        )
        .subscribe((_: any) => {
          console.log('heartbeat');
        });
    } else {
      this.heartBeatSubscription = interval(FOUR_MINUTES)
        .pipe(
          startWith(lockFunction(packagingId)),
          mergeMap(() => lockFunction(packagingId))
        )
        .subscribe({
          next: (_: any) => {
            console.log('lock');
          },
          error: e => {
            if (e.status === 409) {
              this.createUpdateHandler.showLockedPackagingUnitDialog(this.dialog);
              this.canEditForm = false;
              this.isFormLocked = true;
              form.disable();
            }
          },
        });
    }
  }

  disableFormIfRequired(form: FormGroup) {
    if (this.isHistory || this.isUserValidator || this.isUserAdmin || this.isPreview
      || !this.isEditPermitted || this.isFormLocked) {
      this.canEditForm = false;
      form.disable();
    }
  }

  onHistoryVersionChange(versionId: number, navigationFunction: (packagingId: number, packagingVersion?: number) => void) {
    const selectedPackaging: VersionDto | undefined = this.historyVersions.find(x => x.versionNumber === versionId);
    if (!selectedPackaging || selectedPackaging.id == null) { return; }
    if (selectedPackaging.isCurrentVersion) {
      navigationFunction(selectedPackaging.id);
    } else {
      if (selectedPackaging.versionNumber == null) { return; }
      navigationFunction(selectedPackaging.id, selectedPackaging.versionNumber);
    }
  }

  loadHistoryVersions(packagingId: number | undefined, versionsFunction: (packagingId: number) => Observable<VersionDto[]>) {
    if (packagingId == null) { return; }
    if (this.historyVersions.length <= 1) {
      this.spinnerActive = true;
      this.versionSubscription = versionsFunction(packagingId).subscribe(versions => {
        this.historyVersions = versions.sort((a, b) =>
          b.versionNumber != null && a.versionNumber != null ? b.versionNumber - a.versionNumber : 0);
        this.spinnerActive = false;
        this.cdr.detectChanges();
      });
    }
  }

  protected async releaseComponent(
    packagingId: number | undefined, releaseFunction: (packagingId: number, stayOnPage: boolean) => Observable<any>
  ) {
    const isSignedIn = await this.authService.isSignedIn()
    if (!this.canEditForm || packagingId == null || this.isUserValidator || this.isPreview || !isSignedIn) { return; }
    this.releaseSubscription = await releaseFunction(packagingId, true).toPromise();
  }

  acceptChange(
    packagingId: number, componentId: number,
    acceptFunction: (packagingId: number, componentId: number, shouldCreateNewVersion: boolean) => Observable<any>,
    shouldCreateNewVersion: boolean
  ) {
    if (packagingId == null || componentId == null) { return; }
    this.changeSubscription = acceptFunction(packagingId, componentId, shouldCreateNewVersion).subscribe(_ => window.location.reload());
  }

  declineChange(packagingId: number, componentId: number, declineFunction: (packagingId: number, componentId: number) => Observable<any>) {
    if (packagingId == null || componentId == null) { return; }
    this.changeSubscription = declineFunction(packagingId, componentId).subscribe(_ => window.location.reload());
  }

  updateImagesAfterSaving(): void {
    this.imageUploadComponent.recalculateImageIndices();
    this.imageUploadComponent.setMainImage();
    this.imageUploadComponent.setImageNames();
  }

  updateDocumentsAfterSaving(pdfIdsList: number[]): void {
    const newDocuments = this.pdfUploadComponent.pdfs.filter(x => x.documentId == null);
    for (let index = 0; index < pdfIdsList.length; index++) {
      if (newDocuments[index]) {
        newDocuments[index].documentId = pdfIdsList[index];
      }
    }
  }

  protected editVersionName(
    historyVersion: VersionDto,
    currentPackaging: PackagingPartBaseData,
    renameFunction: (packagingId: number, version: number, name: string, stayOnPage: boolean) => Observable<any>
  ) {
    const dialogData = { name: historyVersion.versionName ?? '', type: RenameDialogType.PackagingUnitVersion };
    const dialogConfig = getDialogConfig(dialogData);
    const dialogRef = this.dialog.open(RenameDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT || historyVersion.id == null || historyVersion.versionNumber == null) { return; }
      this.renameSubscription = renameFunction(historyVersion.id, historyVersion.versionNumber, result.data.name, true)
        .subscribe(_ => {
          historyVersion.versionName = result.data.name;
          if (historyVersion.versionNumber === currentPackaging.version) {
            currentPackaging.versionName = result.data.name;
          }
          this.cdr.detectChanges();
        });
    });
  }

  getLocaleDate(timestamp: Date) {
    if (timestamp) {
      return new Date(timestamp).toLocaleDateString();
    } else {
      return (new Date()).toLocaleDateString();
    }
  }

  getDateTimeString(date?: Date) {
    if (!date) { return ''; }
    return `${new Date(date).toLocaleDateString()}: ${new Date(date).toLocaleTimeString()}`;
  }

  onFilesEdited() {
    this.filesEdited = true;
  }

  protected openFormInvalidDialog() {
    const dialogData = this.createUpdateHandler.getInvalidDialogData();
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(dialogData));
  }

  protected async selectDirectory() {
    const rootFolder = this.createUpdateHandler.rootDirectory;
    if (!rootFolder) { throw new Error('No directories available'); }
    if (!rootFolder.childDirectories || rootFolder.childDirectories.length < 1) {
      return rootFolder.id;
    }
    const dialogData = { rootFolder, unreachableFolders: [] };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const dialogResult = await this.dialog.open(SelectLocationDialogComponent, dialogConfig).afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return null; }
    return dialogResult.data.id;
  }

  async shouldCreateNewVersion(isAnalyzablePackagingPart: boolean, packagingPart: number) {
    const dialogData: SimpleDialogData = this.createUpdateHandler.getCreateNewVersionDialogData();
    const dialogRef = this.dialog.open(OptionalVersionDialogComponent, getDialogConfig(dialogData));
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return null; }
    if (!dialogResult.data.createNewVersion) {
      const confirmationData: SimpleDialogData =
        this.createUpdateHandler.getOverwriteConfirmationDialogData(packagingPart);
      const confirmationDialogRef = this.dialog.open(SimpleConfirmDialogComponent, getDialogConfig(confirmationData));
      const confirmationResult = await confirmationDialogRef.afterClosed().toPromise();
      if (confirmationResult.event === DialogActions.REJECT) { return; }
    }
    return dialogResult.data.createNewVersion;
  }

  protected putPackagingItem(
    packagingItem: any, form: FormGroup,
    saveFunction: (packagingItem: any, shouldCreateNewVersion: boolean, stayOnPage: boolean) => Observable<any>,
    navFunction: (id: number, version: number) => void,
    shouldCreateNewVersion: boolean
  ) {
    this.spinner.show();
    this.putSubscription = saveFunction(packagingItem, shouldCreateNewVersion, true).subscribe({
      next: response => {
        Object.keys(form.controls).forEach(control => {
          form.controls[control].markAsPristine();
        });
        form.markAsPristine();
        this.filesEdited = false;

        const dialogData = this.createUpdateHandler.getPackagingUnitSucessDialogData();
        const alertDialogConfig = getDialogConfig(dialogData, '400px');
        const dialogResult = this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
        this.dialogSubscription = dialogResult.afterClosed().subscribe(_ => {
          if (response.id != null && response.version != null) {
            navFunction(response.id, response.version);
          }
        });
      },
      error: e => {
        console.error('An error occurred, ', e);
        const dialogData = this.createUpdateHandler.getFailureDialogData(e);
        const alertDialogConfig = getDialogConfig(dialogData, '400px');
        this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
        this.spinner.hide();
      },
    });
  }

  protected async updateImagesAndDocuments(packagingItem: any) {
    const newImagesFormData = this.imageUploadComponent.getNewImagesFormData();
    const newPdfsFormData = this.pdfUploadComponent.getNewPdfsFormData();

    const imageObservables = this.fileApiService.putImages(newImagesFormData);
    const pdfObservables = this.fileApiService.putDocuments(newPdfsFormData);

    const filesResultIds = await firstValueFrom(forkJoin([imageObservables, pdfObservables]));

    const imageIdsList = filesResultIds[0];
    const pdfIdsList = filesResultIds[1];

    if (!packagingItem.images) { packagingItem.images = []; }
    const materialImageIds = packagingItem.images.map((x: ImageDto) => x.imageId) ?? [];
    for (const imageId of imageIdsList) {
      if (!materialImageIds.includes(imageId)) {
        packagingItem.images.push({ imageId, index: packagingItem.images.length, isMainImage: false, name: '' });
      }
    }
    this.updateImagesAfterSaving();
    this.updateDocumentsAfterSaving(pdfIdsList);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.routeParamsSubscription?.unsubscribe();
    this.lockSubscription?.unsubscribe();
    this.releaseSubscription?.unsubscribe();
    this.putSubscription?.unsubscribe();
    this.changeSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.renameSubscription?.unsubscribe();
    this.versionSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
    this.quantityChangesSubscription?.unsubscribe();
    this.heartBeatSubscription?.unsubscribe();
  }
}
