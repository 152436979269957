import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export class AddEditDirectoryDialogData {
  action!: number;
  directoryName = '';
}

@Component({
  selector: 'app-add-edit-directory-dialog',
  templateUrl: './add-edit-directory-dialog.component.html',
  styleUrls: ['./add-edit-directory-dialog.component.scss']
})
export class AddEditDirectoryDialogComponent implements OnInit {

  directoryForm!: FormGroup;
  action!: number;
  directoryName = '';
  dialogActions = DialogActions;

  constructor(
    private dialogRef: MatDialogRef<AddEditDirectoryDialogComponent>,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: AddEditDirectoryDialogData
  ) {
    if (data) {
      this.action = data.action;
      this.directoryName = data.directoryName ?? '';
    }
  }

  ngOnInit(): void {
    this.directoryForm = this.formBuilder.group({
      directoryName: [this.directoryName, Validators.required]
    });
  }

  acceptChanges() {
    this.directoryForm.markAllAsTouched();

    if (this.directoryForm.invalid) { return; }
    this.dialogRef.close({ data: this.directoryForm.value });
  }

  discardChanges() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
