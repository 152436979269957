import { PackagingComponentDto } from '../../../data-transfer/entities/component-entities/packaging-component-dto';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogActions } from 'src/app/model/dictionary';

export const ADD_WITH_TRACKING = 'AddWithTracking';
export const ADD_WITHOUT_TRACKING = 'AddWithoutTracking';

@Component({
  selector: 'app-selection-dialog-components',
  templateUrl: './selection-dialog-components.component.html',
  styleUrls: ['./selection-dialog-components.component.scss']
})
export class SelectionDialogComponentsComponent {

  dataSource: MatTableDataSource<PackagingComponentDto>;
  displayedColumns: string[] = [];
  dialogTitle = '';
  selectedComponents: PackagingComponentDto[];

  constructor(
    protected dialogRef: MatDialogRef<SelectionDialogComponentsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    this.dataSource = new MatTableDataSource<PackagingComponentDto>();
    this.selectedComponents = [];
    if (data) {
      this.dataSource = data.dataSource;
      this.displayedColumns = data.displayedColumns;
      this.dialogTitle = data.dialogTitle;
    }
  }

  componentsSelected(e: PackagingComponentDto[]) {
    this.selectedComponents = e;
  }

  importComponents(withTracking: boolean) {
    /* let event: string;
    if (withTracking) {
      event = ADD_WITH_TRACKING;
      this.selectedComponents.forEach(x => {
        // Tracking input is ignored by backend, but needed to display tracking icon
        // x.hasInternalTracking = true;
        x.multiMaterial.hasInternalTracking = true;
      });
    } else {
      event = ADD_WITHOUT_TRACKING;
      this.selectedComponents.forEach(x => x.id = undefined);
    } */
    const event = withTracking ? ADD_WITH_TRACKING : ADD_WITHOUT_TRACKING;
    this.dialogRef.close({ event, data: this.selectedComponents });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
