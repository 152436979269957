import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { COLOR_THEME_DARK, ColorThemeService } from 'src/app/navigation/services/color-theme-service';

@Component({
  selector: 'app-dimensions-info',
  templateUrl: './dimensions-info.component.html',
  styleUrls: ['./dimensions-info.component.scss']
})
export class DimensionsInfoComponent implements OnDestroy {

  image2D = '';
  image3D = '';
  imageCylindrical = '';
  isDarkTheme = false;

  private themeSubscription?: Subscription;
  
  constructor(
    private colorThemeService: ColorThemeService,
    private translateService: TranslateService
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      const language = this.translateService.store.currentLang;
      const langString = language === 'de' ? 'de' : 'en';
      this.image2D = `${PROJECT_IMAGES_PATH}dimensions/2D_${langString}${this.isDarkTheme ? '_dark' : ''}.svg`;
      this.image3D = `${PROJECT_IMAGES_PATH}dimensions/3D_${langString}${this.isDarkTheme ? '_dark' : ''}.svg`;
      this.imageCylindrical = `${PROJECT_IMAGES_PATH}dimensions/cylindrical_${langString}${this.isDarkTheme ? '_dark' : ''}.svg`;
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
