<div class="outer-container">

  <mat-tab-group (selectedTabChange)="onCountrySwitch($event)">
    <mat-tab *ngFor="let evaluation of recDataSource; let countryIndex = index"
      [label]="evaluation[0].evaluatedCountry">

      <mat-expansion-panel expanded class="expansion-panel-class">
        <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
          <mat-panel-title>
            <h1>{{ 'analysis.analysisComparison.recyclability.title' | translate }}</h1>
          </mat-panel-title>
          <mat-panel-description>
            <h2>{{ 'analysis.analysisComparison.recyclability.descriptionUnits' | translate }}</h2>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="inner-container">
          <div class="scrolling-wrapper">
            <div class="item-container">
              <div *ngFor="let element of evaluation" class="item">
                <mat-card>
                  <div class="chart-container" #chartContainer *ngIf="analysesDataLoaded | async">
                    <app-recyclability-chart [chartRawData]="element" [chartHeight]="300" [showLabels]="false"
                      [minimizeLegend]="true" [showExport]="false" [showTitle]="false" [isDarkTheme]="isDarkTheme"
                      [chartWidth]="(1600 - (32*evaluation.length))/evaluation.length"
                      (newAnalysisRequested)="createNewRecyclabilityAnalysis($event)">
                    </app-recyclability-chart>
                  </div>
                </mat-card>
                <div class="chart-label">{{element.packagingPartName}}</div>
                <div class="total-values-container">
                  <div>
                    <mat-icon class="large-font green"
                      matTooltip="{{ 'analysis.recyclabilityAnalysis.recyclableTotal' | translate }} {{element.recyclableTotal}}%">
                      check
                    </mat-icon>
                    <mat-span
                      matTooltip="{{ 'analysis.recyclabilityAnalysis.recyclableTotal' | translate }} {{element.recyclableTotal}}%">
                      {{element.recyclableTotal}}%
                    </mat-span>
                  </div>
                  <div>
                    <mat-icon class="large-font red"
                      matTooltip="{{ 'analysis.recyclabilityAnalysis.nonRecyclableTotal' | translate }} {{element.nonRecyclableTotal}}%">
                      clear
                    </mat-icon>
                    <mat-span
                      matTooltip="{{ 'analysis.recyclabilityAnalysis.nonRecyclableTotal' | translate }} {{element.nonRecyclableTotal}}%">
                      {{element.nonRecyclableTotal}}%
                    </mat-span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <mat-table [dataSource]="recTableData[countryIndex]" class="mat-elevation-z1">
            <ng-container *ngFor="let column of recDataColumns" [cdkColumnDef]="column.columnDef">
              <mat-header-cell *cdkHeaderCellDef>
                {{ column.header }}
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" matTooltip="{{ column.tooltip(row) }}">
                {{ column.cell(row) }}
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="recColumnHeaders"></mat-header-row>
            <mat-row *matRowDef="let row; columns: recColumnHeaders;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded class="expansion-panel-class">
        <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
          <mat-panel-title>
            <h1>{{ 'analysis.analysisComparison.lca.title' | translate }}</h1>
          </mat-panel-title>
          <mat-panel-description>
            <h2>{{ 'analysis.analysisComparison.lca.description' | translate }}</h2>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <app-normalization #normForm [volumeAvailable]="volumeAvailable" [weightAvailable]="weightAvailable"
          (normalizeClick)="doNormalize($event)">
        </app-normalization>

        <div class="flex-container">
          <mat-button-toggle-group name="environmentalEffect" #group="matButtonToggleGroup" vertical
            [value]="selectedEffectKey" (change)="setSelectedEffect($event.value)" class="buttons-div">
            <mat-button-toggle *ngFor="let effect of effectsList[countryIndex]" [value]="effect.key"
            [matTooltip]="this.getTooltipName(effect.key)" matTooltipPosition = "right">
              <mat-icon *ngIf="!this.isEffectAuthorized(effect.key)">lock</mat-icon>
              {{effect.name}}
            </mat-button-toggle>
          </mat-button-toggle-group>
          <div class="chart-div">
            <mat-card class="description-container">
              <mat-card-content>
                <div class="total">{{ getEffectName() }}</div>
                <div>{{ getEffectDescription() }}</div>
                <div>
                  <strong>{{ 'analysis.lifecycleAnalysis.measurementUnit' | translate }}</strong>
                  {{ getEffectUnitDescription() }}
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="chart-container" #chartContainer *ngIf="analysesDataLoaded | async">
              <mat-card-content>
                <app-buying-from-shop  *ngIf="!this.isEffectAuthorized(this.selectedEffectKey)" [buyType]="buyType.EnvironmentallEffect"
                  [contentValue]="this.getEffectName()">
                </app-buying-from-shop>
                <app-life-cycle-comparison-chart #lcaChart [class]="this.isEffectAuthorized(this.selectedEffectKey)? '' : 'hide-chart'" [chartRawData]="lcaDataSource[countryIndex]"
                  [selectedEffectKey]="selectedEffectKey" [selectedEffectUnit]="selectedEffectUnit"
                  [isDarkTheme]="isDarkTheme" [newAllowed]="newLcaAllowed"
                  [chartSubtitle]="getNormalizationForChartSubtitle()"
                  (newAnalysisRequested)="createNewLifecycleAnalysis($event)">
                </app-life-cycle-comparison-chart>
                <alert type="info" class="lca-chart-alert">
                  {{ 'analysis.lifecycleAnalysis.streamlinedLca' | translate: { version: ecoinventVersion } }}
                </alert>
              </mat-card-content>
            </mat-card>
          </div>
        </div>

        <div class="inner-container">
          <mat-table [dataSource]="lcaTableData[countryIndex]" class="mat-elevation-z1">
            <ng-container *ngFor="let column of lcaDataColumns" [cdkColumnDef]="column.columnDef">
              <mat-header-cell *cdkHeaderCellDef>
                {{ column.header }}
              </mat-header-cell>
              <mat-cell *cdkCellDef="let row" matTooltip="{{ column.tooltip(row) }}"
                [ngClass]="{'relevant-dark' : row.isRelevant && isDarkTheme, 'relevant-light': row.isRelevant && !isDarkTheme}">
                <mat-icon *ngIf="column.columnDef !== 'environmentalEffect'" [style.color]="column.color(row)">eco
                </mat-icon>
                {{ column.cell(row) }}
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="lcaColumnHeaders"></mat-header-row>
            <mat-row *matRowDef="let row; columns: lcaColumnHeaders;"></mat-row>
          </mat-table>
        </div>
      </mat-expansion-panel>
    </mat-tab>
  </mat-tab-group>
</div>
