<h1 mat-dialog-title *ngIf="action===dialogActions.ADD">{{ 'dataManagement.directory.dialog.addEditFolder.addTitle' | translate }}
</h1>
<h1 mat-dialog-title *ngIf="action===dialogActions.RENAME">
  {{ 'dataManagement.directory.dialog.addEditFolder.renameTitle' | translate }}</h1>

<mat-dialog-content>

  <form [formGroup]="directoryForm">
    <mat-form-field class="form-field-wide">
      <mat-label>{{ 'dataManagement.directory.dialog.addEditFolder.nameLabel' | translate }}</mat-label>
      <input matInput formControlName="directoryName" autocomplete="off">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="acceptChanges()" *ngIf="action===dialogActions.ADD"
    type="submit">{{ 'common.buttons.add' | translate }}</button>
  <button mat-raised-button color="primary" (click)="acceptChanges()" *ngIf="action===dialogActions.RENAME"
    type="submit">{{ 'common.buttons.rename' | translate }}</button>
  <button mat-raised-button (click)="discardChanges()" type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
