import { Component, Input } from '@angular/core';
import { RecyclingStreamTranslationsDto } from 'src/app/data-transfer/entities/evaluation-entities/recycling-stream-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

@Component({
  selector: 'app-analysis-recyclability-packaging-part',
  templateUrl: './analysis-recyclability-packaging-part.component.html',
  styleUrls: ['./analysis-recyclability-packaging-part.component.scss']
})
export class AnalysisRecyclabilityPackagingPartComponent {

  @Input() recyclingStreams: RecyclingStreamTranslationsDto[] = [];
  @Input() isDarkTheme = false;

  selectedNodeType!: number;
  packagingPart = PackagingPart;
}
