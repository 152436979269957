import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { ChartSeries, EffectInfosDictionary } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { LcaEffectInfo, LcaStageInfo } from '../life-cycle-data-information';
import { ComparisonHtmlTemplateComponent } from 'src/app/components/shared-components/comparison-html-template/comparison-html-template.component';

export class LifeCycleResult {

  stagesChartDataSource: ChartSeries[][] = [];
  effectRelevances!: EffectInfosDictionary[];

  packagingPartId?: number;
  packagingPartVersion!: number;
  packagingPartName!: string;
  packagingWeight!: number | null;
  packagingVolume!: number | null;
  analysisId?: string;
  evaluatedCountry!: string;
  evaluatedCountryCode!: string;
  validationState!: number;
  isLcaPossible = false;

  protected initChartData(
    lifeCycleDataSource: LifeCycleResultDto, environmentalEffects: EffectInfosDictionary[], stagesInfo: LcaStageInfo[]
  ) {
    this.stagesChartDataSource = new Array<Array<ChartSeries>>(environmentalEffects.length);

    for (let effectIdx = 0; effectIdx < environmentalEffects.length; effectIdx++) {
      const effectKey = environmentalEffects[effectIdx].key;
      this.stagesChartDataSource[effectIdx] = [];
      stagesInfo.forEach((lifeCycleStage, stageIdx) => {
        const stageName = lifeCycleStage.name;
        const stageTotal = this.getEnvironmentalEffectValue(lifeCycleDataSource, stageIdx, effectKey) ?? 0.0;
        this.stagesChartDataSource[effectIdx].push(this.getChartSeriesObject(stageName, stageTotal));
      });
    }
  }

  private getEnvironmentalEffectValue(lifeCycleResult: LifeCycleResultDto, iLifeCyclePhase: number, iEffect: string) {
    let dto = null;
    /* case 0: dto = lifeCycleResult.environmentalEffectsPrimaryMaterials; break;
        case 1: dto = lifeCycleResult.environmentalEffectsSecondaryMaterials; break;
        case 2: dto = lifeCycleResult.environmentalEffectsManufacturing; break;
        case 3: dto = lifeCycleResult.environmentalEffectsTransport; break;
        case 4: dto = lifeCycleResult.environmentalEffectsDistribution; break;
        case 5: dto = lifeCycleResult.environmentalEffectsEndOfLife; break;
        case 6: dto = lifeCycleResult.environmentalEffectsTotal; break; */
    switch (iLifeCyclePhase) {
      case 0: return lifeCycleResult.environmentalEffectsPrimaryMaterials[iEffect as keyof typeof dto] +
        lifeCycleResult.environmentalEffectsSecondaryMaterials[iEffect as keyof typeof dto] +
        lifeCycleResult.environmentalEffectsManufacturing[iEffect as keyof typeof dto];
      case 1: dto = lifeCycleResult.environmentalEffectsTransport; break;
      case 2: dto = lifeCycleResult.environmentalEffectsDistribution; break;
      case 3: dto = lifeCycleResult.environmentalEffectsEndOfLife; break;
      case 4: dto = lifeCycleResult.environmentalEffectsTotal; break;
      default: return null;
    }
    return dto[iEffect as keyof typeof dto];
  }

  protected createEffectRelevances(
    lifeCycleDataSource: LifeCycleResultDto, effectDescriptionsList: LcaEffectInfo[]
  ): EffectInfosDictionary[] {
    const effectRelevances = lifeCycleDataSource.relevantEnvironmentalEffects;
    const effectKeysList = effectRelevances ? effectRelevances.map(x => x.name) : effectDescriptionsList.map(x => x.key);
    const effectsKeysSortedByImpact: EffectInfosDictionary[] = [];
    for (const effectKey of effectKeysList) {
      const effectInfo = effectDescriptionsList.find(x => x.key === effectKey);
      const effectRelevance = effectRelevances ? effectRelevances.find(x => x.name === effectKey) : null;
      effectsKeysSortedByImpact.push({
        key: effectKey,
        impact: effectRelevance?.impact ?? 0,
        isRelevant: effectRelevance?.isRelevant,
        name: effectInfo?.name ?? '',
        unit: effectInfo?.unit ?? '',
        description: effectInfo?.description ?? '',
        unitDescription: effectInfo?.unitDescription ?? ''
      });
    }
    effectsKeysSortedByImpact.sort((a, b) => {
      return b.impact - a.impact;
    });
    const climateChangeIndex = effectsKeysSortedByImpact.findIndex(x => x.key === 'climateChange');
    ComparisonHtmlTemplateComponent.moveArrayItem(effectsKeysSortedByImpact, climateChangeIndex, 0);
    return effectsKeysSortedByImpact;
  }

  protected getChartSeriesObject(name: string, value: number): ChartSeries {
    return {
      name,
      value,
      label: value.toExponential(2),
      series: []
    };
  }
}
