import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { MaterialLayersReportDto } from 'src/app/data-transfer/entities/material-entities/material-report-dto';

@Component({
  selector: 'app-material-layers-report-template',
  templateUrl: './material-layers-report-template.component.html',
  styleUrls: ['./material-layers-report-template.component.scss']
})
export class MaterialLayersReportTemplateComponent {

  displayedColumns: string[];

  constructor(
    private translateService: TranslateService
  ) {
    const materialLayerFields = Object.getOwnPropertyNames(new MaterialLayersReportDto());
    this.displayedColumns = materialLayerFields.map(field =>
      this.translateService.instant('fieldNamesForExport.' + field));
  }
}
