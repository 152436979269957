import { PackagingUnitUsageInEvalDialogComponent } from '../../../dialogs/packaging-unit-usage-in-eval-dialog/packaging-unit-usage-in-eval-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HistoryWrapperLifeCycle } from '../../../../data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { ComparisonDataService } from '../../../../navigation/services/comparison-data-service';
import { CreditsService } from '../../../../services/credits-service';
import { SimpleConfirmDialogComponent } from '../../../dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SelectLocationDialogComponent } from '../../dialogs/select-location-dialog/select-location-dialog.component';
import { OverviewTableComponent } from '../../../shared-components/parent-components/overview-table/overview-table.component';
import { PackagingUnitTypeService } from '../../../../navigation/services/packaging-unit-type-service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogData, DeleteItemDialogComponent } from '../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { Observable, forkJoin, of } from 'rxjs';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewInit, OnInit, LOCALE_ID, Inject, ChangeDetectorRef } from '@angular/core';
import { PackagingUnitNavigationService } from 'src/app/navigation/services/navigation-services/packaging-unit-navigation.service';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { PermissionTypeDto } from 'src/app/data-transfer/entities/permission-type-dto';
import { PackagingUnitInfoDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-info-dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DeletedEntitiesDto } from 'src/app/data-transfer/entities/deleted-entities-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { UserApiService } from 'src/app/data-transfer/services/user-api-service';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { DialogActions } from 'src/app/model/dictionary';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { EditQuantitiesDialogComponent } from '../quantities/edit-quantities-dialog/edit-quantities-dialog.component';
import { EditQuantityDialogComponent } from '../quantities/edit-quantity-dialog/edit-quantity-dialog.component';

@Component({
  selector: 'app-packaging-units-overview',
  templateUrl: './packaging-units-overview.component.html',
  styleUrls: ['./packaging-units-overview.component.scss']
})
export class PackagingUnitsOverviewComponent extends OverviewTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() dataSource: MatTableDataSource<PackagingUnitDto> | MatTableDataSource<PackagingUnitInfoDto>;
  @Input() isUserValidator = false;
  @Input() isRecyclingBinSelected = false;
  @Input() displayedColumns: string[] = [];
  @Input() public analysisWrappersRec?: HistoryWrapperRecyclability[];
  @Input() public analysisWrappersLca?: HistoryWrapperLifeCycle[];
  @Input() sortByColumnName?: string;
  @Input() form: FormGroup = this.formBuilder.group({ quantityForms: this.formBuilder.array([]) });

  @Output() dataChanged = new EventEmitter();
  @Output() distributionCountryAdded = new EventEmitter();

  selectedPackagingUnits: PackagingUnitInfoDto[] = [];
  callerLevelId = PackagingPart.Unit;

  constructor(
    private packagingUnitApiService: PackagingUnitApiService,
    private analysisApiService: AnalysisApiService,
    private userApiService: UserApiService,
    private comparisonDataService: ComparisonDataService,
    private formBuilder: FormBuilder,
    private packagingUnitTypeService: PackagingUnitTypeService,
    protected dialog: MatDialog,
    protected directoryApiService: DirectoryApiService,
    protected importExportApiService: ImportExportApiService,
    protected navigationService: PackagingUnitNavigationService,
    protected translateService: TranslateService,
    protected spinner: NgxSpinnerService,
    protected creditsService: CreditsService,
    public cd: ChangeDetectorRef,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(dialog, directoryApiService, importExportApiService, navigationService, translateService, spinner, creditsService, locale);
    this.dataSource = new MatTableDataSource<PackagingUnitInfoDto>();
  }

  get quantityForms(): FormArray {
    return this.form.controls.quantityForms as FormArray;
  }

  ngOnInit(): void {
    if (this.isUserValidator) {
      this.displayedColumns.push('validation');
      this.displayedColumns.push('organizationId');
      this.displayedColumns.push('creationTimestamp');
      this.organizationsSubscription = this.userApiService.getOrganizations()
        .subscribe(organizations => this.organizations = organizations);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource != null) {
      super.setDataSourceFilter(this.dataSource);
      this.dataSourceSubscription = this.dataSource.connect().subscribe(_ => {
        this.updateDisplayTrackedColumn();
      });
      this.selectedPackagingUnits = [];
    }
  }

  updateDisplayTrackedColumn() {
    super.updateDisplayTrackedColumn(this.dataSource);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data: PackagingUnitInfoDto, sortHeaderLabel: string) => {
      let value;
      if (sortHeaderLabel === 'quantityInput') {
        value = this.form.value.quantityForms.find((quantityEntry: any) => quantityEntry.id === data.id).quantity;
      } else if (sortHeaderLabel === 'analysisId') {
        value = this.getAnalysisIdForPackaging(data.id);
      } else {
        value = data[sortHeaderLabel as keyof PackagingUnitInfoDto];
      }
      return typeof value === 'string' ? value.toLowerCase() : value;
    };
  }

  setPackagingUnitSelected(checked: boolean, item: PackagingUnitInfoDto) {
    if (checked) {
      this.selectedPackagingUnits.push(item);
    } else {
      const index = this.selectedPackagingUnits.indexOf(item);
      this.selectedPackagingUnits.splice(index, 1);
    }
    this.packagingPartsSelected.emit(this.selectedPackagingUnits);
  }

  isPackagingUnitSelected(item: PackagingUnitInfoDto) {
    if (this.selectedPackagingUnits && this.selectedPackagingUnits.length > 0) {
      return this.selectedPackagingUnits.includes(item);
    }
  }

  private openDeleteDialog(packagingUnits: PackagingUnitInfoDto[]) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('packagingUnit.deletePackagingUnitsHeader'),
      dialogText: this.translateService.instant('packagingUnit.deletePackagingUnitsText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.deletePackagingUnits(packagingUnits);
      }
    });
  }

  emptyRecyclingBin() {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingUnitsOverviewComponent: Recycling bin must be selected prior to emptying.');
      return;
    }
    this.deletePackagingUnits(this.dataSource.data);
  }

  private deletePackagingUnits(packagingUnits: PackagingUnitInfoDto[]) {
    this.spinner.show();
    const deletePermamently = this.isRecyclingBinSelected;
    const toDeleteDto = new DeletedEntitiesDto();
    toDeleteDto.deleted = [];
    for (const packaging of packagingUnits) {
      if (packaging.id != null && packaging.version != null) {
        toDeleteDto.deleted.push({ id: packaging.id, version: packaging.version });
      }
    }
    this.deleteBackendSubscription = this.packagingUnitApiService.deletePackagingUnits(toDeleteDto, deletePermamently, true).subscribe({
      next: _ => {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeletePackagingUnitSuccessData(), '500px'));
        this.dataSource.data = this.dataSource.data.filter(value => !toDeleteDto.deleted.map(x => x.id).includes(value.id ?? -1));
        this.spinner.hide();
      },
      error: e => {
        if (e.status === 403) {
          this.dialog.open(PackagingUnitUsageInEvalDialogComponent, getDialogConfig(this.getPackagingUnitUsedData(e), '500px'));
        } else {
          this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeletePackagingUnitErrorData(), '500px'));
        }
        this.spinner.hide();
      },
    });
    this.resetSelection();
    this.packagingPartsSelected.emit(this.selectedPackagingUnits);
  }

  private getDeletePackagingUnitSuccessData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.packagingUnitsDeletedSuccess')], icon: 'info'
    };
  }

  private getPackagingUnitUsedData(error: HttpErrorResponse) {
    return {
      title: this.translateService.instant('common.text.error'),
      icon: 'error',
      exceptionReasons: error.error.exceptionReasons ?? []
    };
  }

  private getDeletePackagingUnitErrorData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.packagingUnitsDeletedError')], icon: 'error'
    };
  }

  restorePackagingPart() {
    this.restorePackagingUnits(this.selectedPackagingUnits);
  }

  restoreAllPackagingUnits() {
    this.restorePackagingUnits(this.dataSource.data);
  }

  private restorePackagingUnits(packagingUnits: PackagingUnitInfoDto[]) {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingUnitsOverviewComponent: Recycling bin must be selected prior to restoring.');
      return;
    }
    for (const packagingUnit of packagingUnits) {
      this.dataSource.data = this.dataSource.data.filter(value => {
        return value.id !== packagingUnit.id;
      });
      if (packagingUnit.id != null && packagingUnit.version != null) {
        this.restoreSubscription = this.directoryApiService.restorePackagingUnitFromRecyclingBin(
          packagingUnit.id, packagingUnit.version).subscribe(_ => {
            window.location.reload();
          });
      }
    }
  }

  navigateToPackagingUnit(packagingUnit: PackagingUnitDto) {
    if (packagingUnit.packagingTypeId != null) {
      this.packagingUnitTypeService.setLastSelectedTypeId(packagingUnit.packagingTypeId);
    }
    if (packagingUnit.id != null) {
      this.navigationService.navigateToPackagingUnit(packagingUnit.id);
    }
  }

  getDirectoryNames(): string[] {
    const dirNames: string[] = [];
    if (this.dataSource.data.length > 0) {
      this.dataSource.data.map(x => {
        if (dirNames.findIndex(y => y === x.directoryName) === -1 && x.directoryName) {
          dirNames.push(x.directoryName);
        }
      });
    }
    return dirNames;
  }

  getPackagingUnitTypeNames() {
    return this.packagingUnitTypeService.getPackagingUnitTypeNames();
  }

  selectAllPackagingUnitsButtonClick() {
    this.allItemsSelected = !this.allItemsSelected;
    this.resetSelection();
    if (this.allItemsSelected) {
      for (const element of this.dataSource.filteredData) {
        this.selectedPackagingUnits.push(element);
      }
    }
    this.packagingPartsSelected.emit(this.selectedPackagingUnits);
  }

  public selectAllPackagingUnits() {
    this.resetSelection();
    for (const element of this.dataSource.filteredData) {
      this.selectedPackagingUnits.push(element);
    }
    this.packagingPartsSelected.emit(this.selectedPackagingUnits);
  }

  getTagsSettingFunction(isEditing: boolean) {
    return (id: number, tagIds: number[]) => isEditing ?
      this.packagingUnitApiService.setPackagingUnitTags(id, tagIds) :
      this.packagingUnitApiService.addPackagingUnitTags(id, tagIds);
  }

  resetSelection() {
    this.selectedPackagingUnits = [];
    this.packagingPartsSelected.emit(this.selectedPackagingUnits);
  }

  isGenericPackaging(packagingUnitTypeId: number) {
    return this.packagingUnitTypeService.isPackagingUnitTypeGeneric(packagingUnitTypeId);
  }

  movePackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedPackagingUnits.filter(pu => pu.id != null && pu.version != null).map(packagingUnit => {
        return this.directoryApiService.movePackagingUnit(result.data.id, packagingUnit.id, packagingUnit.version);
      });
      this.moveBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit());
    });
  }

  copyPackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfigFolderSelection = getDialogConfig(data, '500px');
    const dialogRefFolderSelection = this.dialog.open(SelectLocationDialogComponent, dialogConfigFolderSelection);
    this.dialogSubscription = dialogRefFolderSelection.afterClosed().subscribe(foldersResult => {
      if (foldersResult.event === DialogActions.REJECT) { this.spinner.hide(); return; }
      this.doCopy(foldersResult.data.id);
      // TODO uncomment when functionality in backend available
      /* if (!dialogConfigTrackingSelection) {
        this.doCopy(foldersResult.data.id);
      } else {
        const dialogRefTrackingSelection = this.dialog.open(CopyWithTrackingComponent, dialogConfigTrackingSelection);
        dialogRefTrackingSelection.afterClosed().subscribe(trackingResult => {
          if (trackingResult.event !== DialogActions.REJECT) {
            this.doCopy(foldersResult.data.id);
          }
        });
      } */
    });
  }

  private doCopy(folderId: number) {
    const observables = this.selectedPackagingUnits.map(packagingUnit => {
      if (packagingUnit.id != null && packagingUnit.version != null) {
        return this.directoryApiService.copyPackagingUnit(folderId, packagingUnit.id, packagingUnit.version);
      }
      return of(null);
    });
    this.copyBackendSubscription = forkJoin(observables).subscribe({ next: _ => this.dataChanged.emit(), error: _ => this.spinner.hide() });
  }

  deletePackagingPart(itemsToDelete = this.selectedPackagingUnits) {
    this.spinner.show();
    const observables = itemsToDelete.filter(packaging => packaging.id != null).map(packaging => {
      return this.packagingUnitApiService.getPackagingUnitPermissions(packaging.id);
    });
    this.permissionsSubscription = forkJoin(observables).subscribe((permissionsList: PermissionTypeDto[]) => {
      if (permissionsList.filter(x => x.delete === false).length > 0) {
        this.spinner.hide();
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteNotAllowedData(this.translateService), '500px'));
      } else {
        this.spinner.hide();
        this.openDeleteDialog(itemsToDelete);
      }
    });
  }

  exportPackagingPart() {
    this.spinner.show();
    const observables = this.selectedPackagingUnits.map(packagingUnit => {
      return this.analysisApiService.isRecyclabilityAnalysisPossible(packagingUnit.id, true, PackagingPart.Unit);
    });
    this.recSubscription = forkJoin(observables).subscribe(returnList => {
      const proceedWithExport = !returnList.includes(false);
      const dialogConfig = getDialogConfig({ packagingUnits: this.selectedPackagingUnits }, '50%');
      if (proceedWithExport) { this.doExport(dialogConfig); }
      else {
        const data: SimpleDialogData = {
          title: this.translateService.instant('common.text.warning'),
          messages: [this.translateService.instant('warnings.exportIncompletePackagingUnit')], icon: 'warning'
        };
        const confirmDialogConfig = getDialogConfig(data, '350px');
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, confirmDialogConfig);
        this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
          if (result.event === DialogActions.REJECT) {
            this.spinner.hide();
            return;
          } else {
            this.doExport(dialogConfig);
          }
        });
      }
    });
  }

  private async doExport(dialogConfig: MatDialogConfig) {
    const exportProfilesFunction = () => this.importExportApiService.getPackagingUnitExportProfiles();
    const exportFunction = (packagingId: number, principalId: number, profileId?: number) =>
      this.importExportApiService.exportPackagingUnit(packagingId, principalId, profileId);
    super.exportItems(dialogConfig, exportProfilesFunction, exportFunction);
    this.resetSelection();
  }

  getAnalysisWrappersRecForPackaging(packagingUnitId: number) {
    return this.analysisWrappersRec?.find(x => x.id === packagingUnitId) ?? undefined;
  }

  getAnalysisWrappersLcaForPackaging(packagingUnitId: number) {
    return this.analysisWrappersLca?.find(x => x.id === packagingUnitId) ?? undefined;
  }

  getAnalysisIdForPackaging(packagingUnitId: number) {
    if (this.analysisWrappersRec) {
      return this.analysisWrappersRec?.find(x => x.id === packagingUnitId)?.analysisId ?? null;
    } else {
      return this.analysisWrappersLca?.find(x => x.id === packagingUnitId)?.analysisId ?? null;
    }
  }

  downloadPackagingUnitsJson() {
    const packagingUnitsToDownload: { id: number, version: number }[] = [];
    this.selectedPackagingUnits.forEach(x => {
      if (x.id != null && x.version != null) { packagingUnitsToDownload.push({ id: x.id, version: x.version }); }
    });
    this.jsonSubscription = this.packagingUnitApiService.downloadPackagingUnitsJson(packagingUnitsToDownload).subscribe(zipFile => {
      const zipUrl = window.URL.createObjectURL(zipFile);
      window.open(zipUrl, '_blank', 'noreferrer');
    });
  }

  getPackagingUnitTypeImage(packagingUnitTypeId: number) {
    return this.packagingUnitTypeService.getPackagingUnitTypeImage(packagingUnitTypeId);
  }

  getFormControlForQuantity(element: PackagingUnitDto) {
    return (this.quantityForms.controls.find(control => control.value.id === element.id) as FormGroup).controls.quantity as FormControl;
  }

  addCountryToPackaging(packagingUnit: PackagingUnitDto) {
    this.distributionCountryAdded.emit({ id: packagingUnit.id, version: packagingUnit.version });
  }

  comparePackagingPart() {
    const observablesPermissions = this.selectedPackagingUnits.filter(packagingUnit => packagingUnit.id != null).map(packagingUnit => {
      return this.packagingUnitApiService.getPackagingUnitPermissions(packagingUnit.id);
    });
    this.permissionsSubscription = forkJoin(observablesPermissions).subscribe((permissionsList: PermissionTypeDto[]) => {
      const isComparisonPermitted = permissionsList.map(x => x.analyze).find(x => x === false) === undefined;
      if (!isComparisonPermitted) {
        this.navigationService.stopWhenAnalysisNotPermitted();
        return;
      }
      const listOfIds: number[] = this.selectedPackagingUnits.filter(x => x.id != null).map(x => x.id ?? -1);
      this.comparisonDataService.setPackagingVersionMapping(this.selectedPackagingUnits);

      this.navigationService.navigateToComparison(listOfIds, 0);
    });
  }

  editPackagingPartQuantity() {
    const dialogConfig = getDialogConfig({ packagingUnits: this.selectedPackagingUnits }, '900px');
    const dialogType: any = this.selectedPackagingUnits.length > 1 ? EditQuantitiesDialogComponent : EditQuantityDialogComponent;
    this.dialog.open(dialogType, dialogConfig);
  }
}
