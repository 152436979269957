import { AuthService } from './../../../../services/auth-service';
import { DirectoryDto } from './../../../../data-transfer/entities/directory-dto';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { DtoFolderService } from './../../services/dto-folder-service';
import { AddDirectoryDto } from './../../../../data-transfer/entities/add-directory-dto';
import { AddEditDirectoryDialogComponent } from './../../dialogs/add-edit-directory-dialog/add-edit-directory-dialog.component';
import { PermissionDto } from './../../../../data-transfer/entities/permission-dto';
import { PermissionsDialogData, PermissionsDialogComponent, PermissionMapping } from './../../dialogs/permissions-dialog/permissions-dialog.component';
import { DirectoryApiService } from '../../../../data-transfer/services/directory-api-service';
import { COMPONENT_CATEGORIES } from './../../../../navigation/services/component-type-service';
import { Router } from '@angular/router';
import { PathResolveService } from './../../../../services/path-resolve.service';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { Component, Input, Output, ViewChild, EventEmitter, OnDestroy } from '@angular/core';
import { DeleteDialogData, DeleteItemDialogComponent } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { DialogActions } from 'src/app/model/dictionary';
import { SelectLocationDialogComponent } from '../../dialogs/select-location-dialog/select-location-dialog.component';

@Component({
  selector: 'app-toolbar-directory',
  templateUrl: './toolbar-directory.component.html',
  styleUrls: ['./toolbar-directory.component.scss']
})
export class ToolbarDirectoryComponent implements OnDestroy {

  @Input() selectedPackagingPartTab = PackagingPart.System;
  @Input() selectedEntriesCount = 0;
  @Input() isAddEditAllowed = true;
  @Input() isGenericSelected = false;
  @Input() isComparisonAllowed = true;
  @Input() isRecyclingBinSelected = false;
  @Input() public selectedDtoFolder: DirectoryDto | undefined;
  @Input() public rootDtoFolder!: DirectoryDto;

  @Output() private moveClicked = new EventEmitter();
  @Output() private copyClicked = new EventEmitter();
  @Output() private exportClicked = new EventEmitter();
  @Output() private deleteClicked = new EventEmitter();
  @Output() private restoreClicked = new EventEmitter();
  @Output() private compareClicked = new EventEmitter();
  @Output() private editQuantityClicked = new EventEmitter();
  @Output() private folderUpdated = new EventEmitter();
  @Output() private folderRemoved = new EventEmitter();
  @Output() private editTagsClicked = new EventEmitter();
  @Output() private addTagsClicked = new EventEmitter();
  @Output() private downloadJsonClicked = new EventEmitter();

  @ViewChild(MatMenu, { static: true }) createMenu!: MatMenu;

  isUserAdmin: boolean;
  componentCategories = COMPONENT_CATEGORIES;
  packagingPartEnum = PackagingPart;
  private standardDialogWidth = '500px';

  private principalsSubscription?: Subscription;
  private setPermissionsSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private addFolderSubscription?: Subscription;
  private renameFolderSubscription?: Subscription;
  private moveFolderSubscription?: Subscription;
  private deleteFolderSubscription?: Subscription;
  private tagsSubscription?: Subscription;

  constructor(
    private router: Router,
    private directoryApiService: DirectoryApiService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private dtoFolderService: DtoFolderService,
    private spinner: NgxSpinnerService,
    private authService: AuthService
  ) {
    this.isUserAdmin = this.authService.isUserAdmin();
  }

  movePackagingPart() { this.moveClicked.emit(); }

  copyPackagingPart() { this.copyClicked.emit(); }

  exportPackagingPart() { this.exportClicked.emit(); }

  deletePackagingPart() { this.deleteClicked.emit(); }

  restorePackagingPart() { this.restoreClicked.emit(); }

  comparePackagingPart() { this.compareClicked.emit(); }

  editPackagingPartQuantity() { this.editQuantityClicked.emit(); }

  editPackagingPartTags() { this.editTagsClicked.emit(); }

  addPackagingPartTags() { this.addTagsClicked.emit(); }

  downloadPackagingPart() { this.downloadJsonClicked.emit(); }

  addFolder() {
    if (!this.selectedDtoFolder) { return; }
    const selectedFolder: DirectoryDto = this.selectedDtoFolder;
    const dialogConfig = getDialogConfig({ action: DialogActions.ADD }, this.standardDialogWidth);
    const dialogRef = this.dialog.open(AddEditDirectoryDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const nameExistsInParent = this.dtoFolderService.dtoFolderNameExistsInParent(result.data.directoryName, selectedFolder);
      if (!nameExistsInParent) {
        const addedDirectory: AddDirectoryDto = {
          name: result.data.directoryName,
          parentDirectoryId: selectedFolder.id
        };
        this.spinner.show();
        this.addFolderSubscription = this.directoryApiService.createDirectory(addedDirectory).subscribe({
          next: newDirId => {
            const newFolder: DirectoryDto = {
              id: newDirId,
              name: result.data.directoryName,
              ownerPrincipalId: selectedFolder.ownerPrincipalId,
              childDirectories: [],
            };
            selectedFolder.childDirectories.push(newFolder);
            this.folderUpdated.emit();
            this.spinner.hide();
          },
          error: e => {
            console.log(e.error);
            this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.serverErrorDialogData()));
            this.spinner.hide();
          },
        });
      } else {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.nameExistsDialogData()));
      }
    });
  }

  renameFolder() {
    if (!this.selectedDtoFolder) { return; }
    const selectedFolder: DirectoryDto = this.selectedDtoFolder;
    const dialogConfig = getDialogConfig({
      action: DialogActions.RENAME,
      directoryName: selectedFolder.name
    }, this.standardDialogWidth);
    const dialogRef = this.dialog.open(AddEditDirectoryDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const parentOfSelectedFolder = this.dtoFolderService.getDtoFolderParent(this.rootDtoFolder, selectedFolder.id);
      const nameExistsInParent = this.dtoFolderService.dtoFolderNameExistsInParent(result.data.directoryName, parentOfSelectedFolder);
      if (!nameExistsInParent) {
        this.renameFolderSubscription = this.directoryApiService.renameDirectory(
          selectedFolder.id, result.data.directoryName)
          .subscribe(_ => {
            selectedFolder.name = result.data.directoryName;
          });
      } else {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.nameExistsDialogData()));
      }
    });
  }

  moveFolder() {
    if (!this.selectedDtoFolder) { return; }
    const selectedFolder: DirectoryDto = this.selectedDtoFolder;
    const parentOfSelectedFolder = this.dtoFolderService.getDtoFolderParent(this.rootDtoFolder, selectedFolder.id);
    const allChildren = this.dtoFolderService.getAllChildren(selectedFolder);
    const unreachableFolders = [selectedFolder, parentOfSelectedFolder].concat(allChildren);
    const dialogConfig = getDialogConfig({
      rootFolder: this.rootDtoFolder,
      unreachableFolders
    }, this.standardDialogWidth);
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const targetDtoFolder = result.data;
      const nameExistsInTarget = this.dtoFolderService.dtoFolderNameExistsInParent(selectedFolder.name, targetDtoFolder);
      if (!nameExistsInTarget) {
        this.moveFolderSubscription = this.directoryApiService.moveDirectory(selectedFolder.id, targetDtoFolder.id)
          .subscribe(_ => {
            const dtoParentOfSelectedFolder = this.dtoFolderService.getDtoFolderParent(this.rootDtoFolder, selectedFolder.id);
            this.dtoFolderService.moveDtoFolder(dtoParentOfSelectedFolder, targetDtoFolder, selectedFolder);
            this.folderUpdated.emit();
          });
      } else {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.nameExistsDialogData()));
      }
    });
  }

  removeFolder() {
    if (!this.selectedDtoFolder) { return; }
    const selectedFolder: DirectoryDto = this.selectedDtoFolder;
    const dialogConfig = getDialogConfig(this.confirmDeleteDialogData(), this.standardDialogWidth);
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      this.spinner.show();
      this.deleteFolderSubscription = this.directoryApiService.deleteDirectory(selectedFolder.id).subscribe({
        next: _ => {
          this.folderRemoved.emit();
          this.spinner.hide();
        },
        error: e => {
          if (e.status === 403) {
            this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.deletionImpossibleDialogData(), '350px'));
          }
          this.spinner.hide();
        },
      });
    });
  }

  private deletionImpossibleDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('packagingUnit.messages.componentInUse')],
      icon: 'error'
    };
  }

  private confirmDeleteDialogData(): DeleteDialogData {
    return {
      dialogHeader: `${this.translateService.instant(
        'dataManagement.directory.dialog.removeFolder.title')} ${this.selectedDtoFolder?.name ?? ''}`,
      dialogText: this.translateService.instant('dataManagement.directory.dialog.removeFolder.confirmation')
    };
  }

  private nameExistsDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.warning'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.warnings.nameAlreadyExists')], icon: 'warning'
    };
  }

  private serverErrorDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('errors.serverCommunicationError')], icon: 'error'
    };
  }

  createPackagingSystem() {
    this.router.navigate([PathResolveService.getCreatePackagingSystemPath(true)]);
  }

  createPackagingUnit() {
    this.router.navigate([PathResolveService.getCreatePackagingUnitPath(true)]);
  }

  createPackagingComponent(componentTypeId: number) {
    const componentLabel = COMPONENT_CATEGORIES.find(x => x.key === componentTypeId)?.label ?? '';
    const path = PathResolveService.getCreateComponentPath(true, componentLabel);
    this.router.navigate([path]);
  }

  createCompositeMaterial() {
    this.router.navigate([PathResolveService.getCreateMaterialPath(true)]);
  }

  openPermissionsDialog() {
    if (!this.selectedDtoFolder) { return; }
    const directoryId = this.selectedDtoFolder.id;
    this.principalsSubscription = this.directoryApiService.getSecurityPrincipals().subscribe(
      allPrincipals => {
        const permissionsDialogData: PermissionsDialogData = {
          directoryId,
          principals: allPrincipals
        };
        const dialogConfig = getDialogConfig(permissionsDialogData, this.standardDialogWidth);
        const dialogRef = this.dialog.open(PermissionsDialogComponent, dialogConfig);
        this.dialogSubscription = dialogRef.componentInstance.permissionsApplied
          .subscribe((permissionsMappings: PermissionMapping[]) => {
            this.setPermissionsInBackend(permissionsMappings, directoryId);
          });
        this.dialogSubscription = dialogRef.afterClosed().subscribe(permissionsMapping => {
          if (permissionsMapping.event === DialogActions.REJECT) { return; }
          this.setPermissionsInBackend(permissionsMapping.data, directoryId);
        });
      });
  }

  private setPermissionsInBackend(permissionsMappings: PermissionMapping[], directoryId: number) {
    const oblervables: Observable<any>[] = [];
    for (const permissionMapping of permissionsMappings) {
      const permission: PermissionDto = {
        directoryId,
        principalId: permissionMapping.principalId,
        deny: permissionMapping.deny,
        permissionType: permissionMapping.permissions
      };
      oblervables.push(this.directoryApiService.setPermission(permission));
    }
    this.setPermissionsSubscription = forkJoin(oblervables).subscribe();
  }

  ngOnDestroy(): void {
    this.principalsSubscription?.unsubscribe();
    this.setPermissionsSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.addFolderSubscription?.unsubscribe();
    this.renameFolderSubscription?.unsubscribe();
    this.moveFolderSubscription?.unsubscribe();
    this.deleteFolderSubscription?.unsubscribe();
  }
}
