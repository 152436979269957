<div class="tag-container">
  <mat-chip-listbox [selectable]="false" #tagList>
    <mat-chip *ngFor="let tag of tagsToDisplay" class="tag-chip" [selectable]="false" [disableRipple]="true"
      [ngStyle]="{'background-color': tag?.colorCode}" [matTooltip]="tag.description ?? ''" matTooltipPosition="above">
      <span class="tag-text">{{ tag?.name }}</span>
    </mat-chip>
    <button *ngIf="showMore" class="small-icon-button" mat-icon-button
      [matMenuTriggerFor]="menu"><mat-icon>more_horiz</mat-icon></button>
  </mat-chip-listbox>
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <div class="tag-menu-container tag-container">
    <mat-chip-listbox [selectable]="false" class="chip-menu">
      <mat-chip *ngFor="let tag of tags" class="tag-chip" [selectable]="false" [disableRipple]="true"
        [ngStyle]="{'background-color': tag?.colorCode}" [matTooltip]="tag.description ?? ''" matTooltipPosition="above">
        <span class="tag-text">{{ tag?.name }}</span>
      </mat-chip>
    </mat-chip-listbox>
  </div>
</mat-menu>
