<h1 mat-dialog-title *ngIf="action===dialogActions.ADD">{{ 'material.addMaterial' | translate }}</h1>
<h1 mat-dialog-title *ngIf="action===dialogActions.EDIT">{{ 'material.editMaterial' | translate }}</h1>

<mat-dialog-content class="dialog-content">

  <form [formGroup]="addEditMaterialForm">
    <div class="flex-container">
      <mat-card class="material-card">
        <mat-card-content class="inner-container">
          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.function' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.function' | translate }}</mat-label>
            <mat-select required formControlName="functionId">
              <mat-option *ngFor="let func of allMaterialFunctions" [value]="func.id">
                {{func.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.functionId.errors">
              <span *ngIf="formControls.functionId.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.material' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.material' | translate }}</mat-label>
            <mat-select required formControlName="materialId">
              <mat-option *ngFor="let mat of validMaterials" [value]="mat.id">
                {{mat.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.materialId.errors">
              <span *ngIf="formControls.materialId.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.manifestation' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.manifestation' | translate }}</mat-label>
            <mat-select formControlName="materialManifestationId" required>
              <mat-option *ngFor="let manifestation of validManifestations" [value]="manifestation.id">
                {{manifestation.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.materialManifestationId.errors">
              <span *ngIf="formControls.materialManifestationId.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.manufacturingType' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.manufacturingType' | translate }}
            </mat-label>
            <mat-select required formControlName="manufacturingTypeId">
              <mat-option *ngFor="let type of validManufacturingTypes" [value]="type.id">
                {{type.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.manufacturingTypeId.errors">
              <span *ngIf="formControls.manufacturingTypeId.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field
            [ngClass]="defaultRecyclatePercentage || !isRecyclateAllowed ? 'form-field-button-icon' : 'form-field-button'"
            matTooltip="{{ 'material.layer.tooltips.recyclatePercentage' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.recyclatePercentage' | translate }}
            </mat-label>
            <input required matInput type="number" formControlName="recyclatePercentage" min="0" max="100"
              autocomplete="off">
            <mat-error *ngIf="formControls.recyclatePercentage.errors">
              <span *ngIf="formControls.recyclatePercentage.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
              <span *ngIf="formControls.recyclatePercentage.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0%'} }}
              </span>
              <span *ngIf="formControls.recyclatePercentage.errors.max">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeGreaterThan' | translate: {value: '100%'} }}
              </span>
            </mat-error>
          </mat-form-field>
          <button class="icon-right" mat-icon-button [disabled]="!isResetRecyclatDefaultValueAllowed()" (click)="resetRecyclatDefaultValue()" type="button"
            matTooltip="{{ 'material.layer.tooltips.resetRecyclate' | translate: {percentage: getResetValue()} }}">
            <mat-icon>
              undo
            </mat-icon>
          </button>
          <mat-icon *ngIf="!isRecyclateAllowed && defaultRecyclatePercentage" class="icon-right"
            matTooltip="{{ 'material.layer.tooltips.recyclateNotAllowedDefaultAvailable' | translate: {percentage: defaultRecyclatePercentage} }}">
            notifications_active
          </mat-icon>
          <mat-icon *ngIf="isRecyclateAllowed && defaultRecyclatePercentage" class="icon-right"
            matTooltip="{{ 'material.layer.tooltips.defaultRecyclatePercentage' | translate: {percentage: defaultRecyclatePercentage} }}">
            notifications_active
          </mat-icon>
          <mat-icon *ngIf="!isRecyclateAllowed && !defaultRecyclatePercentage" class="icon-right"
            matTooltip="{{ 'material.layer.tooltips.recyclateNotAllowed' | translate }}">
            notifications_active
          </mat-icon>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.color' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.color' | translate }}</mat-label>
            <mat-select required formControlName="colorId">
              <mat-option *ngFor="let color of validColors" [value]="color.id">
                {{color.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formControls.colorId.errors">
              <span *ngIf="formControls.colorId.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <mat-card class="material-card">
        <mat-card-content class="inner-container">
          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.originCountry' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.originCountry' | translate }}
            </mat-label>
            <mat-select formControlName="materialOriginCountryCode">
              <mat-option>{{ 'common.text.noSelection' | translate }}</mat-option>
              <mat-option *ngFor="let country of handler.manufacturingCountries" [value]="country.code">
                {{country.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.grammage' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.grammage' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="grammage" min="0.001" autocomplete="off">
            <mat-error *ngIf="formControls.grammage.errors">
              <span *ngIf="formControls.grammage.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.thickness' | translate }}">
            <mat-label class="label-large">
              {{ 'material.layer.thickness' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="thickness" min="0.001" autocomplete="off">
            <mat-error *ngIf="formControls.thickness.errors">
              <span *ngIf="formControls.thickness.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.density' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.density' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="density" min="0.001" autocomplete="off" required>
            <mat-error *ngIf="formControls.density.errors">
              <span *ngIf="formControls.density.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.percentage' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.percentage' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="massPercentage" min="0" max="100" autocomplete="off">
            <mat-error *ngIf="formControls.massPercentage.errors">
              <span *ngIf="formControls.massPercentage.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0%'} }}
              </span>
              <span *ngIf="formControls.massPercentage.errors.max">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeGreaterThan' | translate: {value: '100%'} }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.layer.tooltips.mass' | translate }}">
            <mat-label class="label-large">{{ 'material.layer.mass' | translate }}
            </mat-label>
            <input [required]="isMassRequired" matInput type="number" formControlName="mass" autocomplete="off">
            <mat-error *ngIf="formControls.mass.errors">
              <span *ngIf="formControls.mass.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
              <span *ngIf="formControls.mass.errors.min">
                {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
              </span>
            </mat-error>
          </mat-form-field>

          <div>
            {{ 'material.layer.massCalculator.calculateLabel' | translate }}
            <button mat-raised-button *ngIf="!showCalculator; else closeCalculator" (click)="toggleCalculator()"
              [disabled]="!isWeightAllowed || preventEditing || !canEditForm" color="primary" type="button">
              {{ 'material.layer.massCalculator.openCalculatorButton' | translate }}
              <mat-icon>arrow_right</mat-icon>
            </button>
            <ng-template #closeCalculator>
              <button mat-raised-button (click)="toggleCalculator()"
                [disabled]="!isWeightAllowed || preventEditing || !canEditForm" color="primary" type="button">
                <mat-icon>arrow_left</mat-icon>
                {{ 'material.layer.massCalculator.closeCalculatorButton' | translate }}
              </button>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card class="material-card" *ngIf="showCalculator">
        <mat-card-content class="inner-container">
          <app-mass-calculator [totalWeight]="totalWeight"
            [calculatorTotalWeight]="calculatorTotalWeight" [totalGrammage]="totalGrammage"
            [layerGrammage]="formControls.grammage.value" [layerThickness]="formControls.thickness.value"
            [layerDensity]="formControls.density.value" [layerPercentage]="formControls.massPercentage.value"
            [callerId]="callerId" (layerMassCalculated)="layerMassCalculated($event)"
            (calculatorTotalMassChange)="calculatorTotalMassChanged($event)"
            (calculatorLayerGrammageChange)="calculatorLayerGrammageChanged($event)"
            (calculatorLayerThicknessChange)="calculatorLayerThicknessChanged($event)"
            (calculatorLayerDensityChange)="calculatorLayerDensityChange($event)"
            (calculatorLayerPercentageChange)="calculatorLayerPercentageChange($event)">
          </app-mass-calculator>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-card *ngIf="allLayersTableDataSource.data.length > 0" class="full-width">
      <mat-card-header>
        <mat-card-title>
          <h5>{{ 'material.layersTableHeader' | translate }}</h5>
        </mat-card-title>
      </mat-card-header>
      <app-material-layers-view [dataSource]="allLayersTableDataSource"></app-material-layers-view>
    </mat-card>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="action===dialogActions.ADD" (click)="doAction()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.add' | translate }}</button>
  <button *ngIf="action===dialogActions.EDIT" (click)="doAction()" mat-raised-button type="submit" color="primary"
    [disabled]="!canEditForm">{{ 'common.buttons.save' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
