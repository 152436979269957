<table mat-table *ngIf="rowSpansComponentsTable" [dataSource]="componentsTableDataSource"
  class="mat-elevation-z8 table-wide" id="components-table">
  <ng-container *ngFor="let column of dataColumnsComponents; let iCol = index" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef [class]="column.columnDef" class="header">
      {{ column.header }}
    </th>
    <td mat-cell *matCellDef="let row; let iRow = index" class="cell"
      [attr.rowspan]="rowSpansComponentsTable[iCol][iRow].span"
      [style.display]="rowSpansComponentsTable[iCol][iRow].span === 0 ? 'none'  : ''"
      [style.border-top]="rowSpansComponentsTable[iCol][iRow].border">
      <div [ngSwitch]="column.columnDef">
        <ng-container *ngSwitchCase="'rating'">
          <div class="circle" [style.background]="column.color(row)" [style.color]="column.fontColor(row)"
            [matTooltip]="column.tooltip(row)">
            {{ column.cell(row) }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'recyclingStream'">
          <div class="icon-text-container" *ngIf="column.cell(row)===''">
            {{ 'analysis.recyclabilityAnalysis.noInformation' | translate }}
            <mat-icon class="gray-icon-18" [matTooltip]="column.recyclingStreamTooltip(row)">info
            </mat-icon>
          </div>
          {{ column.cell(row) }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ column.cell(row) }}
        </ng-container>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumnsComponents"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsComponents"></tr>
</table>