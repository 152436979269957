<div class="normalization-container">
  <mat-form-field class="select-criterion-dropdown">
    <mat-label>{{ 'analysis.lifecycleAnalysis.normCriterionSelection' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedCriterion" (ngModelChange)="setCriterionUnits($event)">
      <mat-option *ngFor="let criterion of normalizationCriteria" [value]="criterion">
        {{ criterion.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="selectedCriterion && selectedCriterion.label !== 'none'" class="select-criterion-value">
    <mat-label>{{ 'analysis.lifecycleAnalysis.normValue' | translate }}</mat-label>
    <input matInput type="number" [(ngModel)]="normalizationFactor" autocomplete="off">
  </mat-form-field>
  <mat-form-field class="select-unit-dropdown"
    *ngIf="selectedCriterion && selectedCriterion.label !== 'none' && selectedCriterion.label !== 'quantity'">
    <mat-label>{{ 'analysis.lifecycleAnalysis.unitsSelection' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedUnit">
      <mat-option *ngFor="let unit of criterionUnits" [value]="unit">
        {{ unit }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-raised-button color="primary" (click)="doNormalize()" class="custom-button" type="button"
    [disabled]="selectedCriterion == undefined || (selectedCriterion.label !== 'none' && normalizationFactor == undefined)">
    {{ 'analysis.lifecycleAnalysis.normButton' | translate }}
  </button>
  <span class="hint margin" *ngIf="selectedCriterion && actualVolume && selectedCriterion.label === 'volume'">
    {{ 'analysis.lifecycleAnalysis.actualVolume' | translate }}: {{ actualVolume }} ml
  </span>
  <span class="hint margin" *ngIf="selectedCriterion && actualWeight && selectedCriterion.label === 'weight'">
    {{ 'analysis.lifecycleAnalysis.actualWeight' | translate }}: {{ actualWeight }} g
  </span>
</div>
