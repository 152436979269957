<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.authorizations.title' | translate }}</h1>

<mat-dialog-content>
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
    <mat-tab label="{{ 'dataManagement.manageOrganizations.authorizations.countryAuthorizations' | translate }}">
      <table mat-table [dataSource]="countryAuthorizationsDataSource" id="country-authorizations-table" matSort (matSortChange)="sortCountryData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.country' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{countryNameByCode(element.countryCode)}}</td>
        </ng-container>
        <ng-container matColumnDef="authorizationStart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.from' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{dateToString(element.authorizationStart)}}</td>
        </ng-container>
        <ng-container matColumnDef="authorizationEnd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.to' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{dateToString(element.authorizationEnd)}}</td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.bought">
              <button mat-icon-button color="primary" (click)="editCountryAuthorization(element)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="deleteCountryAuthorization(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!element.bought">
              {{ 'dataManagement.manageOrganizations.authorizations.inherited' | translate }}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columns;" [class.expired]="row.isExpired"></tr>
      </table>
    </mat-tab>

    <mat-tab label="{{ 'dataManagement.manageOrganizations.authorizations.environmentalEffects' | translate }}">
      <table mat-table [dataSource]="environmentalEffectAuthorizationsDataSource" id="environmental-effect-authorizations-table" matSort (matSortChange)="sortEnvironmentalEffectsData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.environmentalEffect' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{environmentalEffectNameById(element.environmentalEffectId)}}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.analysisType' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{environmentalEffecttype(element.analysisType)}}</td>
        </ng-container>
        <ng-container matColumnDef="authorizationStart">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.from' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{dateToString(element.authorizationStart)}}</td>
        </ng-container>
        <ng-container matColumnDef="authorizationEnd">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.authorizations.to' | translate }}
          </th>
          <td mat-cell *matCellDef="let element">{{dateToString(element.authorizationEnd)}}</td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.bought">
              <button mat-icon-button color="primary" (click)="editEnvironmentalEffectAuthorization(element)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button color="primary" (click)="deleteEnvironmentalEffectAuthorization(element)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

            <div *ngIf="!element.bought">
              {{ 'dataManagement.manageOrganizations.authorizations.inherited' | translate }}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsEnvironmentalEffect; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsEnvironmentalEffect;" [class.expired]="row.isExpired"></tr>
      </table>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>

<mat-dialog-actions id="buttons-container">
  <button mat-raised-button type="submit" color="primary" (click)="onSuccess()" [disabled]="isSaveButtonDisabled">
    {{ 'common.buttons.save' | translate }}
  </button>

  <button (click)="closeDialog()" mat-raised-button type="button">{{ 'common.buttons.cancel' | translate }}</button>

  <button mat-mini-fab color="primary" class="add-button" (click)="onAddAuthorization()">
    <mat-icon>add</mat-icon>
  </button>

</mat-dialog-actions>

