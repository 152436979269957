import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagingSystemExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { PLASTIC_TAX_COUNTRIES } from 'src/app/model/expense-countries';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ExpenseCalculationParentComponent } from '../expense-calculation-parent/expense-calculation-parent.component';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';

@Component({
  selector: 'app-plastic-tax-packaging-system',
  templateUrl: './plastic-tax-packaging-system.component.html',
  styleUrls: ['./plastic-tax-packaging-system.component.scss']
})
export class PlasticTaxPackagingSystemComponent extends ExpenseCalculationParentComponent implements OnInit {

  plasticTaxResult!: PackagingSystemExpenseResultDto[];
  packagingSystem!: PackagingSystemDto;

  constructor(private route: ActivatedRoute) { super(); }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.plasticTaxResult = data.plasticTaxResult;
      this.packagingSystem = this.plasticTaxResult[0].analyzedPackagingSystem;
      this.packagingPartWeight = super.getPackagingSystemWeight(this.packagingSystem);
      this.countriesAvailability = PLASTIC_TAX_COUNTRIES;
      this.packagingPartType = PackagingPart.System;
    });
  }
}