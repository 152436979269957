export class PackagingCollectiveQuantityDto {
    packagingSystemQuantities: PackagingQuantityDto[] = [];
    packagingUnitQuantities: PackagingQuantityDto[] = [];
}

export class PackagingQuantityDto {
    packagingUnitId?: number;
    country = '';
    month = -1;
    year = -1;
    quantity = -1;
}
