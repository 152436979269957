import { ComponentRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-recyclability-result-dto';
import { RecyclabilityLabelData } from '../../util/analyses-util/recyclability/recyclability-label-data';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';

export interface RecyclabilityDataSource {
  name: string;
  value: number;
  label: string;
  components: RecyclabilityComponentDataSource[];
}

export interface RecyclabilityComponentDataSource {
  name: string;
  weightPercentage: number;
  analyzedComponent: RecyclabilityResultDto;
}

export class RecyclabilityResult {

  packagingPartId?: number;
  packagingPartVersion?: number;
  packagingPartName!: string;
  packagingPartDistributionCountries!: string[];
  analysisId?: string;
  ratingA!: number;
  ratingB!: number;
  ratingC!: number;
  ratingD!: number;
  ratingX!: number;
  ratingUnknown!: number;
  totalWeight!: number;
  totalRecyclateWeight!: number;
  evaluatedCountry!: string;
  evaluatedCountryCode!: string;
  recyclableTotal!: number;
  nonRecyclableTotal!: number;
  dataSource: RecyclabilityDataSource[] = [];
  validationState!: number;
  isRecPossible = false;

  protected labelData!: RecyclabilityLabelData;

  constructor(
    private packagingPart: PackagingSystemRecyclabilityResultDto | PackagingUnitRecyclabilityResultDto | ComponentRecyclabilityResultDto
  ) {
    this.analysisId = this.packagingPart.id;
    this.ratingA = this.packagingPart.percentageRatingA;
    this.ratingB = this.packagingPart.percentageRatingB;
    this.ratingC = this.packagingPart.percentageRatingC;
    this.ratingD = this.packagingPart.percentageRatingD;
    this.ratingX = this.packagingPart.percentageRatingX;
    this.ratingUnknown = this.packagingPart.percentageRatingUnknown;
    this.totalWeight = this.packagingPart.totalWeight;
    this.totalRecyclateWeight = this.packagingPart.totalRecyclateWeight;
    this.evaluatedCountryCode = this.packagingPart.evaluatedCountry;
    this.recyclableTotal = +(this.packagingPart.percentageRatingA + this.packagingPart.percentageRatingB).toFixed(2);
    this.nonRecyclableTotal = +(100 - this.recyclableTotal).toFixed(2);
    this.validationState = this.packagingPart.validationState;
  }

  protected createHighchartDataSource(components: RecyclabilityResultDto[]) {
    const highchartDataSource: RecyclabilityDataSource[] = [];
    let totalWeight = 0;
    components.forEach(x => totalWeight = totalWeight + x.totalWeight);

    let ratingA = 0;
    let ratingB = 0;
    let ratingC = 0;
    let ratingD = 0;
    let ratingX = 0;
    let ratingUnknown = 0;
    components.forEach(x => ratingA = ratingA + x.percentageRatingA / totalWeight * x.totalWeight);
    components.forEach(x => ratingB = ratingB + x.percentageRatingB / totalWeight * x.totalWeight);
    components.forEach(x => ratingC = ratingC + x.percentageRatingC / totalWeight * x.totalWeight);
    components.forEach(x => ratingD = ratingD + x.percentageRatingD / totalWeight * x.totalWeight);
    components.forEach(x => ratingX = ratingX + x.percentageRatingX / totalWeight * x.totalWeight);
    components.forEach(x => ratingUnknown = ratingUnknown + x.percentageRatingUnknown / totalWeight * x.totalWeight);

    const listOfRatings = [];
    listOfRatings.push({ name: 'A', value: ratingA, components: components.filter(x => x.percentageRatingA !== 0) });
    listOfRatings.push({ name: 'B', value: ratingB, components: components.filter(x => x.percentageRatingB !== 0) });
    listOfRatings.push({ name: 'C', value: ratingC, components: components.filter(x => x.percentageRatingC !== 0) });
    listOfRatings.push({ name: 'D', value: ratingD, components: components.filter(x => x.percentageRatingD !== 0) });
    listOfRatings.push({ name: 'X', value: ratingX, components: components.filter(x => x.percentageRatingX !== 0) });
    listOfRatings.push({ name: '?', value: ratingUnknown, components: components.filter(x => x.percentageRatingUnknown !== 0) });

    for (const rating of listOfRatings) {
      const componentsDataSource: RecyclabilityComponentDataSource[] = [];
      for (const component of rating.components) {
        const name = '';
        const weightPercentage = +((component.totalWeight / totalWeight) * 100).toFixed(2);
        componentsDataSource.push({ name, weightPercentage, analyzedComponent: component });
      }
      highchartDataSource.push({
        name: this.labelData.getLabel(rating.name) ?? '',
        label: rating.name,
        value: +rating.value.toFixed(2),
        components: componentsDataSource
      });
    }
    return highchartDataSource;
  }
}
