import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TagDto } from '../entities/tag-dto';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TagApiService extends ParentApiService {

  constructor(private http: HttpClient) {
    super();
  }

  public getAllTags(): Observable<TagDto[]> {
    const url = `${environment.webApiAddress}/api/tags`;
    return this.http.get<TagDto[]>(url);
  }

  public getTagById(): Observable<TagDto> {
    const url = `${environment.webApiAddress}/api/tags/by-id`;
    return this.http.get<TagDto>(url);
  }

  public saveTag(tag: TagDto, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/tags`;
    return this.http.put(url, tag, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deleteTag(tagId: number, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/tags?id=${tagId}`;
    return this.http.delete<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }
}
