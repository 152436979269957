<h1 mat-dialog-title>{{ 'packagingSystem.packagingSystem' | translate }}</h1>

<mat-dialog-content>
  <app-packaging-systems-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
    [isUserValidator]="false" (packagingPartsSelected)="packagingSystemsSelected($event)">
  </app-packaging-systems-overview>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="importPackagingSystems()" mat-raised-button type="submit" color="primary"
    [disabled]="selectedPackagingSystems.length === 0">
    {{ 'common.buttons.import' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
