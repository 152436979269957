import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopNumberScrolling]'
})
export class StopScrollingDirective {

  constructor() { }

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.disableScroll(event);
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.disableScroll(event);
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.disableScroll(event);
  }

  disableScroll(event: any) {
    if (event.srcElement.type === 'number') {
      event.srcElement.blur();
    }
  }
}
