import { getDialogConfig } from 'src/app/util/dialog-util';
import { EnvironmentHelperService } from './../../../services/environment-helper.service';
import { COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import { ColorThemeService } from '../../../navigation/services/color-theme-service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, LoginResponse } from '../../../services/auth-service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '../../../services/snackbar.service';
import { LanguageHandler } from 'src/app/util/language-handler';
import { UiVersion } from './../../../model/ui-version';
import { ChangelogDialogComponent } from '../../dialogs/changelog-dialog/changelog-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  private returnUrl = '';
  public loginForm!: FormGroup;
  public countryCodes!: string[];
  public latestUiVersion?: UiVersion;
  languageHandler: LanguageHandler;
  imageSource = '';
  buttonDisabled = true;
  spinnerActive = false;
  isDarkTheme = false;

  externalLogins?: string[] = undefined;

  private statusSubscription?: Subscription;
  private versionsubscription?: Subscription;
  private themeSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private colorThemeService: ColorThemeService,
    private environmentService: EnvironmentHelperService,
    private dialog: MatDialog
  ) {
    this.languageHandler = new LanguageHandler(this.translateService);
  }

  async ngOnInit() {
    this.countryCodes = this.languageHandler.getLanguageCodes();
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') || '/';
    const loginError = this.route.snapshot.queryParamMap.get('loginError');
    if (loginError != null) {
      this.handleLoginError(loginError);
    }

    this.loginForm = this.formBuilder.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.statusSubscription = this.loginForm.statusChanges.subscribe(_ => {
      this.buttonDisabled = this.field.login.value === '' || this.field.password.value === '';
    });
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = `${PROJECT_IMAGES_PATH}logo/logo_paco${this.isDarkTheme ? '_light' : '_dark'}.svg`;
    });
    this.versionsubscription = this.environmentService.getUiVersions().subscribe(versions => {
      this.latestUiVersion = versions.sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime())[0];
    });

    this.authService.getExternalLogins().subscribe(_ => {
      this.externalLogins = _;
    });
  }

  get field() {
    return this.loginForm.controls;
  }

  public async signInAzureAd(provider: string) {
    this.spinnerActive = true;
    this.buttonDisabled = true;
    this.authService.signInAzureAd(provider);
  }

  public async signIn() {
    this.spinnerActive = true;
    this.buttonDisabled = true;
    const loginResponse: LoginResponse = await this.authService.signIn(this.field.login.value, this.field.password.value);

    if (loginResponse === LoginResponse.OtherError) {
      this.snackbarService.showError('login.errors.unknown');
    }
    if (loginResponse === LoginResponse.UserUnknown) {
      this.field.login.markAsTouched();
      this.field.login.setErrors({ unknownUser: true });
    }
    if (loginResponse === LoginResponse.PasswordInvalid) {
      this.field.password.markAsTouched();
      this.field.password.setErrors({ wrongPassword: true });
    }
    if (loginResponse === LoginResponse.OK) {
      await this.router.navigateByUrl(this.returnUrl);
      const lastLogin = this.authService.getLastLogin();
      if (this.latestUiVersion && lastLogin && lastLogin < this.latestUiVersion.releaseDate) {
        const dialogConfig = getDialogConfig(this.latestUiVersion, '800px');
        setTimeout(() => this.dialog.open(ChangelogDialogComponent, dialogConfig));
      }
    }
    this.spinnerActive = false;
    this.buttonDisabled = false;
  }

  setLanguage(countryCode: string) {
    this.languageHandler.setLanguage(countryCode);
  }

  ngOnDestroy() {
    this.statusSubscription?.unsubscribe();
    this.versionsubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
  }

  private handleLoginError(loginError: string) {
    if (loginError != null && loginError !== '') {
      const words = loginError.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
      // Remove spaces and special characters from string
      let newString = words.join(" ").replace(/[^A-Z0-9]/ig, "");
      newString = newString.charAt(0).toLowerCase() + newString.slice(1);
      const translateString = 'login.errors.'+ newString;
      const translatedWords = this.translateService.instant(translateString);
      if (translatedWords === translateString) {
        this.snackbarService.showError(loginError);
      } else {
        this.snackbarService.showError(translatedWords);
      }
    }
  }
}
