<div [formGroup]="form" id="flex-container"
  [ngClass]="isPartOfMaterialImport ? 'flex-container-row' : 'flex-container-column'">

  <div id="data-container">
    <div [ngClass]="isPartOfMaterialImport ? 'cards-container' : 'grid-container-double'">
      <mat-card class="input-card">
        <mat-card-content class="inner-container">
          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.articleName' | translate }}">
            <mat-label class="label-large">{{ 'material.fields.articleName' | translate }}
            </mat-label>
            <input matInput formControlName="articleName" type="text" autocomplete="off">
            <mat-error *ngIf="form.controls.articleName.errors">
              <span *ngIf="form.controls.articleName.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.articleNumber' | translate }}">
            <mat-label class="label-large">
              {{ 'material.fields.articleNumber' | translate }}
            </mat-label>
            <input matInput formControlName="articleNumber" type="text" autocomplete="off">
            <mat-error *ngIf="form.controls.articleNumber.errors">
              <span *ngIf="form.controls.articleNumber.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.manufacturer' | translate }}">
            <mat-label class="label-large">{{ 'material.fields.manufacturer' | translate }}
            </mat-label>
            <input matInput formControlName="manufacturerName" type="text" autocomplete="off">
            <mat-error *ngIf="form.controls.manufacturerName.errors">
              <span *ngIf="form.controls.manufacturerName.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.manufacturingCountry' | translate }}">
            <mat-label class="label-large">
              {{ 'material.fields.manufacturingCountry' | translate }}
            </mat-label>
            <mat-select formControlName="manufacturingCountry" required>
              <mat-option *ngFor="let country of handler.manufacturingCountries" [value]="country.code">
                {{country.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.manufacturingCountry.errors">
              <span *ngIf="form.controls.manufacturingCountry.errors.required">
                {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
              </span>
            </mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>
      <mat-card class="input-card">
        <mat-card-content class="inner-container">
          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.gtin' | translate }}">
            <mat-label class="label-large">{{ 'material.fields.gtin' | translate }}</mat-label>
            <input matInput formControlName="gtin" type="text" autocomplete="off">
            <mat-error *ngIf="form.controls.gtin.errors">
              <span *ngIf="form.controls.gtin.errors.gtinError">
                {{ 'dataManagement.userData.userDialog.validation.gtinError' | translate }}
              </span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.totalGrammage' | translate }}">
            <mat-label class="label-large">
              {{ 'material.fields.totalGrammage' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="totalGrammage" autocomplete="off">
          </mat-form-field>

          <mat-form-field class="form-field-wide"
            matTooltip="{{ 'material.fields.tooltips.totalWeight' | translate }}">
            <mat-label class="label-large">
              {{ 'material.fields.totalWeight' | translate }}
            </mat-label>
            <input matInput type="number" formControlName="totalWeight" autocomplete="off">
          </mat-form-field>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
