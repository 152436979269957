import { Observable, Subscription } from 'rxjs';
import { ReportApiService } from 'src/app/data-transfer/services/report-api-service';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MenuPositionX } from '@angular/material/menu';
import { VersionDto } from 'src/app/data-transfer/entities/version-dto';
import { ComponentApiService } from 'src/app/data-transfer/services/component-api-service';
import { MaterialApiService } from 'src/app/data-transfer/services/material-api-service';
import { AnalysisMenuCaller } from '../../shared-components/analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { ReportGenerationDialogComponent } from '../report-generation-dialog/report-generation-dialog.component';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingUnitApiService } from 'src/app/data-transfer/services/packaging-unit-api-service';

@Component({
  selector: 'app-reports-menu-for-packaging-part',
  templateUrl: './reports-menu-for-packaging-part.component.html',
  styleUrls: ['./reports-menu-for-packaging-part.component.scss']
})
export class ReportsMenuForPackagingPartComponent implements OnInit, OnDestroy {

  @ViewChild(MatMenu, { static: true }) reportsMenu!: MatMenu;

  @Input() callerItemId? = -1;
  @Input() callerPage!: number;
  @Input() callerLevel!: number;

  menuDirectionX: MenuPositionX = 'before';
  versions: VersionDto[] = [];
  existingReports: any[] = [];

  existingReportsLoaded?: Promise<boolean>;
  versionFieldName!: string;

  private versionsSubscription?: Subscription;
  private reportsSubscription?: Subscription;
  private downloadSubscription?: Subscription;

  constructor(
    private packagingUnitApiService: PackagingUnitApiService,
    private componentApiService: ComponentApiService,
    private materialApiService: MaterialApiService,
    private reportApiService: ReportApiService,
    private dialog: MatDialog
  ) {
    this.existingReportsLoaded = Promise.resolve(false);
  }

  ngOnInit(): void {
    this.menuDirectionX = this.callerPage === AnalysisMenuCaller.Homepage ? 'before' : 'after';
    switch (this.callerLevel) {
      case PackagingPart.Unit: this.versionFieldName = 'packagingUnitVersion'; break;
      case PackagingPart.Component: this.versionFieldName = 'packagingComponentVersion'; break;
      default: this.versionFieldName = ''; break;
    }
  }

  loadReports() {
    this.loadCallerItemVersions();
    this.loadExistingReports();
  }

  private loadCallerItemVersions() {
    if (this.callerItemId == null || this.callerItemId === -1) { return; }
    switch (this.callerLevel) {
      case PackagingPart.System: break;
      case PackagingPart.Unit: {
        this.versionsSubscription = this.packagingUnitApiService.getPackagingUnitHistoryVersions(this.callerItemId)
          .subscribe(versions => this.versions = versions);
        break;
      }
      case PackagingPart.Component: {
        this.versionsSubscription = this.componentApiService.getComponentHistoryVersions(this.callerItemId)
          .subscribe(versions => this.versions = versions);
        break;
      }
      case PackagingPart.Material: {
        this.versionsSubscription = this.materialApiService.getCompositeMaterialHistoryVersions(this.callerItemId)
          .subscribe(versions => this.versions = versions);
        break;
      }
      default: break;
    }
  }

  private loadExistingReports() {
    let observableReports: Observable<any> | null = null;
    switch (this.callerLevel) {
      case PackagingPart.System: break;
      case PackagingPart.Unit: {
        observableReports = this.reportApiService.getAllPackagingUnitReportsById(this.callerItemId);
        break;
      }
      case PackagingPart.Component: {
        observableReports = this.reportApiService.getAllComponentReportsById(this.callerItemId);
        break;
      }
      case PackagingPart.Material: break;
      default: break;
    }
    if (!observableReports) { return; }
    this.reportsSubscription = observableReports.subscribe(reports => {
      this.existingReports = reports;
      this.existingReportsLoaded = Promise.resolve(true);
    });
  }

  private async getReportTemplates() {
    switch (this.callerLevel) {
      case PackagingPart.System: break;
      case PackagingPart.Unit: {
        return this.reportApiService.getAllPackagingUnitReportTemplates().toPromise();
      }
      case PackagingPart.Component: {
        return this.reportApiService.getAllComponentReportTemplates().toPromise();
      }
      case PackagingPart.Material: break;
      default: break;
    }
  }

  async createReport(version: VersionDto) {
    if (version.versionNumber == null) { return; }
    const reportTemplates = await this.getReportTemplates();
    const data = {
      callerLevel: this.callerLevel, id: this.callerItemId, version: version.versionNumber,
      reportTemplates, countries: version.distributionCountries
    };
    this.dialog.open(ReportGenerationDialogComponent, getDialogConfig(data, '600px'));
  }

  viewReport(reportId: number) {
    let observable: Observable<any> | null = null;
    switch (this.callerLevel) {
      case PackagingPart.System: break;
      case PackagingPart.Unit: {
        observable = this.reportApiService.downloadPackagingUnitReport(reportId);
        break;
      }
      case PackagingPart.Component: {
        observable = this.reportApiService.downloadComponentReport(reportId);
        break;
      }
      case PackagingPart.Material: break;
      default: break;
    }
    if (!observable) { return; }
    this.downloadSubscription = observable.subscribe(reportPdf => {
      const pdfUrl = window.URL.createObjectURL(reportPdf);
      window.open(pdfUrl, '_blank');
    });
  }

  ngOnDestroy(): void {
    this.versionsSubscription?.unsubscribe();
    this.reportsSubscription?.unsubscribe();
    this.downloadSubscription?.unsubscribe();
  }
}
