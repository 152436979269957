import { CompositeMaterialExportProfileDto } from './../../../../../data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PackagingComponentExportProfileDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';

@Injectable({ providedIn: 'root' })
export class FormToDtoService {

  getPackagingSystemDtoFromForm(form: FormGroup, defaultUnitProfileId?: number): PackagingSystemExportProfileDto {
    const formValue = JSON.parse(JSON.stringify(form.value));
    const profileDto: PackagingSystemExportProfileDto = formValue;

    const profileData = formValue.profileData;
    profileDto.id = profileData.id;
    profileDto.profileName = profileData.profileName;
    profileDto.owningOrganizationId = profileData.owningOrganizationId;
    profileDto.owningOrganizationName = profileData.owningOrganizationName;
    profileDto.isDefaultProfile = profileData.isDefaultProfile;
    delete formValue.profileData;

    const productInfo = formValue.productInfo;
    profileDto.brandName = productInfo.brandName;
    profileDto.productName = productInfo.productName;
    profileDto.articleNumber = productInfo.articleNumber;
    profileDto.gtin = productInfo.gtin;
    delete formValue.productInfo;

    const manufacturingInfo = formValue.manufacturingInfo;
    profileDto.distributionCountries = manufacturingInfo.distributionCountries;
    profileDto.assemblyCountry = manufacturingInfo.assemblyCountry;
    profileDto.length = manufacturingInfo.length;
    profileDto.width = manufacturingInfo.width;
    profileDto.height = manufacturingInfo.height;
    delete formValue.manufacturingInfo;

    const exportImages = formValue.images.exportImages;
    profileDto.images.imageId = exportImages;
    profileDto.images.index = exportImages;
    profileDto.images.isMainImage = exportImages;
    profileDto.images.name = exportImages;

    const exportDocuments = formValue.documents.exportDocuments;
    profileDto.documents.documentId = exportDocuments;
    profileDto.documents.index = exportDocuments;
    profileDto.documents.name = exportDocuments;

    profileDto.defaultPackagingUnitExportProfileId = defaultUnitProfileId;

    return profileDto;
  }

  getPackagingUnitDtoFromForm(form: FormGroup): PackagingUnitExportProfileDto {
    const formValue = JSON.parse(JSON.stringify(form.value));
    const profileDto: PackagingUnitExportProfileDto = formValue;

    const profileData = formValue.profileData;
    profileDto.id = profileData.id;
    profileDto.profileName = profileData.profileName;
    profileDto.owningOrganizationId = profileData.owningOrganizationId;
    profileDto.owningOrganizationName = profileData.owningOrganizationName;
    profileDto.isDefaultProfile = profileData.isDefaultProfile;
    delete formValue.profileData;

    const productInfo = formValue.productInfo;
    profileDto.brandName = productInfo.brandName;
    profileDto.productName = productInfo.productName;
    profileDto.articleNumber = productInfo.articleNumber;
    profileDto.gtin = productInfo.gtin;
    delete formValue.productInfo;

    const fillingGoodInfo = formValue.fillingGoodInfo;
    profileDto.fillingGoodType = fillingGoodInfo.fillingGoodType;
    profileDto.fillingGoodCategory = fillingGoodInfo.fillingGoodCategory;
    profileDto.fillingGood = fillingGoodInfo.fillingGood;
    profileDto.packagingQuantity = fillingGoodInfo.packagingQuantity;
    profileDto.packagingVolume = fillingGoodInfo.packagingVolume;
    delete formValue.fillingGoodInfo;

    const manufacturingInfo = formValue.manufacturingInfo;
    profileDto.distributionCountries = manufacturingInfo.distributionCountries;
    profileDto.assemblyCountry = manufacturingInfo.assemblyCountry;
    profileDto.length = manufacturingInfo.length;
    profileDto.width = manufacturingInfo.width;
    profileDto.height = manufacturingInfo.height;
    delete formValue.manufacturingInfo;

    const mainBody = formValue.mainBody;
    profileDto.mainBody.manufacturer = mainBody.manufacturingInfo.manufacturer;
    profileDto.mainBody.articleName = mainBody.manufacturingInfo.articleName;
    profileDto.mainBody.articleNumber = mainBody.manufacturingInfo.articleNumber;
    profileDto.mainBody.manufacturingCountry = mainBody.manufacturingInfo.manufacturingCountry;
    profileDto.mainBody.gtin = mainBody.manufacturingInfo.gtin;
    profileDto.mainBody.printingCoverage = mainBody.manufacturingInfo.printingCoverage;
    profileDto.mainBody.isRigidComponent = mainBody.manufacturingInfo.isRigidComponent;
    profileDto.mainBody.containsNearInfraredBarrier = mainBody.manufacturingInfo.containsNearInfraredBarrier;
    profileDto.mainBody.packagingVolume = mainBody.manufacturingInfo.packagingVolume;
    profileDto.mainBody.length = mainBody.massInfo.length;
    profileDto.mainBody.width = mainBody.massInfo.width;
    profileDto.mainBody.height = mainBody.massInfo.height;
    profileDto.mainBody.multiMaterial.totalGrammage = mainBody.massInfo.totalGrammage;
    const mainBodyLayers = formValue.mainBody.multiMaterial;
    profileDto.mainBody.multiMaterial.layers = this.getPackagingComponentMultiMaterialLayers(mainBodyLayers);
    this.setTotalGrammage(profileDto.mainBody, mainBody.massInfo);
    delete formValue.mainBody.manufacturingInfo;
    delete formValue.mainBody.massInfo;

    const closures = formValue.closures;
    this.setPackagingComponentDtoFromForm(profileDto.closures, closures);
    profileDto.closures.isRigidComponent = closures.productInfo.isRigidComponent;
    this.setTotalGrammage(profileDto.closures, closures.manufacturingInfo);
    delete formValue.closures.productInfo;
    delete formValue.closures.manufacturingInfo;

    const inlays = formValue.inlays;
    this.setPackagingComponentDtoFromForm(profileDto.inlays, inlays);
    profileDto.inlays.isRigidComponent = inlays.productInfo.isRigidComponent;
    profileDto.inlays.isAdhesiveUsed = inlays.manufacturingInfo.isAdhesiveUsed;
    profileDto.inlays.removabilityCondition = inlays.manufacturingInfo.removabilityCondition;
    profileDto.inlays.isPhysicallyAttached = inlays.manufacturingInfo.isPhysicallyAttached;
    this.setTotalGrammage(profileDto.inlays, inlays.manufacturingInfo);
    delete formValue.inlays.productInfo;
    delete formValue.inlays.manufacturingInfo;

    const packagingAids = formValue.packagingAids;
    this.setPackagingComponentDtoFromForm(profileDto.packagingAids, packagingAids);
    profileDto.packagingAids.isRigidComponent = packagingAids.productInfo.isRigidComponent;
    this.setTotalGrammage(profileDto.packagingAids, packagingAids.manufacturingInfo);
    delete formValue.packagingAids.productInfo;
    delete formValue.packagingAids.manufacturingInfo;

    const decorations = formValue.decorations;
    profileDto.labels = decorations.labels;
    this.setPackagingComponentDtoFromForm(profileDto.labels, decorations.labels);
    profileDto.labels.surfaceRatio = decorations.labels.productInfo.surfaceRatio;
    profileDto.labels.isAdhesiveUsed = decorations.labels.manufacturingInfo.isAdhesiveUsed;
    profileDto.labels.removabilityCondition = decorations.labels.manufacturingInfo.removabilityCondition;
    profileDto.sleeves = decorations.sleeves;
    this.setPackagingComponentDtoFromForm(profileDto.sleeves, decorations.sleeves);
    profileDto.sleeves.surfaceRatio = decorations.sleeves.productInfo.surfaceRatio;
    profileDto.inMoldLabels = decorations.inMoldLabels;
    this.setPackagingComponentDtoFromForm(profileDto.inMoldLabels, decorations.inMoldLabels);
    profileDto.inMoldLabels.surfaceRatio = decorations.inMoldLabels.productInfo.surfaceRatio;
    this.setTotalGrammage(profileDto.labels, decorations.labels.manufacturingInfo);
    this.setTotalGrammage(profileDto.sleeves, decorations.sleeves.manufacturingInfo);
    this.setTotalGrammage(profileDto.inMoldLabels, decorations.inMoldLabels.manufacturingInfo);
    delete formValue.decorations.labels.productInfo;
    delete formValue.decorations.labels.manufacturingInfo;
    delete formValue.decorations.sleeves.productInfo;
    delete formValue.decorations.sleeves.manufacturingInfo;
    delete formValue.decorations.inMoldLabels.productInfo;
    delete formValue.decorations.inMoldLabels.manufacturingInfo;
    delete formValue.decorations;

    const exportImages = formValue.images.exportImages;
    profileDto.images.imageId = exportImages;
    profileDto.images.index = exportImages;
    profileDto.images.isMainImage = exportImages;
    profileDto.images.name = exportImages;

    const exportDocuments = formValue.documents.exportDocuments;
    profileDto.documents.documentId = exportDocuments;
    profileDto.documents.index = exportDocuments;
    profileDto.documents.name = exportDocuments;

    profileDto.printingCoverage = true;

    profileDto.labels.isRigidComponent = true;
    profileDto.sleeves.isRigidComponent = true;
    profileDto.inMoldLabels.isRigidComponent = true;

    return profileDto;
  }

  private setPackagingComponentDtoFromForm(componentDto: any, component: any) {
    componentDto.articleName = component.productInfo.articleName;
    componentDto.articleNumber = component.productInfo.articleNumber;
    componentDto.gtin = component.productInfo.gtin;
    componentDto.printingCoverage = component.productInfo.printingCoverage;
    componentDto.isRigidComponent = component.productInfo.isRigidComponent;
    componentDto.containsNearInfraredBarrier = component.productInfo.containsNearInfraredBarrier;
    componentDto.manufacturer = component.manufacturingInfo.manufacturer;
    componentDto.manufacturingCountry = component.manufacturingInfo.manufacturingCountry;
    componentDto.length = component.manufacturingInfo.length;
    componentDto.width = component.manufacturingInfo.width;
    componentDto.height = component.manufacturingInfo.height;
    componentDto.removedByConsumer = component.manufacturingInfo.removedByConsumer ?? null;
    componentDto.removedBeforeUsage = component.manufacturingInfo.removedBeforeUsage ?? null;
    componentDto.multiMaterial.layers = this.getPackagingComponentMultiMaterialLayers(component.multiMaterial);
  }

  /**
   * Total grammage is saved in multiMaterial, but displayed in the component
   */
  private setTotalGrammage(componentDto: any, component: any) {
    componentDto.multiMaterial.totalGrammage = component.totalGrammage;
  }

  private getPackagingComponentMultiMaterialLayers(component: any) {
    const returnMultiMaterial = {
      function: component.materialInfo.function,
      material: component.materialInfo.material,
      materialManifestation: component.materialInfo.materialManifestation,
      manufacturingType: component.materialInfo.manufacturingType,
      recyclingMaterialPercentage: component.materialInfo.recyclatePercentage,
      color: component.materialInfo.color,
      grammage: component.massInfo.grammage,
      thickness: component.massInfo.thickness,
      density: component.massInfo.density,
      massPercentage: component.massInfo.massPercentage,
      mass: component.massInfo.mass
    };
    delete component.materialInfo;
    delete component.massInfo;
    return returnMultiMaterial;
  }

  getComponentDtoFromForm(form: FormGroup): PackagingComponentExportProfileDto {
    const formValue = JSON.parse(JSON.stringify(form.value));
    const profileDto: PackagingComponentExportProfileDto = formValue;

    const profileData = formValue.profileData;
    profileDto.id = profileData.id;
    profileDto.profileName = profileData.profileName;
    profileDto.owningOrganizationId = profileData.owningOrganizationId;
    profileDto.owningOrganizationName = profileData.owningOrganizationName;
    profileDto.isDefaultProfile = profileData.isDefaultProfile;

    const productInfo = formValue.productInfo;
    profileDto.articleName = productInfo.articleName;
    profileDto.articleNumber = productInfo.articleNumber;
    profileDto.gtin = productInfo.gtin;
    profileDto.printingCoverage = productInfo.printingCoverage;
    profileDto.containsNearInfraredBarrier = productInfo.containsNearInfraredBarrier;

    const manufacturingInfo = formValue.manufacturingInfo;
    profileDto.distributionCountries = manufacturingInfo.distributionCountries;
    profileDto.manufacturer = manufacturingInfo.manufacturer;
    profileDto.manufacturingCountry = manufacturingInfo.manufacturingCountry;
    profileDto.length = manufacturingInfo.length;
    profileDto.width = manufacturingInfo.width;
    profileDto.height = manufacturingInfo.height;

    const multiMaterial = formValue.multiMaterial;
    profileDto.multiMaterial.layers = this.getPackagingComponentMultiMaterialLayers(multiMaterial);
    profileDto.multiMaterial.totalGrammage = true;
    this.setTotalGrammage(profileDto, formValue.manufacturingInfo);

    const exportImages = formValue.images.exportImages;
    profileDto.images.imageId = exportImages;
    profileDto.images.index = exportImages;
    profileDto.images.isMainImage = exportImages;
    profileDto.images.name = exportImages;

    const exportDocuments = formValue.documents.exportDocuments;
    profileDto.documents.documentId = exportDocuments;
    profileDto.documents.index = exportDocuments;
    profileDto.documents.name = exportDocuments;

    profileDto.isRigidComponent = true;

    return profileDto;
  }

  getMaterialDtoFromForm(form: FormGroup): CompositeMaterialExportProfileDto {
    const formValue = JSON.parse(JSON.stringify(form.value));
    const profileDto: CompositeMaterialExportProfileDto = formValue;

    const profileData = formValue.profileData;
    profileDto.id = profileData.id;
    profileDto.profileName = profileData.profileName;
    profileDto.owningOrganizationId = profileData.owningOrganizationId;
    profileDto.owningOrganizationName = profileData.owningOrganizationName;
    profileDto.isDefaultProfile = profileData.isDefaultProfile;

    const productInfo = formValue.productInfo;
    profileDto.articleName = productInfo.articleName;
    profileDto.articleNumber = productInfo.articleNumber;
    profileDto.manufacturerName = productInfo.manufacturer;
    profileDto.manufacturingCountry = productInfo.manufacturingCountry;

    const manufacturingInfo = formValue.manufacturingInfo;
    profileDto.gtin = manufacturingInfo.gtin;
    profileDto.totalGrammage = manufacturingInfo.totalGrammage;

    const layers = formValue.layers;
    profileDto.layers = this.getPackagingComponentMultiMaterialLayers(layers);

    const exportImages = formValue.images.exportImages;
    profileDto.images.imageId = exportImages;
    profileDto.images.index = exportImages;
    profileDto.images.isMainImage = exportImages;
    profileDto.images.name = exportImages;

    const exportDocuments = formValue.documents.exportDocuments;
    profileDto.documents.documentId = exportDocuments;
    profileDto.documents.index = exportDocuments;
    profileDto.documents.name = exportDocuments;

    return profileDto;
  }
}
