import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { RecyclabilityResult } from './recyclability-result';
import { ComponentRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-recyclability-result-dto';
import { RecyclabilityLabelData } from 'src/app/util/analyses-util/recyclability/recyclability-label-data';

export class RecyclabilityResultPackagingUnit extends RecyclabilityResult {

  componentResults: ComponentRecyclabilityResultDto[];

  constructor(
    private recResultDto: PackagingUnitRecyclabilityResultDto,
    protected labelData: RecyclabilityLabelData,
    public countryName: string
  ) {
    super(recResultDto);
    const unit = this.recResultDto.analyzedPackagingUnit;
    this.packagingPartId = unit.id;
    this.packagingPartVersion = unit.version ?? 0;
    this.packagingPartName = `${unit.brandName}/${unit.productName}`;
    this.packagingPartDistributionCountries = unit.distributionCountries;
    this.evaluatedCountry = this.countryName;
    this.componentResults = this.recResultDto.componentResults;
    this.labelData = labelData;
    this.setDataSourceComponents(this.componentResults);
  }

  private setDataSourceComponents(componentResults: ComponentRecyclabilityResultDto[]) {
    if (!componentResults) { return; }
    this.dataSource = super.createHighchartDataSource(componentResults);
    this.dataSource.forEach(x => x.components.forEach(component => {
      const analyzedPackagingPart = (component.analyzedComponent as ComponentRecyclabilityResultDto).analyzedPackagingComponent;
      component.name = `${analyzedPackagingPart.packagingComponentTypeName}
      (${analyzedPackagingPart.packagingComponentSubtypeName},
      ${analyzedPackagingPart.multiMaterial.layers[0]?.materialManifestationName})`;
    }));
  }
}
