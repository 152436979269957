import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { ChangeMaterialCandidateDto } from '../../../../../data-transfer/entities/material-entities/change-material-candidate-dto';
import { ImportMaterialCandidateDto } from '../../../../../data-transfer/entities/material-entities/import-material-candidate-dto';
import { MatSort } from '@angular/material/sort';
import { ImportComponentCandidateDto } from '../../../../../data-transfer/entities/component-entities/import-component-candidate-dto';
import { ChangeComponentCandidateDto } from '../../../../../data-transfer/entities/component-entities/change-component-candidate-dto';
import { ChangePackagingUnitCandidateDto } from '../../../../../data-transfer/entities/packaging-unit-entities/change-packaging-unit-candidate-dto';
import { ImportPackagingUnitCandidateDto } from '../../../../../data-transfer/entities/packaging-unit-entities/import-packaging-unit-candidate-dto';
import { ChangeCandidateDto } from './../../../../../data-transfer/entities/change-candidate-dto';
import { ImportCandidateDto } from './../../../../../data-transfer/entities/import-candidate-dto';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { MatTableDataSource } from '@angular/material/table';
import { ImportPackagingSystemCandidateDto } from 'src/app/data-transfer/entities/packaging-system-entities/import-packaging-system-candidate-dto';
import { ChangePackagingSystemCandidateDto } from 'src/app/data-transfer/entities/packaging-system-entities/change-packaging-system-candidate-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';

@Component({
  selector: 'app-import-html',
  templateUrl: './import-html.component.html',
  styleUrls: ['./import-html.component.scss']
})
export class ImportHtmlComponent {

  @Input() displayedColumns: string[] = [];
  @Input() selectedCandidates: ImportCandidateDto[] | ChangeCandidateDto[] = [];
  @Input() isPackagingSystem = false;
  @Input() isPackagingUnit = false;
  @Input() isComponent = false;
  @Input() isMaterial = false;
  @Input() isChange = false;

  @Output() candidatesSelected = new EventEmitter();
  @Output() navigationClicked = new EventEmitter();

  @ViewChild(MatSort) sort!: MatSort;

  mappedDataSource!: MatTableDataSource<ImportCandidateDto | ChangeCandidateDto>;
  allCandidatesSelected = false;

  sortingDataAccessor = (item: any, property: any) => {
    switch (property) {
      case 'exportingOrganization': return item.exportingOrganization.name;
      default: return item[property];
    }
  }

  setDataSource(updatedDataSource: MatTableDataSource<ImportCandidateDto> | MatTableDataSource<ChangeCandidateDto>) {
    this.mappedDataSource = new MatTableDataSource<ImportCandidateDto | ChangeCandidateDto>();
    for (const entry of updatedDataSource.data) {
      let mappedEntry: ImportCandidateDto | ChangeCandidateDto;
      if (this.isChange) {
        mappedEntry = this.setDataSourceChange(entry as ChangeCandidateDto) as ChangeCandidateDto;
      } else {
        mappedEntry = this.setDataSourceImport(entry as ImportCandidateDto) as ImportCandidateDto;
      }
      if (!mappedEntry) { return; }
      for (const columnName of this.displayedColumns) {
        if (columnName === 'itemId') {
          mappedEntry['itemId' as keyof typeof mappedEntry] = this.getField(entry, 'id');
        } else {
          mappedEntry[columnName as keyof typeof mappedEntry] = this.getField(entry, columnName);
        }
      }
      mappedEntry.exportingOrganization = entry.exportingOrganization;
      this.mappedDataSource.data.push(mappedEntry);
    }
    this.mappedDataSource.sort = this.sort;
    this.mappedDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  private setDataSourceImport(entry: ImportCandidateDto) {
    let mappedEntry;
    if (this.isPackagingSystem) {
      mappedEntry = new ImportPackagingSystemCandidateDto();
      mappedEntry.packagingSystem = (entry as ImportPackagingSystemCandidateDto).packagingSystem;
    } else if (this.isPackagingUnit) {
      mappedEntry = new ImportPackagingUnitCandidateDto();
      mappedEntry.packagingUnit = (entry as ImportPackagingUnitCandidateDto).packagingUnit;
    } else if (this.isComponent) {
      mappedEntry = new ImportComponentCandidateDto();
      mappedEntry.packagingComponent = (entry as ImportComponentCandidateDto).packagingComponent;
    } else if (this.isMaterial) {
      mappedEntry = new ImportMaterialCandidateDto();
      mappedEntry.multiMaterial = (entry as ImportMaterialCandidateDto).multiMaterial;
    }
    if (!mappedEntry) { return; }
    mappedEntry.id = entry.id;
    return mappedEntry;
  }

  private setDataSourceChange(entry: ChangeCandidateDto) {
    let mappedEntry;
    if (this.isPackagingSystem) {
      mappedEntry = new ChangePackagingSystemCandidateDto();
      mappedEntry.existingPackagingSystem = (entry as ChangePackagingSystemCandidateDto).existingPackagingSystem;
      mappedEntry.updatedPackagingSystem = (entry as ChangePackagingSystemCandidateDto).updatedPackagingSystem;
    } else if (this.isPackagingUnit) {
      mappedEntry = new ChangePackagingUnitCandidateDto();
      mappedEntry.existingPackagingUnit = (entry as ChangePackagingUnitCandidateDto).existingPackagingUnit;
      mappedEntry.updatedPackagingUnit = (entry as ChangePackagingUnitCandidateDto).updatedPackagingUnit;
    } else if (this.isComponent) {
      mappedEntry = new ChangeComponentCandidateDto();
      mappedEntry.existingPackagingComponent = (entry as ChangeComponentCandidateDto).existingPackagingComponent;
      mappedEntry.updatedPackagingComponent = (entry as ChangeComponentCandidateDto).updatedPackagingComponent;
    } else if (this.isMaterial) {
      mappedEntry = new ChangeMaterialCandidateDto();
      mappedEntry.existingMultiMaterial = (entry as ChangeMaterialCandidateDto).existingMultiMaterial;
      mappedEntry.updatedMultiMaterial = (entry as ChangeMaterialCandidateDto).updatedMultiMaterial;
    }
    if (!mappedEntry) { return; }
    mappedEntry.importCandidateId = entry.importCandidateId;
    return mappedEntry;
  }

  navigateToCandidate(candidate: any, navigateToCurrentVersion?: boolean) {
    this.navigationClicked.emit({ candidate, navigateToCurrentVersion });
  }

  selectAll() {
    this.allCandidatesSelected = !(this.selectedCandidates.length === this.mappedDataSource.data.length);
    this.selectedCandidates = [];
    if (this.allCandidatesSelected) {
      for (const element of this.mappedDataSource.data) {
        this.isChange ? (this.selectedCandidates as ChangeCandidateDto[]).push(element as ChangeCandidateDto) :
          (this.selectedCandidates as ImportCandidateDto[]).push(element as ImportCandidateDto);
      }
    }
    this.candidatesSelected.emit(this.selectedCandidates);
  }

  isSelectedCandidate(candidate: any) {
    if (this.selectedCandidates && this.selectedCandidates.length > 0) {
      return this.selectedCandidates.includes(candidate);
    }
  }

  setSelectedCandidates(checked: boolean, candidate: any) {
    if (checked) {
      this.selectedCandidates.push(candidate);
    } else {
      const index = this.selectedCandidates.indexOf(candidate);
      this.selectedCandidates.splice(index, 1);
    }
    this.candidatesSelected.emit(this.selectedCandidates);
  }

  private getField(candidate: any, fieldName: string): any {
    if (this.isPackagingSystem) {
      return this.isChange ?
        (candidate as ChangePackagingSystemCandidateDto).updatedPackagingSystem[fieldName as keyof PackagingSystemDto] :
        (candidate as ImportPackagingSystemCandidateDto).packagingSystem[fieldName as keyof PackagingSystemDto];
    } else if (this.isPackagingUnit) {
      return this.isChange ?
        (candidate as ChangePackagingUnitCandidateDto).updatedPackagingUnit[fieldName as keyof PackagingUnitDto] :
        (candidate as ImportPackagingUnitCandidateDto).packagingUnit[fieldName as keyof PackagingUnitDto];
    } else if (this.isComponent) {
      return this.isChange ?
        (candidate as ChangeComponentCandidateDto).updatedPackagingComponent[fieldName as keyof PackagingComponentDto] :
        (candidate as ImportComponentCandidateDto).packagingComponent[fieldName as keyof PackagingComponentDto];
    } else if (this.isMaterial) {
      return this.isChange ?
        (candidate as ChangeMaterialCandidateDto).updatedMultiMaterial[fieldName as keyof MultiMaterialCompositeDto] :
        (candidate as ImportMaterialCandidateDto).multiMaterial[fieldName as keyof MultiMaterialCompositeDto];
    }
  }
}
