import { UserDto } from '../entities/user-dto';
import { UserApiService } from '../services/user-api-service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { UserGroupDto } from '../entities/user-group-dto';
import { BillingAccountDto } from '../entities/billing-account-dto';
import { OrganizationDto } from '../entities/organization-dto';
import { CountryDto } from '../entities/country-dto';

export const usersResolver: ResolveFn<UserDto[]> = () => {
    return inject(UserApiService).getUsers();
};

export const userGroupsResolver: ResolveFn<UserGroupDto[]> = () => {
    return inject(UserApiService).getAllUserGroups();
};

export const billingAccountsResolver: ResolveFn<BillingAccountDto[]> = () => {
    return inject(UserApiService).getBillingAccounts();
};

export const organizationsResolver: ResolveFn<OrganizationDto[]> = () => {
    return inject(UserApiService).getOrganizations();
};