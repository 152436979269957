import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-contact-info-dialog',
  templateUrl: './contact-info-dialog.component.html',
  styleUrls: ['./contact-info-dialog.component.scss']
})
export class ContactInfoDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<ContactInfoDialogComponent>,
    private translateService: TranslateService
  ) {
  }

  doConfirm() {
    this.dialogRef.close({ event: DialogActions.CONFIRM });
  }

  getEmailText(translationString:string) : string{
    return "mailto:"+this.translateService.instant(translationString)
  }
}
