import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';
import { LifeCycleResultPackagingUnit } from 'src/app/model/evaluations/life-cycle-result-packaging-unit';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { EffectInfosDictionary } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { NormalizationComponent, NormalizationData } from '../normalization/normalization.component';
import { LifeCycleComparisonChartComponent } from '../../analyses/charts/life-cycle-comparison-chart/life-cycle-comparison-chart.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ComparisonLcaRowDefinition, ComparisonRowDefinition } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { ECOINVENT_VERSION } from 'src/app/model/life-cycle-data-information';
import { TranslateService } from '@ngx-translate/core';
import { ColorGradientCalculator } from 'src/app/util/color-gradient-calculator';
import { LifeCycleResult } from 'src/app/model/evaluations/life-cycle-result';
import { Subscription } from 'rxjs';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';
import {LicenseService} from 'src/app/services/licensing-service'
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';
import {DomSanitizer} from '@angular/platform-browser';
import {CountryDto} from 'src/app/data-transfer/entities/country-dto'
import {BuyType} from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'


class ComparisonTableColumn {
  columnDef = '';
  header = '';
  cell = (_: ComparisonRowDefinition | ComparisonLcaRowDefinition) => '';
  color = (_: ComparisonRowDefinition | ComparisonLcaRowDefinition) => '';
  tooltip = (_: ComparisonRowDefinition | ComparisonLcaRowDefinition) => '';
}

@Component({
  selector: 'app-comparison-html-template',
  templateUrl: './comparison-html-template.component.html',
  styleUrls: ['./comparison-html-template.component.scss']
})
export class ComparisonHtmlTemplateComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('normForm') normForm!: NormalizationComponent;
  @ViewChild('lcaChart') lcaChart!: LifeCycleComparisonChartComponent;

  @Input() recDataSource!: (RecyclabilityResultPackagingSystem | RecyclabilityResultPackagingUnit)[][];
  @Input() lcaDataSource!: (LifeCycleResultPackagingSystem | LifeCycleResultPackagingUnit)[][];
  @Input() analysesDataLoaded!: Promise<boolean>;
  @Input() recTableData: ComparisonRowDefinition[][] = [];
  @Input() lcaTableData: ComparisonLcaRowDefinition[][] = [];
  @Input() newLcaAllowed: boolean[] = [];
  @Input() volumeAvailable = false;
  @Input() weightAvailable = false;

  @Output() createRecAnalysis = new EventEmitter();
  @Output() createLcaAnalysis = new EventEmitter();

  private normalizationData: NormalizationData | null = null;

  recDataColumns: ComparisonTableColumn[] = [];
  lcaDataColumns: ComparisonTableColumn[] = [];
  recColumnHeaders: string[] = [];
  lcaColumnHeaders: string[] = [];
  effectsList: EffectInfosDictionary[][] = [];
  selectedEffectKey = '';
  selectedEffectUnit = '';
  ecoinventVersion = ECOINVENT_VERSION;
  isDarkTheme!: boolean;

  showBuyText: boolean = false;
  emailString!: string;

  allowedEffects : EnvironmentalEffectDto[] = [];
  allowedCountries: CountryDto[] = [];

  buyType = BuyType;

  private selectedCountryTabIndex = 0;
  private selectedEffectIndex = -1;

  private themeSubscription?: Subscription;

  private static onlyUnique = ((value: any, index: number, self: string | any[]) => self.indexOf(value) === index);
  static moveArrayItem = ((arr: any[], fromIndex: number, toIndex: number) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  });

  constructor(
    private translateService: TranslateService,
    protected colorThemeService: ColorThemeService,
    protected licenseService: LicenseService,
    protected sanitizer : DomSanitizer
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
    this.allowedEffects = licenseService.allowedEffects;
    this.allowedCountries = licenseService.allowedCountries;
    this.emailString = "mailto:"+this.translateService.instant('shop.emailWorkaround.email')
      +'?Subject='+this.translateService.instant('shop.emailWorkaround.environmentalEffects.subjectContent')+'&body='
  }

  ngOnInit(): void {
    this.recDataColumns = (this.recDataSource[0] && this.recDataSource[0].length > 0) ?
      this.getTableColumns(this.recDataSource[0].map(x => x.packagingPartName),
        'recyclabilityRating', this.translateService.instant('analysis.analysisComparison.recyclability.tableColumn')) : [];
    this.recColumnHeaders = this.getTableColumnHeaders(this.recDataColumns);

    this.setEnvironmentalEffectsData(this.lcaDataSource);
    this.lcaDataColumns = (this.lcaDataSource[0] && this.lcaDataSource[0].length > 0) ?
      this.getTableColumns(this.lcaDataSource[0].map(x => x.packagingPartName),
        'environmentalEffect', this.translateService.instant('analysis.analysisComparison.lca.tableColumn')) : [];
    this.lcaColumnHeaders = this.getTableColumnHeaders(this.lcaDataColumns);
    this.lcaTableData.forEach(x => this.assignColorsToCells(x));
  }

  ngAfterViewInit(): void {
    this.setOriginalLcaData();
  }

  private getTableColumns(productNames: string[], labelsColumnName: string, labelsColumnHeader: string): ComparisonTableColumn[] {
    const dataColumns: ComparisonTableColumn[] = [];
    const labelsColumn: ComparisonTableColumn = {
      columnDef: labelsColumnName,
      header: labelsColumnHeader,
      cell: element => element.label,
      color: () => 'white',
      tooltip: () => ''
    };
    dataColumns.push(labelsColumn);
    for (let evalIndex = 0; evalIndex < productNames.length; evalIndex++) {
      dataColumns.push({
        columnDef: evalIndex.toString(),
        header: productNames[evalIndex],
        cell: element => element.entries[evalIndex].displayValue,
        color: element => element.entries[evalIndex].color,
        tooltip: element => element.entries[evalIndex].tooltip,
      });
    }
    return dataColumns;
  }

  private getTableColumnHeaders(dataColumns: ComparisonTableColumn[]) { return dataColumns.map(c => c.columnDef); }

  private setEnvironmentalEffectsData(lcaDataSource: LifeCycleResult[][]) {
    this.effectsList = this.getEffectsSortedByRelevanceForComparison(lcaDataSource);
    this.setSelectedEffect(this.effectsList[0][0].key);
  }

  private getEffectsSortedByRelevanceForComparison(lcaDataSource: LifeCycleResult[][]) {
    const effectsList: EffectInfosDictionary[][] = [];
    for (const country of lcaDataSource) {
      effectsList.push(this.getEffectsSortedByRelevance(country));
    }
    return effectsList;
  }

  /**
   * Sort effects by impact descending, whereby impact is calculated as average over
   * all compared packaging parts. Climate change is at the top of the list
   */
  private getEffectsSortedByRelevance(lcaData: LifeCycleResult[]): EffectInfosDictionary[] {
    const effectRelevances = lcaData.map(x => x.effectRelevances);
    const effectImpactTotals: EffectInfosDictionary[] = [];
    effectRelevances.forEach(relevance => {
      relevance.forEach(effect => {
        const effectInList = effectImpactTotals.find(x => x.key === effect.key);
        if (effectInList) {
          effectInList.impact = effectInList.impact + (effect.impact ?? 0);
        } else {
          effectImpactTotals.push(effect);
        }
      });
    });
    effectImpactTotals.forEach(x => x.impact = x.impact / lcaData.length);
    const effectsSortedByImpact = effectImpactTotals.sort((a, b) => {
      return b.impact - a.impact;
    });
    const climateChangeIndex = effectsSortedByImpact.findIndex(x => x.key === 'climateChange');
    if (climateChangeIndex !== 0) {
      ComparisonHtmlTemplateComponent.moveArrayItem(effectsSortedByImpact, climateChangeIndex, 0);
    }
    return effectsSortedByImpact;
  }

  protected setSelectedEffect(key: string) {
    this.selectedEffectKey = key;
    this.selectedEffectIndex = this.effectsList[this.selectedCountryTabIndex].findIndex(x => x.key === this.selectedEffectKey);
    this.selectedEffectUnit = this.effectsList[this.selectedCountryTabIndex].find(x => x.key === this.selectedEffectKey)?.unit ?? '';
  }

  protected createNewRecyclabilityAnalysis(data: { id: number, version: number }) {
    this.createRecAnalysis.emit(data);
  }

  protected createNewLifecycleAnalysis(data: { id: number, version: number }) {
    this.createLcaAnalysis.emit(data);
  }

  protected doNormalize(normData: NormalizationData) {
    this.resetLcaDataToOriginal();
    this.normalizationData = null;
    if (normData.normalizeByQuantity || normData.normalizeByVolume || normData.normalizeByWeight) {
      this.lcaDataSource[this.selectedCountryTabIndex].forEach(x => this.normForm.normalizeLcaResultData(x, normData));
      this.lcaTableData[this.selectedCountryTabIndex].forEach(x => this.normForm.normalizeLcaComparisonTableData(x, normData));
      this.normalizationData = normData;
    }
    this.reassignColorsToCells(this.lcaTableData);
    this.lcaChart.onDataUpdate();
  }

  private reassignColorsToCells(lcaTableData: ComparisonLcaRowDefinition[][]) {
    lcaTableData.forEach(countryData => { this.assignColorsToCells(countryData); });
  }

  private assignColorsToCells(lcaTableData: ComparisonLcaRowDefinition[]): ComparisonLcaRowDefinition[] {
    for (const row of lcaTableData) {
      const displayValues = row.entries.map(x => x.displayValue);
      const unsortedArray = this.copyArray(displayValues);
      const sortedArray = this.copyAndSortArray(displayValues);
      const uniqueEntries = this.getUniqueValuesArray(sortedArray);
      const colorGradient: string[] = ColorGradientCalculator.getGreenToRedGradient(uniqueEntries.length);
      let colorIndex = -1;
      for (const sortedEntry of sortedArray) {
        const valueIndices = this.getValueOccurenceIndices(unsortedArray, sortedEntry);
        if (valueIndices.length > 0) { colorIndex++; }
        for (const valueIndex of valueIndices) {
          unsortedArray[valueIndex] = '';
          row.entries[valueIndex].color = colorGradient[colorIndex];
        }
      }
    }
    return lcaTableData;
  }

  private copyArray(array: string[]): string[] {
    const copiedArray: any[] = [];
    array.forEach(entry => copiedArray.push(entry));
    return copiedArray;
  }

  private copyAndSortArray(array: string[]): string[] {
    const sortedArray: any[] = [];
    array.forEach(entry => entry !== '--' ? sortedArray.push(entry) : null);
    return sortedArray.sort((n1, n2) => n1 - n2);
  }

  private getUniqueValuesArray(array: string[]): string[] {
    return array.filter(ComparisonHtmlTemplateComponent.onlyUnique);
  }

  private getValueOccurenceIndices(array: string[], value: string): number[] {
    return array.reduce((a: number[], e: string, i: number) => { if (e === value) { a.push(i); } return a; }, []);
  }

  protected onCountrySwitch(tabChangeEvent: MatTabChangeEvent) {
    this.selectedCountryTabIndex = tabChangeEvent.index;
    this.normForm.resetData();
    this.resetLcaDataToOriginal();
    this.lcaDataSource = this.normForm.originalLcaDataSource;
    this.lcaTableData = this.normForm.originalLcaTableData;
  }

  private setOriginalLcaData() {
    this.normForm.originalLcaDataSource = JSON.parse(JSON.stringify(this.lcaDataSource));
    this.normForm.originalLcaTableData = JSON.parse(JSON.stringify(this.lcaTableData));
  }

  private resetLcaDataToOriginal() {
    this.lcaDataSource = JSON.parse(JSON.stringify(this.normForm.originalLcaDataSource));
    this.lcaTableData = JSON.parse(JSON.stringify(this.normForm.originalLcaTableData));
  }

  protected getNormalizationForChartSubtitle(): string {
    if (!this.normalizationData) { return ''; }
    const normalizationFactor = this.normalizationData.normalizationFactor.toString();
    const normalizationUnits = this.normalizationData.normalizeByQuantity ?
      this.translateService.instant('analysis.lifecycleAnalysis.quantityUnit') :
      this.normalizationData.normalizationUnit;
    const parameterString = `${normalizationFactor} ${normalizationUnits}`;
    return this.translateService.instant('analysis.lifecycleAnalysis.normalizedFor', { factor: parameterString });
  }

  protected getEffectName() {
    return this.effectsList[this.selectedCountryTabIndex][this.selectedEffectIndex]?.name;
  }

  protected getEffectDescription() {
    return this.effectsList[this.selectedCountryTabIndex][this.selectedEffectIndex]?.description;
  }

  protected getEffectUnitDescription() {
    return this.effectsList[this.selectedCountryTabIndex][this.selectedEffectIndex]?.unitDescription;
  }

  protected isEffectAuthorized(selectedEffectKey: string) : boolean {
    return this.allowedEffects.some((effect: EnvironmentalEffectDto) => effect.name == selectedEffectKey);
  }

  protected invertBuyText () {
    this.showBuyText = !this.showBuyText;
  }
  protected getEmailString (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + + this.translateService.instant('shop.emailWorkaround.environmentalEffects.cradleToGravePostfix') + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.sanitize(this.emailString+this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{effect:namestring}));
  }

  protected getEmailContentStringForBrowser (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + this.translateService.instant('shop.emailWorkaround.environmentalEffects.cradleToGravePostfix') + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{effect:namestring}).replace(/%0D%0A/g,'\n');
  }

  protected sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  protected getTooltipName(selectedEffectKey: string) {
    const effect = this.effectsList[this.selectedCountryTabIndex].find(x => x.key === selectedEffectKey);
    if(effect) {
      if (this.isEffectAuthorized(effect.key)) {
        return this.translateService.instant('analysis.lifecycleAnalysis.impact') + ': ' +(effect.impact * 100).toFixed(2) + '%';
      } else {
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.impactNotAuthorized',{name: ''});
      }
    }
    return"";
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
