<h1 mat-dialog-title>Copy with tracking?</h1>

<mat-dialog-content>
  <span>
    Selected packaging units will be copied with tracking, unselected packaging units will be copied without
    tracking
  </span>

  <mat-card>
    <div class="scrolling-wrapper">
      <mat-selection-list #withTracking class="export-packaging-units-selection">
        <mat-list-option *ngFor="let packagingUnit of packagingUnits" [value]="packagingUnit" selected="true">
          {{ packagingUnit.brandName }}: {{ packagingUnit.productName }}
        </mat-list-option>
        <mat-list-option *ngFor="let component of components" [value]="component" selected="true">
          {{ component.id }}
        </mat-list-option>
      </mat-selection-list>
    </div>

    <mat-label class="label-large">
      {{ 'dataManagement.directory.dialog.export.summaryPackagingUnits' | translate }}:
      {{ withTracking.selectedOptions.selected.length }}
    </mat-label>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="acceptSelection()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.ok' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
