import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentHelperService } from '../../../services/environment-helper.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit, OnDestroy {
  version = '';
  feBuild = '';
  apiVersion = '';

  private subscription?: Subscription;

  constructor(private environmentService: EnvironmentHelperService) { }

  ngOnInit() {
    this.version = this.environmentService.version;
    this.subscription = this.environmentService.getApiVersion()
      .subscribe(version => this.apiVersion = version);

    if (this.environmentService.isTestEnvironment()) {
      this.feBuild = this.environmentService.feBuild;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
