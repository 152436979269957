<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.button.addOrganization' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field id="org-name-field">
    <mat-label>{{ 'dataManagement.manageOrganizations.newOrgDialog.organizationName' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="organizationName">
  </mat-form-field>
  <button (click)="addUser()" mat-raised-button color="primary">
    <mat-icon>person_add</mat-icon>
    {{ 'dataManagement.userData.texts.add' | translate }}
  </button>
  <div class="error" *ngIf="organizationNameExists()">
    <mat-error>{{ 'dataManagement.manageOrganizations.errors.nameExists' | translate }}</mat-error>
  </div>
  <p></p>
  <mat-form-field id="credits-field-recyclability">
    <mat-label>{{ 'dataManagement.manageOrganizations.newOrgDialog.recyclabilityCredits' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.recyclabilityCredits" type="number">
  </mat-form-field><div class="error" *ngIf="creditsInvalid(creditType.RECYCLABILITY)">
    <mat-error>{{ 'dataManagement.manageOrganizations.errors.creditsInvalid' | translate }}</mat-error>
  </div>
  <p></p>
  <mat-form-field id="credits-field-lca">
    <mat-label>{{ 'dataManagement.manageOrganizations.newOrgDialog.lcaCredits' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.lcaCredits" type="number">
  </mat-form-field><div class="error" *ngIf="creditsInvalid(creditType.LCA)">
    <mat-error>{{ 'dataManagement.manageOrganizations.errors.creditsInvalid' | translate }}</mat-error>
  </div>
  <table mat-table *ngIf="usersInOrgDataSource.data.length > 0" [dataSource]="usersInOrgDataSource" id="users-table"
    #usersTableSort="matSort" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataManagement.manageOrganizations.userTableColumn.name' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataManagement.manageOrganizations.userTableColumn.email' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataManagement.manageOrganizations.userTableColumn.actions' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editNewUser(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="deleteNewUser(element)">
          <mat-icon>delete_forever</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="usersColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="addOrganization()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.add' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
