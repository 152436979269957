<mat-menu #reportsMenu="matMenu" [xPosition]="menuDirectionX" yPosition="above">
  <ng-template matMenuContent>
    <div>
      <button mat-menu-item [matMenuTriggerFor]="generateReport">
        {{ 'report.buttons.createReport' | translate}}
      </button>
      <button mat-menu-item [matMenuTriggerFor]="showReports" *ngIf="existingReports.length > 0">
        {{ 'report.buttons.viewReports' | translate}}
      </button>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #generateReport="matMenu" [xPosition]="menuDirectionX">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let version of versions" (click)="createReport(version)">
      Version {{ version.versionNumber }}
    </button>
  </ng-template>
</mat-menu>

<mat-menu #showReports="matMenu" [xPosition]="menuDirectionX">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let item of existingReports" (click)="viewReport(item.id)">
      {{ 'report.reportForVersion' | translate}} {{item[versionFieldName]}}
      <span *ngIf="item.name">({{item.name}})</span>
    </button>
  </ng-template>
</mat-menu>
