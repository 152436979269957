import { PackagingUnitExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PLASTIC_TAX_COUNTRIES } from 'src/app/model/expense-countries';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ExpenseCalculationParentComponent } from '../expense-calculation-parent/expense-calculation-parent.component';

@Component({
  selector: 'app-plastic-tax-packaging-unit',
  templateUrl: './plastic-tax-packaging-unit.component.html',
  styleUrls: ['./plastic-tax-packaging-unit.component.scss']
})
export class PlasticTaxPackagingUnitComponent extends ExpenseCalculationParentComponent implements OnInit {

  plasticTaxResult!: PackagingUnitExpenseResultDto[];
  packagingUnit!: PackagingUnitDto;

  constructor(private route: ActivatedRoute) { super(); }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.plasticTaxResult = data.plasticTaxResult;
      this.packagingUnit = this.plasticTaxResult[0].analyzedPackagingUnit;
      this.packagingPartWeight = super.getPackagingUnitWeight(this.packagingUnit);
      this.countriesAvailability = PLASTIC_TAX_COUNTRIES;
      this.packagingPartType = PackagingPart.Unit;
    });
  }
}
