import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-disclaimer-dialog',
  templateUrl: './disclaimer-dialog.component.html',
  styleUrls: ['./disclaimer-dialog.component.scss']
})
export class DisclaimerDialogComponent {

  sectionsWithBulletpoints: { title: string, items: string[] }[] = [];
  sections: { title: string, text: string }[] = [];

  tr = (key: string, params?: any) => this.translateService.instant(key, params);

  constructor(
    public dialogRef: MatDialogRef<DisclaimerDialogComponent>,
    private translateService: TranslateService
  ) {
    this.initLists();
  }

  private initLists() {
    this.sectionsWithBulletpoints = [
      {
        title: this.tr('disclaimer.sectionTitle.colorsAndInks'),
        items: [
          this.tr('disclaimer.notCarbonBased'),
          this.tr('disclaimer.notToxicOrBleeding'),
          this.tr('disclaimer.recyclable'),
          this.tr('disclaimer.eupia')
        ]
      }
    ];

    this.sections = [
      {
        title: this.tr('disclaimer.sectionTitle.sealingsAndAdhesives'),
        text: this.tr('disclaimer.sealingsAndAdhesives')
      },
      {
        title: this.tr('disclaimer.sectionTitle.emptiability'),
        text: this.tr('disclaimer.emptiability')
      },
      {
        title: this.tr('disclaimer.sectionTitle.surfaceCoverage'),
        text: this.tr('disclaimer.surfaceCoverage')
      },
      {
        title: this.tr('disclaimer.sectionTitle.fiber'),
        text: this.tr('disclaimer.fiber')
      },
      {
        title: this.tr('disclaimer.sectionTitle.specialCases'),
        text: this.tr('disclaimer.specialCases')
      }
    ];
  }

  doConfirm() {
    this.dialogRef.close({ event: DialogActions.CONFIRM });
  }

  doReject() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
