<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.authorizations.edit.title' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field id="authorizations">
    <mat-label>{{ 'dataManagement.manageOrganizations.authorizations.authorization' | translate }}</mat-label>
    <mat-select [formControl]="authorizationsDropdown" >
      <mat-option *ngFor="let authorization of allAuthorizations" [value]="authorization">
        {{authorization.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="type">
    <mat-label>{{ 'dataManagement.manageOrganizations.authorizations.analysisType' | translate }}</mat-label>
    <mat-select [formControl]="typeDropdown" >
      <mat-option *ngFor="let type of AllTypes" [value]="type">
        {{type.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="start-date-field">
    <mat-label>{{ 'dataManagement.manageOrganizations.authorizations.startDate' | translate }}</mat-label>
    <input matInput autocomplete="off" [matDatepicker]="dpStart" [formControl]="startDateControl">
    <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
    <mat-datepicker #dpStart startView="multi-year">
    </mat-datepicker>

    <mat-error *ngIf="datesFormGroup.controls.startDate.errors">
      <span *ngIf="datesFormGroup.controls.startDate.errors.required">
        {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
      </span>
      <span *ngIf="datesFormGroup.controls.startDate.errors.endBeforeStart">
        {{ 'dataEvaluation.performEvaluations.errors.endBeforeStart' | translate }}
      </span>
      <span *ngIf="datesFormGroup.controls.startDate.errors.changedPastStartDate">
        {{ 'dataManagement.manageOrganizations.authorizations.edit.startDateInPastError' | translate }}
      </span>
    </mat-error>
  </mat-form-field>

  <mat-form-field id="end-date-field">
    <mat-label>{{ 'dataManagement.manageOrganizations.authorizations.endDate' | translate }}</mat-label>
    <input matInput autocomplete="off" [matDatepicker]="dpEnd" [formControl]="endDateControl">
    <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
    <mat-datepicker #dpEnd startView="multi-year">
    </mat-datepicker>

    <mat-error *ngIf="datesFormGroup.controls.endDate.errors">
      <span *ngIf="datesFormGroup.controls.endDate.errors.required">
        {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
      </span>
      <span *ngIf="datesFormGroup.controls.endDate.errors.endBeforeStart">
        {{ 'dataEvaluation.performEvaluations.errors.endBeforeStart' | translate }}
      </span>
      <span *ngIf="datesFormGroup.controls.endDate.errors.changedPastEndDate">
        {{ 'dataManagement.manageOrganizations.authorizations.edit.endDateInPastError' | translate }}
      </span>
    </mat-error>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="onSuccess()" [disabled]="isSaveButtonDisabled()">
    {{ 'common.buttons.ok' | translate }}
  </button>

  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>

</mat-dialog-actions>

