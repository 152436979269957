import { PackagingSystemApiService } from '../services/packaging-system-api-service';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { PackagingSystemDto } from '../entities/packaging-system-entities/packaging-system-dto';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { AuthService } from 'src/app/services/auth-service';
import { ChangeText, OriginalText } from 'src/app/model/path-building-blocks';
import { PackagingSystemInfoDto } from '../entities/packaging-system-entities/packaging-system-info-dto';
import { DateHelperService } from 'src/app/services/date-helper.service';
import * as moment from 'moment';

export const packagingSystemResolver: ResolveFn<PackagingSystemDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingSystemApiService).getPackagingSystem(id) : EMPTY;
};

export const allPackagingSystemsResolver: ResolveFn<PackagingSystemInfoDto[]> = () => {
  const startDate = inject(DateHelperService).getDateInDaysUTCMoment(moment(), -6);
  const endDate = inject(DateHelperService).getDateInDaysUTCMoment(moment(), 1);
  return inject(PackagingSystemApiService).getFilteredPackagingSystems(startDate.toISOString(), endDate.toISOString());
};

export const packagingSystemHistoryResolver: ResolveFn<PackagingSystemDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ? inject(PackagingSystemApiService).getPackagingSystem(id, versionId) : EMPTY;
};

export const packagingSystemPermissionsResolver: ResolveFn<PermissionTypeDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingSystemApiService).getPackagingSystemPermissions(id) : EMPTY;
};

export const lastViewedPackagingSystemsResolver: ResolveFn<PackagingSystemDto[]> = () => {
  if (!inject(AuthService).isUserValidator()) {
    return inject(PackagingSystemApiService).getLastViewedPackagingSystems(8);
  } else {
    return of([]);
  }
};

export const changedUnitsResolver: ResolveFn<number[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingSystemApiService).getChangedUnitIdsInPackagingSystem(id) : EMPTY;
};

export const changedSystemsResolver: ResolveFn<number[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingSystemApiService).getChangedSystemIdsInPackagingSystem(id) : EMPTY;
};

export const multiplePackagingSystemsResolver: ResolveFn<PackagingSystemDto[]> = (route: ActivatedRouteSnapshot) => {
  const ids = PathResolveService.getPackagingUnitIdListFromRoute(route);

  if (ids && ids.length > 0) {
    const observablesList: Observable<PackagingSystemDto>[] = [];
    for (const id of ids) {
      observablesList.push(inject(PackagingSystemApiService).getPackagingSystem(id));
    }
    return forkJoin(observablesList);
  } else {
    return of([] as PackagingSystemDto[]);
  }
};

export const packagingSystemPreviewResolver: ResolveFn<PackagingSystemDto> = (route: ActivatedRouteSnapshot) => {
  const candidateId = PathResolveService.getCandidateIdFromRoute(route);
  if (candidateId == null) { return EMPTY; }

  const url = route.url.map(segment => segment.toString());
  if (url.findIndex(x => x === ChangeText) > -1) {
    if (url.findIndex(y => y === OriginalText) > -1) {
      return resolveChange(candidateId, true);
    }
    return resolveChange(candidateId, false);
  } else {
    return resolveImport(candidateId);
  }
};

const resolveImport = (candidateId: number) => {
  return inject(PackagingSystemApiService).getPackagingSystemPreview(candidateId);
};

const resolveChange = (candidateId: number, isOriginal = false) => {
  return isOriginal ? inject(PackagingSystemApiService).getPackagingSystem(candidateId) :
    inject(PackagingSystemApiService).getPackagingSystemPreview(candidateId);
}