import { TranslateService } from '@ngx-translate/core';

export class LanguageHandler {

    private translateService: TranslateService;

    constructor(translateService: TranslateService) {
        this.translateService = translateService;
    }

    setLanguage(countryCode: string): void {
        const language: string = (countryCode === 'gb' ? 'en' : countryCode);
        localStorage.setItem('language', language);
        window.location.reload();
    }

    getLanguageCodes() {
        return this.translateService.getLangs().map(countryCode => countryCode === 'en' ? 'gb' : countryCode);
    }
}

