<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="month">
    <td mat-cell *matCellDef="let row">
      {{ row.value.month | date: 'MMMM ' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <td mat-cell *matCellDef="let row; let i = index">
      <ng-container [formGroup]="row">
        <mat-form-field class="quantity-form">
          <mat-label>{{ 'dataManagement.quantities.quantity'| translate }}</mat-label>
          <input matInput type="number" matInput autocomplete="off" formControlName="quantity" appStopNumberScrolling />
        </mat-form-field>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
