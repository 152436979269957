import { EnvironmentHelperService } from '../../../services/environment-helper.service';
import { Component, OnInit } from '@angular/core';

class Dependency {
  name = '';
  version = '';
  link = '';
  license = '';
}

const ANGULAR_LINKS = {
  link: 'https://github.com/angular/angular',
  license: 'https://github.com/angular/angular/blob/master/LICENSE'
};
const ANGULAR_COMPONENT_LINKS = {
  link: 'https://github.com/angular/components',
  license: 'https://github.com/angular/components/blob/master/LICENSE'
};

const DEPENDENCY_LINKS = [{
  name: '@angular/flex-layout',
  link: 'https://github.com/angular/flex-layout',
  license: 'https://github.com/angular/flex-layout/blob/master/LICENSE'
},
{
  name: '@ngx-translate/core',
  link: 'https://github.com/ngx-translate/core',
  license: 'https://github.com/ngx-translate/core/blob/master/LICENSE'
},
{
  name: '@ngx-translate/http-loader',
  link: 'https://github.com/ngx-translate/http-loader',
  license: 'https://github.com/ngx-translate/http-loader/blob/master/LICENSE'
},
{
  name: 'bootstrap',
  link: 'https://github.com/twbs/bootstrap',
  license: 'https://github.com/twbs/bootstrap/blob/main/LICENSE'
},
{
  name: 'moment',
  link: 'https://github.com/moment/moment',
  license: 'https://github.com/moment/moment/blob/develop/LICENSE'
},
{
  name: 'ngx-bootstrap',
  link: 'https://github.com/valor-software/ngx-bootstrap',
  license: 'https://github.com/valor-software/ngx-bootstrap/blob/development/LICENSE'
},
{
  name: 'ngx-lightbox',
  link: 'https://github.com/themyth92/ngx-lightbox',
  license: 'https://github.com/themyth92/ngx-lightbox/blob/master/LICENSE'
},
{
  name: 'ngx-markdown',
  link: 'https://github.com/jfcere/ngx-markdown',
  license: 'https://github.com/jfcere/ngx-markdown/blob/master/LICENSE'
},
{
  name: 'ngx-spinner',
  link: 'https://github.com/Napster2210/ngx-spinner',
  license: 'https://github.com/Napster2210/ngx-spinner/blob/master/LICENSE'
},
{
  name: 'rxjs',
  link: 'https://github.com/reactivex/rxjs',
  license: 'https://github.com/ReactiveX/rxjs/blob/master/LICENSE.txt'
},
{
  name: 'tslib',
  link: 'https://github.com/Microsoft/tslib',
  license: 'https://github.com/microsoft/tslib/blob/main/LICENSE.txt'
},
{
  name: 'zone.js',
  link: 'https://github.com/angular/angular',
  license: 'https://github.com/angular/angular/blob/master/LICENSE'
},
{
  name: 'highcharts',
  link: 'https://www.highcharts.com/',
  license: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/' +
    'uploads/2021/04/13071309/Highsoft-Standard-License-Agreement-13.0.pdf'
},
{
  name: 'highcharts-angular',
  link: 'https://github.com/highcharts/highcharts-angular',
  license: 'https://github.com/highcharts/highcharts-angular/blob/master/LICENSE'
},
{
  name: '@highcharts/map-collection',
  link: 'https://www.highcharts.com/docs/maps/map-collection',
  license: 'https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/' +
    '2021/04/13071309/Highsoft-Standard-License-Agreement-13.0.pdf'
}];

@Component({
  selector: 'app-dependencies',
  templateUrl: './dependencies.component.html',
  styleUrls: ['./dependencies.component.scss']
})
export class DependenciesComponent implements OnInit {

  displayedDependencyList: Dependency[] = [];

  constructor(
    private environmentService: EnvironmentHelperService
  ) { }

  ngOnInit(): void {
    const dependencies = this.environmentService.dependencies;
    const dependencyNames = Object.keys(this.environmentService.dependencies);
    const angularDependencies = ['@angular/animations', '@angular/common', '@angular/compiler',
      '@angular/core', '@angular/forms', '@angular/localize', '@angular/platform-browser',
      '@angular/platform-browser-dynamic', '@angular/router'];
    const angularComponentDependencies = ['@angular/cdk', '@angular/material',
      '@angular/material-moment-adapter', '@angular/', '@angular/', '@angular/', '@angular/',
      '@angular/', '@angular/'];
    for (const dependencyName of dependencyNames) {
      const dependencyVersion = dependencies[dependencyName as keyof typeof dependencies] ?? '';
      let dependencyLink: string;
      let dependencyLicense: string;
      if (angularDependencies.includes(dependencyName)) {
        dependencyLink = ANGULAR_LINKS.link;
        dependencyLicense = ANGULAR_LINKS.license;
      } else if (angularComponentDependencies.includes(dependencyName)) {
        dependencyLink = ANGULAR_COMPONENT_LINKS.link;
        dependencyLicense = ANGULAR_COMPONENT_LINKS.license;
      } else {
        const dependency = DEPENDENCY_LINKS.find(x => x.name === dependencyName);
        dependencyLink = dependency ? dependency.link : '';
        dependencyLicense = dependency ? dependency.license : '';
      }
      const displayedDependency: Dependency = {
        name: dependencyName,
        version: dependencyVersion.toString(),
        link: dependencyLink,
        license: dependencyLicense
      };
      this.displayedDependencyList.push(displayedDependency);
    }
  }

}


