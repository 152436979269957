import { COLOR_THEME_DARK, ColorThemeService } from './../../../../../navigation/services/color-theme-service';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { Subscription } from 'rxjs';
import { ExportProfileDto } from 'src/app/data-transfer/entities/export-profile-dto';
import { ExportProfilesDataSource } from '../export-profiles-data-source';

@Component({
  selector: 'app-export-profiles-table',
  templateUrl: './export-profiles-table.component.html',
  styleUrls: ['./export-profiles-table.component.scss']
})
export class ExportProfilesTableComponent implements OnInit, OnDestroy {

  @Input() selectedProfile?: ExportProfileDto;
  @Input() dataSource!: ExportProfilesDataSource;
  @Input() displayedColumns!: string[];
  @Input() canSelectRows = false;
  @Input() isAdmin = false;

  @Output() editProfileEvent = new EventEmitter();
  @Output() deleteProfileEvent = new EventEmitter();
  @Output() viewProfileEvent = new EventEmitter();
  @Output() selectedProfileChange =
    new EventEmitter<ExportProfileDto | undefined>();

  isDarkTheme = false;

  private themeSubscription?: Subscription;

  constructor(
    private colorThemeService: ColorThemeService
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
    });
  }

  ngOnInit() {
    this.dataSource.data.sort((a) => a.isDefaultProfile ? -1 : 1);
  }

  editClicked(profile: PackagingUnitExportProfileDto) {
    this.editProfileEvent.emit(profile);
  }

  deleteClicked(profile: PackagingUnitExportProfileDto) {
    this.deleteProfileEvent.emit(profile);
  }

  viewClicked(profile: PackagingUnitExportProfileDto) {
    this.viewProfileEvent.emit(profile);
  }

  onNameClicked(element: any) {
    if (!this.canSelectRows) { return; }
    if (element !== this.selectedProfile) {
      this.selectedProfile = element;
    } else {
      this.selectedProfile = undefined;
    }
    this.selectedProfileChange.emit(this.selectedProfile);
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
