<mat-tab-group id="tab-group">
  <mat-tab label="{{ 'dataEvaluation.evaluationType.recLabel' | translate }}">
    <div class="parent-container">
      <mat-card id="evals-list-container">
        <div class="scrolling-wrapper">
          <mat-action-list>
            <button mat-list-item *ngFor="let recEvaluation of allRecEvaluations"
              (click)="showRecEvaluationDetails(recEvaluation)">
              <span class="margin-text"><strong>{{ recEvaluation.name }}</strong></span>
              {{ recEvaluation.evaluationReferenceTimestamp }}
              <span (click)="$event.stopPropagation()">
                <button mat-icon-button (click)="editRecEvalName(recEvaluation)" color="primary"
                  matTooltip="{{ 'dataEvaluation.viewEvaluations.messages.editName' | translate }}">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteRecEval(recEvaluation)" color="primary"
                  matTooltip="{{ 'dataEvaluation.viewEvaluations.delete.tooltip' | translate }}">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </span>
            </button>
          </mat-action-list>
        </div>
      </mat-card>
      <mat-card #recEvalCard class="eval-container" *ngIf="selectedRecEvaluation; else epmtyCard">
        <div class="eval-request-details">
          <ng-container *ngTemplateOutlet="requestData"></ng-container>
        </div>
        <app-view-recyclability-aggregate-evaluation class="eval-chart" [recEvaluation]="selectedRecEvaluation"
          [isDarkTheme]="isDarkTheme" [isScrollingEnabled]="isScrollingEnabled" (scrollToTableEvent)="scrollToTable()">
        </app-view-recyclability-aggregate-evaluation>
      </mat-card>
    </div>
  </mat-tab>

  <mat-tab label="{{ 'dataEvaluation.evaluationType.lcaLabel' | translate }}">
    <div class="parent-container">
      <mat-card id="evals-list-container">
        <div class="scrolling-wrapper">
          <mat-action-list>
            <button mat-list-item *ngFor="let lcaEvaluation of allLcaEvaluations"
              (click)="showLcaEvaluationDetails(lcaEvaluation)">
              <span class="margin-text"><strong>{{ lcaEvaluation.name }}</strong></span>
              {{ lcaEvaluation.evaluationReferenceTimestamp }}
              <span (click)="$event.stopPropagation()">
                <button mat-icon-button (click)="editLcaEvalName(lcaEvaluation)" color="primary"
                  matTooltip="{{ 'dataEvaluation.viewEvaluations.messages.editName' | translate }}">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="deleteLcaEval(lcaEvaluation)" color="primary"
                  matTooltip="{{ 'dataEvaluation.viewEvaluations.delete.tooltip' | translate }}">
                  <mat-icon>delete_forever</mat-icon>
                </button>
              </span>
            </button>
          </mat-action-list>
        </div>
      </mat-card>
      <mat-card class="eval-container" *ngIf="selectedLcaEvaluation; else epmtyCard">
        <div class="eval-request-details">
          <ng-container *ngTemplateOutlet="requestData"></ng-container>
        </div>
        <app-view-lifecycle-aggregate-evaluation [lcaEvaluation]="selectedLcaEvaluation" [isDarkTheme]="isDarkTheme"
          id="lca-container">
        </app-view-lifecycle-aggregate-evaluation>
      </mat-card>
    </div>
  </mat-tab>
</mat-tab-group>

<ng-template #epmtyCard>
  <mat-card class="eval-container" id="placeholder-card">
    <span class="hint margin">{{ 'dataEvaluation.viewEvaluations.messages.noEvalSelected' | translate }}</span>
  </mat-card>
</ng-template>

<ng-template #requestData>
  <div class="flex-container">
    <div id="request-data">
      <div class="long-row">
        <span class="long-row-entry"><b>{{ 'dataEvaluation.viewEvaluations.messages.packagingUnits' | translate }}:</b>
        </span>
        <span *ngFor="let packagingUnit of packagingUnitIdsVersions" class="long-row-entry">
          <a [routerLink]="['/cockpit/packaging/packaging-unit/update', packagingUnit.id, packagingUnit.version]"
            target="_blank">{{packagingUnit.id}}</a>
        </span>
      </div>
      <div class="long-row">
        <b>{{ 'dataEvaluation.viewEvaluations.messages.countries' | translate }}:</b> {{countryNames}}
      </div>
      <p><b>{{ 'dataEvaluation.viewEvaluations.messages.dateRange' | translate }}:</b>
        {{ 'dataEvaluation.viewEvaluations.messages.fromTo' | translate: {from: dateFrom, to: dateTo} }}
      </p>
    </div>
    <div>
      <button mat-raised-button color="primary"
        (click)="showQuantities()">{{ 'dataEvaluation.viewEvaluations.quantities.button' | translate }}</button>
    </div>
  </div>
</ng-template>
