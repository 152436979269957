import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';

@Component({
  selector: 'app-international-infos',
  templateUrl: './international-infos.component.html',
  styleUrls: ['./international-infos.component.scss']
})
export class InternationalInfosComponent implements OnInit {

  allCountries: CountryDto[] = [];
  selectedCountryCode?: string | null;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.allCountries = data.allCountries;
      this.selectedCountryCode = this.allCountries[0].code;
    });
  }

  onCountrySelection(countryCode: string) {
    this.selectedCountryCode = countryCode;
  }
}
