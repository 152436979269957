import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PackagingComponentTypesEnum } from './packaging-component-types-enum';

export enum DialogActions {
  ADD,
  EDIT,
  DELETE,
  CONFIRM,
  REJECT,
  RENAME
}

export interface Dictionary {
  key: string;
  value: string;
}

export interface NumericDictionary {
  key: number;
  value: string;
}

export interface BooleanDictionary {
  key: boolean;
  value: string;
}

export interface TriStateDictionary {
  key: boolean | null;
  value: string;
}

@Injectable({ providedIn: 'root' })
export class DictionaryHandler {
  YES_NO_DICTIONARY: BooleanDictionary[] = [
    { key: true, value: 'yes' },
    { key: false, value: 'no' }
  ];

  private PACKAGING_FLEXIBILITY: BooleanDictionary[] = [
    { key: true, value: 'Rigid' },
    { key: false, value: 'Flexible' },
  ];

  private DEFAULT_TRI_STATE_DICTIONARY: TriStateDictionary[] = [
    { key: true, value: 'yes' },
    { key: false, value: 'no' },
    { key: null, value: 'notSpecified' },
  ];

  private TRANSPORT_PACKAGING: TriStateDictionary[] = [
    { key: false, value: 'consumer' },
    { key: true, value: 'transport' },
    { key: null, value: 'notSpecified' },
  ];

  private COMPONENT_CATEGORIES_DICTIONARY: NumericDictionary[] = [
    { key: PackagingComponentTypesEnum.MainBody, value: 'MainBody' },
    { key: PackagingComponentTypesEnum.Closure, value: 'Closure' },
    { key: PackagingComponentTypesEnum.Decoration, value: 'Decoration' },
    { key: PackagingComponentTypesEnum.PackagingAid, value: 'PackagingAid' },
    { key: PackagingComponentTypesEnum.Inlay, value: 'Inlay' }
  ];

  constructor(private translateService: TranslateService) { }

  private translateDictionary(dictionary: Dictionary[] | NumericDictionary[] | BooleanDictionary[]| TriStateDictionary[]) {
    const returnDictionary: any[] = [];
    // Copy array elements by value
    dictionary.forEach(entry => returnDictionary.push(Object.assign({}, entry)));
    // Edit the copied array: translate component names
    returnDictionary.forEach(x => x.value = this.translateService.instant('common.dictionary.' + x.value));
    return returnDictionary;
  }

  public getYesNoDictionary() {
    return this.translateDictionary(this.YES_NO_DICTIONARY);
  }

  public getComponentCategoriesDictionary() {
    return this.translateDictionary(this.COMPONENT_CATEGORIES_DICTIONARY);
  }

  public getFlexibilityDictionary() {
    return this.translateDictionary(this.PACKAGING_FLEXIBILITY);
  }

  public getNirBarrierDictionary() {
    return this.translateDictionary(this.DEFAULT_TRI_STATE_DICTIONARY);
  }

  public getIsMultiUseDictionary() {
    return this.translateDictionary(this.DEFAULT_TRI_STATE_DICTIONARY);
  }

  public getPackagingPartOfDictionary() {
    return this.translateDictionary(this.DEFAULT_TRI_STATE_DICTIONARY);
  }

  public getTransportDictionary() {
    return this.translateDictionary(this.TRANSPORT_PACKAGING);
  }
}
