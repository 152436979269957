import { Component, Input } from '@angular/core';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { AnalysisCradleToGateLifeCyclePackagingPartComponent } from '../analysis-cradle-to-gate-life-cycle-packaging-part/analysis-cradle-to-gate-life-cycle-packaging-part.component';
import {LicenseService} from 'src/app/services/licensing-service'
import {DomSanitizer} from '@angular/platform-browser';
import { BuyType } from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
import { PackagingSystemLifeCycleCradleToGateResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-cradle-to-gate-result-dto';

@Component({
  selector: 'app-analysis-cradle-to-gate-life-cycle-packaging-system',
  templateUrl: './analysis-cradle-to-gate-life-cycle-packaging-system.component.html',
  styleUrls: ['./analysis-cradle-to-gate-life-cycle-packaging-system.component.scss']
})

export class AnalysisCradleToGateLifeCyclePackagingSystemComponent extends AnalysisCradleToGateLifeCyclePackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingSystemLifeCycleCradleToGateResultDto;
  @Input() packagingSystem!: PackagingSystemDto;

  buyType = BuyType;

  constructor(
    private lcaService: LifeCycleService,
    protected translateService: TranslateService,
    protected analysisApiService: AnalysisApiService,
    protected dialog: MatDialog,
    protected licenseService: LicenseService,
    protected sanitizer: DomSanitizer
  ) {
    super(translateService, analysisApiService, dialog, licenseService,sanitizer);
    this.selectedNodeType = PackagingPart.System;
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.System) {
      this.onSystemNodeChanged(node.id);
    } else if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
    super.onNodeChanged();
  }

  private onSystemNodeChanged(systemId: number | null) {
    if (systemId == null) { return; }
    const selectedResultDefault = this.lcaService.findSystemResultRecursively(this.selectedAnalysisVersion.defaultAllocationResult, systemId);
    const selectedResultFull = this.lcaService.findSystemResultRecursively(this.selectedAnalysisVersion.fullAllocationResult, systemId);
    if (!selectedResultDefault || !selectedResultFull) { return; }
    this.dataSourceForCountryDefault = this.lcaService.getPackagingSystemLcaResultFromDto(selectedResultDefault);
    this.dataSourceForCountryDefault.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryDefault.effectRelevances);
    this.dataSourceForCountryFullAllocation = this.lcaService.getPackagingSystemLcaResultFromDto(selectedResultFull);
    this.dataSourceForCountryFullAllocation.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryFullAllocation.effectRelevances);

    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }

  private onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    const selectedResultDefault = this.lcaService.findUnitResultRecursively(this.selectedAnalysisVersion.defaultAllocationResult, unitId);
    const selectedResultFull = this.lcaService.findUnitResultRecursively(this.selectedAnalysisVersion.fullAllocationResult, unitId);
    if (!selectedResultDefault || !selectedResultFull) { return; }
    this.dataSourceForCountryDefault = this.lcaService.getPackagingUnitLcaResultFromDto(selectedResultDefault);
    this.dataSourceForCountryDefault.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryDefault.effectRelevances);
    this.dataSourceForCountryFullAllocation = this.lcaService.getPackagingUnitLcaResultFromDto(selectedResultFull);
    this.dataSourceForCountryFullAllocation.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryFullAllocation.effectRelevances);
    super.setOriginalLcaData();
    this.volume = selectedResultDefault.analyzedPackagingUnit.packagingVolume;
    this.weight = selectedResultDefault.analyzedPackagingUnit.packagingQuantity;
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResultDefault = this.lcaService.findComponentResultRecursively(this.selectedAnalysisVersion.defaultAllocationResult, componentId);
    const selectedResultFull = this.lcaService.findComponentResultRecursively(this.selectedAnalysisVersion.fullAllocationResult, componentId);
    if (!selectedResultDefault || !selectedResultFull) { return; }
    this.dataSourceForCountryDefault = this.lcaService.getComponentLcaResultFromDto(selectedResultDefault);
    this.dataSourceForCountryDefault.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryDefault.effectRelevances);
    this.dataSourceForCountryFullAllocation = this.lcaService.getComponentLcaResultFromDto(selectedResultFull);
    this.dataSourceForCountryFullAllocation.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountryFullAllocation.effectRelevances);
    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }
}

