import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export function getPaginatorIntl(translateService: TranslateService) {
    const paginatorIntl = new MatPaginatorIntl();

    paginatorIntl.itemsPerPageLabel = translateService.instant('home.paginator.countLabel');
    paginatorIntl.nextPageLabel = translateService.instant('home.paginator.nextPage');
    paginatorIntl.previousPageLabel = translateService.instant('home.paginator.previousPage');

    paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${translateService.instant('home.paginator.rangeLabel')} ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} ${translateService.instant('home.paginator.rangeLabel')} ${length}`;
    };

    return paginatorIntl;
}
