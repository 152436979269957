import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { Observable, of } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

interface Option<T> {
  value: T,
  name: string
}

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent<T> implements OnInit {
  @Input({ required: true }) options: T[] = [];
  @Input({ required: true }) displayFn: ( (t: T) => string) = _ => "";
  @Input() multiple: boolean = false;
  @Input() optional: boolean = false;
  @Input() label: string = '';

  @Output() readonly selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  @ViewChild('search') searchTextBox!: ElementRef;

  allOptions: Option<T>[] = [];

  inputControl = new FormControl();
  dropdownControl: FormControl<Option<T>[] | null> = new FormControl([]);

  selectedOptions:Option<T>[] = [];
  filteredOptions: Observable<Option<T>[]> = of([]);
  lastFilter: string = '';

  dummyOption: Option<T> = { name: "", value: this.options[0] }

  displayText = "";


  ngOnInit() {
    this.dummyOption = { name: "dummy", value: this.options[0] }

    if (this.optional) {
      this.dropdownControl = new FormControl([]);
    } else {
      this.dropdownControl = new FormControl([], [Validators.required]);
    }

    this.allOptions = this.options.map(x => this.mapOption(x, this.displayFn));
    this.filteredOptions = this.inputControl.valueChanges.pipe(
      startWith<string | Option<T>[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
  }

  private mapOption(t: T, nameFn: ( (t: T) => string)): Option<T> {
    const name = nameFn(t);

    return  {
      value: t,
      name: name
    };
  }

  filter(filter: string): Option<T>[] {
    this.setSelectedOptions();

    this.lastFilter = filter;

    if (filter) {
      this.displayText = this.selectedOptions.map(o => o.name).join(", ");
      return this.allOptions.filter(option => option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
    } else {
      this.displayText = this.selectedOptions.map(o => o.name).join(", ");
      return this.allOptions.slice();
    }
  }

  clearSearch(event: any) {
    event.stopPropagation();
    this.inputControl.setValue('');
  }

  onSelectionChange(event: MatSelectChange): void {
    let value = event.value?.value;

    if (this.multiple) {
      value = this.selectedOptions.map(x => x.value);
    }

    event.value = value;

    this.selectionChange.emit(event);
  }

  onOptionSelectionChanges(event: MatOptionSelectionChange ) {
    if (!this.multiple){
      return ;
    }

    const option: Option<T> = event.source.value;
    const selected: boolean = event.source.selected;

    if (selected) {
      if (this.selectedOptions.indexOf(option) == -1 && option != this.dummyOption) {
        this.selectedOptions.push(option);
      }
    } else {
      const index = this.selectedOptions.indexOf(option);
      this.selectedOptions.splice(index, 1);
    }

    this.displayText = this.selectedOptions.map(o => o.name).join(", ");
  }

  setSelectedOptions() {
    if (!this.multiple){
      return;
    }

    if (this.dropdownControl.value) {
      for (const option of this.dropdownControl.value) {
        if (this.selectedOptions.indexOf(option) == -1 && option != this.dummyOption) {
          this.selectedOptions.push(option);
        }
      }
    }
  }

  openedChange(isOpen: boolean) {
    if (!this.multiple){
      return;
    }

    this.inputControl.setValue('');
    if (isOpen) {
      this.searchTextBox.nativeElement.focus();
      this.dropdownControl.setValue(this.dropdownControl.value?.concat(this.dummyOption) ?? [this.dummyOption]);
    } else {
      this.dropdownControl.setValue(this.dropdownControl.value?.filter(x => x != this.dummyOption) ?? null);
    }
  }
}
