import { TagDto } from './../../../../../data-transfer/entities/tag-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { GENERIC_PACKAGING_TYPE_ID } from '../../../../../navigation/services/packaging-unit-type-service';
import { RenameDialogComponent, RenameDialogType } from './../../../../dialogs/rename-dialog/rename-dialog.component';
import { VersionDto } from './../../../../../data-transfer/entities/version-dto';
import { ComponentHandler } from './../../../../../services/component-services/component-handler';
import { MaterialManifestationDto } from 'src/app/data-transfer/entities/material-manifestation-dto';
import { MaterialFunctionDto } from '../../../../../data-transfer/entities/material-function-dto';
import { Subscription } from 'rxjs';
import { ColorDto } from 'src/app/data-transfer/entities/color-dto';
import { CountryDto } from './../../../../../data-transfer/entities/country-dto';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CompositeMaterialSevice } from 'src/app/services/material-services/composite-material-service';
import { MultiMaterialTableComponent } from 'src/app/components/shared-components/multi-material-table/multi-material-table.component';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisMenuCaller } from 'src/app/components/shared-components/analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { DialogActions } from 'src/app/model/dictionary';
import { ChangedPackagingPartDisplayInfo } from 'src/app/components/shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';

@Component({
  selector: 'app-component-html-template',
  templateUrl: './component-html-template.component.html',
  styleUrls: ['./component-html-template.component.scss']
})
export class ComponentHtmlTemplateComponent implements OnInit, OnDestroy, OnChanges {

  @ViewChild(MultiMaterialTableComponent) private materialsTable!: MultiMaterialTableComponent;

  @Input() packagingComponent!: PackagingComponentDto;
  @Input() form!: FormGroup;
  @Input() componentSubtypes!: PackagingPartTypeDto[];
  @Input() callerId = -1;
  @Input() packagingUnitTypeId = GENERIC_PACKAGING_TYPE_ID;
  @Input() isDecoration = false;
  @Input() isMainBody = false;
  @Input() isPreview = false;
  @Input() isTracked = false;
  @Input() isEditPermitted = true;
  @Input() isFormLocked = false;
  @Input() isUserValidator = false;
  @Input() canEditForm = true;
  @Input() isDarkTheme = false;
  @Input() spinnerActive = false;
  @Input() historyVersions: VersionDto[] = [];
  @Input() imageSource = '';
  @Input() componentTypeSelectionLabel = '';
  @Input() changedMaterialsInfo: ChangedPackagingPartDisplayInfo[] = [];

  @Output() submitted = new EventEmitter();
  @Output() decorationSubtypeChanged = new EventEmitter();
  @Output() historicVersionClicked = new EventEmitter();
  @Output() historicVersionChanged = new EventEmitter();
  @Output() versionRenamed = new EventEmitter();
  @Output() materialChangeHandled = new EventEmitter();

  callerPageId = AnalysisMenuCaller.Packaging;
  callerLevelId = PackagingPart.Component;
  manufacturingCountries!: CountryDto[];
  distributionCountries!: CountryDto[];
  allMaterialFunctions!: MaterialFunctionDto[];
  allManifestations!: MaterialManifestationDto[];
  allColors!: ColorDto[];
  selectedManufacturingCountry!: string;
  allTags: TagDto[] = [];
  selectedTags: TagDto[] = [];
  maxCommentLength = 250;

  materialEdited = false;

  private dialogSubscription?: Subscription;
  private grammageSubscription?: Subscription;
  private manufCountrySubscription?: Subscription;

  constructor(
    private componentHandler: ComponentHandler,
    private dialog: MatDialog,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.manufacturingCountries = this.componentHandler.manufacturingCountries;
    this.distributionCountries = this.componentHandler.distributionCountries;
    this.allManifestations = this.componentHandler.allManifestations;
    this.allColors = this.componentHandler.allColors;
    this.allTags = this.componentHandler.allTags;
    this.allMaterialFunctions = this.getAllMaterialFunctions();

    if (this.isPreview) { this.form.disable(); }

    if (this.packagingComponent?.multiMaterial === undefined) {
      this.packagingComponent.multiMaterial = CompositeMaterialSevice.createEmptyCompositeMaterial();
    }
    this.grammageSubscription = this.form.controls.totalGrammage.valueChanges.subscribe(newTotalGrammage =>
      this.packagingComponent.multiMaterial.totalGrammage = newTotalGrammage);
    this.manufCountrySubscription = this.form.controls.manufacturingCountry.valueChanges.subscribe(newCountry =>
      this.selectedManufacturingCountry = newCountry);
    this.selectedManufacturingCountry = this.form.controls.manufacturingCountry.value ?? '';
    this.selectedTags = this.allTags.filter(tag => this.packagingComponent.associatedTagIdentifiers.includes(tag.id ?? -1) ?? false);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.packagingUnitTypeId) {
      this.allMaterialFunctions = this.getAllMaterialFunctions();
    }
  }

  private getAllMaterialFunctions(): MaterialFunctionDto[] {
    return this.componentHandler.getMaterialFunctions(this.packagingUnitTypeId, this.callerId);
  }

  getComponentCreationDate(): string {
    if (this.packagingComponent.creationTimestamp) {
      return new Date(this.packagingComponent.creationTimestamp).toLocaleDateString();
    } else {
      return (new Date()).toLocaleDateString();
    }
  }

  onHistoryVersionClicked() {
    this.historicVersionClicked.emit();
  }

  onHistoryVersionChange(version: number) {
    this.historicVersionChanged.emit(version);
  }

  onDecorationSubtypeChange(decoType: number) {
    this.decorationSubtypeChanged.emit(decoType);
  }

  getDateTimeString(date?: Date) {
    if (!date) { return ''; }
    return `${new Date(date).toLocaleDateString()}: ${new Date(date).toLocaleTimeString()}`;
  }

  totalWeightChanged(newTotalWeight: number): void {
    this.form.controls.totalWeight.patchValue(newTotalWeight);
    this.packagingComponent.multiMaterial.totalWeight = newTotalWeight;
    this.materialEdited = true;
  }

  onSubmit() {
    const multiMaterialControl = this.formBuilder.control(this.packagingComponent.multiMaterial);
    if (this.form.controls.multiMaterial) { delete this.form.controls.multiMaterial; }
    this.form.addControl('multiMaterial', multiMaterialControl);
    const areMaterialsValid = this.materialsTable.areMaterialLayersValid();
    if (!areMaterialsValid) {
      multiMaterialControl.setErrors({ invalidMaterialLayers: true });
    }
    this.submitted.emit(this.materialEdited);
  }

  editVersionName(historyVersion: VersionDto) {
    const dialogData = { name: historyVersion.versionName ?? '', type: RenameDialogType.PackagingComponentVersion };
    const dialogConfig = getDialogConfig(dialogData);
    const dialogRef = this.dialog.open(RenameDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const newName = result.data.name;
      this.versionRenamed.emit({ historyVersion, newName });
    });
  }

  setTags() {
    this.packagingComponent.associatedTagIdentifiers = this.selectedTags.map(tag => tag.id ?? -1);
  }

  handleMaterialChange(accepted: boolean, materialId: number) {
    this.materialChangeHandled.emit({ accepted, materialId });
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.grammageSubscription?.unsubscribe();
    this.manufCountrySubscription?.unsubscribe();
  }
}
