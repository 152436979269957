import { PackagingComponentTypesEnum } from './../../../model/packaging-component-types-enum';
import { MINIMAL_TOTAL_WEIGHT, MINIMAL_GRAMMAGE, MINIMAL_THICKNESS, MINIMAL_DENSITY, MAXIMAL_PERCENTAGE, MINIMAL_PERCENTAGE } from '../../../services/material-services/material-data-handler';
import { FormGroup, FormBuilder, AbstractControlOptions, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

export function layerGrammageLessThanTotal(layerControlName: string, totalControlName: string) {
  return (formGroup: FormGroup) => {
    const layerGrammage = formGroup.controls[layerControlName];
    const totalGrammage = formGroup.controls[totalControlName];

    if (layerGrammage.value > totalGrammage.value) {
      layerGrammage.setErrors({ grammageError: true });
      totalGrammage.setErrors({ grammageError: true });
    } else {
      if (layerGrammage.errors && layerGrammage.errors.grammageError) {
        delete layerGrammage.errors.grammageError;
        if (Object.keys(layerGrammage.errors).length === 0) {
          layerGrammage.setErrors(null);
        }
      }
      if (totalGrammage.errors && totalGrammage.errors.grammageError) {
        delete totalGrammage.errors.grammageError;
        if (Object.keys(totalGrammage.errors).length === 0) {
          totalGrammage.setErrors(null);
        }
      }
    }
  };
}

@Component({
  selector: 'app-mass-calculator',
  templateUrl: './mass-calculator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./mass-calculator.component.scss']
})
export class MassCalculatorComponent implements OnInit, OnChanges {

  @Input() totalWeight = -1;
  @Input() calculatorTotalWeight = -1;
  @Input() totalGrammage = -1;
  @Input() layerGrammage = -1;
  @Input() layerThickness = -1;
  @Input() layerDensity = -1;
  @Input() layerPercentage = -1;
  @Input() callerId = -1;
  @Output() layerMassCalculated = new EventEmitter();
  @Output() calculatorTotalMassChange = new EventEmitter();
  @Output() calculatorLayerGrammageChange = new EventEmitter();
  @Output() calculatorLayerThicknessChange = new EventEmitter();
  @Output() calculatorLayerDensityChange = new EventEmitter();
  @Output() calculatorLayerPercentageChange = new EventEmitter();

  calculatorForm!: FormGroup;

  isGrammageInputOpen = true;
  isThicknessInputOpen = false;
  isPercentageInputOpen = false;
  callerName = '';

  showTotalWeightWarning = false;
  showTotalGrammageWarning = false;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.calculatorForm = this.formBuilder.group({
      calculatorTotalMass: [this.calculatorTotalWeight, [Validators.required, Validators.min(MINIMAL_TOTAL_WEIGHT)]],
      calculatorTotalGrammage: [this.totalGrammage, [Validators.required, Validators.min(MINIMAL_GRAMMAGE)]],
      calculatorLayerGrammage: [this.layerGrammage, [Validators.required, Validators.min(MINIMAL_GRAMMAGE)]],
      calculatorLayerThickness: [this.layerThickness, [Validators.min(MINIMAL_THICKNESS)]],
      calculatorLayerDensity: [this.layerDensity, [Validators.min(MINIMAL_DENSITY)]],
      calculatorLayerPercentage: [this.layerPercentage, [Validators.min(MINIMAL_PERCENTAGE), Validators.max(MAXIMAL_PERCENTAGE)]]
    }, {
      validator: layerGrammageLessThanTotal('calculatorLayerGrammage', 'calculatorTotalGrammage')
    } as AbstractControlOptions);
    this.setCallerName();
    this.showTotalWeightWarning = this.calculatorTotalWeight !== this.totalWeight;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.calculatorForm) {
      if (changes.layerGrammage) {
        this.calculatorForm.controls.calculatorLayerGrammage.patchValue(changes.layerGrammage.currentValue);
      }
      if (changes.layerThickness) {
        this.calculatorForm.controls.calculatorLayerThickness.patchValue(changes.layerThickness.currentValue);
      }
      if (changes.layerDensity) {
        this.calculatorForm.controls.calculatorLayerDensity.patchValue(changes.layerDensity.currentValue);
      }
      if (changes.layerPercentage) {
        this.calculatorForm.controls.calculatorLayerPercentage.patchValue(changes.layerPercentage.currentValue);
      }
    }
  }

  private setCallerName() {
    switch (this.callerId) {
      case PackagingComponentTypesEnum.Closure:
        this.callerName = 'closure';
        break;
      case PackagingComponentTypesEnum.Decoration:
        this.callerName = 'decoration';
        break;
      case PackagingComponentTypesEnum.Inlay:
        this.callerName = 'inlay';
        break;
      case PackagingComponentTypesEnum.PackagingAid:
        this.callerName = 'packAid';
        break;
      default:
        this.callerName = 'mainBody';
        break;
    }
  }

  get formControls() { return this.calculatorForm.controls; }

  grammageInputOpened() {
    this.isGrammageInputOpen = true;
    this.isThicknessInputOpen = false;
    this.isPercentageInputOpen = false;
    this.calculatorForm.controls.calculatorLayerGrammage.setValidators(
      [Validators.required, Validators.min(MINIMAL_GRAMMAGE)]);
    this.calculatorForm.controls.calculatorTotalGrammage.setValidators(
      [Validators.required, Validators.min(MINIMAL_GRAMMAGE)]);
    this.calculatorForm.controls.calculatorLayerThickness.clearValidators();
    this.calculatorForm.controls.calculatorLayerDensity.clearValidators();
    this.calculatorForm.controls.calculatorLayerPercentage.clearValidators();
    this.updateFieldsValidity();
  }

  thicknessInputOpened() {
    this.isGrammageInputOpen = false;
    this.isThicknessInputOpen = true;
    this.isPercentageInputOpen = false;
    this.calculatorForm.controls.calculatorLayerThickness.setValidators(
      [Validators.required, Validators.min(MINIMAL_THICKNESS)]);
    this.calculatorForm.controls.calculatorLayerDensity.setValidators(
      [Validators.required, Validators.min(MINIMAL_DENSITY)]);
    this.calculatorForm.controls.calculatorTotalGrammage.setValidators(
      [Validators.required, Validators.min(MINIMAL_GRAMMAGE)]);
    this.calculatorForm.controls.calculatorLayerGrammage.clearValidators();
    this.calculatorForm.controls.calculatorLayerPercentage.clearValidators();
    this.updateFieldsValidity();
  }

  percentageInputOpened() {
    this.isGrammageInputOpen = false;
    this.isThicknessInputOpen = false;
    this.isPercentageInputOpen = true;
    this.calculatorForm.controls.calculatorLayerPercentage.setValidators(
      [Validators.required, Validators.min(MINIMAL_PERCENTAGE), Validators.max(MAXIMAL_PERCENTAGE)]);
    this.calculatorForm.controls.calculatorTotalGrammage.clearValidators();
    this.calculatorForm.controls.calculatorLayerGrammage.clearValidators();
    this.calculatorForm.controls.calculatorLayerThickness.clearValidators();
    this.calculatorForm.controls.calculatorLayerDensity.clearValidators();
    this.updateFieldsValidity();
  }

  private updateFieldsValidity() {
    Object.keys(this.calculatorForm.controls).forEach(key => {
      this.calculatorForm.get(key)?.updateValueAndValidity();
    });
  }

  calculateMass() {
    if (this.isThicknessInputOpen) {
      const calculatedlayerGrammage = this.tryCalculateLayerGrammageFromThickness();
      this.calculatorForm.controls.calculatorLayerGrammage.patchValue(calculatedlayerGrammage);
    }
    if (this.calculatorForm.invalid) {
      this.calculatorForm.markAllAsTouched();
      return;
    }
    const totalMass = this.calculatorForm.controls.calculatorTotalMass.value;
    const totalGrammage = this.calculatorForm.controls.calculatorTotalGrammage.value;
    const layerGrammage = this.calculatorForm.controls.calculatorLayerGrammage.value;
    const layerThickness = this.calculatorForm.controls.calculatorLayerThickness.value;
    const layerDensity = this.calculatorForm.controls.calculatorLayerDensity.value;
    const layerPercentage = this.calculatorForm.controls.calculatorLayerPercentage.value;

    let layerMass = 0;
    if (this.isPercentageInputOpen) {
      layerMass = +(totalMass * (layerPercentage / 100)).toFixed(5);
    } else {
      layerMass = +(totalMass * layerGrammage / totalGrammage).toFixed(5);
    }

    this.layerMassCalculated.emit(layerMass);
    this.calculatorTotalMassChange.emit(totalMass);
    this.calculatorLayerGrammageChange.emit(layerGrammage);
    this.calculatorLayerThicknessChange.emit(layerThickness);
    this.calculatorLayerDensityChange.emit(layerDensity);
    this.calculatorLayerPercentageChange.emit(layerPercentage);

    if (this.totalWeight !== totalMass) { this.showTotalWeightWarning = true; }
    if (this.totalGrammage !== totalGrammage) { this.showTotalGrammageWarning = true; }
  }

  private tryCalculateLayerGrammageFromThickness() {
    const layerThickness = this.calculatorForm.controls.calculatorLayerThickness.value;
    const layerDensity = this.calculatorForm.controls.calculatorLayerDensity.value;

    if (!layerThickness || !layerDensity) { return null; }
    return +(layerThickness * layerDensity).toFixed(5);
  }
}
