<table mat-table *ngIf="rowSpansRecyclabilityTable" [dataSource]="recyclabilityTableDataSource"
  class="mat-elevation-z8 table-wide">
  <ng-container *ngFor="let column of dataColumnsRecyclability; let iCol = index" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef [class]="column.columnDef" class="header">
      {{ column.header }}
    </th>
    <td mat-cell *matCellDef="let row; let iRow = index" class="cell"
      [attr.rowspan]="rowSpansRecyclabilityTable[iCol][iRow].span"
      [style.display]="rowSpansRecyclabilityTable[iCol][iRow].span === 0 ? 'none'  : ''"
      [style.border-top]="rowSpansRecyclabilityTable[iCol][iRow].border">
      <div>{{ column.cell(row) }}</div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumnsRecyclability"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsRecyclability"></tr>
</table>