import { ParentNavigationService } from './parent-navigation-service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { MatDialog } from '@angular/material/dialog';
import { CreditsService } from 'src/app/services/credits-service';

@Injectable({ providedIn: 'root' })
export class MaterialNavigationService extends ParentNavigationService {

  constructor(
    private router: Router,
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected creditsService: CreditsService
  ) {
    super(dialog, translateService, creditsService);
  }

  navigateToMaterial(materialId: number, versionId?: number) {
    const path = versionId == null ? PathResolveService.getUpdateMaterialPath(true, materialId) :
      PathResolveService.getMaterialHistoryPath(true, materialId, versionId);
    this.router.navigate([path]);
  }

  navigateToMaterialPreview(candidateId: number, isChange = false, seePackagingUnitBeforeChange = false) {
    this.router.navigate([PathResolveService.getMaterialPreviewPath(true, candidateId, isChange, seePackagingUnitBeforeChange)]);
  }
}
