<mat-card class="images-card">
  <mat-card-title class="grid-item-title">
    {{ 'image.productImages' | translate }}
  </mat-card-title>

  <mat-card-content class="table-container-wide">
    <div *ngIf="imagePreviews.length > 0">
      <div class="images-container">
        <div *ngFor="let imagePreview of imagePreviews; let i = index" class="image-container">
          <div class="uploaded-image">
            <img [src]="imagePreview.image" [ngClass]="{'main-image': imagePreview.isMain}"
              (click)="openImageFullscreen(imagePreview)">
          </div>
          <div>
            <!-- https://stackblitz.com/edit/angular-input-dynamic-width?file=app%2Finput-text-width-example.html -->
            <div id="height-cap">
              <span #invisibleNames id="invisible-input">
                <mat-icon matPrefix class="edit-name-icon">edit</mat-icon>
                ...{{ imagePreview.name }}
              </span>
            </div>
            <mat-form-field #formFieldInput [ngStyle]="{'width.px': nameFieldWidth[i]}" appearance="outline">
              <input matInput class="name-input" [(ngModel)]="imagePreview.name" (paste)="resizeInput(i)"
                (cut)="resizeInput(i)" (input)="resizeInput(i)" (change)="onNameEditClicked(i)"
                (click)="setCurrentNameBeforeChange(imagePreviews, i)" [disabled]="!canEditForm">
              <mat-icon matPrefix class="edit-name-icon">edit</mat-icon>
              <mat-icon matSuffix class="error-name-icon" *ngIf="imagePreview.name.length === 0">error</mat-icon>
            </mat-form-field>
          </div>
          <div>
            <button mat-icon-button (click)="onDeleteImageClick(imagePreview)" color="primary" [disabled]="!canEditForm"
              type="button" matTooltip="{{ 'image.tooltips.deleteImage' | translate }}">
              <mat-icon>delete_forever</mat-icon>
            </button>

            <button *ngIf="!imagePreview.isMain" mat-icon-button (click)="onMakeImageMainClick(imagePreview)" color="primary" matTooltip=" {{ 'image.tooltips.makeImageMainTooltip' | translate }}"
              [disabled]="!canEditForm" type="button">
                <mat-icon>crop_original</mat-icon>
            </button>

            <button *ngIf="imagePreview.isMain" mat-icon-button (click)="onMakeImageMainClick(imagePreview)" color="primary" matTooltip=" {{ 'image.tooltips.imageIsMainTooltip' | translate }}"
              [disabled]="!canEditForm" type="button">
                <mat-icon>image</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <input type="file" accept="image/*" multiple (change)="onUploadImagesClick(hiddenFileInput.files)" #hiddenFileInput
      onclick="value=null" style="display: none" />

    <button mat-raised-button (click)="hiddenFileInput.click()" color="primary" class="custom-button-top"
      [disabled]="!canEditForm || maxCountReached" type="button"
      matTooltip="{{ 'image.tooltips.uploadImage' | translate }}">
      <mat-icon class="material-icons left">add</mat-icon>
      {{ 'image.uploadImageButton' | translate }}
    </button>
  </mat-card-content>
</mat-card>