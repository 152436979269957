import { ExportListComponent } from './components/directory-tree/components/export-list/export-list.component';
import { ImportMaterialComponent } from './components/directory-tree/components/import/import-material/import-material.component';
import { MaterialRoutingWrapperComponent } from './components/packaging-component-material/create-update/common/material-routing-wrapper/material-routing-wrapper.component';
import { CreateUpdateMainBodyComponent } from './components/packaging-component/create-update/create-update-main-body/create-update-main-body.component';
import { MaterialHomeComponent } from './components/packaging-component-material/material-home/material-home.component';
import { ViewAggregateEvaluationsComponent } from './components/analyses/aggregate-evaluations/view-aggregate-evaluations/view-aggregate-evaluations.component';
import { exportProfilesResolver } from './data-transfer/resolvers/export-profile-resolver';
import { ExportProfilesComponent } from './components/directory-tree/components/export-profiles/export-profiles/export-profiles.component';
import { ChangelogComponent } from './components/various-components/changelog/changelog.component';
import { DirectoryManagementComponent } from './components/directory-tree/components/directory-management/directory-management.component';
import { OrganizationManagementComponent } from './components/various-components/organization-management/organization-management.component';
import { HomepageComponent } from './components/various-components/homepage/homepage.component';
import { CreateUpdatePackagingAidComponent } from './components/packaging-component/create-update/create-update-packaging-aid/create-update-packaging-aid.component';
import { CreateUpdateDecorationComponent } from './components/packaging-component/create-update/create-update-decoration/create-update-decoration.component';
import { ComponentHomeComponent } from './components/packaging-component/component-home/component-home.component';
import { CreateUpdateInlayComponent } from './components/packaging-component/create-update/create-update-inlay/create-update-inlay.component';
import { ComparePackagingUnitsComponent } from './components/packaging-unit/compare/compare-packaging-units.component';
import { AnalysisLifeCycleComponent } from './components/analyses/analysis-life-cycle/analysis-life-cycle.component';
import { AnalysisCradleToGateLifeCycleComponent } from './components/analyses/analysis-cradle-to-gate-life-cycle/analysis-cradle-to-gate-life-cycle.component';
import { CreateUpdatePackagingUnitComponent } from './components/packaging-unit/create-update/create-update-packaging-unit/create-update-packaging-unit.component';
import { PackagingRoutingWrapperComponent } from './components/packaging-unit/create-update/common/packaging-routing-wrapper/packaging-routing-wrapper.component';
import { ImportPackagingUnitComponent } from './components/directory-tree/components/import/import-packaging-unit/import-packaging-unit.component';
import { UserManagementComponent } from './components/various-components/user-management/user-management.component';
import { ErrorPageComponent } from './components/various-components/error-page/error-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/various-components/login/login.component';
import { unsavedChangesGuard } from './services/unsaved-changes-guard';
import { PathResolveService } from './services/path-resolve.service';
import { PackagingUnitHomeComponent } from './components/packaging-unit/packaging-unit-home/packaging-unit-home.component';
import { AppNavComponent } from './navigation/components/app-nav/app-nav.component';
import { directoryResolver } from './data-transfer/resolvers/directory-resolver';
import { CreateUpdateClosureComponent } from './components/packaging-component/create-update/create-update-closure/create-update-closure.component';
import { ImportComponentComponent } from './components/directory-tree/components/import/import-component/import-component.component';
import { DependenciesComponent } from './components/various-components/dependencies/dependencies.component';
import { ValidatorViewComponent } from './components/various-components/validator-view/validator-view.component';
import { ComponentRoutingWrapperComponent } from './components/packaging-component/create-update/common/component-routing-wrapper/component-routing-wrapper.component';
import { PerformAggregateEvaluationsComponent } from './components/analyses/aggregate-evaluations/perform-aggregate-evaluations/perform-aggregate-evaluations.component';
import { CompositeMaterialComponent } from './components/packaging-component-material/create-update/composite-material/composite-material.component';
import { ReportTemplateWizardComponent } from './components/reports/templates/report-template-wizard/report-template-wizard.component';
import { TagsManagementComponent } from './components/various-components/tags/tags-management/tags-management.component';
import { LcaDataExcelImportComponent } from './components/shared-components/upload/lca-data-excel-import/lca-data-excel-import.component';
import { CreateUpdatePackagingSystemComponent } from './components/packaging-system/create-update/create-update-packaging-system/create-update-packaging-system.component';
import { PackagingSystemHomeComponent } from './components/packaging-system/packaging-system-home/packaging-system-home.component';
import { ReportTemplatesHomeComponent } from './components/reports/templates/report-templates-home/report-templates-home.component';
import { ReportsHomeComponent } from './components/reports/reports-home/reports-home.component';
import { ImportPackagingSystemComponent } from './components/directory-tree/components/import/import-packaging-system/import-packaging-system.component';
import { aggregatedRecyclabilityResolver, aggregatedLifecycleResolver } from './data-transfer/resolvers/aggregated-analysis-resolver';
import { usersResolver, organizationsResolver, userGroupsResolver, billingAccountsResolver } from './data-transfer/resolvers/user-data-resolver';
import { reportTemplateComponentResolver, reportTemplatePackagingUnitResolver, reportTemplatesComponentResolver, reportTemplatesPackagingUnitResolver, reportsComponentResolver, reportsPackagingUnitResolver } from './data-transfer/resolvers/report-resolver';
import { componentResolver, componentPermissionsResolver, componentHistoryResolver, componentTypesEngResolver, componentTypesResolver, lastViewedComponentsResolver, componentPreviewResolver, decorationPreviewResolver, decorationSubtypesEngResolver, decorationSubtypesResolver, closureSubtypesResolver, inlaySubtypesResolver, mainBodySubtypesResolver, packagingAidSubtypesResolver, changedMaterialsResolver } from './data-transfer/resolvers/component-resolver';
import { allPackagingUnitsResolver, packagingUnitResolver, packagingUnitPermissionsResolver, packagingUnitHistoryResolver, packagingUnitTypesEngResolver, packagingUnitTypesResolver, lastViewedPackagingUnitsResolver, multiplePackagingUnitsResolver, packagingUnitPreviewResolver, changedComponentsResolver } from './data-transfer/resolvers/packaging-unit-resolver';
import { allPackagingSystemsResolver, changedSystemsResolver, changedUnitsResolver, lastViewedPackagingSystemsResolver, multiplePackagingSystemsResolver, packagingSystemHistoryResolver, packagingSystemPermissionsResolver, packagingSystemPreviewResolver, packagingSystemResolver } from './data-transfer/resolvers/packaging-system-resolver';
import { lastViewedMaterialsResolver, compositeMaterialResolver, compositeMaterialPermissionsResolver, compositeMaterialHistoryResolver, compositeMaterialPreviewResolver, changedSubaterialsResolver } from './data-transfer/resolvers/material-resolver';
import { fillingGoodsResolver, fillingGoodCategoriesResolver, fillingGoodTypesResolver, removabilityConditionsResolver, materialFunctionsResolver, colorsResolver, manifestationsResolver, distributionCountriesResolver, assemblyCountriesResolver, manufacturingCountriesResolver, allCountriesResolver, allCountriesEngResolver } from './data-transfer/resolvers/base-data-resolver';
import { errorCodeResolver } from './data-transfer/resolvers/error-code-resolver';
import { errorPathResolver } from './data-transfer/resolvers/error-path-resolver';
import { allTagsResolver } from './data-transfer/resolvers/tag-resolver';
import { InternationalInfosComponent } from './components/various-components/international-infos/international-infos.component';
import { authGuard } from './services/auth-guard';
import { lcaComparisonResolverSystem, lcaComparisonResolverUnit, licenseFeeResolverSystems, licenseFeeResolverUnits, lifeCycleEvaluationHistoryResolverSystem, lifeCycleEvaluationHistoryResolverUnit,  plasticTaxResolverSystems, plasticTaxResolverUnits, recyclabilityComparisonResolverSystem, recyclabilityComparisonResolverUnit, recyclabilityEvaluationHistoryResolverSystem, recyclabilityEvaluationHistoryResolverUnit, recyclingStreamsResolver, lifeCycleCradleToGateEvaluationHistoryResolverSystem, lifeCycleCradleToGateEvaluationHistoryResolverUnit } from './data-transfer/resolvers/analysis-resolver';
import { AnalysisRecyclabilityComponent } from './components/analyses/analysis-recyclability/analysis-recyclability.component';
import { ComparePackagingSystemsComponent } from './components/packaging-system/compare/compare-packaging-systems.component';
import { CLOSURE_NAME } from './model/components/closure-component';
import { DECORATION_NAME } from './model/components/decoration-component';
import { INLAY_NAME } from './model/components/inlay-component';
import { MAIN_BODY_NAME } from './model/components/main-body-component';
import { PACKAGING_AID_NAME } from './model/components/packaging-aid-component';
import { LicenseFeePackagingUnitComponent } from './components/analyses/license-fee-packaging-unit/license-fee-packaging-unit.component';
import { PlasticTaxPackagingUnitComponent } from './components/analyses/plastic-tax-packaging-unit/plastic-tax-packaging-unit.component';
import { PlasticTaxPackagingSystemComponent } from './components/analyses/plastic-tax-packaging-system/plastic-tax-packaging-system.component';
import { LicenseFeePackagingSystemComponent } from './components/analyses/license-fee-packaging-system/license-fee-packaging-system.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { exportListResolver } from './data-transfer/resolvers/export-list-resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: PathResolveService.getCockpitPath(),
    pathMatch: 'full'
  },
  {
    path: PathResolveService.getLoginPath(),
    component: LoginComponent,
    canActivate: [authGuard]
  },
  {
    path: PathResolveService.getCockpitPath(),
    component: AppNavComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        redirectTo: PathResolveService.getStartPath(),
        pathMatch: 'full'
      },
      {
        path: 'landing-page',
        component: LandingPageComponent
      },
      {
        path: PathResolveService.getStartPath(),
        component: HomepageComponent,
        canActivate: [authGuard],
        resolve: {
          lastViewedPackagingSystems: lastViewedPackagingSystemsResolver,
          lastViewedPackagingUnits: lastViewedPackagingUnitsResolver,
          lastViewedComponents: lastViewedComponentsResolver,
          lastViewedMaterials: lastViewedMaterialsResolver
        }
      },
      {
        path: PathResolveService.getUserManagementPath(),
        component: UserManagementComponent,
        canActivate: [authGuard],
        resolve: { users: usersResolver }
      },
      {
        path: PathResolveService.getDependencyVersionsPath(),
        component: DependenciesComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getUiChangelogPath(),
        component: ChangelogComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getExportProfilesPath(),
        component: ExportProfilesComponent,
        canActivate: [authGuard],
        resolve: { profiles: exportProfilesResolver }
      },
      {
        path: PathResolveService.getExportListPath(),
        component: ExportListComponent,
        canActivate: [authGuard],
        resolve: { exportLists: exportListResolver }
      },
      {
        path: PathResolveService.getOrganizationsManagementPath(),
        component: OrganizationManagementComponent,
        canActivate: [authGuard],
        resolve: {
          allOrganizations: organizationsResolver,
          allUserGroups: userGroupsResolver,
          allUsers: usersResolver,
          allBillingAccounts: billingAccountsResolver
        }
      },
      {
        path: PathResolveService.getLcaDataExcelImportPath(),
        component: LcaDataExcelImportComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getTagsManagementPath(),
        component: TagsManagementComponent,
        canActivate: [authGuard],
        resolve: {
          allTags: allTagsResolver
        }
      },
      {
        path: PathResolveService.getValidatorViewSystems(),
        component: ValidatorViewComponent,
        canActivate: [authGuard],
        resolve: {
          allPackagingParts: allPackagingSystemsResolver
        }
      },
      {
        path: PathResolveService.getValidatorViewUnits(),
        component: ValidatorViewComponent,
        canActivate: [authGuard],
        resolve: {
          allPackagingParts: allPackagingUnitsResolver
        }
      },
      {
        path: PathResolveService.getDirectoryManagementPath(),
        component: DirectoryManagementComponent,
        runGuardsAndResolvers: 'always',
        canActivate: [authGuard],
        data: {
          reloadComponent: true
        },
        resolve: {
          rootDtoDirectory: directoryResolver,
          allTags: allTagsResolver
        }
      },
      {
        path: PathResolveService.getPackagingSystemImportPath(),
        component: ImportPackagingSystemComponent,
        canActivate: [authGuard],
        resolve: {
          rootDtoDirectory: directoryResolver
        }
      },
      {
        path: PathResolveService.getPackagingUnitImportPath(),
        component: ImportPackagingUnitComponent,
        canActivate: [authGuard],
        resolve: {
          rootDtoDirectory: directoryResolver
        }
      },
      {
        path: PathResolveService.getComponentImportPath(),
        component: ImportComponentComponent,
        canActivate: [authGuard],
        resolve: {
          rootDtoDirectory: directoryResolver
        }
      },
      {
        path: PathResolveService.getMaterialImportPath(),
        component: ImportMaterialComponent,
        canActivate: [authGuard],
        resolve: {
          rootDtoDirectory: directoryResolver
        }
      },
      {
        path: PathResolveService.getPerformEvaluationPath(),
        component: PerformAggregateEvaluationsComponent,
        canActivate: [authGuard],
        resolve: {
          allPackagingUnits: allPackagingUnitsResolver,
          allCountries: distributionCountriesResolver
        }
      },
      {
        path: PathResolveService.getViewEvaluationPath(),
        component: ViewAggregateEvaluationsComponent,
        canActivate: [authGuard],
        resolve: {
          allAggregatedRecEvaluations: aggregatedRecyclabilityResolver,
          allAggregatedLcaEvaluations: aggregatedLifecycleResolver
        },
      },
      {
        path: PathResolveService.getReportTemplatesHomePath(),
        component: ReportTemplatesHomeComponent,
        canActivate: [authGuard],
        resolve: {
          packagingUnitReportTemplates: reportTemplatesPackagingUnitResolver,
          componentReportTemplates: reportTemplatesComponentResolver
        },
      },
      {
        path: PathResolveService.getReportsHomePath(),
        component: ReportsHomeComponent,
        canActivate: [authGuard],
        resolve: {
          packagingUnitReports: reportsPackagingUnitResolver,
          componentReports: reportsComponentResolver
        },
      },
      {
        path: PathResolveService.getCreatePackagingUnitReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getUpdatePackagingUnitReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard],
        resolve: {
          reportTemplate: reportTemplatePackagingUnitResolver
        }
      },
      {
        path: PathResolveService.getCreateComponentReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getUpdateComponentReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard],
        resolve: {
          reportTemplate: reportTemplateComponentResolver
        }
      },
      {
        path: PathResolveService.getCreateMaterialReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getUpdateMaterialReportTemplatePath(),
        component: ReportTemplateWizardComponent,
        canActivate: [authGuard]
      },
      {
        path: PathResolveService.getInternationalInfosPath(),
        component: InternationalInfosComponent,
        canActivate: [authGuard],
        resolve: {
          allCountries: allCountriesResolver
        }
      },
      {
        path: PathResolveService.getTempPlasticTaxSystemPath(),
        component: PlasticTaxPackagingSystemComponent,
        canActivate: [authGuard],
        resolve: {
          plasticTaxResult: plasticTaxResolverSystems
        }
      },
      {
        path: PathResolveService.getTempLicenseFeeSystemPath(),
        component: LicenseFeePackagingSystemComponent,
        canActivate: [authGuard],
        resolve: {
          licenseFeeResult: licenseFeeResolverSystems
        }
      },
      {
        path: PathResolveService.getTempPlasticTaxUnitPath(),
        component: PlasticTaxPackagingUnitComponent,
        canActivate: [authGuard],
        resolve: {
          plasticTaxResult: plasticTaxResolverUnits
        }
      },
      {
        path: PathResolveService.getTempLicenseFeeUnitPath(),
        component: LicenseFeePackagingUnitComponent,
        canActivate: [authGuard],
        resolve: {
          licenseFeeResult: licenseFeeResolverUnits
        }
      },
      {
        path: 'packaging',
        component: MaterialRoutingWrapperComponent,
        canActivate: [authGuard],
        data: {
          reloadComponent: true
        },
        children: [
          {
            path: 'packaging-system',
            component: PackagingRoutingWrapperComponent,
            canActivate: [authGuard],
            children: [
              {
                path: '',
                redirectTo: PathResolveService.getPackagingSystemHomepagePath(),
                pathMatch: 'full'
              },
              {
                path: PathResolveService.getPackagingSystemHomepagePath(),
                component: PackagingSystemHomeComponent,
                canActivate: [authGuard],
              },
              {
                path: 'create',
                component: CreateUpdatePackagingSystemComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingSystemComponent,
                resolve: {
                  packagingSystem: packagingSystemResolver,
                  changedSystemIds: changedSystemsResolver,
                  changedUnitIds: changedUnitsResolver,
                  permissions: packagingSystemPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingSystemComponent,
                resolve: {
                  packagingSystem: packagingSystemHistoryResolver,
                  changedSystemIds: changedSystemsResolver,
                  changedUnitIds: changedUnitsResolver,
                  permissions: packagingSystemPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllPackagingSystemPreviewPaths().map(path => ({
                path,
                component: CreateUpdatePackagingSystemComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  packagingSystem: packagingSystemPreviewResolver
                }
              })),
            ],
            resolve: {
              fillingGoods: fillingGoodsResolver,
              fillingGoodCategories: fillingGoodCategoriesResolver,
              fillingGoodTypes: fillingGoodTypesResolver,
              assemblyCountries: assemblyCountriesResolver,
              mainBodySubtypes: mainBodySubtypesResolver,
              closureSubtypes: closureSubtypesResolver,
              decorationSubtypes: decorationSubtypesResolver,
              inlaySubtypes: inlaySubtypesResolver,
              packagingAidSubtypes: packagingAidSubtypesResolver,
              removabilityConditions: removabilityConditionsResolver
            }
          },
          {
            path: 'packaging-unit',
            component: PackagingRoutingWrapperComponent,
            canActivate: [authGuard],
            children: [
              {
                path: '',
                redirectTo: PathResolveService.getPackagingUnitHomepagePath(),
                pathMatch: 'full'
              },
              {
                path: PathResolveService.getPackagingUnitHomepagePath(),
                component: PackagingUnitHomeComponent,
                canActivate: [authGuard],
              },
              {
                path: PathResolveService.getPackagingUnitTypePath(),
                component: PackagingUnitHomeComponent,
                canActivate: [authGuard]
              },
              {
                path: 'create',
                component: CreateUpdatePackagingUnitComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingUnitComponent,
                resolve: {
                  packagingUnit: packagingUnitResolver,
                  changedComponentsIds: changedComponentsResolver,
                  permissions: packagingUnitPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingUnitComponent,
                resolve: {
                  packagingUnit: packagingUnitHistoryResolver,
                  changedComponentsIds: changedComponentsResolver,
                  permissions: packagingUnitPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllPackagingUnitPreviewPaths().map(path => ({
                path,
                component: CreateUpdatePackagingUnitComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  packagingUnit: packagingUnitPreviewResolver
                }
              }))
            ],
            resolve: {
              fillingGoods: fillingGoodsResolver,
              fillingGoodCategories: fillingGoodCategoriesResolver,
              fillingGoodTypes: fillingGoodTypesResolver,
              assemblyCountries: assemblyCountriesResolver,
              mainBodySubtypes: mainBodySubtypesResolver,
              closureSubtypes: closureSubtypesResolver,
              decorationSubtypes: decorationSubtypesResolver,
              inlaySubtypes: inlaySubtypesResolver,
              packagingAidSubtypes: packagingAidSubtypesResolver,
              removabilityConditions: removabilityConditionsResolver
            }
          },
          {
            path: 'component',
            component: ComponentRoutingWrapperComponent,
            canActivate: [authGuard],
            children: [
              {
                path: '',
                redirectTo: PathResolveService.getComponentHomepagePath(),
                pathMatch: 'full'
              },
              {
                path: PathResolveService.getComponentHomepagePath(),
                component: ComponentHomeComponent,
                canActivate: [authGuard]
              },
              {
                path: PathResolveService.getComponentTypePath(),
                component: ComponentHomeComponent,
                canActivate: [authGuard]
              },
              {
                path: 'mainBody/create/:packagingType',
                component: CreateUpdateMainBodyComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'mainBody/create',
                component: CreateUpdateMainBodyComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'mainbody/update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateMainBodyComponent,
                resolve: {
                  component: componentResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'mainbody/update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateMainBodyComponent,
                resolve: {
                  component: componentHistoryResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllComponentPreviewPaths(MAIN_BODY_NAME).map(path => ({
                path,
                component: CreateUpdateMainBodyComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  component: componentPreviewResolver
                }
              })),
              {
                path: 'closure/create',
                component: CreateUpdateClosureComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'closure/update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateClosureComponent,
                resolve: {
                  component: componentResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'closure/update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateClosureComponent,
                resolve: {
                  component: componentHistoryResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllComponentPreviewPaths(CLOSURE_NAME).map(path => ({
                path,
                component: CreateUpdateClosureComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  component: componentPreviewResolver
                }
              })),
              {
                path: 'inlay/create',
                component: CreateUpdateInlayComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'inlay/update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateInlayComponent,
                resolve: {
                  component: componentResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'inlay/update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateInlayComponent,
                resolve: {
                  component: componentHistoryResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllComponentPreviewPaths(INLAY_NAME).map(path => ({
                path,
                component: CreateUpdateInlayComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  component: componentPreviewResolver
                }
              })),
              {
                path: 'decoration/create',
                component: CreateUpdateDecorationComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'decoration/update/:id/:decorationType',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateDecorationComponent,
                resolve: {
                  component: componentResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'decoration/update/:id/:versionId/:decorationType',
                runGuardsAndResolvers: 'always',
                component: CreateUpdateDecorationComponent,
                resolve: {
                  component: componentHistoryResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllDecorationPreviewPaths(DECORATION_NAME).map(path => ({
                path,
                component: CreateUpdateDecorationComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  component: decorationPreviewResolver
                }
              })),
              {
                path: 'packagingAid/create',
                component: CreateUpdatePackagingAidComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'packagingaid/update/:id',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingAidComponent,
                resolve: {
                  component: componentResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'packagingaid/update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CreateUpdatePackagingAidComponent,
                resolve: {
                  component: componentHistoryResolver,
                  changedMaterialIds: changedMaterialsResolver,
                  permissions: componentPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllComponentPreviewPaths(PACKAGING_AID_NAME).map(path => ({
                path,
                component: CreateUpdatePackagingAidComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  component: componentPreviewResolver
                }
              }))
            ],
            resolve: {
              closureSubtypes: closureSubtypesResolver,
              decorationSubtypes: decorationSubtypesResolver,
              inlaySubtypes: inlaySubtypesResolver,
              packagingAidSubtypes: packagingAidSubtypesResolver,
              mainBodySubtypes: mainBodySubtypesResolver
            }
          },
          {
            path: 'material',
            canActivate: [authGuard],
            children: [
              {
                path: '',
                redirectTo: PathResolveService.getMaterialHomepagePath(),
                pathMatch: 'full'
              },
              {
                path: PathResolveService.getMaterialHomepagePath(),
                component: MaterialHomeComponent,
                canActivate: [authGuard],
              },
              {
                path: 'create',
                component: CompositeMaterialComponent,
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id',
                runGuardsAndResolvers: 'always',
                component: CompositeMaterialComponent,
                resolve: {
                  compositeMaterial: compositeMaterialResolver,
                  changedSubmaterialIds: changedSubaterialsResolver,
                  permissions: compositeMaterialPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              {
                path: 'update/:id/:versionId',
                runGuardsAndResolvers: 'always',
                component: CompositeMaterialComponent,
                resolve: {
                  compositeMaterial: compositeMaterialHistoryResolver,
                  changedSubmaterialIds: changedSubaterialsResolver,
                  permissions: compositeMaterialPermissionsResolver
                },
                canDeactivate: [unsavedChangesGuard]
              },
              ...PathResolveService.getAllMaterialPreviewPaths().map(path => ({
                path,
                component: CompositeMaterialComponent,
                canDeactivate: [unsavedChangesGuard],
                resolve: {
                  compositeMaterial: compositeMaterialPreviewResolver
                }
              }))
            ]
          }
        ],
        resolve: {
          manufacturingCountries: manufacturingCountriesResolver,
          distributionCountries: distributionCountriesResolver,
          materialFunctions: materialFunctionsResolver,
          colors: colorsResolver,
          allManifestations: manifestationsResolver,
          rootDirectory: directoryResolver,
          allTags: allTagsResolver
        }
      },
      {
        path: PathResolveService.getRecyclabilityAnalysisHistoryPathUnit(),
        component: AnalysisRecyclabilityComponent,
        canActivate: [authGuard],
        resolve: {
          recyclabilityEvaluationHistoryResults: recyclabilityEvaluationHistoryResolverUnit,
          packagingUnit: packagingUnitHistoryResolver,
          recyclingStreams: recyclingStreamsResolver
        }
      },
      {
        path: PathResolveService.getLifecycleAnalysisHistoryPathUnit(),
        component: AnalysisLifeCycleComponent,
        canActivate: [authGuard],
        resolve: {
          lifeCycleEvaluationHistoryResults: lifeCycleEvaluationHistoryResolverUnit,
          packagingUnit: packagingUnitHistoryResolver
        }
      },
      {
        path: PathResolveService.getCradleToGateLifecycleAnalysisHistoryPathUnit(),
        component: AnalysisCradleToGateLifeCycleComponent,
        canActivate: [authGuard],
        resolve: {
          lifeCycleEvaluationHistoryResults: lifeCycleCradleToGateEvaluationHistoryResolverUnit,
          packagingUnit: packagingUnitHistoryResolver
        }
      },
      {
        path: PathResolveService.getPlasticTaxHistoryPathUnit(),
        component: PlasticTaxPackagingUnitComponent,
        canActivate: [authGuard],
        resolve: {
          packagingUnit: packagingUnitHistoryResolver
        }
      },
      {
        path: PathResolveService.getLicenseFeeHistoryPathUnit(),
        component: LicenseFeePackagingUnitComponent,
        canActivate: [authGuard],
        resolve: {
          packagingUnit: packagingUnitHistoryResolver
        }
      },
      {
        matcher: PathResolveService.comparisonPathMatcherUnits,
        component: ComparePackagingUnitsComponent,
        canActivate: [authGuard],
        resolve: {
          recyclabilityForComparison: recyclabilityComparisonResolverUnit,
          lcaForComparison: lcaComparisonResolverUnit,
          packagingUnits: multiplePackagingUnitsResolver
        }
      },
      {
        path: PathResolveService.getRecyclabilityAnalysisHistoryPathSystem(),
        component: AnalysisRecyclabilityComponent,
        canActivate: [authGuard],
        resolve: {
          recyclabilityEvaluationHistoryResults: recyclabilityEvaluationHistoryResolverSystem,
          packagingSystem: packagingSystemHistoryResolver,
          recyclingStreams: recyclingStreamsResolver
        }
      },
      {
        path: PathResolveService.getLifecycleAnalysisHistoryPathSystem(),
        component: AnalysisLifeCycleComponent,
        canActivate: [authGuard],
        resolve: {
          lifeCycleEvaluationHistoryResults: lifeCycleEvaluationHistoryResolverSystem,
          packagingSystem: packagingSystemHistoryResolver
        }
      },
      {
        path: PathResolveService.getCradleToGateLifecycleAnalysisHistoryPathSystem(),
        component: AnalysisCradleToGateLifeCycleComponent,
        canActivate: [authGuard],
        resolve: {
          lifeCycleEvaluationHistoryResults: lifeCycleCradleToGateEvaluationHistoryResolverSystem,
          packagingSystem: packagingSystemHistoryResolver
        }
      },
      {
        path: PathResolveService.getPlasticTaxHistoryPathSystem(),
        component: PlasticTaxPackagingSystemComponent,
        canActivate: [authGuard],
        resolve: {
          packagingSystem: packagingSystemHistoryResolver
        }
      },
      {
        path: PathResolveService.getLicenseFeeHistoryPathSystem(),
        component: LicenseFeePackagingSystemComponent,
        canActivate: [authGuard],
        resolve: {
          packagingSystem: packagingSystemHistoryResolver
        }
      },
      {
        matcher: PathResolveService.comparisonPathMatcherSystems,
        component: ComparePackagingSystemsComponent,
        canActivate: [authGuard],
        resolve: {
          recyclabilityForComparison: recyclabilityComparisonResolverSystem,
          lcaForComparison: lcaComparisonResolverSystem,
          packagingSystems: multiplePackagingSystemsResolver
        }
      },
      {
        path: PathResolveService.getErrorPath(),
        component: ErrorPageComponent,
        canActivate: [authGuard],
      }
    ],
    resolve: {
      packagingUnitTypes: packagingUnitTypesResolver,
      packagingUnitTypesEng: packagingUnitTypesEngResolver,
      componentTypes: componentTypesResolver,
      componentTypesEng: componentTypesEngResolver,
      decorationTypesEnglish: decorationSubtypesEngResolver,
      allCountries: allCountriesResolver,
      allCountriesEng: allCountriesEngResolver
    }
  },
  {
    path: '**',
    component: ErrorPageComponent,
    canActivate: [authGuard],
    resolve: {
      path: errorPathResolver,
      errorCode: errorCodeResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
