import { PackagingParentDto } from '../packaging-parent-dto';

export class PackagingUnitParentDto extends PackagingParentDto {
    packagingTypeId?: number;
    fillingGoodId?: number;
    fillingGoodCategoryId?: number;
    fillingGoodTypeId?: number;
    packagingQuantity?: number;
    packagingVolume?: number;
}
