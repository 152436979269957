<table mat-table [dataSource]="usersDataSource" id="users-table" #usersTableSort="matSort" matSort>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.email' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
  </ng-container>
  <ng-container matColumnDef="organization">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.organization' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{element.organization.name}} </td>
  </ng-container>

  <ng-container matColumnDef="isPremiumUser">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      IsPremiumUser
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox (change)="onTogglePremiumUser(element, $event.checked)" [checked]="element.isPremiumUser"></mat-checkbox>
      <!-- <button mat-icon-button color="primary" (click)="resetPassword(element)"
        matTooltip="{{ 'dataManagement.userData.texts.changePassword' | translate }}" type="button">
        <mat-icon>lock_open</mat-icon>
      </button> -->
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'common.buttons.action' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="resetPassword(element)"
        matTooltip="{{ 'dataManagement.userData.texts.changePassword' | translate }}" type="button">
        <mat-icon>lock_open</mat-icon>
      </button>
      <button *ngIf="isAdmin" mat-icon-button color="primary" (click)="deleteUser(element)"
        matTooltip="{{ 'dataManagement.userData.texts.delete' | translate }}" type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="usersColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
</table>

<table mat-table *ngIf="deletedUsersDataSource.data.length > 0" [dataSource]="deletedUsersDataSource" id="users-table"
  class="deleted-table" #delUsersTableSort="matSort" matSort>
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="deleted-entry"> {{element.id}} </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.name' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="deleted-entry"> {{element.name}} </td>
  </ng-container>
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.email' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="deleted-entry"> {{element.email}} </td>
  </ng-container>
  <ng-container matColumnDef="organization">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.organization' | translate }}
    </th>
    <td mat-cell *matCellDef="let element" class="deleted-entry"> {{element.organization.name}} </td>
  </ng-container>

  <ng-container matColumnDef="isPremiumUser">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'dataManagement.userData.columns.isPremiumUser' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox (change)="onTogglePremiumUser(element, $event.checked)" [checked]="element.isPremiumUser"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>
      {{ 'common.buttons.action' | translate }}
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="restoreUser(element)"
        matTooltip="{{ 'dataManagement.userData.texts.restore' | translate }}" type="button">
        <mat-icon>restore</mat-icon>
      </button>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="usersColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: usersColumns;"></tr>
</table>
