export class MaterialReportDto { }

export class MaterialLayersReportDto {
  materialFunction = true;
  materialManifestation = true;
  material = true;
  mass = true;
  thickness = true;
  grammage = true;
  density = true;
  recyclingMaterialPercentage = true;
  color = true;
  manufacturingType = true;
}
