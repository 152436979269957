<div class="data-container" *ngIf="!isPartOfMaterialImport || dataSource.data.length > 0; else noMaterialsMessage">
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" id="materials-table">

    <ng-container matColumnDef="tracking">
      <th mat-header-cell *matHeaderCellDef [hidden]="!displayTrackedColumn"></th>
      <td mat-cell *matCellDef="let element" [hidden]="!displayTrackedColumn">
        <mat-icon *ngIf="element.isTracked">track_changes</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="moveLayer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element; let i=index">
        <button (click)="moveMaterialOneDown(element)" mat-icon-button color="primary" [disabled]="!canEditForm"
          type="button" *ngIf="element.isFirstLayerInGroup">
          <mat-icon>arrow_circle_down</mat-icon>
        </button>
        <button (click)="moveMaterialOneUp(element)" mat-icon-button color="primary" [disabled]="!canEditForm"
          type="button" *ngIf="element.isFirstLayerInGroup">
          <mat-icon>arrow_circle_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="isDetectionLayer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'material.layer.detectionLayer' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon *ngIf="element.isDetectionLayer">check</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="functionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'material.layer.function' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.functionName}} </td>
    </ng-container>

    <ng-container matColumnDef="materialManifestationName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'material.layer.manifestation' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.materialManifestationName}} </td>
    </ng-container>

    <ng-container matColumnDef="mass">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'material.layer.mass' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.mass}} </td>
    </ng-container>

    <ng-container matColumnDef="colorName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'material.layer.color' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.colorName}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'common.buttons.action' | translate }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button (click)="editMaterial(element)" color="primary" *ngIf="canEditForm; else viewLayer"
          type="button">
          <mat-icon *ngIf="element.isValidMaterial; else invalidIcon">edit</mat-icon>
          <ng-template #invalidIcon>
            <mat-icon matBadge="!" matBadgeColor="warn" matBadgePosition="before" aria-hidden="false">edit</mat-icon>
          </ng-template>
        </button>
        <ng-template #viewLayer>
          <button *ngIf="element.isValidMaterial" mat-icon-button (click)="editMaterial(element)" color="primary" type="button">
            <mat-icon>preview</mat-icon>
          </button>
        </ng-template>
        <button mat-icon-button (click)="deleteMaterial(element)" color="primary"
          *ngIf="canEditForm && element.isFirstLayerInGroup" type="button">
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-icon-button (click)="breakUpSubmaterial(element)" color="primary"
          *ngIf="canEditForm && element.isFirstLayerInGroup && element.parentMaterialId !== parentCompositeMaterial.id"
          type="button" matTooltip="{{ 'material.tooltips.dissolveMaterial' | translate }}">
          <mat-icon>content_cut</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <div (click)="$event.stopPropagation()">
          <button mat-button class="filter-button" (click)="selectAllMaterials()" type="button">
            {{ 'common.buttons.selectAll' | translate }} <br> ({{selectedMaterials.length}}
            {{ 'common.text.selected' | translate }})
          </button>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="isMaterialSelected(element)" (change)="setMaterialSelected($event.checked, element)"
          [disabled]="isPartOfMaterialImport && element.functionId === shoulderFunctionId"
          [matTooltipDisabled]="!isPartOfMaterialImport || element.functionId !== shoulderFunctionId"
          matTooltip="{{ 'material.tooltips.shoulderDisabled' | translate }}">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngStyle]="{'background-color': row.color}"></tr>
  </table>
</div>

<ng-template #noMaterialsMessage>
  <span class="hint">{{ 'material.messages.noMaterialsMessage' | translate }}</span>
</ng-template>

<div *ngIf="canEditForm && !isPartOfMaterialImport">
  <button mat-raised-button (click)="addMaterial()" color="primary" class="custom-button" type="button">
    <mat-icon>add</mat-icon>
    {{ 'material.addMaterial' | translate }}
  </button>
  <button mat-raised-button (click)="importCompositeMaterial()" color="primary" class="custom-button" type="button">
    <mat-icon>menu_open</mat-icon>
    {{ 'material.importCompositeMaterial' | translate }}
  </button>
  <button mat-raised-button (click)="saveCompositeMaterial()" color="primary" class="custom-button"
    *ngIf="!isIndependentMaterial" type="button">
    <mat-icon>save_alt</mat-icon>
    {{ 'material.saveCompositeMaterial' | translate }}
  </button>
</div>